<template>
  <div class="absolute-full auto-layout flow-col template-container">
    <div style="padding: 5px 5px 5px;">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="区域" prop="area">
          <el-select v-model="search.area" style=" width:80px;">
            <el-option
              v-for="item in [{id: 1, label: '海外'}, {id: 2, label: '国内'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年度" prop="year">
          <el-input-number v-model="search.year"
                           controls-position="right"
                           style=" width:120px;" size="mini" :min="2018" :max="2050">
          </el-input-number>
        </el-form-item>
        <el-form-item label="季度" prop="year">
          <el-input v-model="search.season" style=" width:80px;" size="mini" >
          </el-input>
        </el-form-item>
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择" :clearable="true" style=" width:120px;">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售人员" prop="name">
          <el-select style="width: 120px;" v-model="search.saleUserId" clearable>
            <el-option v-for="item in allSaleServiceUsers" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服人员" prop="csUserId">
          <el-select style="width: 120px;" v-model="search.csUserId" clearable>
            <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel()" class="button" ><i class="el-icon-download"></i>导出excel</a>
        </el-form-item>
      </el-form>
    </div>
    <charts-dualaxis :title="chartTitle" :chartData="chartData"></charts-dualaxis>
    <pagination :total="page.total"
                :start.sync="page.start"
                :limit.sync="page.limit" style="margin-right: 2px; border-left: solid 1px #ddd;">
    </pagination>
    <div class="auto-layout-full" style="position: relative;">
      <el-table
        :data="dataList" v-loading="loading" height="350">
        <el-table-column prop="area" label="区域" width="50px;">
          <template slot-scope="{ row }">
            <span>{{row.area==1?'海外':'国内'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="year" label="年度" width="50px;"></el-table-column>
        <el-table-column prop="season" label="季度" width="50px;"></el-table-column>
        <el-table-column prop="month" label="月份" width="50px;"></el-table-column>
        <el-table-column prop="platformName" label="平台" width="80px;"></el-table-column>
        <el-table-column prop="proxyCompanyName" label="上游代理"></el-table-column>
        <el-table-column prop="contractSubjectName" label="签约主休"></el-table-column>
        <el-table-column prop="adCustomerName" label="客户名称"></el-table-column>
        <el-table-column prop="rebateStrategyCategory" label="分类名"></el-table-column>
        <el-table-column prop="cost" :label="costLabel"></el-table-column>
        <el-table-column prop="proxyRebateProfit" label="预估代理返点">
          <template slot-scope="{ row }">
            {{formatCurrency(row.upperCurrency)+row.proxyRebateProfit.toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column prop="customerRebateProfit" label="预估客户返点">
          <template slot-scope="{ row }">
            {{formatCurrency(row.lowerCurrency)+row.customerRebateProfit.toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column prop="rebateProfit" label="返点毛利">
          <template slot-scope="{ row }">
            {{formatCurrency(row.lowerCurrency)+row.rebateProfit.toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column prop="rateMargin" label="毛利率">
          <template slot-scope="{ row }">
            {{row.rateMargin.toFixed(2) +'%'}}
          </template>
        </el-table-column>
        <el-table-column prop="csUserName" label="客服人员"></el-table-column>
        <el-table-column prop="saleUserName" label="销售人员"></el-table-column>
      </el-table>
  </div>
  </div>
</template>

<script>
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getSaleServiceUserList } from '../../../../http/api/upper-lower-policy'
  import {
    getGrossProfitDetailDownloadTotalEexcel,
    getGrossProfitDetailList
  } from '../../../../http/api/greoss-profit-report'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import ChartsDualaxis from '../../charts-dual-axis'
  import { getCustomerServiceUserList } from '../../../../http/api/cs-customer'

  export default {
    components: { ChartsDualaxis },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          type: 1,
          area: 1,
          year: new Date().getFullYear(),
          season: null,
          platformId: null,
          adCustomerId: null,
          saleUserId: null,
          csUserId: null,
          downloadType: 2 // 明细
        },
        platforms: [],
        adCustomers: [],
        allSaleServiceUsers: [],
        allCustomerServiceUsers: [],
        loading: false,
        chartTitle: '预估毛利明细',
        chartData: {
          xAxis: [],
          yAxis: {
            data1: [],
            data2: []
          }
        },
        costLabel: '预估消耗点数'
      }
    },
    computed: {},
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      getSaleServiceUserList()
        .success(resp => {
          this.allSaleServiceUsers = resp.data
        })
        .send()
      getCustomerServiceUserList()
        .success(resp => {
          this.allCustomerServiceUsers = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        params.type = '1'
        this.loading = true
        getGrossProfitDetailList()
          .complete(() => (this.loading = false))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
            this.chartData.xAxis = []
            this.chartData.yAxis.data1 = []
            this.chartData.yAxis.data2 = []
            for (let obj of resp.data.records) {
              this.chartData.xAxis.push(obj.adCustomerName)
              this.chartData.yAxis.data1.push(obj.rebateProfit)
              this.chartData.yAxis.data2.push(obj.rateMargin)
            }
            if (resp.data.records.length > 0) {
              if (resp.data.records[0].area === 1) {
                this.costLabel = '预估消耗点数'
              } else {
                this.costLabel = '预估充值金额'
              }
            }
          })
          .send(params)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      formatCurrency (v) {
        if (v === 'USD') return '$'
        if (v === 'CNY') return '￥'
        if (v === 'HKD') return 'HK$'
      },
      downloadExcel () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        params.type = '1'
        return getGrossProfitDetailDownloadTotalEexcel(params)
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .template-container @{deep} .el-table {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
  }

  .template-container @{deep} .el-table__body-wrapper {
    flex: 1;
    overflow-y: auto !important;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
    margin-left: 5px;
  }
  </style>
