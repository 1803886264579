import Absents from '../../pages/absents'
import { FLOW_ABSENT } from '../../config/flows'
import { createWorkflowDetailRouters } from './workflow'

/**
 * 请假申请单路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'AbsentWorkflowDetail', params: {flowId: id}})
 */

export default [
  {
    name: 'AbsentQuery',
    path: '/absents',
    meta: { title: '请假申请单' },
    component: Absents,
    children: [
      createWorkflowDetailRouters('flowId', FLOW_ABSENT)
    ]
  }
]
