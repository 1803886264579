export default class Dom {
  constructor (el) {
    if (typeof el === 'string') {
      this.element = document.createElement(el)
    } else {
      this.element = el
    }
  }

  /**
   * @param type {String}
   * @param callback {Function}
   * @param [context] {Object]
   * @return {Dom}
   */
  on (type, callback, context) {
    var events = this.element._events || {}
    events[type] = function () {
      callback.apply(context, arguments)
    }
    this.element.addEventListener(type, events[type])
    this.element._events = events
    return this
  }

  /**
   * @param type {String}
   * @return {Dom}
   */
  off (type) {
    var events = this.element._events || {}
    var handler = events[type]
    if (handler) {
      this.element.removeEventListener(type, handler)
    }
    return this
  }

  _fireEvent (node, eventName) {
    // https://stackoverflow.com/questions/2381572/how-can-i-trigger-a-javascript-event-click
    var doc
    if (node.ownerDocument) {
      doc = node.ownerDocument
    } else if (node.nodeType === 9) {
      doc = node
    } else {
      throw new Error('Invalid node passed to fireEvent: ' + node.id)
    }

    if (node.dispatchEvent) {
      var eventClass = ''

      switch (eventName) {
        case 'click':
        case 'mousedown':
        case 'mouseup':
          eventClass = 'MouseEvents'
          break

        case 'focus':
        case 'change':
        case 'blur':
        case 'select':
          eventClass = 'HTMLEvents'
          break
        default:
          throw new Error("fireEvent: Couldn't find an event class for event '" + eventName + "'.")
      }
      var event = doc.createEvent(eventClass)
      event.initEvent(eventName, true, true)

      event.synthetic = true
      node.dispatchEvent(event, true)
    } else if (node.fireEvent) {
      let event = doc.createEventObject()
      event.synthetic = true
      node.fireEvent('on' + eventName, event)
    }
  }

  /**
   * 触发事件。
   * @param eventName
   * @param [delay] {int} 延迟触发，单位ms
   */
  trigger (eventName, delay) {
    if (delay) {
      setTimeout(() => {
        this._fireEvent(this.element, eventName)
      }, delay)
    } else {
      this._fireEvent(this.element, eventName)
    }
  }

  /**
   * @param name {String | Object}
   * @param value {String}
   * @return {Dom}
   */
  css (name, value) {
    var obj = {}
    if (typeof name === 'string') {
      obj[name] = value
    } else {
      obj = name
    }
    for (var key of Object.keys(obj)) {
      this.element.style[key] = obj[key]
    }
    return this
  }

  /**
   * @return {Dom}
   */
  remove () {
    var events = this.element._events || {}
    for (var type of Object.keys(events)) {
      this.element.removeEventListener(type, events[type])
    }
    this.element.remove()
    return this
  }

  /**
   * @param parentElement {Element}
   * @return {Dom}
   */
  appendTo (parentElement) {
    parentElement.appendChild(this.element)
    return this
  }
}
