<template>
  <el-dialog title="模版属性选择"
             :visible.sync="visible"
             @close="handleDialogClose"
             :modal-append-to-body='false'
             width="80%"
  >

    <div class="wrapper-body">
      <div class="templates">
        <div class="category-item"
             :class="{selected: t.selected}"
             @click="handleCategoryClick(t)"
             v-for="t in templates"
             :key="t.id">
          <div class="info">
            <div>{{t.name}}</div>
          </div>
        </div>
      </div>
      <div class="props">
        <el-table :data="props" @selection-change="handleTableSelectChange" height="300px">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="label" label="属性名称" width="150"></el-table-column>
          <el-table-column property="typeStr" label="属性类型" width="200"></el-table-column>
          <el-table-column property="groups" label="组别"></el-table-column>
          <el-table-column property="typeData" label="额外数据"></el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getProfileTemplateList, getProfileTemplatePropsList } from '@/http/api/profile-template'

  export default {
    data () {
      return {
        visible: false,
        templates: [],
        props: [],
        selectedProps: []
      }
    },
    methods: {
      open () {
        this.visible = true
        if (this.templates.length === 0) {
          getProfileTemplateList()
            .success(resp => {
              let first = null
              for (let item of resp.data) {
                item.selected = false
                if (first == null) {
                  first = item
                }
              }
              this.templates = resp.data
              if (first) {
                this.handleCategoryClick(first)
              }
            })
            .send()
        }
      },
      handleDialogClose () {
        this.selectedProps = []
      },
      handleCategoryClick (template) {
        let selectedCategory = this.templates.find(d => d.selected)
        if (selectedCategory === template) {
          template.selected = !template.selected
          selectedCategory = null
        } else {
          if (selectedCategory) {
            selectedCategory.selected = false
          }
          template.selected = true
          selectedCategory = template
        }

        this.props = []
        this.selectedProps = []
        if (selectedCategory) {
          getProfileTemplatePropsList()
            .success(resp => {
              this.props = resp.data
            })
            .send(selectedCategory.id)
        }
      },
      handleTableSelectChange (data) {
        this.selectedProps = data
      },
      handleOk () {
        let selected = this.selectedProps
        this.$emit('selected', selected)
        this.visible = false
      }
    }
  }
</script>

<style lang="less" scoped>
  .wrapper-body {
    display: flex;
    flex-direction: row;
    border: solid 1px #e9e9e9;
    border-radius: 3px;
    height: 300px;
  }
  //.panel-toolbar {
  //  padding: 0 8px;
  //  height: 40px;
  //  line-height: 40px;
  //  border-bottom: solid 1px #ddd;
  //  background-color: #f9f9f9;
  //}
  @categoryContainerWidth: 250px;
  .templates {
    width: 200px;
    overflow-y: auto;
    border-right: solid 1px #e9e9e9;
    padding: 10px 0;
    .category-item {
      padding: 8px 5px;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
      &.selected {
        background-color: #dedede;
        .info {
          color: #3a3a3a !important;
          font-weight: bold;
        }
      }
    }
  }
  .props {
    flex: 1;
  }
</style>
