<template>
  <el-card>
    <div slot="header">
      <i class="fas fa-bell-slash"></i>
      公告
    </div>
    <div class="text-muted font-mini">
      无公告
    </div>
  </el-card>
</template>

<script>
  export default {
  }
</script>

<style scoped>

</style>
