<!--
  流程中处理阶段的细节，用户选择了阶段的处理条件后进入到本页面，可输入一些工作描述，表单，上传附件等操作。
  查询参数：
    conditionId 处理条件ID，如果是表达式条件则不用传该参数。
-->
<template>
  <page :route-name="getRouteName('ProcessStage')" :page-title="pageTitle" v-on:pageReady="onPageReady">

    <div class="container" slot="center">
      <processed-stage-list ref="processedStageList"></processed-stage-list>
      <div class="tag-wrapper">
        <el-tabs class="process-stage-tags" v-model="activeTab" v-if="buildTabs">
          <!--表单-->
          <el-tab-pane v-if="!!submitObject" :label="formTabTitle" name="form">
            <submit-form
                ref="form"
                :flow-type="getFlowType"
                :flow-id="flowId"
                :submit="submitObject"
                :stage="stageId"
                v-on:adPolicyChanged="adPolicyFormData">
            </submit-form>
            <UpPolicyTable
              :flow-id="flowId"
              :form-data="adPolicyFormDataObject"
              v-if="getFlowType==='AdPolicy' && stageId=='cooperate_policy'"
              >
            </UpPolicyTable>
          </el-tab-pane>
          <!--附件-->
          <el-tab-pane v-if="!!attachmentConfig" name="attachment">
          <span slot="label">
            {{attachmentTitle}}
            <!-- element-ui无法检测标签页的变化，暂时放弃此功能 -->
            <!--<span class="badge">{{attachmentTotal}}</span>-->
          </span>
            <attachment
                :flow-id="flowId"
                :flow-type="getFlowType"
                :flow-id-name="flowIdName"
                :condition-id="conditionId"
                :stage="stageId"
                :attachment-config="attachmentConfig"
                :get-form="() => this.$refs.form ? this.$refs.form.getForm() : null"
                v-on:fileTotalChanged="onAttachmentTotalChanged">
            </attachment>
          </el-tab-pane>
          <!--描述-->
          <el-tab-pane label="描述" name="description">
            <textarea id="workflow-stage-description" style="display: none"></textarea>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="bottom-bar" slot="bottom">
      <transition name="slideUp">
        <div class="user-select" v-show="userSelectVisible" style="animation-duration: .2s">
          <div class="header">
            {{userSelectTitle}}
          </div>
          <div class="body">
            <div class="role" v-for="role in userSelectList" :key="role.alias">
              <div class="role-name">
                {{role.title}}
                <span class="font-mini text-muted"
                      style="font-weight: normal"
                      v-if="role.countRange"
                >
                  （人数限制：{{role.countRange.description}}）
                </span>
              </div>
              <div class="user" v-for="user in role.users" :key="user.id" @click="handleUserSelect(role, user)">
                <i class="el-icon-check selected-icon" :class="{hidden: user.selected !== true}"></i>
                {{user.name}}
              </div>
            </div>
          </div>
          <div class="footer">
            <el-row :gutter="10">
              <el-col :span="12"><el-button type="primary" style="width: 100%;" @click="submit" :loading="submitting">确定提交</el-button></el-col>
              <el-col :span="12"><el-button style="width: 100%;" @click="closeUserSelectPanel" :loading="submitting">取消</el-button></el-col>
            </el-row>
          </div>
        </div>
      </transition>
      <div class="submit-button" :class="{submitting: submitting}" @click="beforeSubmit">
        <i :class="{'el-icon-circle-check-outline': !submitting, 'el-icon-loading': submitting}"></i>
        {{submitting ? '正在提交...' : '提交处理'}}
      </div>
    </div>

    <image-uploader :rich-editor-id="editorId"></image-uploader>
  </page>
</template>

<script>
  import mixins from '../mixins'
  import RichEditor from '../../../../../components/rich-editor'
  import ImageUploader from '../../../../../components-business/rich-editor-image-uploader'
  import SubmitForm from './form'
  import Attachment from './attachment'
  import kit from '../../../../../tools/kit'
  import { getNextStageInfo, processNextStage } from '../../../../../http/api/stage'
  import ProcessedStageList from './processed-stage-list'
  import UpPolicyTable from '../../../../policy/upper/comp/up-policy-table'

  const STORAGE_KEY = 'workflow_draft'
  export default {
    mixins: [mixins],
    components: { SubmitForm, Attachment, ImageUploader, ProcessedStageList, UpPolicyTable },
    data () {
      return {
        submitting: false,
        conditionId: this.$route.query['conditionId'],
        buildTabs: false,
        activeTab: null,
        formTabTitle: null,
        submitObject: null,
        attachmentTitle: null,
        attachmentConfig: null,
        stageId: null,
        editorId: 'workflow-stage-description',
        editor: null,
        attachmentTotal: 0,
        pageTitle: null,
        disableSaveDraft: false, // 提交数据后将此值设置为true，提交成功后不保存草稿
        userSelectList: [],
        userSelectVisible: false,
        userSelectTitle: null,
        adPolicyFormDataObject: {}
      }
    },
    watch: {
      activeTab (v) {
        // 标签切换到富文本编辑器时，重新绘制编辑器的大小以最大化
        if (v === 'description' && this.editor) {
          this.$nextTick(() => {
            let dom = document.getElementById('pane-description')
            this.editor.get().resize(dom.offsetWidth, dom.offsetHeight)
          })
        }
      }
    },
    computed: {
      selectedUsers () {
        let tmp = []
        this.userSelectList.forEach(r => {
          let selectedUserIds = []
          r.users.forEach(u => {
            if (u.selected) {
              selectedUserIds.push(u.id)
            }
          })
          tmp.push(r.alias + ':' + selectedUserIds.join(','))
        })
        return tmp.join('|')
      }
    },
    methods: {
      onAttachmentTotalChanged (total) {
        this.attachmentTotal = total
      },
      onPageReady () {
        this.$pageLoading(true, '正在加载配置...')
        getNextStageInfo()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            let data = resp.data
            if (data.conditionTitle) {
              this.pageTitle = `${data.title}（${data.conditionTitle}）`
            } else {
              this.pageTitle = data.title
            }
            this.stageId = data.stageId
            let tab = 'description'
            if (data.attachment) {
              this.attachmentTitle = data.attachment.title || '附件上传'
              this.attachmentConfig = data.attachment
              tab = 'attachment'
            }
            if (data.submit) {
              this.formTabTitle = data.submit.title || '表单提交'
              this.submitObject = data.submit
              this.submitObject.fieldGroups = data.fieldGroups
              tab = 'form'
            }
            this.buildTabs = true
            this.activeTab = tab

            this.$nextTick(() => {
              // 读取草稿
              this.clearDraft()
              let draft = this.getDraft(this.flowId + this.stageId)
              let descriptionContent = ''
              if (draft) {
                if (draft.form) {
                  this.$refs.form.setForm(draft.form)
                }
                if (draft.description) {
                  descriptionContent = draft.description
                }
              }
              this.editor = new RichEditor(this.editorId, { content: descriptionContent })
            })

            this.$refs.processedStageList.loadStages(this.flowId)
          })
          .send(this.flowId, this.$route.query['conditionId'])
      },
      beforeSubmit () {
        this.submit()
      },
      submit () {
        if (this.submitting) return
        if (this.editor === undefined) {
          return this.$alert('编辑器未初始化，请刷新页面。')
        }
        this.$confirm(`提交后无法再更改，确定要提交吗？`, '确认', { type: 'warning' })
          .then(() => {
            this.submitting = true

            processNextStage()
              .complete(() => (this.submitting = false))
              .success(resp => {
                if (resp.data.success) {
                  this.$message.success('处理成功。')
                  this.$dispatcher.dispatch('flow_stage_submit', this.flowId)
                  this.$dispatcher.dispatch('FlowUpdate', this.flowId)
                  this.disableSaveDraft = true
                  this.clearDraft(this.flowId + this.stageId)
                  this.closeUserSelectPanel()
                  this.toFlowDetailPage(true)
                } else {
                  for (let r of resp.data.selectUsers) {
                    for (let u of r.users) {
                      u.selected = false
                    }
                  }
                  this.userSelectList = resp.data.selectUsers
                  this.userSelectTitle = resp.data.selectUserDescription
                  this.userSelectVisible = true
                }
              })
              .error(resp => {
                let msg = resp.message
                let status = resp.code
                if (status === 901) {
                  let content = ''
                  for (let item of resp.details) {
                    content += `<p style="font-size: 12px;"><label style="font-weight: bold; padding-right: 8px;">${item.label}：</label><span>${item.message}</span></p>`
                  }
                  content = `<div style="max-height: 400px; overflow: auto;">${content}</div>`
                  this.$alert(content, '表单数据无效', {
                    dangerouslyUseHTMLString: true,
                    type: 'error'
                  })
                } else {
                  this.$message.error(msg + '')
                }
              })
              .send(
                this.flowId,
                this.stageId,
                this.conditionId,
                this.editor.getContent(),
                this.$refs.form ? JSON.stringify(this.$refs.form.getForm()) : null,
                this.selectedUsers
              )
          })
          .catch(() => {})
      },
      handleUserSelect (role, user) {
        if (!user.selected) {
          let selectedUser = role.users.filter(u => u.selected)
          let range = role.countRange
          if (selectedUser.length >= range.max) {
            selectedUser[0].selected = false
          }
          user.selected = true
        } else {
          user.selected = !user.selected
        }
      },
      getDraft (id) {
        let tmp = localStorage.getItem(STORAGE_KEY)
        let root
        try {
          root = JSON.parse(tmp)
        } catch (e) {
          root = {}
        }
        root = root || {}
        return id ? root[id] : root
      },
      /**
       * 将表单和描述保存为草稿。
       * @param id flowId + stageId 组合
       * @param form 表单
       * @param descriptionContent 描述编辑器的内容
       */
      saveAsDraft (id, form, descriptionContent) {
        let draft = this.getDraft()
        draft[id] = {
          expire: new Date().getTime() + 1000 * 60 * 60 * 24 * 2, // 两天后过期
          form: form,
          description: descriptionContent
        }
        localStorage.setItem(STORAGE_KEY, JSON.stringify(draft))
      },
      /**
       * 清除草稿缓存，如果提供了id表示只清除这个id的草稿，否则清除所有过期的草稿。
       * @param [id] flowId + stageId组合
       */
      clearDraft (id) {
        let draft = this.getDraft()
        if (id) {
          delete draft[id]
        } else {
          let removeKeys = []
          let now = new Date().getTime()
          for (let key of Object.keys(draft)) {
            let obj = draft[key]
            if (obj && obj.expire < now) {
              removeKeys.push(key)
            }
          }
          for (let key of removeKeys) {
            delete draft[key]
          }
        }
        localStorage.setItem(STORAGE_KEY, JSON.stringify(draft))
      },
      closeUserSelectPanel () {
        this.userSelectVisible = false
      },
      adPolicyFormData (formData) {
        if (this.getFlowType === 'AdPolicy' && this.stageId === 'cooperate_policy') {
          this.adPolicyFormDataObject = formData
        }
      }
    },
    beforeDestroy () {
      this.editor && this.editor.destroy()
    },
    beforeRouteLeave (to, from, next) {
      if (this.disableSaveDraft) {
        return next()
      }
      let form = null
      let count = 0
      if (this.$refs.form) {
        form = this.$refs.form.getForm()
        for (let name of Object.keys(form)) {
          if (form[name] != null && form[name] !== undefined) {
            if (Array.isArray(form[name])) {
              if (form[name].length > 0) {
                count++
              }
            } else {
              count++
            }
          }
        }
      }
      let descriptionContent = null
      if (this.editor) {
        descriptionContent = this.editor.getContent()
      }

      if (count > 0 || !kit.str.isBlank(descriptionContent)) {
        this.$confirm('是否将表单和描述保存为草稿（2天后过期）？', '确认', {
          type: 'info',
          cancelButtonText: '不保存',
          confirmButtonText: '保存'
        })
          .then(() => {
            try {
              this.saveAsDraft(this.flowId + this.stageId, form, descriptionContent)
              this.$message.success('已存储为草稿。')
              next()
            } catch (e) {
              this.$alert('草稿保存失败：' + e.message)
            }
          })
          .catch(() => {
            next()
          })
      } else {
        next()
      }
    }
  }
</script>

<style lang="less" scoped>

  @deep: ~'>>>';
  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    overflow-y: hidden;
  }

  .tag-wrapper {
    flex: 1;
    position: relative;
  }

  // 富文本、福建、表单等标签页
  .process-stage-tags {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    flex: 1;
    display: flex;
    flex-flow: column;
  }
  .process-stage-tags @{deep} .el-tabs__content {
    display: flex;
    flex-flow: column;
    flex: 1;
    .el-tab-pane {
      flex: 1;
      height: 100vh;
      overflow-y: auto;
    }
  }
  .process-stage-tags @{deep} .badge {
    padding: 2px 7px;
    color: #fff;
    background-color: #ff5142;
    font-size: 12px;
    border-radius: 10px;
  }


  //.process-stage-body {
  //  //position: absolute;
  //  //left: 0;
  //  //top: 0;
  //  //right: 0;
  //  //bottom: 0;
  //  display: flex;
  //}

  //.attachment-container {
  //  width: 350px;
  //  margin: 10px 10px 0 10px;
  //}

  .bottom-bar {
    @barHeight: 40px;
    position: relative;
    height: @barHeight;
    line-height: @barHeight;
    padding-left: 10px;
    border-top: solid 1px #ddd;
    .submit-button {
      text-align: center;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      color: #434343;
      &:not(.submitting):hover {
        background-color: #f9f9f9;
      }
      &.submitting {
        cursor: not-allowed;
        color: #b5b5b5;
      }
    }
    .user-select {
      @height: 400px;
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -200px;
      width: 400px;
      height: @height;
      padding: 0;
      z-index: 1;
      line-height: normal;
      box-shadow: 0 0 5px rgba(0,0,0,.3);
      border-radius: 5px;
      background-color: #fff;
      .header {
        padding: 5px 10px;
        background-color: #f9f9f9;
        border-bottom: solid 1px #e6e6e6;
        font-size: 12px;
        color: #b53639;
      }
      .body {
        flex: 1;
        overflow: auto;
        padding: 10px 0;
        .role + .role {
          margin-top: 10px;
        }
        .role-name {
          font-weight: bold;
          padding-left: 10px;
        }
        .user {
          padding: 5px 10px 5px 30px;
          font-size: 13px;
          color: #676767;
          cursor: pointer;
          .selected-icon {
            color: #45874d;
            &.hidden {
              visibility: hidden;
            }
          }
          &:hover {
            background-color: #f2f2f2;
          }
        }
      }
      .footer {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-top: solid 1px #e6e6e6;
      }
    }
  }

</style>

<style lang="less">

  //.process-stage-tags {
  //  //position: absolute;
  //  //left: 20px;
  //  //top: 0;
  //  //right: 20px;
  //  //bottom: 10px;
  //  flex: 1;
  //  display: flex;
  //  flex-flow: column;
  //  .el-tabs__content {
  //    display: flex;
  //    flex-flow: column;
  //    flex: 1;
  //    .el-tab-pane {
  //      flex: 1;
  //      height: 100vh;
  //      overflow-y: auto;
  //    }
  //  }
  //  .badge {
  //    padding: 2px 7px;
  //    color: #fff;
  //    background-color: #ff5142;
  //    font-size: 12px;
  //    border-radius: 10px;
  //  }
  //}
</style>
