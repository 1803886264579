/*
  广告平台账号授权工具。
  platform可选值：google, facebook, bing
  AuthWin.open(platform, (success, acc) => {
    // success === true, acc是一个账号数据对象
    // success === false，acc是一个错误消息字符串
    // 此回调函数只有在授权流程完成，导航回来时才会触发，用户自己关闭窗口或执行close()函数时是不会触发的
  })
  注意，如果你在vue组件中打开了窗口，那么在destroy钩子函数中应该调用AuthWin.close()来关闭窗口（不管窗口打不打开，最好都执行一下关闭）。

  广告平台导向回我们的服务端时，服务端可以返回一段javascript代码来执行回调函数并提供相应的acc数据。
  // java demo
  String str = "window.opener['_AdsBindingCallback_'](true, {...})";
  response.setContentType("text/html");
  response.getWriter().println(str);
  // str里面有两个参数需要你拼接，这两个参数就是上面的success和acc。
 */
import api, { URL_ADS_AUTH } from '../config/urls'
import { userStore } from '../store'

export default {
  open: function (platform, closeCallback) {
    if (this.isOpened()) {
      return this.openWin.focus()
    }

    let _this = this
    window['_AdsBindingCallback_'] = function (success, data) {
      console && console.log('_AdsBindingCallback_ result: ' + success)
      console && console.log(data)
      closeCallback && closeCallback(success, data)
      _this.close()
    }

    // 窗口设置
    let winWidth = 600
    let winHeight = 600
    let winTop = (window.screen.availHeight - 30 - winHeight) / 2
    let winLeft = (window.screen.availWidth - 30 - winWidth) / 2
    this.openWin = window.open(
      api(URL_ADS_AUTH, { platform: platform }) + '?_token=' + userStore.state.token,
      '_blank',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no,
          width=${winWidth}, height=${winHeight}, left=${winLeft}, top=${winTop}`
    )
  },
  onClose: function () {
    this.openWin = null
  },
  close: function () {
    if (this.isOpened()) {
      this.openWin.close()
    }
  },
  isOpened: function () {
    if (this.openWin) {
      return !this.openWin.closed
    } else {
      return false
    }
  }
}
