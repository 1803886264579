<!--
  流程图渲染组件，基于gojs引擎。
-->
<template>
  <transition name="fade">
    <div class="diagram-container" v-show="show">
      <div class="title">
        {{title}}
        <i class="el-icon-close close" @click="show = false"></i>
      </div>
      <div :id="id" class="flow-diagram"></div>
    </div>
  </transition>
</template>

<script>
  import go from 'gojs'
  import kit from '../../../tools/kit'
  import { getFlowDiagram } from '../../../http/api/workflow'
  let _ = go.GraphObject.make

  export default {
    data () {
      return {
        id: kit.str.id(8),
        title: null,
        show: false
      }
    },
    mounted () {
      let diagram = _(go.Diagram, this.id, {
        initialContentAlignment: go.Spot.Center,
        layout: _(go.LayeredDigraphLayout, { direction: 90, layerSpacing: 30, columnSpacing: 50 })
      })
      // 开始和结束模板
      let seTemplate = _(
        go.Node, 'Auto',
        _(go.Shape, 'Ellipse', { fill: '#57955d' }),
        _(go.TextBlock, { margin: 10, stroke: '#fff' }, new go.Binding('text', 'title'))
      )
      // 阶段模板
      let stageTemplate = _(
        go.Node,
        'Auto',
        _(go.Shape, { figure: 'Rectangle', fill: '#555555' }),
        _(go.TextBlock, { margin: new go.Margin(10, 15), stroke: '#fff', width: 100, wrap: go.TextBlock.WrapFit }, new go.Binding('text', 'title'))
      )
      // 条件模板
      let conditionTemplate = _(
        go.Node,
        'Auto',
        _(go.Shape, { figure: 'Diamond', fill: '#f2f2f2' }),
        _(go.TextBlock, { margin: new go.Margin(5, 10), stroke: '#737373' }, new go.Binding('text', 'title'))
      )
      var templateMap = new go.Map('string', go.Node)
      templateMap.add('', stageTemplate)
      templateMap.add('condition', conditionTemplate)
      templateMap.add('se', seTemplate)
      diagram.nodeTemplateMap = templateMap

      // 连接线
      diagram.linkTemplate = _(
        go.Link,
        { routing: go.Link.Orthogonal },
        _(go.Shape),
        _(go.Shape, { toArrow: 'Standard' }),
        _(go.TextBlock, { stroke: '#909090', font: '12px sans-serif', width: 100, wrap: go.TextBlock.WrapFit }, new go.Binding('text', 'title'))
      )
      this.diagram = diagram
    },
    methods: {
      render (data) {
        this.show = true
        this.title = data['title']
        this.diagram.model = new go.GraphLinksModel(data['models'], data['links'])
      },
      open (flowType, version) {
        getFlowDiagram()
          .success(resp => {
            this.render(resp.data)
          })
          .send(flowType, version)
      }
    }
  }
</script>

<style scoped>
  .diagram-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0,0,0,.3);
  }

  .title {
    position: relative;
    margin: 10px auto;
    padding: 10px;
    width: 500px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #fff;
  }

  .flow-diagram {
    position: absolute;
    left: 100px;
    right: 100px;
    top: 70px;
    bottom: 20px;
    background-color: #fff;
  }

  .close {
    position: absolute;
    top: 3px;
    right: -40px;
    padding: 10px;
    background-color: #ce4533;
    color: #fff;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
  }
</style>
