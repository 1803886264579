/*
  如果你的页面组件需要用到分页组件时，可以使用本配置混合到组件中，你的组件必须提供一个load函数来加载数据。

  用例：

  import PaginationMixins from ...
  // 模板
  <template>
    <div>
      <!--混合配置中提供了一个reload函数来重新加载你的数据-->
      <el-button @click="reload"></el-button>
      <!--你的表格组件-->
      <el-table>...</el-table>
      <!--全局的分页组件-->
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
  </template>
  // 代码
  export default {
    mixins: [PaginationMixins],
    methods: {
      load () {
        // 加载数据
        // 请求时请的将this.page.start和this.page.limit参数发给服务端
        // 然后在请求成功时，将服务端返回的记录总数赋予给this.page.total属性
      }
    }
  }
 */
export default {
  data () {
    return {
      page: {
        total: 0,
        start: 0,
        limit: this.$shareProps.pageLimit
      }
    }
  },
  watch: {
    'page.start' () {
      this.load()
    },
    'page.limit' () {
      this.reload()
    }
  },
  methods: {
    reload () {
      if (this.page.start === 0) {
        this.load()
      } else {
        this.page.start = 0
      }
    }
  }
}
