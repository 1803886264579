<!--
  考核客户
-->
<template>
  <page route-name="KpiUser">
    <el-button slot="toolbar" icon="el-icon-plus"  type="primary" @click="$refs['kpiUserPanel'].open()">创建</el-button>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="名称查询">
          <el-input v-model="query.name" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      stripe
      :data="dataList">
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-delete" type="danger" @click="deleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="姓名" width="200"></el-table-column>
      <el-table-column prop="titleStr" label="职位" ></el-table-column>
    </el-table>
    <!--编辑-->
    <kpi-user-editor ref="kpiUserPanel" @submit="onEditorSubmit"></kpi-user-editor>
  </page>
</template>

<script>
  import KpiUserEditor from './comp/editor'
  import api, { API_KPI_USERS, API_KPI_USER_DELETE } from '../../../config/urls'
  import kit from '@/tools/kit'
  export default {
    components: { KpiUserEditor },
    data () {
      return {
        dataList: [],
        query: {
          name: null
        }
      }
    },
    computed: {},
    methods: {
      load () {
        this.$http.get(api(API_KPI_USERS), {
          params: {
            name: this.query.name
          },
          success: resp => {
            this.dataList = resp
          }
        })
      },
      onEditorSubmit (data) {
        this.load()
        // kit.arr.pushOrReplace(this.dataList, data)
      },
      deleteData (data) {
        this.$confirm(`确定要删除[${data.userId}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            this.$http.del(api(API_KPI_USER_DELETE, { id: data.id }), {
              completeBefore: () => this.$pageLoading(false),
              success: () => {
                this.$message.success('删除成功。')
                kit.arr.remove(this.dataList, data)
              }
            })
          })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
