<template>
  <page route-name="BgOrder">
    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button type="primary"  @click="handleProcessSelectedOrders()">处理已选择订单</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExportToExcel">导出Excel</el-button>
      </el-form-item>
      <el-form-item>
        <el-tooltip content="释放订单后，订单会回到待处理列表，其他人可以继续选择该订单操作。" placement="bottom">
          <el-button type="warning"  @click="releaseOrder()">释放订单</el-button>
        </el-tooltip>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$refs.screenshotZipUpload.open()">批量上传截图</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success"  @click="sendOrderBang()">提交订单到棒谷</el-button>
      </el-form-item>
<!--      <el-form-item>-->
<!--        <el-button @click="$router.push({ name: 'ProcessingOrder' })">查看正在处理的订单</el-button>-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--        <el-button @click="$router.push({ name: 'SuccessOrder' })">查看已提交订单</el-button>-->
<!--      </el-form-item>-->
    </el-form>
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="订单日期">
          <el-date-picker
            label="日期范围"
            style="width: 250px;"
            v-model="search.times"
            type="datetimerange"
            align="right"
            :unlink-panels="true"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['12:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付类型" prop="recieve">
          <el-select v-model="search.tradeType" :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in tradeTypes"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人">
          <el-select v-model="search.processUserId" :clearable="true" style="width: 100px;">
            <el-option
                v-for="item in processUserOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="search.processStatus" multiple style="width: 300px;">
            <el-option
                v-for="item in processStatusOption"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务订单号">
          <el-input v-model="search.bizNo" :clearable="true" style="width: 110px;"></el-input>
        </el-form-item>
        <el-form-item label="流水号">
          <el-input v-model="search.serialNo" :clearable="true" style="width: 130px;"></el-input>
        </el-form-item>
        <el-form-item label="货币">
          <el-input v-model="search.currency" :clearable="true" style="width: 80px;"></el-input>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="search.payStatus" :clearable="true" style="width: 110px;">
            <el-option
                v-for="item in payStatusOption"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-select v-model="search.sortType" :clearable="true" style="width: 100px;">
            <el-option
                v-for="item in sortTypeOption"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.sortDir" style="width: 80px;">
            <el-option label="升序" value="asc"></el-option>
            <el-option label="降序" value="desc"></el-option>
          </el-select>
        </el-form-item>
        <br>
        <el-form-item>
          <el-button size="small" type="primary" @click="load(true)" style="margin-right: 5px;">下载棒谷订单</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" @click="load()">本地查询</el-button>
        </el-form-item>
      </el-form>
    </div>




    <el-table
      slot="center"
      stripe
      :data="dataList" @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="{ row }">
          <el-button size="mini"
                     @click="updateOrder(row)"
                     v-if="row.processStatus !== processStatusMap.wait && row.processUserId === userId"
          >
            更新回单
          </el-button>
          <span v-if="row.processUserId !== userId" class="text-danger font-mini">不是自己的单</span>
        </template>
      </el-table-column>
      <el-table-column prop="bizNo" label="业务订单号" width="110"></el-table-column>
      <el-table-column label="处理状态" width="90">
        <template slot-scope="{ row }">
          <span v-if="row.processStatus === processStatusMap.wait" class="font-mini">待处理</span>
          <span v-else-if="row.processStatus === processStatusMap.enter" class="text-primary font-mini">已录入</span>
          <span v-else-if="row.processStatus === processStatusMap.verified" class="text-success font-mini">已校验</span>
          <span v-else-if="row.processStatus === processStatusMap.pending" class="text-warning font-mini">正在处理</span>
          <span v-else-if="row.processStatus === processStatusMap.success" class="text-danger font-mini" style="font-weight: bold;">已提交</span>
        </template>
      </el-table-column>
      <el-table-column label="支付状态" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.payStatus === payStatusMap.success" class="text-success font-mini">支付成功</span>
          <span v-else-if="row.payStatus === payStatusMap.fail" class="text-danger font-mini">支付失败</span>
          <span v-else class="text-muted font-mini">等待支付</span>
        </template>
      </el-table-column>
      <el-table-column label="收款金额" width="150">
        <template slot-scope="{ row }">
          {{row.currency}} <span style="font-size: 16px; font-weight: bold; color: #96342d;">{{row.price}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recieveName" label="收款人" width="110"></el-table-column>
      <el-table-column prop="recieveAccount" label="收款账户" width="200"></el-table-column>
      <el-table-column prop="tradeTypeStr" label="收款类型" width="100"></el-table-column>
      <el-table-column prop="processUserName" label="处理人" width="100"></el-table-column>
      <el-table-column prop="remark" label="支付备注"></el-table-column>
      <el-table-column prop="payTime" label="支付时间" width="140"></el-table-column>
      <el-table-column prop="sortTime" width="140" label="排序时间"></el-table-column>
<!--      <el-table-column prop="bank" label="银行" width="100"></el-table-column>-->
<!--      <el-table-column prop="bankDivision" label="银行支行" width="100"></el-table-column>-->
<!--      <el-table-column prop="province" label="省份" ></el-table-column>-->
<!--      <el-table-column prop="city" label="城市" ></el-table-column>-->
    </el-table>
    <div slot="bottom">
      <div style="color: #5d0b0b; padding: 5px 0; text-align: center; font-size: 12px; font-weight: bold;">{{ priceSummaries }}</div>
      <pagination
          style="border-top: solid 1px #e2e2e2"
          :total="page.total"
          :start.sync="page.start"
          :limit.sync="page.limit"
          :pageSizes="[30, 50, 100, 200, 500]"
      >
      </pagination>
    </div>

    <order-editor ref="orderPanel" @submit="onEditorSubmit"></order-editor>

    <screenshot-zip-upload
        ref="screenshotZipUpload"
        @processCompleted="handleScreenshotZipProcessed"
        @onItemClick="handleScreenshotZipOnItemClick"
    >
    </screenshot-zip-upload>
  </page>
</template>

<script>

  import {
    downloadProcessingListToExcel,
    getApiList,
    getList,
    getPendingList,
    releaseOrder,
    sendOrderBangList,
    getProcessUsers
  } from '../../http/api/bg-order'
  import kit from '@/tools/kit'
  import {
    payStatusOption,
    processStatusOption,
    STATUS_WAITING,
    STATUS_ENTERED,
    STATUS_PENDING,
    STATUS_SUCCESS,
    STATUS_VERIFIED,
    tradeTypesOption,
    PAY_STATUS_SUCCESS,
    PAY_STATUS_FAIL
  } from './common-attributes'
  import OrderEditor from './processing-order/comp/order-editor'
  import ScreenshotZipUpload from './processing-order/comp/screenshot-zip-upload'
  import { userStore } from '../../store'

  export default {
    components: {
      OrderEditor, ScreenshotZipUpload
    },
    data () {
      let processStatusMap = {}
      for (let opt of processStatusOption) {
        processStatusMap[opt.id] = opt.label
      }
      return {
        dataList: [],
        multipleSelection: [],
        page: {
          total: 0,
          start: 0,
          limit: 500
        },
        search: {
          times: [],
          recieve: null,
          tradeType: null,
          izNo: null,
          serialNo: null,
          processUserId: null,
          processStatus: [],
          payStatus: null,
          sortType: 'sort_time',
          sortDir: 'asc',
          currency: null
        },
        sortTypeOption: [{
          id: 'biz_no',
          label: '业务订单号'
        }, {
          id: 'sort_time',
          label: '订单时间'
        }, {
          id: 'trade_type',
          label: '交易类型'
        }, {
          id: 'payee_name',
          label: '收款人姓名'
        }, {
          id: 'pay_time',
          label: '支付时间'
        }],
        payStatusOption: payStatusOption,
        processStatusOption: processStatusOption,
        processStatusMap: {
          wait: STATUS_WAITING,
          enter: STATUS_ENTERED,
          verified: STATUS_VERIFIED,
          pending: STATUS_PENDING,
          success: STATUS_SUCCESS
        },
        payStatusMap: {
          success: PAY_STATUS_SUCCESS,
          fail: PAY_STATUS_FAIL
        },
        tradeTypes: tradeTypesOption,
        processUserOptions: [],
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }]
        }
      }
    },
    computed: {
      ...(userStore.mapState({
        userId: 'id'
      })),
      priceSummaries () {
        let amount = 0
        let currency = null
        let multiCurrency = false
        for (let row of this.dataList) {
          amount += row.price
          if (currency == null) {
            currency = row.currency
          } else {
            if (currency !== row.currency) {
              currency = '存在多种货币，请检查'
              multiCurrency = true
            }
          }
        }
        if (multiCurrency) {
          return '合计：' + kit.str.fmtCurrency(amount, 2) + '（' + currency + '）'
        } else {
          return '合计：' + kit.str.fmtCurrency(amount, 2, currency)
        }
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    methods: {
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load (fromBangGu) {
        let params = Object.assign({
          start: this.page.start,
          limit: this.page.limit
        }, this.search)

        let api = getList
        if (fromBangGu === true) {
          api = getApiList
          this.search.processStatus = [STATUS_WAITING]
        }

        if (params.times && params.times.length === 2) {
          params.startTime = params.times[0]
          params.endTime = params.times[1]
          delete params.times
        }
        params.processStatus = this.search.processStatus.join(',')


        this.$pageLoading(true)
        api()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      createReport: function (platform) {
        this.$router.push({ name: 'AdsReportDetail', params: { platform: platform } })
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
      },
      handleProcessSelectedOrders () {
        if (this.multipleSelection.length > 0) {
          let idList = this.multipleSelection.map(item => item.id)
          this.$pageLoading(true)
          getPendingList()
            .complete(() => (this.$pageLoading(false)))
            .success(resp => {
              this.$alert('选择的订单已经归属到你的帐户下，重新为你加载【正在处理】的订单。')
                .then(() => {
                  this.search.processUserId = this.userId
                  this.search.processStatus = [STATUS_PENDING, STATUS_ENTERED, STATUS_VERIFIED]
                  this.reload()
                })
            })
            .send(idList)
        } else {
          this.$alert('请选择要处理的订单数据！', { type: 'warning' })
        }
      },
      updateOrder (row) {
        this.$refs['orderPanel'].open(row, this.dataList)
      },
      onEditorSubmit (data) {
        kit.arr.pushOrReplace(this.dataList, data)
      },
      handleExportToExcel () {
        let params = Object.assign({}, this.search)
        if (params.times && params.times.length === 2) {
          params.startTime = params.times[0]
          params.endTime = params.times[1]
          delete params.times
        }
        params.processStatus = params.processStatus.join(',')
        let url = downloadProcessingListToExcel(params)
        window.open(url)
      },
      sendOrderBang () {
        if (this.multipleSelection.length > 0) {
          this.$confirm(`确定要提交选中的${this.multipleSelection.length}笔订单给棒谷吗？`, '确认')
            .then(() => {
              let idList = this.multipleSelection.map(item => item.id)
              this.$pageLoading(true)
              sendOrderBangList()
                .complete(() => (this.$pageLoading(false)))
                .success(resp => {
                  let successTotal = resp.data.success.length
                  let errorIdList = Object.keys(resp.data.error)
                  this.$alert(`提交完成，成功提交 ${successTotal} 条，失败 ${errorIdList.length} 条。`, { type: 'info' })
                  this.load()
                })
                .send(idList)
            })
        } else {
          this.$alert('请选择要提交的订单数据！', { type: 'warning' })
        }
      },
      releaseOrder () {
        if (this.multipleSelection.length > 0) {
          this.$confirm('订单释放后，其他人可以继续选择该订单处理, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$pageLoading(true)
            releaseOrder()
              .complete(() => (this.$pageLoading(false)))
              .success(resp => {
                this.multipleSelection.forEach(element => kit.arr.remove(this.dataList, element))
                this.$message.success('释放成功！')
              })
              .send(this.multipleSelection.map(item => item.id))
          }).catch(() => {})
        } else {
          this.$alert('请选择要释放的订单数据！', { type: 'warning' })
        }
      },
      handleScreenshotZipProcessed (items) {
        for (let item of items) {
          kit.arr.pushOrReplace(this.dataList, item)
        }
      },
      handleScreenshotZipOnItemClick (row) {
        let found = this.dataList.find(item => item.id === row.id)
        if (found) {
          this.$refs['orderPanel'].open(found, this.dataList)
        } else {
          this.$message.error('找不到数据。')
        }
      }
    },
    mounted () {
      getProcessUsers()
        .success(resp => {
          this.processUserOptions = resp.data
        })
        .send()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

</style>
