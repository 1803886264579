<template>
<eCharts ref="line" :options="options" :autoresize="true"/>
</template>

<script>
  import 'echarts/lib/chart/line'
  import 'echarts/lib/chart/bar'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/legend'
  import 'echarts/lib/component/markPoint'
  import 'echarts/lib/component/title'
  import 'echarts/lib/component/dataZoom'
  import 'echarts/lib/component/toolbox'
  import eCharts from 'vue-echarts'
/*
  chartData:{
    xAxis: [],
      yAxis: {
      data1: []
      data2: []
    }
  }
 */
  export default {
    name: 'chartsDualaxis',
    components: { eCharts },
    props: {
      title: { type: String },
      chartData: { type: Object, required: true }
    },
    data () {
      return {
        options: { }
      }
    },
    computed: {
    },
    watch: {
      'chartData.yAxis.data1' () {
        this.setOption()
      }
    },
    methods: {
      setOption () {
        // let line = this.$refs.line
        this.options = {
          title: {
            show: true,
            text: this.title,
            padding: 20,
            x: 'center',
            y: 'top',
            textAlign: 'left'
      },
          legend: {
            selectedMode: true, // 可点击
            data: ['返点毛利', '毛利率'],
            bottom: 10
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          dataZoom: [// dataZoom 组件 用于区域缩放，从而能自由关注细节的数据信息，或者概览数据整体，或者去除离群点的影响。
            {
              show: true,
              start: 0,
              end: 30
            },
            {
              type: 'inside',
              start: 0,
              end: 30
            }
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'line' // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function (params, b, c) {
              let mark0 = `${params[0].marker}`
              mark0.replace('</span>', '')
              let mark1 = `${params[1].marker}`
              mark1.replace('</span>', '')
              return `${params[0].name}<br/>${mark0}${params[0].seriesName}:&nbsp;${params[0].value}</span><br/>${mark1}${params[1].seriesName}:&nbsp;${params[1].value}%</span>`
             }
          },
          grid: {
            left: 80,
            bottom: 150
          },
          xAxis: [
            {
              type: 'category',
              data: this.chartData.xAxis,
              splitLine: {// 不显示分割线
                show: false
              },
              axisLabel: {
                interval: 0,
                rotate: 40,
                textStyle: {
                  fontSize: 10// 更改坐标轴文字大小
                }

                // formatter: function (value) {
                //   var ret = '' // 拼接加\n返回的类目项
                //   var maxLength = 10 // 每项显示文字个数
                //   var valLength = value.length // X轴类目项的文字个数
                //   var rowN = Math.ceil(valLength / maxLength) // 类目项需要换行的行数
                //   if (rowN > 1) { // 如果类目项的文字大于3,
                //     for (var i = 0; i < rowN; i++) {
                //       var temp = '' // 每次截取的字符串
                //       var start = i * maxLength // 开始截取的位置
                //       var end = start + maxLength // 结束截取的位置
                //       temp = value.substring(start, end) + '\n'
                //       ret += temp
                //     }
                //     return ret
                //   } else {
                //     return value
                //   }
                // }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '返点毛利',
              splitLine: {// 显示分割线
                show: true
              }
            },
            {
              type: 'value',
              name: '毛利率',
              splitLine: {
                show: true
              },
              axisLabel: {
                formatter: function (value) {
                  return value + '%'
                }
              }
            }
          ],
          series: [
            {
              name: '返点毛利',
              type: 'bar',
              data: this.chartData.yAxis.data1,
              itemStyle: {
                color: 'rgba(132, 136, 211, 1)'
              },
              label: {
                show: true,
                position: 'top',
                color: 'rgb(60,16,48)'
              }
            },
            {
              name: '毛利率',
              type: 'line',
              yAxisIndex: 1, // 索引从0开始
              data: this.chartData.yAxis.data2,
              itemStyle: {
                color: 'rgba(91, 245, 204, 1)'
              },
              label: {
                show: true,
                position: 'top',
                formatter: '{c}% ',
                color: 'rgb(60,16,48)'
              }
            }
          ]
        }
      }
    },
    mounted () {
      this.setOption()
    }
  }
</script>

<style lang="less" scoped>
  .echarts {
    width: 100%;
    height: 800px;
  }
</style>
