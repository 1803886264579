import defineApi from '../define-api'
import http from '../application-api'

export const addOrder = defineApi((config, order) => {
  config.data = order
  http.post('/pay_order/add', config)
})

export const updateOrder = defineApi((config, order) => {
  config.data = order
  http.post('/pay_order/update', config)
})

export const getOrderList = defineApi((config, searchParams) => {
  config.params = searchParams
  http.get('/pay_order/list', config)
})

export const cancelOrder = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/pay_order/cancel', config)
})

export const sendOrderPaySms = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/pay_order/send_sms', config)
})
