import { createWorkflowDetailRouters } from './workflow'
import PaymentRemind from '../../pages/payment-remind'
import Checking from '../../pages/payment-remind/checking'
import Finance from '../../pages/payment-remind/finance'
import FinanceDetail from '../../pages/payment-remind/finance-detail'

/**
 * 充值款项催收的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'PaymentRemindWorkflowDetail', params: {flowType: t, flowId: id}})
 *
 * 如果使用mixin：
 * this.navToWorkflowDetail('_CUSTOM_PaymentRemind', flowId, flowType)
 * @see /src/mixin/flow-detail-nav.js
 */

export default [
  {
    name: 'RechargePaymentRemind',
    path: '/payment_remind',
    meta: { title: '催收列表' },
    component: PaymentRemind,
    children: [
      createWorkflowDetailRouters('flowId', '_CUSTOM_PaymentRemind'),
      {
        name: 'Checking',
        path: 'checking',
        meta: { title: '查账申请' },
        component: Checking
      },
      {
        name: 'Finance',
        path: 'finance',
        meta: { title: '财务收款确认' },
        component: Finance
      },
      {
        name: 'FinanceDetail',
        path: 'financedetail',
        meta: { title: '财务收款确认详情' },
        component: FinanceDetail
      }
    ]
  }
]
