import User from '../../pages/manager/user'
import Role from '../../pages/manager/user/role/role'

export default [
  {
    name: 'User',
    path: '/user',
    meta: { title: '用户管理' },
    component: User,
    children: [
      {
        name: 'Role',
        path: 'role',
        meta: { title: '角色管理' },
        component: Role
      }
    ]
  }
]
