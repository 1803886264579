<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="政策公司编辑"
    @save="onSave"
    @close="onClose"
  >
    <el-form ref="form" :model="formData" label-width="150px">
      <el-form-item label="代理公司或签约主体" prop="name" class="app_required-input">
        <el-input v-model="formData.name" />
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="formData.type" style="width: 100%">
          <el-option
            v-for="item in [{id: 1, label: '代理公司'}, {id: 2, label: '签约主体'}]"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="户名" prop="accountName" >
        <el-input v-model="formData.accountName" />
      </el-form-item>
      <el-form-item label="开户行" prop="accountBankName" >
        <el-input v-model="formData.accountBankName" />
      </el-form-item>
      <el-form-item label="银行账号" prop="bankAccount" >
        <el-input v-model="formData.bankAccount" />
      </el-form-item>
      <el-form-item label="银行代码" prop="bankCode" >
        <el-input v-model="formData.bankCode" />
      </el-form-item>
      <el-form-item label="swiftCode" prop="swiftCode" >
        <el-input v-model="formData.swiftCode" />
      </el-form-item>
      <el-form-item label="银行地址" prop="bankAddress" >
        <el-input v-model="formData.bankAddress" />
      </el-form-item>
      <el-form-item label="备注" prop="bankRemark" >
        <el-input v-model="formData.bankRemark"  type="textarea" :rows="3"/>
      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import {
    addPolicyCompany,
    updatePolicyCompany
  } from '../../../../http/api/policy-company'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        formData: {
          id: null,
          name: null,
          type: 1,
          enabled: null,
          accountName: null,
          accountBankName: null,
          bankAccount: null,
          bankCode: null,
          swiftCode: null,
          bankAddress: null,
          bankRemark: null
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.model = model
        this.visible = true
        if (model) {
          kit.obj.getProperties(this.formData, model)
        }
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.formData.id = null
      },
      onSave () {
        this.saveButtonLoading = true
        this.closable = false
        let api = this.isUpdate ? updatePolicyCompany() : addPolicyCompany()
        api
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.formData.id = resp.data.id
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data)
            this.visible = false
          })
          .send(this.formData)
      }
    }
  }
</script>

<style scoped>

</style>
