<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    :title="trans?'申请转户':'编辑内容'"
    @save="onSave"
    @close="onClose"
    :width="600"
  >
    <el-form ref="form" :model="formData" label-width="150px">
      <el-form-item label="平台" prop="platformId" class="app_required-input">
        <el-select v-model="formData.platformId" placeholder="请选择" :disabled="true" >
          <el-option
            v-for="item in platforms"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上游代理" prop="proxyCompanyId" class="app_required-input">
        <el-select style="width: 100%;"
          v-model="formData.proxyCompanyId"
          filterable
          remote
          :remote-method="searchPolicyCompanyProxy"
          :loading="loading"
          :placeholder="trans?'请重新选择要转户的代理公司名称':'请输入代理公司名称的关键字进行搜索'"
          @change="proxyChange"
        >
          <el-option
            v-for="item in proxyCompanies"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户ID" prop="adId" class="app_required-input">
        <el-input v-model="formData.adId" ></el-input>
      </el-form-item>
      <el-form-item label="账户名" prop="adName" class="app_required-input">
        <el-input v-model="formData.adName" ></el-input>
      </el-form-item>
      <el-form-item label="客户主体签约名称" prop="adCustomerId" class="app_required-input">
        <el-select style="width: 100%;"
          v-model="formData.adCustomerId"
          filterable
          remote
          :clearable="true"
          :remote-method="loadAdCustomersForSearch"
          :loading="loading"
          placeholder="可输入客户关键字搜索"
        >
          <el-option v-for="item in adCustomersForSearch" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="openLabel" prop="openSubjectMcc" v-if="formData.platformId === '1' || formData.platformId === '2'">
        <el-input v-model="formData.openSubjectMcc" ></el-input>
      </el-form-item>
      <el-form-item label="签约主体" prop="subjectId" class="app_required-input">
        <el-select style="width: 100%;"
          v-model="formData.subjectId"
          filterable
          remote
          :remote-method="searchPolicyCompanyContract"
          :loading="loading"
          placeholder="请输入签约主体名称的关键字进行搜索"
        ><el-option
          v-for="item in contractSubjects"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算方式" prop="payTypeId" class="app_required-input">
        <el-select v-model="formData.payTypeId" placeholder="请选择"  >
          <el-option
            v-for="item in payTypes"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放方式" prop="putTypeId" class="app_required-input">
        <el-select v-model="formData.putTypeId" placeholder="请选择"  >
          <el-option
            v-for="item in putTypes"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户支付币种" prop="currency" class="app_required-input">
        <el-select v-model="formData.currencyId" placeholder="请选择"  >
          <el-option
            v-for="item in currencies"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="销售人员" prop="saleUserId" class="app_required-input">
        <el-select  v-model="formData.saleUserId" >
          <el-option v-for="item in allSaleServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客服人员" prop="csUserId" class="app_required-input">
        <el-select  v-model="formData.csUserId" >
          <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客服人员2" prop="assignCs" >
        <div >
          <el-row >
            <el-col :span="12" >
              <el-select  v-model="assignCs.csUserId" >
                <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" @click="handleAddCsItem()">添加</el-button>
            </el-col>
          </el-row>
          <el-row  v-for="item in assignCsList" :key="item.id" style="margin-top: 5px;">
            <el-col :span="12">
              <el-select  v-model="item.csUserId" abled="true" >
                <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
            <el-switch v-model="item.enabled" ></el-switch>
            </el-col>
          </el-row>
        </div>
      </el-form-item>
      <el-form-item label="客户类型" prop="customerType" >
        <el-select v-model="formData.customerType" placeholder="请选择"  >
          <el-option
            v-for="item in customerTypes"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户时间" prop="openDate" class="app_required-input">
        <el-date-picker
          v-model="formData.openDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择开户时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上线时间" prop="onlineDate" >
            <el-date-picker
              v-model="formData.onlineDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择政上线时间">
            </el-date-picker>
       </el-form-item>
      <el-form-item label="停用时间" prop="stopDate" >
        <el-date-picker
          v-model="formData.stopDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择停用时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="转户时间" prop="transDate" >
        <el-date-picker
          v-model="formData.transDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择转户用时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否退返客户" prop="backCustomer" class="app_required-input">
        <el-radio-group v-model="formData.backCustomer">
          <el-radio :label=1>是</el-radio>
          <el-radio :label=0>否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="退返客户金额" prop="customBackAmount" >
        <el-input v-model="formData.customBackAmount" ></el-input>
      </el-form-item>
      <el-form-item label="备注说明" prop="remark" >
        <el-input v-model="formData.remark" type="textarea"></el-input>
      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import { updateAdCustomerAd, updateTransAdCustomerAd, checkTransAdCustomerAd, getAssignCsUser } from '../../../../http/api/ad-customer-ad'
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getFlowFormDictItemListEnabled } from '../../../../http/api/workflow-form-dict'
  import { DICT_PUT_TYPE, DICT_PAY_TYPE, DICT_CURRENCY, DICT_CUSTOMER_TYPE } from '../../../../config/flow-form-dict-name'
  import { getPolicyCompanyByLikenameWithType } from '../../../../http/api/policy-company'
  import { getCustomerServiceUserList } from '../../../../http/api/cs-customer'
  import {
    getSaleServiceUserList
  } from '../../../../http/api/upper-lower-policy'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        loading: false,
        formData: {
          id: null,
          platformId: null,
          proxyCompanyId: null,
          adId: null,
          adName: null,
          adCustomerId: null,
          openSubjectMcc: null,
          subjectId: null,
          payTypeId: null,
          putTypeId: null,
          currencyId: null,
          csUserId: null,
          saleUserId: null,
          customerType: null,
          openDate: null,
          onlineDate: null,
          stopDate: null,
          transDate: null,
          backCustomer: null,
          customBackAmount: null,
          remark: null,
          createUserId: null,
          uploadTime: null,
          csUsersList: []
        },
        platforms: [],
        contractSubjects: [],
        proxyCompanies: [],
        payTypes: [],
        putTypes: [],
        currencies: [],
        customerTypes: [],
        customers: [],
        allCustomerServiceUsers: [],
        allSaleServiceUsers: [],
        adCustomersForSearch: [],
        openLabel: null,
        trans: null,
        assignCs: {
          id: null,
          csUserId: null,
          enabled: true
      },
        assignCsList: []
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    watch: {
    },
    methods: {
      open (model, trans) {
        this.model = model
        this.visible = true
        this.trans = trans
        if (model) {
          kit.obj.getProperties(this.formData, model)
          this.getAssignCsInfo(model.id)
          if (this.model.backCustomer == null) {
            this.formData.backCustomer = 0
          }
          if (model.proxyCompanyName != null) {
            kit.arr.pushOrReplace(this.proxyCompanies, {
              'id': model.proxyCompanyId,
              'name': model.proxyCompanyName
            })
            if (trans) {
              this.formData.proxyCompanyId = null
            } else {
              this.formData.proxyCompanyId = model.proxyCompanyId
            }
          }
          if (model.contractSubjectName != null) {
            kit.arr.pushOrReplace(this.contractSubjects, {
              'id': model.subjectId,
              'name': model.contractSubjectName
            })
            this.formData.contractSubjectId = model.contractSubjectId
          }
          if (model.adCustomerName != null) {
            kit.arr.pushOrReplace(this.adCustomersForSearch, {
              'id': model.adCustomerId,
              'name': model.adCustomerName
            })
            this.formData.adCustomerId = model.adCustomerId
          }
        }
        getAdPlatformListIdNameMode()
          .success(resp => {
            this.platforms = resp.data
          })
          .send()
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.payTypes = resp.data
          })
          .send(DICT_PAY_TYPE)
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.putTypes = resp.data
          })
          .send(DICT_PUT_TYPE)
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.currencies = resp.data
          })
          .send(DICT_CURRENCY)
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.customerTypes = resp.data
          })
          .send(DICT_CUSTOMER_TYPE)
        getCustomerServiceUserList()
          .success(resp => {
            this.allCustomerServiceUsers = resp.data
          })
          .send()
        getSaleServiceUserList()
          .success(resp => {
            this.allSaleServiceUsers = resp.data
          })
          .send()
        if (model.platformId === '1') {
          this.openLabel = 'MCC账户名'
        } else if (model.platformId === '2') {
          this.openLabel = '开户主体'
        } else {
          this.openLabel = ''
        }
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.formData.id = null
        this.assignCs = {}
        this.assignCs = []
        this.formData.csUsersList = []
      },
      onSave () {
        this.saveButtonLoading = true
        this.closable = false
        this.formData.csUsersList = this.assignCsList
        let api = this.trans ? updateTransAdCustomerAd() : updateAdCustomerAd()
        api
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.formData.id = resp.data.id
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data)
            this.visible = false
          })
          .send(this.formData)
      },
      searchPolicyCompanyProxy (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.proxyCompanies = resp.data
            })
            .send(keyword, 1)
        }
      },
      searchPolicyCompanyContract (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.contractSubjects = resp.data
            })
            .send(keyword, 2)
        }
      },
      loadAdCustomersForSearch (query) {
        this.loading = true
        getAdCustomerListByKeyword()
          .complete(() => (this.loading = false))
          .success(resp => {
            this.adCustomersForSearch = resp.data
          })
          .send(query)
      },
      proxyChange (val) {
        if (this.trans) {
          checkTransAdCustomerAd()
            .success(resp => {
              if (resp.data) {
                this.$message.error('代理公司旗下已有此客户。')
                this.formData.proxyCompanyId = null
              }
            })
            .send(this.formData.adId, this.formData.proxyCompanyId)
        }
      },
      getAssignCsInfo (adCustomerAdId) {
        getAssignCsUser()
          .success(resp => {
              this.assignCsList = resp.data
          })
          .send(adCustomerAdId)
      },
      handleAddCsItem () {
        if (this.assignCs.csUserId == null) {
          return this.$message.warning('请选择客服人中。')
        }
        this.assignCs.id = kit.str.id(5)
        this.assignCs.enabled = true
        let item = { ...this.assignCs }
        kit.arr.pushOrReplace(this.assignCsList, item)
        this.assignCs = {}
      }
    }
  }
</script>

<style scoped>

</style>
