import AdCustomerAd from '../../pages/ad/ad-customer'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'AdCustomerAd',
    path: '/ad_customer_ad',
    meta: { title: '平台客户管理' },
    component: AdCustomerAd
  }
]
