<template>
  <page route-name="AdPlatform">
    <!--工具按钮-->
    <div slot="toolbar">
       <el-button  type="primary" icon="el-icon-plus" @click="$refs['platformPanel'].open()">添加</el-button>
    </div>
   <el-table
     ref="table"
      slot="center"
      :data="dataList"
      @row-dblclick="handleTableRowClick"
    >
     <el-table-column type="expand" >
       <template slot-scope="{ row }">
         <div style="width: 400px; margin: 0 auto;">
           <el-table
             :data="row.items"
             stripe
           >
             <el-table-column label="流程名称" prop="id" width="120"></el-table-column>
             <el-table-column label="流程对应平台值" prop="value" width="150"></el-table-column>
           </el-table>
         </div>
       </template>
     </el-table-column>
     <el-table-column label="操作" width="100">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="updatePlatform(row)" >编辑</el-button>
        </template>
     </el-table-column>
     <el-table-column prop="name" label="名称" width="150"></el-table-column>
     <el-table-column prop="position" label="排序值" width="150"></el-table-column>
      <el-table-column prop="area" label="区域" width="150">
        <template slot-scope="{ row }">
          <span>{{row.area==1?'海外':'国内'}}</span>
        </template>
      </el-table-column>
     <el-table-column prop="enabled" label="可用状态" >
       <template slot-scope="{ row }">
         <span style="color: #50a654;" v-if="row.enabled">正常</span>
         <span style="color: #b53639;" v-else>禁用</span>
       </template>
     </el-table-column>
   </el-table>
    <platform-editor ref="platformPanel" @saved="onEditorSubmit"></platform-editor>
  </page>
</template>

<script>
  import PlatformEditor from './comp/platform-editor'
  import kit from '../../../tools/kit'
  import {
    getAdPlatformList
  } from '../../../http/api/ad-platform'

  export default {
    components: { PlatformEditor },
    data () {
      return {
        dataList: [],
        loading: false
      }
    },
    computed: {
    },
    mounted () {
      this.load()
    },
    watch: {
    },
    methods: {
      initItem (rowData) {
        rowData.items = []
        if (rowData.flowFormData) {
          let flow = JSON.parse(rowData.flowFormData)
          for (const [key, value] of Object.entries(flow)) {
            let item = {}
            item.id = key
            item.value = value
            rowData.items.push(item)
          }
        }
        return rowData
      },
      load () {
        this.$pageLoading(true)
        getAdPlatformList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            for (let obj of resp.data) {
              this.initItem(obj)
            }
            this.dataList = resp.data
          })
          .send()
      },
      onEditorSubmit (form) {
          kit.arr.pushOrReplace(this.dataList, this.initItem(form))
      },
      updatePlatform (lw) {
        this.$refs['platformPanel'].open(lw)
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
