<template>
  <page route-name="RebateReport">
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户主体签约名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="platform">
          <el-select v-model="search.type" placeholder="请选择"  :clearable="true">
            <el-option
              v-for="item in  [{id: 'cash', name: '返现金'}, {id: 'bill', name: '抵扣账单'}]"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
     </el-form>
    </div>
   <el-table
     ref="table"
     slot="center"
     :data="dataList"
    >
     <el-table-column prop="platformName" label="平台" width="80"></el-table-column>
     <el-table-column prop="adCustomerName" label="客户主体签约名称" width="200" ></el-table-column>
     <el-table-column  label="类型" width="70" >
       <template slot-scope="{ row }">
         <span v-if="row.type ==='cash'">返现金</span>
         <span v-else-if="row.type ==='bill'">抵扣账单</span>
       </template>
     </el-table-column>
     <el-table-column prop="dateStart" width="120" label="消耗返现年月份">
       <template slot-scope="{ row }">
         <span v-if="row.dateStart == row.dateEnd">{{row.dateStart}}</span>
         <span v-else>{{row.dateStart}}--{{row.dateEnd}}</span>
       </template>
     </el-table-column>
     <el-table-column prop="price" label="金额" ></el-table-column>
     <el-table-column prop="currency" width="80" label="返现币种" ></el-table-column>
     <el-table-column prop="exchangeRate" label="汇率">
       <template slot-scope="{ row }">
         <span v-if="row.exchangeRate >=0.00000001">{{row.exchangeRate}}</span>
       </template>
     </el-table-column>
     <el-table-column prop="deducted" label="已抵扣金额" width="100"></el-table-column>
     <el-table-column prop="lave" label="剩余抵扣金额" width="100"></el-table-column>
     <el-table-column prop="createUserName" width="80" label="创建人" ></el-table-column>
     <el-table-column prop="createTime" label="创建时间" ></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getRebateReportList } from '../../../../http/api/refund-Transfer'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'

  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          adCustomerId: null,
          type: null
        },
        platforms: [],
        adCustomers: [],
        loading: false
      }
    },
    computed: {
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getRebateReportList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
