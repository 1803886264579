import { render, staticRenderFns } from "./auto-complete.vue?vue&type=template&id=054997da&scoped=true&"
import script from "./auto-complete.vue?vue&type=script&lang=js&"
export * from "./auto-complete.vue?vue&type=script&lang=js&"
import style0 from "./auto-complete.vue?vue&type=style&index=0&id=054997da&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054997da",
  null
  
)

export default component.exports