<!--
  阶段列表项，显示流程的每个阶段的信息。
  组件需要的属性：
  stage {Object | String}
    如果是一个字符串，可选值为'completed'或'abort'，分别表示流程已完成或终止，'completed'会显示一个"完成"节点标识，
    'abort'会显示一个已终止的标识，所以这个数据应该放在数组的最后一个位置。

    如果是一个对象：
    "已完成的阶段"：数据必须是一个Stage数据模型。
    "下一步阶段"：数据必须是一个StageConfig数据模型，切有一个next:boolean属性，值为true。
-->
<template>
  <transition name="fade">
    <div class="stages">
      <!--已经处理的阶段-->
      <div class="stage-container" :key="stage.id" v-for="stage in stages">
        <!--无效的阶段组-->
        <div class="stage-group" v-if="stage.group === true">
          <div class="stage-group-head" v-if="stage.stages.length > 1">
            <div class="title" @click="stage.showStages = !stage.showStages">
              还有{{stage.stages.length - 1}}个无效的阶段
              <i class="fas fa-chevron-up" v-if="stage.showStages"></i>
              <i class="fas fa-chevron-down" v-else></i>
            </div>
          </div>
          <el-collapse-transition>
            <div v-show="stage.showStages">
              <completed-stage
                :flow-id-name="flowIdName"
                :flow-id="flowId"
                :flow-type="flowType"
                :key="invalidStage.id"
                v-for="invalidStage in stage.getStages()"
                :stage="invalidStage"></completed-stage>
            </div>
          </el-collapse-transition>

          <completed-stage
            :stage="stage.getLastStage()"
            :flow-id-name="flowIdName"
            :flow-id="flowId"
            :flow-type="flowType">
          </completed-stage>
        </div>
        <!--有效的阶段-->
        <completed-stage
          :stage="stage"
          :flow-id-name="flowIdName"
          :flow-id="flowId"
          :flow-type="flowType"
          v-else>
        </completed-stage>
      </div>

      <!--下一步要处理的阶段-->
      <div class="stage-item next" v-if="showNextStage">
        <span class="stage-notice" style="background-color: #ddd">Next</span>
        <div class="time-line-box">
          <span class="time-line"><span class="round"></span></span>
        </div>
        <div class="head">
          <div class="stage-title font-bold font-large-x" style="color: #8a8a8a;">{{next.title}}</div>
        </div>
        <div class="detail">
          <!--流程阶段的可处理人提示-->
          <div class="process-roles">
            <span v-if="next['conditionType'] === 'combine'"><i class="fas fa-users"></i>多人协作：</span>
            <span v-else><i class="fas fa-user"></i>处理人：</span>
            {{processUsersDescription}}
          </div>

          <combine-conditions
            v-if="next['conditionType'] === 'combine'"
            style="margin-top: 15px;"
            :stage-id="next.id"
            :flow-id-name="flowIdName"
            :flow-id="flowId"
            :flow-type="flowType">
          </combine-conditions>

          <div v-if="next.description" class="text-muted font-mini">工作描述：{{next.description}}</div>

          <div v-if="canProcessNextStage == null" class="font-mini text-muted">
            <i class="fa fa-spin fa-spinner"></i>
            正在查询您的权限...
          </div>
          <div v-else-if="canProcessNextStage === true">
            <div class="font-mini text-muted">你要如何处理本阶段？</div>
            <ul class="conditions">
              <li :key="c.id" v-for="c in nextConditions">
                <el-row>
                  <el-col :span="6">
                    <el-button type="primary" icon="el-icon-arrow-right" size="mini" @click="toProcessStagePage(c.id)">
                      {{c.title}}
                    </el-button>
                  </el-col>
                  <el-col :span="10">
                    <span class="text-muted font-mini" v-if="!!c.description">：{{c.description}}</span>
                    <ul class="condition-intros" v-if="c.condIntros.length > 0">
                      <li v-for="intro in c.condIntros">{{intro}}</li>
                    </ul>
                  </el-col>
                  <el-col :span="8">
                    <span v-show="c['nextStageTitle']" class="text-normal font-mini">
                      将进入阶段：
                      <span style="font-weight: bold; color: #c16136;">{{c['nextStageTitle']}}</span>
                    </span>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </div>
          <div class="text-danger font-mini" v-else>
            <i class="fas fa-exclamation-triangle"></i>
            您无权处理本阶段工作
          </div>
        </div>
      </div>

      <!--流程完成-->
      <div class="stage-item completed reject" v-else-if="flowStatus === 'completed' && flowReject === true">
        <i class="fas fa-times-circle"></i>
        <span>流程没有通过</span>
      </div>
      <div class="stage-item completed" v-else-if="flowStatus === 'completed'">
        <i class="fas fa-check-circle"></i>
        <span>流程已结束</span>
      </div>

      <!--流程关闭-->
      <div class="stage-item close" v-else-if="flowStatus === 'close'">
        <i class="fas fa-ban"></i>
        <span>流程已关闭</span>
      </div>
    </div>
  </transition>
</template>

<script>
  import CombineConditions from './combine-stages'
  import CompletedStage from './completed-stage'
  import mixins from './mixins'
  import { getFlowStages } from '../../../../http/api/stage'
  import { isProcessableByCurrentUser } from '../../../../http/api/workflow'

  class StageGroup {
    constructor (id) {
      this.id = id
      this.showStages = false
      this.group = true
      this.stages = []
    }
    getStages () {
      return this.stages.slice(0, this.stages.length - 1)
    }
    getLastStage () {
      return this.stages[this.stages.length - 1]
    }
  }
  export default {
    mixins: [mixins],
    components: { CombineConditions, CompletedStage },
    props: {
      flowId: { type: String, required: true },
      flowStatus: { type: String, required: true },
      flowReject: { type: Boolean }
    },
    data () {
      return {
        stages: [], // 已经处理过的步骤
        next: null, // 当前需要处理的步骤
        processUsers: [], // 可以处理下个阶段的用户
        nextConditions: [], // 决定进入哪个步骤的条件
        canProcessNextStage: null
      }
    },
    watch: {
      flowId () {
        this.loadStages()
      }
    },
    computed: {
      showNextStage () {
        return this.next !== null && this.flowStatus === 'open'
      },
      processUsersDescription () {
        let str = ''
        this.processUsers.forEach(r => {
          let users = []
          r.users.forEach(u => (users.push(u.name)))
          str += (r.title || '') + '（' + users.join('，') + '） '
        })
        return str
      }
    },
    methods: {
      loadStages () {
        this.stages = []
        this.next = null
        this.processUsers = []
        getFlowStages()
          .success(resp => {
            this.next = resp.data['next']
            this.nextConditions = resp.data['nextConditions']
            this.processUsers = resp.data['processUsers']
            let arr = []
            let g = null
            let gid = 1
            for (let s of resp.data['stages']) {
              if (s.status === 'invalid') {
                if (g === null) {
                  g = new StageGroup(++gid)
                  arr.push(g)
                }
                g.stages.push(s)
              } else {
                arr.push(s)
                g = null
              }
            }
            this.stages = arr
            if (this.next) {
              this.checkUserCanProcessFlowNextStage()
            }
          })
          .send(this.flowId)
      },
      checkUserCanProcessFlowNextStage () {
        isProcessableByCurrentUser()
          .success(resp => {
            this.canProcessNextStage = resp.data
          })
          .send(this.flowId)
      }
    },
    mounted () {
      this.loadStages()
    }
  }
</script>

<style lang="less" scoped>

  .time-line-color (@c) {
    .time-line {
      background-color: @c !important;
      .round {
        border-color: @c !important;
        &::after {
          background-color: @c !important;
        }
      }
    }
  }

  @deep: ~'>>>';

  .stages @{deep} .stage-item {
    position: relative;
    display: flex;
    padding: 10px 0;
    border-left: solid 1px #ddd;
    &:not(:first-of-type) {
      padding-top: 20px;
    }
    .time-line-color(#57955d) !important;
    // 无效的阶段
    &.invalid {
      .time-line-color(#df5040) !important;
    }
    // 下一个阶段
    &.next {
      padding-top: 30px;
      .time-line-color(#ddd) !important;
    }
    // 流程关闭和流程完成的状态
    &.close, &.completed {
      i {
        position: absolute;
        top: 0;
        left: -20px;
        display: block;
        padding: 8px;
        border-radius: 100%;
        font-size: 22px;
        color: #fff;
      }
      span {
        display: block;
        position: absolute;
        top: 10px;
        left: -20px + 50px;
        font-size: 13px;
      }
    }
    &.close {
      i {
        background-color: #df5040
      }
      span {
        color: #df5040;
      }
    }
    &.completed {
      i {
        background-color: #4aaa67
      }
      span {
        color: #4aaa67;
      }
    }
    &.reject {
      i {
        background-color: #c48116
      }
      span {
        color: #c48116;
      }
    }

    // 在时间线上的标注，如：Next，流程已完成，流程已终止
    .stage-notice {
      position: absolute;
      left: 0;
      top: 0;
      padding: 2px 15px;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      @radius: 9px;
      border-top-right-radius: @radius;
      border-bottom-right-radius: @radius;
    }

    // 时间线上的圆点，指示阶段标题
    .time-line-box {
      width: 50px;
      position: relative;
      .time-line {
        @width: 30px;
        position: absolute;
        height: 1px;
        width: @width;
        left: 0;
        top: 18px;
        .round {
          position: absolute;
          display: block;
          right: -8px;
          top: -8px;
          width: 14px;
          height: 14px;
          border-radius: 100%;
          border: solid 2px;
          background-color: #fff;
          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            left: 50%;
            top: 50%;
            margin-left: -4px;
            margin-top: -4px;
            border-radius: 100%;
          }
        }
      }
    }

    .head {
      width: 200px;
      .stage-title {
        margin-top: 5px;
      }
    }

    .detail {
      position: relative;
      flex: 1;
      padding: 10px 15px;
      border: solid 1px #ddd;
      border-radius: 5px;

      // 阶段处理人
      .process-roles {
        padding: 2px 0;
        color: #777777;
        font-size: 12px;
        border-bottom: solid 1px #efefef;
        margin-bottom: 8px;
        i {
          margin-right: 5px;
        }
      }

      // 处理结果
      .approve {
        position: absolute;
        bottom: -10px;
        left: 10px;
        padding: 2px 8px;
        background-color: #474747;
        box-shadow: 0 0 5px rgba(0,0,0,.3);
        border-radius: 5px;
        color: #fff !important;
      }

      // 下一个阶段的条件列表
      .conditions {
        list-style-type: none;
        width: 100%;
        margin: 10px 0 0 0;
        padding: 0 0 0 20px;
        & > li:not(:first-of-type) {
          margin-top: 10px;
        }

        .condition-intros {
          font-size: 12px;
          color: #888888;
          padding-left: 20px;
          li + li {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .stage-group {
    .stage-group-head {
      position: relative;
      padding-bottom: 10px;
      border-left: solid 1px #ddd;
      .title {
        display: inline-block;
        margin-left: -1px;
        padding: 2px 15px;
        font-size: 12px !important;
        color: #fff;
        background-color: #df5040;
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        cursor: pointer;
      }
    }
  }
</style>
