<template>
  <page route-name="AdsReportDetail">

    <div slot="top">
      <div style="padding: 10px;">
        <el-row>
          <el-col :span="12">
            <el-form :model="form">
              <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.description" resize="none" placeholder="限500字" type="textarea"></el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: center;">
              <el-button type="primary" @click="saveForm">{{isFormUpdate ? '保 存' : '新 增'}}</el-button>
              <el-button @click="$router.push({name: 'AdsReport'})">返 回</el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <ul class="form-info">
              <li>
                <label>广告平台</label>
                <span>{{platform}}</span>
              </li>
              <li>
                <label>创建用户</label>
                <span>{{isFormUpdate ? reportData.createUserName : ''}}</span>
              </li>
              <li>
                <label>创建日期</label>
                <span>{{isFormUpdate ? reportData.createDate : ''}}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>

      <div style="font-size: 13px; font-weight: bold; margin-top: 10px; padding: 5px 10px; border-top: solid 1px #ebeef5; border-bottom: solid 1px #ebeef5;">
        <el-button :disabled="!isFormUpdate" @click="$refs['customerEditor'].open(reportData.id)" type="success" size="mini">创建账户组</el-button>
      </div>
    </div>

    <el-table
      slot="center"
      :data="dataList">
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="$refs['customerEditor'].open(reportData.id, scope.row)"></el-button>
          <el-button icon="el-icon-delete" type="danger" size="mini" @click="deleteCustomer(scope.row)"></el-button>
          <el-button icon="el-icon-user" size="mini" @click="$refs['adAccountBinding'].open(platform, scope.row.id)">绑定广告账户</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="150"></el-table-column>
      <el-table-column prop="position" label="排序" width="80"></el-table-column>
      <el-table-column prop="enabled" label="状态" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.enabled === 1" class="text-success">可用</span>
          <span v-else class="text-danger">不可用</span>
        </template>
      </el-table-column>
      <el-table-column prop="adAccountTotal" label="已绑定的广告账户数"></el-table-column>
    </el-table>

    <customer-editor ref="customerEditor" @submit="onCustomerSave"></customer-editor>
    <ad-account-binding ref="adAccountBinding" @bindAccountSizeChanged="onBindAccountSizeChanged"></ad-account-binding>
  </page>
</template>

<script>
  import api, {
    API_ADS_REPORT_GET_BY_ID,
    API_ADS_REPORT_BINDING_CUSTOMER,
    API_ADS_REPORT_ADD,
    API_ADS_REPORT_UPDATE,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_LIST_BY_TEMPLATE_ID,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_DELETE
  } from '../../config/urls'
  import AdsAuth from '../../components/ads-auth'
  import kit from '../../tools/kit'
  import CustomerEditor from './comp/customer-editor'
  import AdAccountBinding from './comp/ad-account-binding'

  export default {
    components: { CustomerEditor, AdAccountBinding },
    data () {
      return {
        form: {
          name: null,
          description: null
        },
        reportData: null,
        dataList: []
      }
    },
    computed: {
      platform: function () {
        return this.$route.params.platform
      },
      isFormUpdate: function () {
        return this.reportData != null
      }
    },
    methods: {
      goAuth: function () {
        if (this.reportData == null) return
        AdsAuth.open(this.reportData.platform, (success, data) => {
          if (success) {
            this.addCustomerToReportTemplate(data)
          } else {
            this.$message.error(data)
          }
        })
      },
      addCustomerToReportTemplate: function (adsAccount) {
        this.$pageLoading('正在绑定账号...')
        this.$http.post(api(API_ADS_REPORT_BINDING_CUSTOMER, { reportTemplateId: this.reportData.id }), {
          data: adsAccount,
          success: resp => {
            this.$message.success('绑定成功。')
            kit.arr.pushOrReplace(this.dataList, resp)
            this.reportData['adsCustomerTotal']++
            this.$dispatcher.dispatch('AdsReportTemplateUpdateEvent', this.reportData)
          },
          complete: () => (this.$pageLoading(false))
        })
      },
      loadCustomers: function (reportId) {
        this.$http.get(api(API_ADS_REPORT_TEMPLATE_CUSTOMER_LIST_BY_TEMPLATE_ID), {
          params: { reportTemplateId: reportId },
          success: (resp) => (this.dataList = resp)
        })
      },
      saveForm: function () {
        let url, method
        if (this.isFormUpdate) {
          url = api(API_ADS_REPORT_UPDATE, { id: this.reportData.id })
          method = 'put'
        } else {
          url = api(API_ADS_REPORT_ADD)
          method = 'post'
        }
        this.$http[method](url, {
          data: Object.assign({ platform: this.platform }, this.form),
          success: resp => {
            this.reportData = resp
            this.$dispatcher.dispatch('AdsReportTemplateUpdateEvent', resp)
            this.$message.success('保存成功。')
          }
        })
      },
      onCustomerSave (customer, isUpdate) {
        if (isUpdate) {
          kit.arr.pushOrReplace(this.dataList, customer)
        } else {
          kit.arr.insert(this.dataList, 0, customer)
        }
      },
      deleteCustomer (model) {
        this.$confirm(`确定要删除[${model.name}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            this.$http.del(api(API_ADS_REPORT_TEMPLATE_CUSTOMER_DELETE, { id: model.id }), {
              completeBefore: () => this.$pageLoading(false),
              success: () => kit.arr.remove(this.dataList, model)
            })
          })
      },
      onBindAccountSizeChanged (adsReportTemplateCustomerId, size) {
        let item = kit.arr.find(this.dataList, item => item.id === adsReportTemplateCustomerId)
        if (item) item.adAccountTotal = size
      }
    },
    mounted () {
      let id = this.$route.query.id
      if (id) {
        this.$pageLoading(true, '正在加载报表模版信息...')
        this.$http.get(api(API_ADS_REPORT_GET_BY_ID, { id: id }), {
          success: resp => {
            this.reportData = resp
            this.form.name = resp.name
            this.form.description = resp.description
            this.loadCustomers(resp.id)
          },
          complete: () => (this.$pageLoading(false))
        })
      }
    },
    beforeDestroy () {
      AdsAuth.close()
    }
  }
</script>

<style lang="less" scoped>

  .form-info {
    list-style-type: none;
    li {
      display: block;
      padding: 5px;
      font-size: 14px;
      label {
        font-weight: bold;
        &::after {
          content: '：'
        }
      }
      span {
        color: #818181;
      }
    }
  }

</style>
