<template>
  <modal-panel
    :visible.sync="panelVisible"
    :saveButtonLoading="submitting"
    :closable="panelCloseable"
    title="上游政策渠道编辑"
    @close="panelBeforeClose"
    @save="submit"
  >

    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="投放平台" prop="platform" class="app_required-input">
        <el-input v-model="form.platform" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="上游代理性质" prop="proxy">
        <el-input v-model="form.proxy" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="区域/行业" prop="area">
        <el-input type="textarea" :rows="2" v-model="form.area" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="返点返货" prop="bp">
        <el-input type="textarea" :rows="2" v-model="form.bp" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="结算方式" prop="js">
        <el-input v-model="form.js" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="发票开具" prop="invoice">
        <el-input v-model="form.invoice" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="首笔充值" prop="chongzhi">
        <el-input type="textarea" :rows="2" v-model="form.chongzhi" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="是否可用" prop="enabled">
        <el-switch v-model="form.enabled"></el-switch>
      </el-form-item>
    </el-form>
  </modal-panel>

</template>

<script>
  import { addAdChannelInfo, updateAdChannelInfo } from '../../../../http/api/ad-channel'
  import kit from '../../../../tools/kit'

  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    data () {
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        form: {
          id: null,
          platform: null,
          proxy: null,
          bp: null,
          area: null,
          js: null,
          invoice: null,
          chongzhi: null,
          enabled: true
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          kit.obj.getProperties(this.form, model)
        }
      },
      submit () {
        this.submitting = true
        this.panelCloseable = false
        let api
        if (this.isUpdate) {
          api = updateAdChannelInfo
        } else {
          api = addAdChannelInfo
        }
        api()
          .complete(() => {
            this.panelCloseable = true
            this.submitting = false
          })
          .success(resp => {
            this.$message.success('保存成功。')
            this.$emit('submit', resp.data, this.isUpdate)
            this.panelVisible = false
          })
          .send(this.form)
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
        this.form.id = null
      }
    }
  }
</script>

<style scoped>

</style>
