<template>
    <el-header class="app-header">

      <div style="float: left; cursor: pointer;" @click="$router.push({name: 'Home'})">
        <img class="logo" src="/logo.png"/>
        <h3 class="app-name">{{$shareProps.appName}}</h3>
        <span style="font-size: 12px; color: #ededed" v-show="version">（{{version}}）</span>
      </div>

      <div class="right-bar">
        <div class="bar-item user-profile" v-show="isLogin">
          <span class="username">欢迎，{{name}}</span>
          <i class="el-icon-arrow-down"></i>
          <ul class="menu">
            <li class="menu-item" @click="$refs.updatePassword.open()">修改密码</li>
            <li v-if="!weChatBind" class="menu-item" @click="$refs.weChatBind.open()">绑定微信</li>
            <li v-if="weChatBind" class="menu-item" @click="handleUnbindWeChat">解除微信绑定</li>
            <li class="split-line"></li>
            <li class="menu-item" @click="logout">退出系统</li>
          </ul>
        </div>

        <div class="bar-item message" @click="$refs.message.open()">
          <i class="fas fa-lg fa-envelope">
            <span class="badge" v-show="messageTotal > 0">{{messageTotal | totalFilters}}</span>
          </i>
          <span class="text">系统消息</span>
        </div>

        <div class="bar-item todo" @click="handleClickTodo">
          <i class="fas fa-lg fa-calendar">
            <span class="badge" v-show="todoTotal > 0">{{todoTotal | totalFilters}}</span>
          </i>
          <span class="text">待办事项</span>
        </div>
      </div>

      <update-password ref="updatePassword"></update-password>

      <message ref="message" @messageRead="onMessageRead"></message>

      <we-chat-bind ref="weChatBind"></we-chat-bind>

    </el-header>
</template>

<script>
  import UpdatePassword from '../components-business/update-password'
  import Message from '../components-business/message'
  import { userStore, todoStore } from '../store'
  import { MSG_TYPE_MESSAGE, MSG_TYPE_TODO } from '../components/stomp'
  import WeChatBind from '../components-business/wechat-bind'
  import { ROUTE_LOGIN } from '../router/router-constants'
  import { logout } from '../http/api/manager'
  import { getUnReadMessageTotal } from '../http/api/app-message'
  import { unBindWeChat } from '../http/api/wechat'
  import { getAppVersion } from '../http/api/version'
  import { getMyTodoTotal } from '../http/api/todo'

  export default {
    components: { UpdatePassword, Message, WeChatBind },
    created () {
      this.isLogin && this.updateMessageTotal()
    },
    data () {
      return {
        messageTotal: 0,
        version: null
      }
    },
    computed: {
      ...userStore.mapState(['name']),
      ...userStore.mapState(['weChatBind']),
      ...userStore.mapGetters(['isLogin']),
      ...(todoStore.mapGetters({
        todoTotal: 'allTotal'
      }))
    },
    watch: {
      isLogin (v) {
        v && this.updateMessageTotal()
      }
    },
    filters: {
      totalFilters (total) {
        return total <= 99 ? total : '99+'
      }
    },
    methods: {
      logout () {
        this.$confirm('确定要退出系统吗？', '提示', {
          type: 'warning'
        })
          .then(() => {
            logout()
              .success(() => {
                this.$router.push({ name: ROUTE_LOGIN })
              })
              .send()
          })
      },
      handleClickTodo () {
        this.$router.push({ name: 'Todo' })
      },
      loadTodoTotals () {
        if (!this.isLogin) return
        getMyTodoTotal()
          .success(resp => {
            this.$todoStore.setTotals(resp.data)
          })
          .send()
      },
      updateMessageTotal () {
        getUnReadMessageTotal()
          .success(resp => {
            try {
              this.messageTotal = parseInt(resp.data)
            } catch (e) {}
          })
          .send()
      },
      onMessageRead (count) {
        this.messageTotal -= count
      },
      handleUnbindWeChat () {
        this.$confirm('确定要解除绑定吗？', '确认', { type: 'warning' })
          .then(() => {
            unBindWeChat()
              .success(() => {
                this.$message.success('解除绑定成功。')
                userStore.updateWeChatBindStatus(false)
              })
              .send()
          })
          .catch(() => {})
      }
    },
    mounted () {
      getAppVersion()
        .success(resp => {
          this.version = resp.data
        })
        .send()
      this.loadTodoTotals()
    },
    stomp: {
      [MSG_TYPE_MESSAGE]: function (msg) {
        if (msg) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            title: '消息',
            message: `<span class="text-muted font-mini">${msg.message}</span>`,
            type: msg['scene']
          })
        }
        this.updateMessageTotal()
      },
      [MSG_TYPE_TODO] (msg) {
        this.$todoStore.setTotals(msg.data)
      }
    }
  }
</script>

<style lang="less" scoped>
  @height: 60px;
  .app-header {
    height: @height;
    line-height: @height;
    background-color: #2a2a2a;
    color: #fff;

    .logo {
      float: left;
      margin-top: 5px;
      width: 50px;
      height: 50px;
    }

    .app-name {
      float: left;
      margin: 0 0 0 10px;
      color: #fff;
    }
  }

  .right-bar {
    float: right;
    position: relative;
    margin-right: 15px;
    line-height: @height;
    height: @height;
    .bar-item {
      float: right;
      position: relative;
      padding: 0 10px;
      font-size: 14px;
      color: #efefef;
      cursor: pointer;
      &:hover {
        background-color: #393939;
      }
    }
  }

  .bar-item.user-profile {
    &:hover .menu {
      display: block;
    }
    .username {
      margin-right: 10px;
    }

    .menu {
      display: none;
      position: absolute;
      margin: 0;
      padding: 0;
      top: @height;
      right: 0;
      z-index: 500;
      min-width: 150px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #393939;
      box-shadow: 0 5px 10px rgba(0,0,0,.3);
      list-style-type: none;
      overflow: hidden;
      .split-line {
        height: 1px;
        padding: 0;
        margin: 0 15px;
        background-color: #5c5c5c;
      }
      .menu-item {
        height: 28px;
        line-height: 28px;
        margin: 0;
        padding: 3px 15px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: #414141;
        }
        &:active {
          background-color: #464646;
        }
      }
    }
  }

  .bar-item {
    .text {
      font-size: 13px;
      margin-left: 3px;
    }
    i {
      position: relative;
    }
    .badge {
      display: inline-block;
      position: absolute;
      top: -12px;
      left: -12px;
      padding: 3px 4px;
      min-width: 12px;
      text-align: center;
      border-radius: 10px;
      font-size: 12px !important;
      color: #fff;
      background-color: #df5040;
    }
  }
</style>
