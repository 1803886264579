<!--
  分页组件，封装el-pagination组件，使应用系统的分页组件能统一外观和使用方式。

  外部属性：
    total 请求数据后需要更新该属性，使分页组件可以正常工作。
    start.sync 请求数据的开始位置，从0开始，这不是页码，而是数据行数的位置。
    limit.sync 每页的行数。

  在父组件中通过设置start和limit的属性为sync，然后watch这两个属性，属性发生改变时应该重新加载数据。
-->
<template>
  <div class="pagination">
    <el-pagination
      @size-change="onSizeChanged"
      :page-sizes="pageSizes"
      :current-page.sync="currentPage"
      :page-size="limit"
      :total="total"
      prev-text="上一页"
      next-text="下一页"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    props: {
      total: { type: Number, required: true },
      limit: { type: Number, required: true },
      start: { type: Number, required: true },
      pageSizes: { type: Array, default () {
        return [30, 50, 100, 200]
      }}
    },
    computed: {
      currentPage: {
        get () {
          if (this.start === 0) {
            return 1
          } else if (this.start !== undefined) {
            return parseInt(this.start / this.limit) + 1
          } else {
            return 1
          }
        },
        set (page) {
          let start = (page - 1) * this.limit
          this.$emit('update:start', start)
        }
      }
    },
    methods: {
      onSizeChanged (limit) {
        this.$emit('update:limit', limit)
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  @color: #f9f9f9;
  .pagination {
    padding: 8px 10px;
    background-color: @color;
  }
  .pagination @{deep} {
    .btn-prev, .btn-next, .el-pager > li {
      background-color: @color;
    }
  }
</style>
