<template>
  <page route-name="RefundReport">
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户主体签约名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
     </el-form>
    </div>
   <el-table
     ref="table"
     slot="center"
     :data="dataList"
     @expand-change="handleTableExpand"
     @row-dblclick="handleTableRowClick"
    >
     <el-table-column type="expand">
       <template slot-scope="{ row }">
         <div style="width: 400px; margin: 0 auto;">
           <el-table :data="row.items"  stripe >
             <el-table-column label="客户Id" prop="adId" width="150"></el-table-column>
             <el-table-column :label="row.type === 'Refund'?'消耗金额':'转出清零金额'" prop="price" width="120"></el-table-column>
           </el-table>
         </div>
       </template>
     </el-table-column>
     <el-table-column prop="platformName" label="平台" width="80"></el-table-column>
     <el-table-column prop="adCustomerName" label="客户主体签约名称" width="200" ></el-table-column>
     <el-table-column label="流程" width="80">
       <template slot-scope="{ row }">
         <span  v-if="row.type === 'Refund'">退款</span>
         <span  v-else>余额转移</span>
       </template>
     </el-table-column>
     <el-table-column prop="rechargePrice" label="充值金额" ></el-table-column>
     <el-table-column prop="price" label="已消耗金额" ></el-table-column>
     <el-table-column prop="transferPrice" label="转出清零金额"></el-table-column>
     <el-table-column prop="refundPrice" label="实际退款金额">
       <template slot-scope="{ row }">
         <span style="font-weight: bold; color: #a94442">{{row.refundPrice}}</span>
         {{row.currency}}
       </template>
     </el-table-column>
     <el-table-column prop="refundDate" label="实际退款日期"></el-table-column>
     <el-table-column prop="createUserName" label="创建人" ></el-table-column>
     <el-table-column prop="createTime" label="创建时间" ></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getRefundReportList, getDetail } from '../../../../http/api/refund-Transfer'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'

  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          adCustomerId: null
        },
        platforms: [],
        adCustomers: [],
        loading: false
      }
    },
    computed: {
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getRefundReportList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            for (let obj of resp.data.records) {
              this.initDetail(obj)
            }
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      initDetail (detail) {
        detail.items = []
        detail.itemLoading = false
        return detail
      },
      handleTableExpand (row) {
        if (row.expanded === undefined) {
          row.expanded = false
        }
        row.expanded = !row.expanded
        if (!row.itemLoading && row.expanded) {
          row.itemLoading = true
          getDetail()
            .complete(() => (row.itemLoading = false))
            .success(resp => {
              row.items = resp.data
            })
            .send(row.id)
        }
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
