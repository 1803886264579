<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="流程、合同、客户查询权限"
    @save="onSave"
    @close="onClose"
  >

    <div class="font-mini">
      正在编辑的用户：
      <span class="text-muted font-mini"
            v-for="u in users" :key="u.id"
            style="margin-right: 10px; margin-bottom: 10px;"
      >
        {{u.name}}
      </span>
    </div>

    <div>
      <split-line text="客户权限"></split-line>
      <div class="authority-block">
        <div class="radio-item">
          <el-radio v-model="auth.customer.auth" label="all">全部</el-radio>
        </div>
        <div class="radio-item">
          <el-radio v-model="auth.customer.auth" label="part">相关</el-radio>
          <span class="font-mini text-muted">自己创建的或自己维护的客户。</span>
        </div>
        <div class="radio-item">
          <el-radio v-model="auth.customer.auth" label="">不选</el-radio>
          <span class="font-mini text-muted">只能看到自己创建的。</span>
        </div>
      </div>
    </div>


    <div>
      <split-line text="流程附件权限"></split-line>
      <div class="authority-block">
        <div class="radio-item">
          <el-radio v-model="auth.attachment.auth" label="all">全部</el-radio>
        </div>
        <div class="radio-item">
          <el-radio v-model="auth.attachment.auth" label="part">相关</el-radio>
          <span class="font-mini text-muted">自己上传的或自己维护的客户相关的。</span>
        </div>
        <div class="radio-item">
          <el-radio v-model="auth.attachment.auth" label="">不选</el-radio>
          <span class="font-mini text-muted">只能看到自己创建的。</span>
        </div>
      </div>
      <div class="authority-block">
        <div class="radio-item">
          <el-radio v-model="auth.attachment.authPlatform" label="china">国内</el-radio>
          <span class="font-mini text-muted">只能看到国内平台的相关附件。</span>
        </div>
        <div class="radio-item">
          <el-radio v-model="auth.attachment.authPlatform" label="overseas">海外</el-radio>
          <span class="font-mini text-muted">只能看到海外平台的相关附件。</span>
        </div>
        <div class="radio-item">
          <el-radio v-model="auth.attachment.authPlatform" label="">不选</el-radio>
          <span class="font-mini text-muted">可以看到海外和国内的。</span>
        </div>
      </div>
    </div>


    <div>
      <split-line text="流程权限"></split-line>
      <div class="flow-item" v-for="f in auth.workflow.auth" :key="f.id">
        <div style="font-size: 13px; color: #3169a8; margin-bottom: 5px;">{{f.name}}</div>
        <div class="authority-block">
          <div class="radio-item">
            <el-radio v-model="f.auth" label="all">全部</el-radio>
            <span class="font-mini text-muted">查看所有人的流程。</span>
          </div>
          <div class="radio-item">
            <el-radio v-model="f.auth" label="part">相关</el-radio>
            <span class="font-mini text-muted">自己创建的或自己的客户有关的。</span>
          </div>
          <div class="radio-item">
            <el-radio v-model="f.auth" label="">不选</el-radio>
            <span class="font-mini text-muted">只能看到自己创建的。</span>
          </div>
        </div>
        <div class="authority-block">
          <div class="radio-item">
            <el-radio v-model="f.authPlatform" label="china">国内</el-radio>
            <span class="font-mini text-muted">只能看到国内的广告平台的流程。</span>
          </div>
          <div class="radio-item">
            <el-radio v-model="f.authPlatform" label="overseas">海外</el-radio>
            <span class="font-mini text-muted">只能看到海外的广告平台的流程。</span>
          </div>
          <div class="radio-item">
            <el-radio v-model="f.authPlatform" label="">不选</el-radio>
            <span class="font-mini text-muted">可以看到海外和国内的。</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <split-line text="公共查询(销售、客服)权限"></split-line>
      <div class="flow-item" v-for="f in auth.common.auth" :key="f.id">
        <div style="font-size: 13px; color: #3169a8; margin-bottom: 5px;">{{f.name}}</div>
        <div class="authority-block">
          <div class="radio-item">
            <el-radio v-model="f.auth" label="all">全部</el-radio>
          </div>
          <div class="radio-item">
            <el-radio v-model="f.auth" label="">不选</el-radio>
            <span class="font-mini text-muted">只能看到自己的(客服人员、销售人员)。</span>
          </div>
        </div>
      </div>
    </div>
  </modal-panel>
</template>

<script>
  import { getAllFlowConfigs } from '../../../../http/api/workflow'
  import { updateSearchAuthorityForUsers, getUserSearchAuthorities } from '../../../../http/api/search-authority'
  import kit from '../../../../tools/kit'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        users: [],
        auth: this.initAuth()
      }
    },
    watch: {
    },
    computed: {
    },
    methods: {
      initAuth () {
        return {
          customer: { auth: null },
          attachment: { auth: null, authPlatform: null },
          workflow: { auth: [] },
          common: { auth: [] }
        }
      },
      open (users) {
        this.visible = true
        this.users = users
        this.initCommonConfigs()
        getAllFlowConfigs()
          .success(resp => {
            this.initFlowConfigs(resp.data)
            if (users.length === 1) {
              // 单个用户编辑时才显示该用户拥有的权限，多用户编辑不显示
              getUserSearchAuthorities()
                .success(resp => {
                  if (resp.data) {
                    this.auth.customer = resp.data.customer
                    this.auth.attachment = resp.data.attachment
                    for (let a of resp.data.workflow.auth) {
                      kit.arr.pushOrReplace(this.auth.workflow.auth, a, 'flowType')
                    }
                    for (let a of resp.data.common.auth) {
                      kit.arr.pushOrReplace(this.auth.common.auth, a, 'id')
                    }
                  }
                })
                .send(users[0].id)
            }
          })
          .send()
      },
      onClose () {
        this.auth = this.initAuth()
      },
      onSave () {
        this.saveButtonLoading = true
        updateSearchAuthorityForUsers()
          .complete(() => (this.saveButtonLoading = false))
          .success(() => {
            this.$message.success('保存成功。')
          })
          .send({
            userIds: this.users.map(u => u.id),
            authJson: JSON.stringify(this.auth)
          })
      },
      initFlowConfigs (configs) {
        for (let f of configs) {
          this.auth.workflow.auth.push({
            flowType: f.id,
            name: f.name,
            auth: null,
            authPlatform: null
          })
        }
      },
      initCommonConfigs () {
        this.auth.common.auth.push({
          id: 'adCustomerAd',
          name: '平台客户管理',
          auth: null
        })
        this.auth.common.auth.push({
          id: 'paymentRemind',
          name: '催收列表',
          auth: null
        })
        this.auth.common.auth.push({
          id: 'lowerPolicy',
          name: '客户政策',
          auth: null
        })
        this.auth.common.auth.push({
          id: 'upDownCostReport',
          name: '上下游结算数据',
          auth: null
        })
        this.auth.common.auth.push({
          id: 'rechargeReport',
          name: '充值数据报表',
          auth: null
        })
      }
    },
    mounted () {

    }
  }
</script>

<style lang="less" scoped>

  .flow-item {
    padding: 5px;
    &:hover {
      background-color: #f9f9f9;
    }
  }
  .authority-block {
    padding: 5px 10px;
    border-radius: 5px;
    border: solid 1px #eeeeee;
    .title {
      font-size: 12px !important;
      color: #324474 !important;
    }
  }
  .authority-block + .authority-block {
    margin-top: 10px;
  }
</style>
