<template>
  <el-dialog
      title="批量截图上传"
      :visible.sync="visible"
      @close="handleDialogClose"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="handleDialogBeforeClose"
      :fullscreen="false"
      class="screenshot-zip-dialog"
      width="80%"
  >
    <el-alert title="将转账截图按棒谷业务单号命名，如：276531.png，然后将所有截图文件打包成一个压缩文件zip上传。" type="success" :closable="false"></el-alert>
    <div class="container">
      <el-form ref="form" :model="form" style="width: 250px;">
        <el-form-item label="付款账户" prop="payeeAccount">
          <el-input v-model="form.payeeAccount" :clearable="true"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="付款户名" prop="payeeName">
          <el-input v-model="form.payeeName" :clearable="true"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="图片类型" prop="screenshotType">
          <el-select v-model="form.screenshotType" style="width: 100%;">
            <el-option v-for="item in screenshotTypes" :label="item.name" :key="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <br>
        <el-form-item>
          <el-button
              type="primary"
              style="margin-right: 10px; width: 100%;"
              @click="handleSelectZipFile"
              :disabled="!closable"
          >
            选择压缩文件（zip）
          </el-button>
          <div v-if="selectedFile != null" class="text-muted font-mini" style="margin-top: 5px;">
            {{selectedFile.name}}（{{selectedFile.size | fileSize}}）
          </div>
        </el-form-item>
      </el-form>
      <div class="process-progress">
        <div class="progress-message">{{progressMessage}}</div>
        <el-table
            :data="processResults"
            height="350"
            style="width: 100%;"
        >
          <el-table-column label="操作" width="60">
            <template slot-scope="{ row }">
              <el-button v-if="!row.error" size="mini" @click="handleViewResult(row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="fileName" label="文件"></el-table-column>
          <el-table-column prop="price" label="转账金额"></el-table-column>
          <el-table-column prop="payeeAmount" label="手续费"></el-table-column>
          <el-table-column prop="serialNo" label="流水号"></el-table-column>
          <el-table-column prop="payTime" label="时间"></el-table-column>
          <el-table-column label="结果">
            <template slot-scope="{ row }">
              <span v-if="row.error" class="text-danger font-mini">{{row.error}}</span>
              <i v-else class="el-icon-check text-success font-large"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :disabled="!closable">关 闭</el-button>
      <el-button type="primary" @click="handleUpload" :disabled="!closable">开始上传</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { screenshotTypes } from '../../common-attributes'
  import selectFile from '@/tools/select-file'
  import kit from '../../../../tools/kit'
  import { updateFromZip } from '../../../../http/api/bg-order'
  import { MSG_TYPE_BANGGU_ZIP_UPLOAD } from '../../../../components/stomp'

  export default {
    data () {
      return {
        visible: false,
        form: {
          payeeName: null,
          payeeAccount: null,
          screenshotType: null
        },
        selectedFile: null,
        screenshotTypes: screenshotTypes,
        processResults: [],
        processStatus: null // 处理压缩文件中的图片标记，uploading：正在上传，start：开始处理，processing：处理中，end：处理结束
      }
    },
    filters: {
      fileSize (size) {
        return kit.str.fmtSize(size)
      }
    },
    computed: {
      successResults () {
        return this.processResults.filter(item => !item.error)
      },
      errorResults () {
        return this.processResults.filter(item => !!item.error)
      },
      progressMessage () {
        if (this.uploading) {
          return `正在上传【${this.selectedFile.name}】，请稍等...`
        } else if (this.processStatus === 'start') {
          return '开始处理截图。'
        } else if (this.processStatus === 'processing') {
          return `正在处理截图，已经处理了${this.processResults.length}张。`
        } else if (this.processStatus === 'end') {
          return `所有截图已经处理完成，共 ${this.processResults.length} 张，成功 ${this.successResults.length} 张，错误 ${this.errorResults.length} 张。`
        } else {
          return ''
        }
      },
      closable () {
        return !(this.processStatus === 'uploading' || this.processStatus === 'start' || this.processStatus === 'processing')
      }
    },
    stomp: {
      [MSG_TYPE_BANGGU_ZIP_UPLOAD] (msg) {
        if (msg.scene === 'start') {
          this.processStatus = 'start'
        } else if (msg.scene === 'success') {
          let item = kit.obj.merge({}, {
            fileName: msg.data.name,
            error: null
          }, msg.data.order)
          this.processResults.push(item)
        } else if (msg.scene === 'error') {
          let item = {
            fileName: msg.data.name,
            error: msg.message
          }
          this.processResults.push(item)
        } else if (msg.scene === 'endSuccess') {
          this.$message.success('所有截图处理完毕。')
          this.processStatus = 'end'
          this.$emit('processCompleted', this.successResults)
        } else if (msg.scene === 'endError') {
          this.processStatus = 'end'
          this.$message.error(msg.message)
        }
      }
    },
    methods: {
      open () {
        this.visible = true
        this.takeDataFromLocalStorage()
      },
      handleUpload () {
        if (
          kit.str.isBlank(this.form.payeeAccount) ||
          kit.str.isBlank(this.form.payeeName) ||
          kit.str.isBlank(this.form.screenshotType) ||
          this.selectedFile == null
        ) {
          this.$message.error('请填写完整信息再上传。')
        } else {
          this.processStatus = 'uploading'
          this.processResults = []
          updateFromZip()
            .complete((success) => {
              if (!success) {
                this.processStatus = null
              }
            })
            .send(this.selectedFile, this.form)
        }
      },
      handleDialogClose () {
        this.$refs.form.resetFields()
        this.selectedFile = null
      },
      handleDialogBeforeClose (done) {
        if (this.closable) {
          done()
        } else {
          this.$message.warning('正在处理截图，请不要关闭。')
        }
      },
      handleSelectZipFile () {
        selectFile({
          accept: 'application/zip',
          multi: false,
          select: files => {
            let file = files[0]
            if (!file.name.endsWith('.zip')) {
              this.$message.error('必须是zip压缩文件。')
            } else {
              this.selectedFile = file
            }
          }
        })
      },
      saveDataToLocalStorage () {
        localStorage.setItem('usersPayeeAccount', this.form.payeeAccount)
        localStorage.setItem('usersPayeeName', this.form.payeeName)
      },
      takeDataFromLocalStorage () {
        if (!this.form.payeeAccount) {
          this.form.payeeAccount = localStorage.getItem('usersPayeeAccount')
        }
        if (!this.form.payeeName) {
          this.form.payeeName = localStorage.getItem('usersPayeeName')
        }
      },
      handleViewResult (row) {
        this.$emit('onItemClick', row)
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .screenshot-zip-dialog {
  }
  .screenshot-zip-dialog @{deep} .el-dialog {
    //display: flex;
    //flex-direction: column;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
  }
  .screenshot-zip-dialog @{deep} .el-dialog__header {
    height: 54px;
  }
  .screenshot-zip-dialog @{deep} .el-dialog__body {
    //flex: 1;
    position: relative;
    padding: 0 30px !important;
    height: 450px;
  }
  .screenshot-zip-dialog @{deep} .el-dialog__footer {
    border-top: solid 1px #e9e9e9;
    height: 58px;
    background-color: #f9f9f9;
  }
  .container {
    position: absolute;
    left: 30px;
    top: 50px;
    right: 30px;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }
  .process-progress {
    flex: 1;
    margin-left: 20px;
    .progress-message {
      height: 30px;
      line-height: 30px;
      color: #96342d;
      font-size: 12px;
    }
  }
</style>
