<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    saveButtonText="分配客户到客服"
    title="客户分配"
    @save="handleSave"
    @close="onClose"
  >
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="签约客户" prop="customerId" class="app_required-input">
        <el-select
          style="width: 100%;"
          v-model="formData.customerId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="searchCustomer"
          :loading="searchCustomerLoading"
        >
          <el-option v-for="item in searchCustomers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="分配客服" prop="userId" class="app_required-input">
        <el-select style="width: 100%;" v-model="formData.userId">
          <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="接手日期" prop="startDate">
        <el-date-picker
          style="width: 100%;"
          v-model="formData.startDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>

      <div v-if="formData.customerId">
        <split-line text="当前客户下分配过的客服人员" align="center"></split-line>
        <div style="text-align: center;">
          <i v-if="appliedCustomerServiceUsersLoading" class="el-icon-loading"></i>
          <div class="font-mini text-muted" v-if="!appliedCustomerServiceUsersLoading && appliedCustomerServiceUsers.length === 0">
            未分配过客服。
          </div>
        </div>
        <ul class="list-group" style="margin-top: 10px;" v-if="appliedCustomerServiceUsers.length > 0">
          <li class="list-group-item hover-item">
            <div class="flex-row first">
              <div class="flex-item">接手日期</div>
              <div class="flex-item">客服</div>
              <div class="flex-item">停止日期</div>
            </div>
          </li>
          <li class="list-group-item hover-item" v-for="u in appliedCustomerServiceUsers" :key="u.id">
            <div class="flex-row">
              <div class="flex-item">{{u.startDate}}</div>
              <div class="flex-item">{{u.userName}}</div>
              <div class="flex-item">
                <span style="color: #50a654;" class="font-bold" v-if="u.maintain">正在服务</span>
                <span v-else>{{u.endDate}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import {
    getCustomerServiceUserList,
    getCsCustomerByCustomerId,
    applyCustomerToUser
  } from '../../../../http/api/cs-customer'
  import ElFormItem from "element-ui/packages/form/src/form-item";

  export default {
    components: {ElFormItem},
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        searchCustomerLoading: false,
        formData: {
          userId: null,
          customerId: null,
          startDate: null
        },
        allCustomerServiceUsers: [], // 所有的客服用户
        searchCustomers: [],
        appliedCustomerServiceUsersLoading: false,
        appliedCustomerServiceUsers: [] // 选择客户后，加载该客户的已分配过的用户
      }
    },
    watch: {
      'formData.customerId' (cid) {
        if (cid) {
          this.loadAppliedCustomerServiceUsers(cid)
        } else {
          this.appliedCustomerServiceUsers = []
        }
      }
    },
    computed: {

    },
    methods: {
      open () {
        this.visible = true
        this.formData.startDate = kit.date.format(new Date())
        getCustomerServiceUserList()
          .success(resp => {
            this.allCustomerServiceUsers = resp.data
          })
          .send()
      },
      onClose () {
        this.$refs['form'].resetFields()
      },
      handleSave () {
        this.closable = false
        this.saveButtonLoading = true
        applyCustomerToUser()
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data['new'], resp.data['removed'])
            this.loadAppliedCustomerServiceUsers(this.formData.customerId)
          })
          .send(this.formData)
      },
      searchCustomer (q) {
        this.searchCustomerLoading = true
        if (!kit.str.isBlank(q)) {
          getAdCustomerListByKeyword()
            .complete(() => (this.searchCustomerLoading = false))
            .success(resp => {
              this.searchCustomers = resp.data
            })
            .send(q)
        } else {
          this.searchCustomers = []
        }
      },
      loadAppliedCustomerServiceUsers (customerId) {
        this.appliedCustomerServiceUsersLoading = true
        getCsCustomerByCustomerId()
          .complete(() => (this.appliedCustomerServiceUsersLoading = false))
          .success(resp => {
            this.appliedCustomerServiceUsers = resp.data
          })
          .send(customerId)
      }
    }
  }
</script>

<style lang="less" scoped>
  .flex-row {
    display: flex;
    flex-direction: row;
    .flex-item {
      flex: 1;
      color: #787878;
      font-size: 12px;
    }
  }
  .flex-row.first .flex-item {
    font-size: 14px;
    color: #272727;
  }
</style>
