<template>
  <page route-name="LowerPolicyList">
    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button v-authority="'LowerPolicyManage'" type="primary" icon="el-icon-plus" @click="$refs['lowerPanel'].open()">添加下游政策</el-button>
      </el-form-item>
      <el-form-item>
        <el-button v-authority="'LowerPolicyManage'" type="primary" icon="el-icon-upload" @click="selectFile">Excel文件导入</el-button>
      </el-form-item>
      <el-form-item>
        <a :href ="downloadTemplate()" class="button" v-authority="'LowerPolicyManage'"><i class="el-icon-download"></i>Excel模版文件下载</a>
      </el-form-item>
    </el-form>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="状态" prop="status">
          <el-select v-model="search.status" :clearable="true" style="width:100px;" >
            <el-option
              v-for="item in [{id: 1, label: '无效'}, {id: 2, label: '生效中'}, {id: 0, label: '过期'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true" style=" width:100px;">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker
            label="日期范围"
            v-model="search.dates"
            align="right"
            value-format="yyyy-MM-dd"
            type="daterange"
            :unlink-panels="true"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期" style="width:260px;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售人员" prop="name">
          <el-select style="width: 120px;" v-model="search.saleUserId" clearable>
            <el-option v-for="item in allSaleServiceUsers" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel()" class="button" v-authority="'DownloadExcel'" ><i class="el-icon-download"></i>导出excel</a>
        </el-form-item>
      </el-form>
    </div>
   <el-table
     ref="table"
      slot="center"
      :data="dataList"
      @expand-change="handleTableExpand"
      @row-dblclick="handleTableRowClick"
    >
      <el-table-column label="操作" width="100" v-authority="'LowerPolicyManage'">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="adPolicyWorkflowChangedCreate(3, row.workflowId,'变更')" v-if="row.status ==2 && row.workflowId!=null">变更政策</el-button>
          <el-button size="mini" @click="adPolicyWorkflowChangedCreate(2, row.workflowId,'续签')" v-if="row.status==0 && row.workflowId!=null && row.type!=4">续签政策</el-button>
          <el-button size="mini" plain v-authority="'LowerPolicyDelete'" type="danger" @click="deleteLower(row)">删除</el-button>
        </template>
      </el-table-column>
     <el-table-column type="expand">
       <template slot-scope="{ row }">
         <div style="width: 400px; margin: 0 auto;">
           <el-table v-if="row.rebateType!==3"
             :data="row.items"
             stripe
           >
             <el-table-column label="消耗" v-if="row.rebateType===2">
               <template slot-scope="{ row }">
                 <span style="color: #50a654;width: 60px;display:inline-block" >{{row.min}}</span>
                 <span style="color: #50a654;width: 20px;display:inline-block" >--</span>
                 <span style="color: #50a654;width: 60px;display:inline-block" >{{row.max}}</span>
               </template>
             </el-table-column>
             <el-table-column label="比例%" prop="percent" width="80"></el-table-column>
             <el-table-column label="分类名" prop="category" width="80"></el-table-column>
           </el-table>
         </div>
       </template>
     </el-table-column>
      <el-table-column prop="type" label="政策类型">
        <template slot-scope="{ row }">
          <span>{{row.type==1?'首次':row.type==2?'续签':row.type==3?'变更':row.type==4?'变更(已过期)':''}}</span>
        </template>
      </el-table-column>
     <el-table-column prop="contractSubjectName" label="签约主休" ></el-table-column>
     <el-table-column prop="adCustomerName" label="客户名称" ></el-table-column>
     <el-table-column prop="shortName" label="简称" ></el-table-column>
     <el-table-column prop="platformName" label="平台"></el-table-column>
     <el-table-column prop="currency" label="结算币种" ></el-table-column>
     <el-table-column prop="dateStart" label="政策开始日期" ></el-table-column>
     <el-table-column prop="dateEnd" label="政策结束日期" ></el-table-column>
     <el-table-column prop="csUserName" label="客服人员" ></el-table-column>
     <el-table-column prop="saleUserName" label="销售人员" ></el-table-column>
     <el-table-column prop="payTypeName" label="支付类型" ></el-table-column>
     <el-table-column prop="payPolicyName" label="返点结算政策" ></el-table-column>
     <el-table-column prop="rebateType" label="返点类型">
       <template slot-scope="{ row }">
         <span>{{row.rebateType==1?'比例返点':row.rebateType==2?'阶梯返点':'无返点'}}</span>
       </template>
     </el-table-column>
     <el-table-column  label="状态">
       <template slot-scope="{ row }">
         <span style="color: #b53639;" v-if="row.status==1">无效</span>
         <span style="color: #50a654;" v-else-if="row.status==2">生效中</span>
         <span  v-else-if="row.status==0">过期</span>
       </template>
     </el-table-column>
     <el-table-column  label="创建时间">
       <template slot-scope="{ row }">
         <span>{{row.createUserName}}</span><br/>
         <span>{{row.createTime}}</span>
       </template>
     </el-table-column>
     <el-table-column  label="修改时间">
       <template slot-scope="{ row }">
         <span>{{row.updateUserName}}</span><br/>
         <span>{{row.updateTime}}</span>
       </template>
     </el-table-column>
     <el-table-column prop="remark" label="备注" >
       <template slot-scope="{ row }">
         <el-button size="mini" @click="navToWorkflowDetail(flowType, row['workflowId'])" v-if="row.type>0">详情</el-button>
       </template>
     </el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <lower-editor ref="lowerPanel" @saved="onEditorSubmit"></lower-editor>
    <uploader ref="uploader" :uploadExec="uploadHandle"></uploader>
  </page>
</template>

<script>
  import LowerEditor from './comp/lower-editor'
  import kit from '../../../tools/kit'
  import { getAdPlatformListIdNameMode } from '../../../http/api/ad-platform'
  import {
    getLowerPolicyDetailList,
    getlistRebatesBypolicyId,
    deleteLowerPolicyDetail,
    uploadExcel, getSaleServiceUserList
  } from '../../../http/api/upper-lower-policy'
  import selectFile from '../../../tools/select-file'
  import { getAdCustomerListByKeyword } from '../../../http/api/ad-customer'
  import { getAdminUrl } from '../../../http/application-api'
  import { adPolicyWorkflowChanged } from '../../../http/api/workflow'
  import { FLOW_AD_POLICY } from '../../../config/flows'
  import flowDetailNavMixins from '../../../mixins/flow-detail-nav'


  export default {
    mixins: [flowDetailNavMixins],
    components: { LowerEditor },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          dates: [],
          adCustomerId: null,
          status: 2,
          saleUserId: null
        },
        platforms: [],
        loading: false,
        adCustomers: [],
        allSaleServiceUsers: [],
        flowType: FLOW_AD_POLICY
      }
    },
    computed: {
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      getSaleServiceUserList()
        .success(resp => {
          this.allSaleServiceUsers = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      initRebate (rebate) {
        rebate.items = []
        rebate.itemLoading = false
        return rebate
      },
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        if (params.dates && this.search.dates.length === 2) {
          params.startTime = this.search.dates[0] + ' 00:00:00'
          params.endTime = this.search.dates[1] + ' 23:59:59'
          delete params.dates
        }
        this.$pageLoading(true)
        getLowerPolicyDetailList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            for (let obj of resp.data.records) {
              this.initRebate(obj)
            }
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      onEditorSubmit (listlw) {
        for (let lw of listlw) {
          kit.arr.pushOrReplace(this.dataList, this.initRebate(lw))
        }
      },
      updateLower (lw) {
        this.$refs['lowerPanel'].open(lw)
      },
      deleteLower (lw) {
        this.$confirm(`确定要删除吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            deleteLowerPolicyDetail()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.removeItem(this.dataList, lw)
              })
              .send(lw.id)
          })
      },
      handleTableExpand (row) {
        if (row.expanded === undefined) {
          row.expanded = false
        }
        row.expanded = !row.expanded
        if (!row.itemLoading && row.expanded) {
          row.itemLoading = true
          getlistRebatesBypolicyId()
            .complete(() => (row.itemLoading = false))
            .success(resp => {
              row.items = resp.data
            })
            .send(row.id)
        }
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      },
      // 上传文件
      selectFile () {
        selectFile({
          accept: '.xlsx',
          multi: true,
          select: files => {
            this.$refs.uploader.upload(files)
          }
        })
      },
      uploadHandle (file, callback) {
        uploadExcel()
          .success(resp => {
            callback.success()
          })
          .error(resp => {
            callback.error(resp.message, resp.cancel)
          })
          .final(() => {
            callback.complete()
          })
          .config(cfg => {
            cfg.onUploadProgress = e => {
              callback.progress(Math.round((e.loaded * 100) / e.total))
            }
          })
          .send('lower', file.file)
      },
      downloadTemplate () {
        return getAdminUrl('/upper_policy/download/template?type=lower')
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      downloadExcel () {
        let params = Object.assign({}, this.search)
        params.start = 0
        params.limit = 500 // mybatisplus分页时，每页最大是500，些值不能超过500
        let arr = []
        for (let key of Object.keys(params)) {
          if (params[key]) {
            arr.push(key + '=' + params[key])
          }
        }
        return getAdminUrl('/lower_policy_detail/download/excel?' + arr.join('&'))
      },
      adPolicyWorkflowChangedCreate (policyType, workflowId, info) {
        this.$confirm(`确定要${info}政策吗？${info}政策会重新发起新流程`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          adPolicyWorkflowChanged()
            .success(resp => {
              if (resp.data.processed) {
                this.navToWorkflowDetail(FLOW_AD_POLICY, resp.data.workflowAdPolicy.id)
              } else {
                this.navToWorkflowDetail(FLOW_AD_POLICY, resp.data.workflowDoc.id)
              }
            })
            .send(FLOW_AD_POLICY, policyType, workflowId)
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
