<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="600"
    :height="300"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">上传数据</span>
    <div class="file-bar">
      <div class="file-box">
        <div class="block cover" @click="selectFile('facebook')">
          <i class="fa fa-plus" v-show="!fbFileId"></i>
        </div>
        <div class="label">facebook excel文件格式</div>
      </div>
      <div class="file-box">
        <div class="block cover" @click="selectFile('linkedin')">
          <i class="fa fa-plus" v-show="!linkFileId"></i>
        </div>
        <div class="label">Linkedin excel文件格式</div>
      </div>
    </div>
    <div slot="footer" style="text-align: right;">
        <el-button @click="submit">确定</el-button>
    </div>
    <uploader ref="uploader" :uploadExec="uploadHandle"></uploader>
  </slide-panel>

</template>

<script>
  import selectFile from '../../../tools/select-file'
  import {
    uploadExcel
  } from '../../../http/api/aliyun-data'
  export default {
    data () {
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        file: null,
        type: null,
        fbFileId: null,
        linkFileId: null,
        id: null
      }
    },
    computed: {
    },
    methods: {
      open () {
        this.panelVisible = true
        this.id = ''
        this.fbFileId = null
        this.linkFileId = null
      },
      submit () {
        this.$emit('saved', this.model)
        this.panelVisible = false
      },
      // 上传文件
      selectFile (type) {
        this.type = type
        selectFile({
          accept: '.xlsx',
          multi: true,
          select: files => {
            this.$refs.uploader.upload(files)
          }
        })
      },
      // 上传文件
      uploadHandle (file, callback) {
        uploadExcel()
          .success(resp => {
            this.model = resp.data
            this.id = resp.data.id
            this.fbFileId = resp.data.fbFileId
            this.linkFileId = resp.data.linkFileId
            callback.success()
          })
          .error(resp => {
            callback.error(resp.message, resp.cancel)
          })
          .final(() => {
            callback.complete()
          })
          .config(cfg => {
            cfg.onUploadProgress = e => {
              callback.progress(Math.round((e.loaded * 100) / e.total))
            }
          })
          .send(this.type, this.id, file.file)
      },
      panelBeforeClose () {
        this.model = null
      }
    }
  }
</script>

<style lang="less" scoped>
  .slide-panel {
    overflow: visible !important;
  }
  .file-bar {
    margin-top: 10px;
    text-align: center;
    @size: 80px;
    .file-box {
      display: inline-block;
      width: @size;
      height: @size;
      border: dashed 1px #ddd;
      border-radius: 3px;
    }
    .file-box + .file-box {
      margin-left: 50px;
    }
   .block {
      height: @size;
      line-height: @size;
      cursor: pointer;
      &:hover {
        background-color: #f9f9f9;
      }
      i {
        font-size: 22px;
        color: #cacaca;
      }
    }
    .block.cover {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .label {
      margin-top: 5px;
      font-size: 13px;
      color: #6d6d6d;
    }
  }
</style>
