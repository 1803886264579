<template>
  <page route-name="AdsReport">

    <el-dropdown slot="toolbar" size="mini" @command="createReport">
      <el-button type="primary">
        创建报表
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="bing">Bing Platform</el-dropdown-item>
        <el-dropdown-item command="google">Google Platform</el-dropdown-item>
        <el-dropdown-item disabled>Facebook Platform</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>


    <el-table
      slot="center"
      :data="dataList">
      <el-table-column label="操作" width="130">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="$router.push({name: 'AdsReportDetail', params: {platform: scope.row.platform}, query: {id: scope.row.id}})"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="deleteTemplate(scope.row)"></el-button>
          <el-button @click="$router.push({name: 'AdsReportDataCharts', params: {id: scope.row.id}})" size="mini">报表</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="250"></el-table-column>
      <el-table-column prop="adsCustomerTotal" label="广告账户数量" width="150"></el-table-column>
      <el-table-column prop="platform" label="平台" width="100"></el-table-column>
      <el-table-column prop="createUserName" label="创建人" width="100"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>

  import api, {
    API_ADS_REPORT_LIST,
    API_ADS_REPORT_DELETE
  } from '../../config/urls'
  import kit from '../../tools/kit'
  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        }
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      loadReports () {
        this.$pageLoading(true)
        this.$http.load(api(API_ADS_REPORT_LIST), {
          params: {
            start: this.page.start,
            limit: this.page.limit
          },
          completeBefore: () => this.$pageLoading(false),
          success: resp => {
            this.dataList = resp['data']
            this.page.total = resp['total']
          }
        })
      },
      createReport: function (platform) {
        this.$router.push({ name: 'AdsReportDetail', params: { platform: platform } })
      },
      deleteTemplate (row) {
        this.$confirm(`确定要删除[${row.name}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            this.$http.del(api(API_ADS_REPORT_DELETE, { id: row.id }), {
              completeBefore: () => this.$pageLoading(false),
              success: () => kit.arr.removeItem(this.dataList, row)
            })
          })
      }
    },
    mounted () {
      this.loadReports()
      this.$dispatcher.listen('AdsReportTemplateUpdateEvent', 'AdsReportTemplateUpdateListenerId', data => {
        kit.arr.pushOrReplace(this.dataList, data)
      })
    },
    beforeDestroy () {
      this.$dispatcher.removeListen('AdsReportTemplateUpdateEvent', 'AdsReportTemplateUpdateListenerId')
    }
  }
</script>

<style scoped>

</style>
