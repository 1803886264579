<template>
  <page route-name="Elasticsearch">

    <div slot="toolbar">
      <el-button size="mini" @click="$router.push({name: 'IkWord'})">IK分词管理</el-button>
    </div>

    <div slot="top" style="padding: 12px 16px; border-bottom: solid 1px #eeeeee;">
      <el-checkbox v-for="idx in indices" :key="idx.id" v-model="idx.checked">{{idx.name}}</el-checkbox>
      <div style="margin-top: 10px;">
        <div style="display: inline-block; width: 800px; margin-right: 10px;">
          <el-input placeholder="请输入数据模型的Id，多个用英文状态逗号分隔" v-model="indexDocumentIds">
            <el-button type="primary" @click="handleUpdateIndex" slot="append" :disabled="indexing">更新索引</el-button>
          </el-input>
        </div>

        <el-dropdown @command="handleFullIndex" size="large">
          <el-button type="primary" size="large">
            全量索引
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="true" :disabled="indexing">Rebuild</el-dropdown-item>
            <el-dropdown-item :command="false" :disabled="indexing">ReIndex</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <el-alert type="error" :closable="false" style="margin-top: 10px;">
        全量索引：Rebuild会删除现有索引，然后创建新的索引，重新同步数据到索引库里。ReIndex只做Elasticsearch的reindex操作。
        不管哪种索引，在过程中用户都无法再更新文档，但不影响查询。
      </el-alert>
    </div>

    <div slot="center">

      <el-card class="index-log" v-for="item in indexingItems" :key="item.index">
        <div slot="header">
          <span>{{item.title}}</span>
          {{item.progress.position}} / {{item.progress.total}}
        </div>
        <div class="log-row text-muted font-mini">
          <p v-for="msg in item.logs">{{msg}}</p>
        </div>
      </el-card>

    </div>
  </page>
</template>

<script>
  import {
    getElasticsearchIndices,
    rebuildElasticsearchIndex,
    updateDocument
  } from "../../../http/api/elasticsearch"
  import { MSG_TYPE_ELASTICSEARCH_FULL_INDEX } from "../../../components/stomp";
  import kit from '../../../tools/kit'

  export default {
    data () {
      return {
        indices: [],
        indexDocumentIds: null,
        indexingItems: [],
        indexing: false
      }
    },
    computed: {
      selectedIndices () {
        return this.indices.filter(i => i.checked)
      }
    },
    methods: {
      loadIndices () {
        getElasticsearchIndices()
          .success(resp => {
            for (let idx of resp.data) {
              idx.checked = false
            }
            this.indices = resp.data
          })
          .send()
      },
      handleFullIndex (command) {
        let aliases = this.selectedIndices.map(i => i.id)
        if (aliases.length === 0) {
          this.$alert('请选择要全量索引的索引仓库。', { type: 'warning' })
        } else {
          this.$confirm('重建索引的过程中，用户无法更新数据，确定要操作吗？', '确认', { type: 'warning' })
            .then(() => {

              this.indexingItems = []
              this.selectedIndices.forEach(item => {
                this.indexingItems.push({
                  index: item.id,
                  title: item.name,
                  progress: {
                    total: 0,
                    position: 0
                  },
                  logs: []
                })
              })

              rebuildElasticsearchIndex()
                .success(() => {

                })
                .final((success) => {
                  if (!success) {
                    this.indexing = false
                  }
                })
                .send(aliases.join(','), command)
            })
            .catch(() => {})
        }
      },
      handleUpdateIndex () {
        let aliases = this.selectedIndices.map(i => i.id)
        if (aliases.length === 0) {
          this.$alert('请选择文档所在的索引库。', { type: 'warning' })
        } else if (aliases.length > 1) {
          this.$alert('请选择一个索引库，不要多选。', { type: 'warning' })
        } else {
          if (kit.str.isBlank(this.indexDocumentIds)) {
            this.$alert('请输入文档Id，多个Id用英文状态的逗号分隔。', { type: 'warning' })
          } else {
            this.$pageLoading(true)
            updateDocument()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('更新成功。')
              })
              .send(aliases[0], this.indexDocumentIds)
          }
        }
      }
    },
    mounted () {
      this.loadIndices()
    },
    stomp: {
      [MSG_TYPE_ELASTICSEARCH_FULL_INDEX] (msg) {
        if (msg.scene === 'allCompleted') {
          this.indexing = false
          this.$alert('索引构建结束。', {type: 'success'})
        } else {
          let indexAlias = msg.data.index
          let indexItem = this.indexingItems.filter(i => i.index === indexAlias)[0]
          if (indexItem) {
            switch (msg.scene) {
              case 'success':
                indexItem.progress.total = msg.data.total
                indexItem.progress.position = msg.data.pos
                break;
              case 'error':
                indexItem.progress.total = msg.data.total
                indexItem.progress.position = msg.data.pos
                indexItem.logs.push('错误：' + msg.data.docId + '，异常：' + msg.message)
                break;
              case 'startIndex':
                this.indexing = true
                indexItem.logs.push('开始索引')
                break;
              case 'completeIndex':
                indexItem.logs.push('索引完成')
                break;
            }
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>

  @deep: ~'>>>';


  .index-log {
    max-height: 300px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .index-log @{deep} .el-card__body {
    flex: 1;
    overflow: auto;
  }


  .log-row p {
    margin-bottom: 8px;
  }
</style>
