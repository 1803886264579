<!--
  工作流收藏列表页面。
-->
<template>
  <page :page-title="pageTitle" route-name="FavoriteFlows">

    <!--<el-button slot="toolbar" icon="el-icon-plus" @click="$refs.editor.open()" type="primary">创建</el-button>-->
    <!--<el-button slot="toolbar" icon="el-icon-news" @click="$refs.diagram.open($route.params['type'], 0)">流程图</el-button>-->
    <el-button slot="toolbar" icon="el-icon-refresh" @click="load()"></el-button>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search">
        <el-form-item label="流程标题">
          <el-input v-model="search.title" style="width: 150px;"></el-input>
        </el-form-item>
        <el-form-item label="重要等级" prop="priority">
          <el-select v-model="search.priority" placeholder="请选择" :clearable="true">
            <el-option label="紧急" value='1'></el-option>
            <el-option label="重要" value='2'></el-option>
            <el-option label="一般" value='3'></el-option>
            <el-option label="普通" value='4'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="search.status" :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in [{id: 'open', title: '正在走流程'}, {id: 'completed', title: '已完成'}, {id: 'close', title: '已作废'}]"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div slot="center">
      <el-table
        slot="center"
        class="table"
        :row-class-name="tableRowClassName"
        :data="dataList"
      >
        <el-table-column width="90" label="操作">
          <template slot-scope="{ row }">
            <el-dropdown split-button
                         type="default"
                         size="mini"
                         placement="bottom-start"
                         @click="navToWorkflowDetail('Favorite', row.id)"
                         @command="handleRowButtonCommand"
            >
              详情
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{cmd: 'updateTitle', row: row}">修改标题</el-dropdown-item>
                <el-dropdown-item :command="{cmd: 'updateFav', row: row}">修改收藏信息</el-dropdown-item>
                <el-dropdown-item :command="{cmd: 'cancelFav', row: row}">取消收藏</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <div class="row-details">
              <div>版本：{{row.version}}</div>
              <div>标签：<el-tag :key="row.id + '_' + tag" size="mini" type="info" v-for="tag in row.tagList">{{tag}}</el-tag></div>
              <div>备注：{{row.favoriteRemark}}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="title" label="标题">
          <template slot-scope="{ row }">
            <span v-if="row.status === 'close'" style="font-weight: bold; color: #c01b0a;">【已作废】</span>
            <a href="javascript:void(0)" @click="navToWorkflowDetail('Favorite', row.id)">{{row.title}}</a>
          </template>
        </el-table-column>
        <el-table-column label="处理提示" width="100">
          <template slot-scope="{ row }">
            <span v-if="row.status === 'open'">
              <span v-if="row.currentUserCanProcessNextStage" style="font-weight: bold; color: #c16136;">需要处理</span>
              <span v-else-if="row.currentUserProcessed" style="font-weight: bold; color: #374e86;">已处理过</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="nextStage" label="下阶段" width="150">
          <template slot-scope="scope">
            {{scope.row | nextStage}}
          </template>
        </el-table-column>
        <el-table-column label="最终审批" width="80">
          <template slot-scope="{ row }">
            <span v-if="row.reject === 1" style="font-weight: bold; color: #c48116;">拒绝</span>
            <span v-if="row.reject === 0" style="font-weight: bold; color: #45874d;">通过</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人" width="120"></el-table-column>
        <el-table-column prop="createDate" label="创建日期" width="90">
          <template slot-scope="scope">{{scope.row.createDate.substr(0,10)}}</template>
        </el-table-column>
        <el-table-column prop="completeDate" label="完成日期" width="90">
          <template slot-scope="{ row }">
            <span v-if="row.completeDate">
              {{row.completeDate.substr(0,10)}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="favoritePriority" label="重要等级" width="50">
          <template slot-scope="scope">
            <span v-if="scope.row.favoritePriority === 1" >紧急</span>
            <span v-if="scope.row.favoritePriority === 2" >重要</span>
            <span v-if="scope.row.favoritePriority === 3" >一般</span>
            <span v-if="scope.row.favoritePriority === 4" >普通</span>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :title="dirTitel" :visible.sync="dialogFormVisible"　width="40%" center :modal=false >
        <el-form  ref="form" :model="form"　label-width="80px">
          <el-form-item label="收藏夹" prop="workflowDirId" >
            <el-select v-model="form.workflowDirId"  placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in dirs"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="重要等级" prop="priority">
            <el-select v-model="form.priority" placeholder="请选择" style="width: 100%">
              <el-option label="紧急" value='1'></el-option>
              <el-option label="重要" value='2'></el-option>
              <el-option label="一般" value='3'></el-option>
              <el-option label="普通" value='4'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="form.remark" :rows="5"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitFavorite()">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

    <flow-editor
      ref="editor"
      :flow-type="flowType"
      :title-advise="titleAdvise"
      @create="onFlowCreated">
    </flow-editor>
    <flow-diagram ref="diagram"></flow-diagram>
  </page>
</template>

<script>
  import FlowEditor from '../comps/editor'
  import FlowDiagram from '../flow-diagram'
  import flowDetailNavMixin from '../../../../mixins/flow-detail-nav'
  import kit from '../../../../tools/kit'
  import api, {
    API_WORKFLOW_FAVORITE_UPDATE,
    API_WORKFLOW_DIR_LIST_BY_USER,
    API_WORKFLOW_FAVORITE_LIST,
  　API_WORKFLOW_FAVORITE_DELETE
  } from '@/config/urls'

  export default {
    mixins: [flowDetailNavMixin],
    components: { FlowEditor, FlowDiagram },
    data () {
      return {
        pageTitle: '',
        titleAdvise: '',
        dataList: [],
        flowType: '',
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          title: null,
          status: null,
          priority: null,
          dirId: this.$route.params['dirId']
        },
        dialogFormVisible: false,
        form: {
          id: '',
          remark: '',
          priority: '4',
          workflowDirId: '',
          workflowId: ''
        },
        dirs: [],
        dirTitel: ''
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    filters: {
      nextStage (flow) {
        switch (flow.status) {
        case 'close': return '已作废'
        case 'completed': return '已完成'
        default: return flow['nextStageTitle']
        }
      }
    },
    methods: {
      tableRowClassName ({ row }) {
        switch (row.favoritePriority) {
          case 1:
            return 'urgent-row'
          case 2:
            return 'important-row'
          case 3:
            return 'general-row'
        }
        return ''
      },
      onFlowCreated (flow) {
        kit.arr.insert(this.dataList, 0, flow)
        this.$router.push({ name: 'FavoriteWorkflowDetail', params: { id: flow.id } })
      },
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        this.$http.get(api(API_WORKFLOW_FAVORITE_LIST), {
          params: params,
          completeBefore: () => this.$pageLoading(false),
          success: (resp) => {
            this.page.total = resp.total
            this.dataList = resp.data
          }
        })
      },
      loadDir () {
        this.$http.get(api(API_WORKFLOW_DIR_LIST_BY_USER), {
          success: resp => {
            this.dirs = resp
            let old = this.dirs.find(o => o['id'] === this.search.dirId)
            this.pageTitle = old.title
          }
        })
      },
      openDialog(row){
        this.dialogFormVisible = true
        this.form.workflowId = row.id
        this.form.remark = row.favoriteRemark
        this.form.priority = (row.favoritePriority).toString()
        this.form.workflowDirId =row.workflowDirId
        this.form.id = row.favoriteId
        this.dirTitel = row.title
      },
      submitFavorite(){
        this.$http.put(api(API_WORKFLOW_FAVORITE_UPDATE, { id: this.form.id }), {
          data: this.form,
          success: resp=> {
            this.$message.success('修改收藏成功。')
            this.dialogFormVisible = false
              let old = this.dataList.find(o => o['favoriteId'] === resp['id'])
              if(old['workflowDirId']!== resp.workflowDirId){
                 kit.arr.remove(this.dataList, old) //如果修改了收藏夹目录就需要删除本页的数据记录
              } else {
                if (old) {
                  old['favoriteId'] = resp.id;
                  old['favoriteRemark'] =resp.remark
                  old['favoritePriority'] =resp.priority
                  old['workflowDirId'] =resp.workflowDirId
                }
              }
              this.$refs['form'].resetFields()
          }
        })
      },
      openEditor (row){
        this.flowType = row.type
        this.$refs.editor.open(row)
      },
      deleteFavorite (favorite) {
        this.$confirm(`确定要取消[${favorite.title}]收藏吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            this.$http.del(api(API_WORKFLOW_FAVORITE_DELETE,{ id: favorite.favoriteId }), {
              completeBefore: () => this.$pageLoading(false),
              success: () => kit.arr.remove(this.dataList, favorite)
            })
          })
      },
      handleRowButtonCommand (command) {
        switch (command.cmd) {
          case 'updateTitle':
            this.openEditor(command.row)
            break;
          case 'updateFav':
            this.openDialog(command.row)
            break;
          case 'cancelFav':
            this.deleteFavorite(command.row)
            break;
          default:
            break;
        }
      }
    },
    beforeRouteUpdate (target, source, next) {
      if (target.params['dirId'] !== this.search.dirId) {
        this.search.dirId = target.params['dirId']
        this.reload()
        this.loadDir()
      }
      next()
    },
    mounted () {
      this.load()
      this.loadDir()
    }
  }
</script>

<style scoped lang="less">
  @deep: ~'>>>';
  .table @{deep} .urgent-row {
    background: #E6DADF;
  }
  .table @{deep} .important-row {
    background: #e6e6d9;
  }
  .table @{deep} .general-row {
    background: #CCE0E6;
  }
    .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

  .row-details {
    font-size: 12px;
    color: #a1a1a1;
    > div + div {
      margin-top: 5px;
    }
  }
</style>
