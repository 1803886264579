<!--
  实现富文件编辑器的图片上传功能，只需要将本组件注册为子组件放在页面中并提供编辑器ID即可。
  @see ./rich-editor.js
  @see /public/script/ckeditor-extend/plugins/image-upload-adapter
-->
<template>
  <transition name="fade">
    <div class="image-upload-container" v-show="open">
      <div class="panel">
        <i class="el-icon-close close" @click="close"></i>
        <el-upload
          class="image-uploader"
          ref="uploader"
          multiple
          name="upload"
          :action="descriptionImageUploadUrl"
          accept="image/png,image/jpeg"
          :headers="{'X-User-Token': token}"
          :show-file-list="false"
          :limit="10"
          :on-success="onFileUpload"
          :on-error="onFileUploadError"
          :on-change="onFileUploadChanged">
        </el-upload>
        <div class="image-container">
          <div class="image" v-for="url in fileList" :key="url" :style="`background-image:url(${url})`"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import RichEditor from '../components/rich-editor'
  import { userStore } from '../store'
  import Dom from '../tools/dom'
  import { descriptionImageUploadUrl } from '../http/api/description-image'

  export default {
    props: {
      richEditorId: { type: String, required: true }
    },
    data () {
      return {
        fileList: [],
        open: false,
        descriptionImageUploadUrl: descriptionImageUploadUrl()
      }
    },
    computed: {
      ...userStore.mapState(['token'])
    },
    methods: {
      close () {
        this.$refs.uploader.abort()
        this.$refs.uploader.clearFiles()
        this.fileList = []
        this.open = false
      },
      onFileUpload (file) {
        if (file.uploaded === 0) {
          this.$message.error(file.error.message)
        } else {
          this.fileList.push(file.url)
          this.editor.insertContent(`<img src="${file.url}">`)
        }
      },
      onFileUploadError (err) {
        this.$message.error(err.message)
      },
      onFileUploadChanged (file, fileList) {
        for (let f of fileList) {
          if (f.status !== 'success') {
            return
          }
        }
        this.close()
      }
    },
    mounted () {
      RichEditor.onReady(this.richEditorId, richEditor => {
        richEditor.get()['exec_command'] = () => {
          this.open = true
          this.editor = richEditor
          new Dom(this.$refs.uploader.$el.getElementsByClassName('el-upload')[0]).trigger('click', 250)
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in below version 2.1.8 */ {
    opacity: 0
  }

  .image-upload-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    padding-top: 50px;
    overflow: auto;
    background-color: rgba(255,255,255,.5);
  }
  .panel {
    position: relative;
    width: 500px;
    min-height: 200px;
    padding: 0 20px 10px 20px;
    margin: 50px auto 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    background-color: #fff;
  }
  .close {
    position: absolute;
    top: -12px;
    right: -12px;
    padding: 6px;
    border-radius: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    color: #fff;
    background-color: #373737;
    cursor: pointer;
    &:hover {
      background-color: #404040;
    }
    &:active {
      background-color: #373737;
    }
  }
  .image-container {
    .image {
      display: inline-block;
      width: 70px;
      height: 70px;
      margin-right: 15px;
      margin-bottom: 150px;
      background: no-repeat center;
      background-size: contain;
      border: solid 1px #ddd;
    }
  }
</style>
