import { createWorkflowDetailRouters } from './workflow'
import Todo from '../../pages/office-app/todo'

/**
 * 待办事项的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'TodoWorkflowDetail', params: {flowType: t, flowId: id}})
 *
 * 如果使用mixin：
 * this.navToWorkflowDetail('_CUSTOM_Todo', flowId, flowType)
 * @see /src/mixin/flow-detail-nav.js
 */
export default [
  {
    name: 'Todo',
    path: '/todo',
    meta: { title: '待办事项' },
    component: Todo,
    children: [
      createWorkflowDetailRouters('flowId', '_CUSTOM_Todo')
    ]
  }
]
