import Workflow from '../../pages/office-app/workflow'
import WorkflowDetail from '../../pages/office-app/workflow/detail'
import StageDetail from '../../pages/office-app/workflow/detail/stage-detail'
import ProcessStage from '../../pages/office-app/workflow/detail/process'

/**
 * 背景：
 * ---------------------------------------------
 * 流程详情页作为可复用组件，会作为子路由出现在不同的父路由下，如：
 * "广告客户"页面，点击"客户合同详情"会跳转到"流程详情"页，"请假查询"页面，点击列表项也会跳转到"流程详情"页。
 *
 * 解决方案：
 * ---------------------------------------------
 * 将"流程详情"页面以及其下面的子路由页面都定义在需要使用到"流程详情"页面的路由下，并通过一些参数来设置子路由
 * 配置。"流程详情"路由和其子路由都会得到三个prop参数：
 *   flowIdName {String} 流程ID的参数名
 *   flowId {String} 流程ID
 *   flowType {String} 流程类型，由createWorkflowDetailRouters函数的第二个参数提供
 *
 * 需要注意：路由的名称会使用flowType和固定值来组成，下面是路由名称列表：
 *   flowType + 'WorkflowDetail' 流程详情页面
 *   flowType + 'StageDetail' 流程阶段详情页面
 *   flowType + 'CombineStageDetail' 流程组合阶段详情页面
 *   flowType + 'ProcessStage' 处理流程阶段页面
 *
 * @see /src/pages/office-app/workflow/detail/mixins.js
 * @param flowIdName
 * @param flowType
 *   如果是Auto，那么flowType属性会从路由的params['type']中获取，
 *   如果是_CUSTOM_开头，如"_CUSTOM_Todo"，那么flowType会从路由的params['flowType']获取，
 *   其他为流程的类型，参考/src/config/flows.js定义的常量。
 * @return
 */
export const createWorkflowDetailRouters = function (flowIdName, flowType) {
  let routeName = name => `${flowType || ''}${name}`
  let props = (route) => {
    return {
      flowIdName: flowIdName,
      flowId: route.params[flowIdName],
      flowType: flowType
    }
  }
  let flowPrefix
  if (flowType === 'Auto') {
    flowPrefix = ''
  } else if (flowType.startsWith('_CUSTOM_')) {
    flowPrefix = ':flowType/flow/'
  } else {
    flowPrefix = 'flow/'
  }
  return {
    name: routeName('WorkflowDetail'),
    path: `${flowPrefix}:${flowIdName}`,
    component: WorkflowDetail,
    meta: { title: '流程详情' },
    props: props,
    children: [
      {
        name: routeName('StageDetail'),
        path: 'stage/:stageId',
        component: StageDetail,
        meta: { title: '流程阶段详情' },
        props: props
      },
      {
        name: routeName('ProcessStage'),
        path: 'next_stage',
        component: ProcessStage,
        meta: { title: '处理流程' },
        props: props
      }
    ]
  }
}

export default [
  {
    name: 'Workflow',
    path: '/flow/:type',
    meta: { title: '流程' },
    component: Workflow,
    children: [
      createWorkflowDetailRouters('id', 'Auto')
    ]
  }
]
