<template>
  <slide-panel ref="panel" :visible.sync="openPasswordPanel" :width="400" :height="270" position="top">
    <span slot="title">修改密码</span>
    <el-form ref="form" :model="form" :rules="rules" status-icon label-width="100px">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input type="password" v-model="form.oldPassword" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="form.newPassword" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="form.confirmPassword" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button type="primary" @click="submit" :loading="submitting">保存修改</el-button>
      </el-form-item>
    </el-form>
  </slide-panel>
</template>

<script>
  import { updatePassword, logout } from '../http/api/manager'
  import { ROUTE_LOGIN } from '../router/router-constants'

  export default {
    data () {
      let r = this.$elConfig.formRule
      let validateConfirmPwd = (rule, value, callback) => {
        if (this.form.newPassword !== value) {
          callback(new Error('确认密码和新密码不相同。'))
        } else {
          callback()
        }
      }
      return {
        openPasswordPanel: false,
        submitting: false,
        form: {
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        },
        rules: {
          oldPassword: [r.required()],
          newPassword: [r.required(), r.size(8, 16)],
          confirmPassword: [r.required(), { validator: validateConfirmPwd }]
        }
      }
    },
    methods: {
      submit () {
        this.$refs.form.validate(valid => {
          if (!valid) return
          this.submitting = true
          this.$refs.panel.setCloseable(false)
          updatePassword()
            .complete(() => {
              this.$refs.panel.setCloseable(true)
              this.submitting = false
            })
            .success(() => {
              this.openPasswordPanel = false
              this.$confirm('密码修改成功，是否重新登录？')
                .then(() => {
                  logout()
                    .success(() => {
                      this.$router.push({ name: ROUTE_LOGIN })
                    })
                    .send()
                })
            })
            .send(this.form.oldPassword, this.form.newPassword)
        })
      },
      open () {
        this.openPasswordPanel = true
      }
    },
    mounted () {
      this.$parent.$on('beforeClose', () => {
        this.$refs.form.resetFields()
      })
    }
  }
</script>

<style scoped>

</style>
