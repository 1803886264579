import defineApi from '../define-api'
import http from '../application-api'

export const getProfileTemplateList = defineApi((config, name) => {
  config.params = { name: name }
  return http.load('/profile/template/list', config)
})

export const addProfileTemplate = defineApi((config, form) => {
  config.data = form
  http.post('/profile/template/add', config)
})

export const updateProfileTemplate = defineApi((config, form) => {
  config.data = form
  http.post('/profile/template/update', config)
})

export const deleteProfileTemplate = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/profile/template/delete', config)
})
export const getProfileTemplatePropsList = defineApi((config, profileTemplateId) => {
  config.params = { profileTemplateId: profileTemplateId }
  return http.load('/profile/template/get/props', config)
})
export const getProfileTemplatePropsListById = defineApi((config, id) => {
  config.params = { id: id }
  return http.load('/profile/template/get/props/id', config)
})


