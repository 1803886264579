<template>
  <modal-panel
    :visible.sync="visible"
    :disableFooterButton="true"
    :closable="closable"
    title="流程查询选项"
    @close="onClose"
  >
    <el-form ref="form" :model="search" label-width="120px">
      <el-form-item label="关键字" prop="keyword">
        <el-input v-model="search.keyword" :clearable="true"/>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select v-model="search.status" :clearable="true" style="width: 100%;">
          <el-option
            v-for="item in [{id: 'open', title: '进行中'}, {id: 'completed', title: '已完成'}, {id: 'close', title: '已作废'}]"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="流程阶段" prop="stage">
        <el-select v-model="search.stage" :clearable="true" style="width: 100%;">
          <el-option
            v-for="item in flowStageConfigs"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="审批结果" prop="reject">
        <el-select v-model="search.reject" :clearable="true" style="width: 100%;">
          <el-option
            v-for="item in [{id: false, title: '通过'}, {id: true, title: '拒绝'}]"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="推广平台" prop="platformId">
        <el-select v-model="search.platformId" :clearable="true" style="width: 100%;">
          <el-option
            v-for="item in platformOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="账期类型" prop="rechargePayType" v-show="isRechargeFlow">
        <el-select v-model="search.rechargePayType" :clearable="true" style="width: 100%;">
          <el-option
                  v-for="item in rechargePayTypeOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="客户" prop="customerId">
        <el-select
          v-model="search.customerId"
          filterable
          remote
          :clearable="true"
          placeholder="请输入关键词"
          :remote-method="handleSearchCustomer"
          :loading="searchCustomerLoading"
          style="width: 100%;"
        >
          <el-option v-for="item in searchCustomers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="创建人员" prop="createUserId"  v-show="isSignContractFlow">
        <el-select  v-model="search.createUserId" clearable  style="width: 100%;">
          <el-option v-for="item in allSaleServiceUsers" :key="item.id" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="创建日期" prop="createDate">
        <el-date-picker
          v-model="search.createDate"
          type="daterange"
          align="right"
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 100%;"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="只看需要我处理" prop="processable">
        <el-switch v-model="search.processable"></el-switch>
      </el-form-item>
      <el-form-item label="只看我创建的" prop="selfCreateable">
        <el-switch v-model="search.selfCreateable"></el-switch>
      </el-form-item>

      <el-form-item label="排序" prop="orderName">
        <el-select v-model="search.orderName" :clearable="true" style="width: 49%;">
          <el-option
            v-for="item in orderNameOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="search.orderDir" :clearable="true" style="width: 48%; margin-left: 5px;">
          <el-option
            v-for="item in orderDirOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="margin-top: 20px;">
        <el-button size="medium" type="primary" style="width: 49%;" @click="handleSearch">查询</el-button>
        <el-button size="medium" style="width: 49%;" @click="handleClear">清空选项</el-button>
      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import { getSearchFlowPlatformOptions } from '../../../../http/api/workflow'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import { FLOW_RECHARGE, FLOW_SIGN_CONTRACT } from '../../../../config/flows'
  import { getSaleServiceUserList } from '../../../../http/api/upper-lower-policy'

  export default {
    props: {
      flowStageConfigs: Array,
      flowType: String
    },
    data () {
      return {
        visible: false,
        closable: true,
        search: {
          keyword: null,
          stage: null,
          status: null,
          reject: null,
          processable: null,
          platformId: null,
          orderName: null,
          orderDir: null,
          createDate: [],
          customerId: null,
          rechargePayType: null,
          selfCreateable: null,
          createUserId: null
        },
        platformOptions: [],
        orderNameOptions: [
          { id: 'createDate', title: '创建日期' },
          { id: '_score', title: '匹配相似度' }
        ],
        rechargePayTypeOptions: [
          { id: 'payLater', title: '账期' },
          { id: 'imprest1', title: '预付' },
          { id: 'imprest2', title: '预付（我方垫款）' }
        ],
        searchCustomerLoading: false,
        searchCustomers: [],
        allSaleServiceUsers: []
      }
    },
    computed: {
      orderDirOptions () {
        if (this.search.orderName === '_score') {
          return [
            { id: 'desc', title: '最相似' },
            { id: 'asc', title: '最不相似' }
          ]
        } else {
          return [
            { id: 'desc', title: '降序' },
            { id: 'asc', title: '升序' }
          ]
        }
      },
      isRechargeFlow () {
        return this.flowType === FLOW_RECHARGE
      },
      isSignContractFlow () {
        return this.flowType === FLOW_SIGN_CONTRACT
      }
    },
    watch: {
    },
    methods: {
      open (params) {
        this.visible = true
        kit.obj.merge(this.search, params)
        getSearchFlowPlatformOptions()
          .success(resp => {
            this.platformOptions = resp.data
          })
          .send(this.flowType)
      },
      clearSearchForm () {
        this.$refs['form'].resetFields()
        this.search.orderDir = null
      },
      onClose () {
        this.clearSearchForm()
      },
      handleSearch () {
        this.$emit('search', this.search)
      },
      handleClear () {
        this.clearSearchForm()
        this.$emit('clear', this.search)
      },
      handleSearchCustomer (q) {
        this.searchCustomerLoading = true
        getAdCustomerListByKeyword()
          .complete(() => (this.searchCustomerLoading = false))
          .success(resp => {
            this.searchCustomers = resp.data
          })
          .send(q)
      }
    },
    mounted () {
      getSaleServiceUserList()
        .success(resp => {
          this.allSaleServiceUsers = resp.data
        })
        .send()
    }
  }
</script>

<style scoped>

</style>
