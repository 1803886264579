import defineApi from '../define-api'
import http from '../application-api'

export const getPaymentRemindList = defineApi((config, params) => {
  config.params = params
  return http.load('/payment_remind/list', config)
})

export const cancelPaymentRemind = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/payment_remind/cancel', config)
})

export const processCheckPaymentRemind = defineApi((config, id, payedDate, description) => {
  config.params = {
    id: id,
    description: description,
    payedDate: payedDate
  }
  http.post('/payment_remind/checking', config)
})
// 财务收款确认
export const processFinancePaymentRemind = defineApi((config, id, actualPrice, currency, description) => {
  config.params = {
    id: id,
    actualPrice: actualPrice,
    currency: currency,
    description: description
  }
  http.post('/payment_remind/finance', config)
})

// 财务收款确认详情
export const processDetailPaymentRemind = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/payment_remind/detail', config)
})
