<template>
  <page route-name="AdsReportDataCharts" :use-tcb-layout="false">
    <el-row style="border-bottom: solid 1px #f2f2f2;">
      <el-col :span="12">
        <div style="color: #343434; font-weight: bold; padding-top: 10px;">
          <span>{{reportTemplate ? reportTemplate.name : ''}}</span>的数据统计
        </div>
      </el-col>

      <el-col :span="12">
        <el-form :inline="true" :model="searchForm" style="float: right;">
          <el-form-item label="统计日期">
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              value-format="yyyy-MM-dd"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="datePickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loadReport">开始统计</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- 总计折线统计图 -->
    <div style="overflow-x: auto; position: relative; margin-top: 20px;" v-show="report.rows.length > 0">
      <div class="section-title">所有客户每日消耗总计折线图</div>
      <total-line-chart :chart-data="totalLineChartData"></total-line-chart>
    </div>

    <div class="section-title">广告客户消耗明细表</div>
    <el-table
      v-show="report.customers.length > 0"
      :data="report.rows"
      stripe
      show-summary
      :summary-method="getReportSummaries"
      style="width: 100%"
    >
      <el-table-column fixed width="50">
        <template slot-scope="s">
          <el-button size="mini" type="primary" @click="showBarChartWithRow(s.row)">图表</el-button>
        </template>
      </el-table-column>
      <el-table-column fixed label="日期" width="80">
        <template slot-scope="s">
          <span style="font-weight: bold; color: #3171d4;">{{s.row.date}}</span>
        </template>
      </el-table-column>
      <el-table-column fixed label="总计" width="100">
        <template slot-scope="s">
          <span style="font-weight: bold;">{{s.row.total | formatCost(reportCurrency)}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="cust" width="110" :key="cust" v-for="(cust, index) in report.customers">
        <template slot-scope="s">
          {{s.row.spends[index] | formatCost(reportCurrency)}}
        </template>
      </el-table-column>
    </el-table>

    <!-- Bar图表对话框，显示表格里的某一行数据 -->
    <el-dialog
      :title="chartOfRowDialog.title"
      :modal-append-to-body="false"
      :visible.sync="chartOfRowDialog.visible"
    >
      <daily-cost-bar-chart :chart-data="dailyCostBarChartData" style="height: 300px;"></daily-cost-bar-chart>
      <div slot="footer" style="text-align: center;">
        <el-button @click="showBarChartWithPrevRow" type="primary">
          <i class="el-icon-arrow-left"></i>
          上一个
        </el-button>
        <el-button @click="showBarChartWithNextRow" type="primary">
          下一个
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </el-dialog>

  </page>
</template>

<script>
  import kit from '../../tools/kit'
  import TotalLineChart from './total-line-charts'
  import DailyCostBarChart from './daily-cost-bar-charts'
  import api, {
    API_ADS_REPORT_GET_BY_ID,
    API_ADS_REPORT_REPORTS
  } from '../../config/urls'
  export default {
    components: { TotalLineChart, DailyCostBarChart },
    data () {
      return {
        chartOfRowDialog: {
          title: '',
          visible: false,
          displayRow: null
        },
        report: {
          customers: [],
          rows: [] // {date: String, total: Number, spends: Array}
        },
        dailyCostBarChartData: {},
        totalLineChartData: {},
        reportTemplate: null, // 报表模版对象
        reportCurrency: '', // 报表中的金额货币符号
        searchForm: {
          date: null
        },
        datePickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 8)
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 31)
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 91)
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '上一个月',
            onClick (picker) {
              let start = kit.date.prevMonth()
              let end = kit.date.toLastDayInMonth(start)
              picker.$emit('pick', [start, end])
            }
          }]
        }
      }
    },
    filters: {
      formatCost: function (value, currency) {
        return kit.str.fmtCurrency(value, 2, '')
      }
    },
    methods: {
      loadReport: function () {
        if (this.searchForm.date === null || this.searchForm.date.length !== 2) {
          return
        }
        let reportTemplateId = this.$route.params.id
        this.$pageLoading(true, '正在加载报表，可能需要1～5分钟（根据你选择的时间范围）...')
        this.$http.get(api(API_ADS_REPORT_REPORTS, { id: reportTemplateId }), {
          timeout: 1000 * 60 * 15,
          params: {
            startDate: this.searchForm.date[0],
            endDate: this.searchForm.date[1]
          },
          success: resp => {
            if (resp.length === 0) {
              return this.$alert('没有查询到报表数据。')
            }
            this.report = resp
            this.initTotalLineChart()
          },
          complete: () => (this.$pageLoading(false))
        })
      },
      getReportSummaries: function () {
        if (this.report.rows.length > 0) {
          let tmp = ['', '合计']
          let amount = 0
          let spends = new Array(this.report.customers.length).fill(0)
          for (let row of this.report.rows) {
            amount += row.total
            for (let i = 0; i < row.spends.length; i++) {
              spends[i] += row.spends[i]
            }
          }
          tmp.push(kit.str.fmtCurrency(amount, 2))
          for (let i = 0; i < spends.length; i++) {
            spends[i] = kit.str.fmtCurrency(spends[i], 2)
          }
          tmp.push(...spends)
          return tmp
        } else {
          return ['', '合计', '']
        }
      },
      showBarChartWithRow (row) {
        let labels = [...this.report.customers]
        let data = []
        let bgColors = ['#A6D8B2', '#bebb29', '#FFB8A9', '#FFC2EF', '#b957f2', '#DAB5FF', '#7a7a7a', '#FFC15E', '#DFFF99', '#9266FF']
        for (let spend of row.spends) {
          data.push({ y: spend, currency: this.reportCurrency })
        }
        this.dailyCostBarChartData = {
          labels: labels,
          datasets: [
            { data: data, backgroundColor: bgColors }
          ]
        }
        this.chartOfRowDialog.visible = true
        this.chartOfRowDialog.title = row.date
        this.chartOfRowDialog.displayRow = row
      },
      showBarChartWithNextRow () {
        let index = this.report.rows.indexOf(this.chartOfRowDialog.displayRow)
        if (index >= 0 && index !== this.report.rows.length - 1) {
          this.showBarChartWithRow(this.report.rows[index + 1])
        } else {
          this.$message.info('已经是最后一个。')
        }
      },
      showBarChartWithPrevRow () {
        let index = this.report.rows.indexOf(this.chartOfRowDialog.displayRow)
        if (index > 0) {
          this.showBarChartWithRow(this.report.rows[index - 1])
        } else {
          this.$message.info('已经是第一个。')
        }
      },
      initTotalLineChart () {
        let labels = []
        let data = []
        for (let row of this.report.rows) {
          labels.push(row.date)
          data.push({ y: row.total, currency: this.reportCurrency })
        }
        this.totalLineChartData = {
          labels: labels,
          datasets: [
            {
              backgroundColor: '#97c0ff',
              borderColor: '#4785ff',
              fill: true,
              data: data
            }
          ]
        }
      }
    },
    mounted () {
      let id = this.$route.params.id
      this.$http.get(api(API_ADS_REPORT_GET_BY_ID, { id: id }), {
        success: resp => {
          this.reportTemplate = resp
        }
      })

      this.loadReport()
    }
  }
</script>

<style lang="less" scoped>
  .section-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
  }
</style>
