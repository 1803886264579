<template>
  <div id="app" v-loading="loading" :element-loading-text="loadingText">
    <el-container id="app-container" direction="vertical">
      <app-header></app-header>
      <el-container style="overflow: hidden;">
        <app-menu></app-menu>
        <el-main id="app-body-container" class="app-body">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import AppHeader from './layout/header'
  import AppMenu from './layout/menu'
  import store, { userStore } from './store'
  import { mapState } from 'vuex'
  import stomp, { MSG_TYPE_WORKFLOW_STAGE_TO_NEXT } from './components/stomp'
  import Push from 'push.js'

  export default {
    name: 'app',
    store,
    components: {
      AppMenu,
      AppHeader
    },
    computed: {
      ...mapState(['loading', 'loadingText']),
      ...(userStore.mapGetters(['isLogin']))
    },
    watch: {
      isLogin (v) {
        if (v) {
          stomp.connect()
        } else {
          stomp.close()
        }
      }
    },
    mounted () {
      Push.Permission.request()
    },
    created () {
      document.title = this.$shareProps.appName
      if (this.isLogin) {
        stomp.connect()
      }
    },
    stomp: {
      [MSG_TYPE_WORKFLOW_STAGE_TO_NEXT]: function (msg) {
        let data = msg.data
        let ctx = this
        Push.create('工作流程提醒', {
          body: msg.message,
          icon: '/logo.png',
          timeout: 1000 * 10,
          onClick: function () {
            window.focus()
            this.close()
            ctx.$router.push(`/flow/${data.type}/${data.flowId}`)
          }
        })
      }
    }
  }
</script>

<style lang="less">
  @import "assets/less/element-ui-hack";
  @import "assets/less/hack";
  @import "assets/less/commons";
  @import "assets/less/list-group";

  html, body, #app {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  #app {
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #app, #app-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .app-body {
    position: absolute;
    left: 250px;
    top: 60px;
    right: 0;
    bottom: 0;
    padding: 0!important;
    overflow: hidden;
  }
  ul li{
    list-style: none;
  }
  *{
    margin: 0px;
    padding: 0px;
  }
</style>
