// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import VueClipboard from 'vue-clipboard2'

// 自定义UI组件
import SlidePanel from './components/slide-panel'
import AppPage from './layout/page'
import ImageBrowser from './components/image-browser'
import Pagination from './components/pagination'
import AutoComplete from './components/auto-complete'
import Uploader from './components/file-uploader'
import FileIcon from './components/file-icon'
import FileDownload from './components/file-download'
import ModalPanel from './components/modal-panel'
import SplitLine from './components/split-line'


// 自定义组件
import SharePropertiesConfig from './config/share-properties'
import ElementUIConfig from './config/element-ui-config'
import Security from './security'
import store, { SET_LOADING } from './store'
import Dispatcher from './tools/dispatcher'
import AuthorityDirective from './directives/authority'

Vue.use(SharePropertiesConfig)
Vue.use(ElementUIConfig)
Vue.use(ElementUI, { size: 'small' })
Vue.use(SlidePanel)
Vue.use(AppPage)
Vue.use(ImageBrowser)
Vue.use(Pagination)
Vue.use(AutoComplete)
Vue.use(FileIcon)
Vue.use(FileDownload)
Vue.use(Dispatcher)
Vue.use(VueClipboard)
Vue.component(Uploader.name, Uploader)
Vue.component(ModalPanel.name, ModalPanel)
Vue.component(SplitLine.name, SplitLine)
Vue.directive('authority', AuthorityDirective)


// 为各组件实例提供一个便捷函数，来控制全屏"加载动画"的显示和隐藏
Vue.use((Vue) => {
  Vue.prototype.$loading = function (loading, text) {
    this.$store.commit(SET_LOADING, { loading: loading, text: text })
  }
  Vue.prototype.$pageLoading = function (loading, text) {
    this.$store.commit(SET_LOADING, { loading: loading, text: text, area: 'page' })
  }
})


Vue.config.productionTip = false

new Vue({
  router: Security(router),
  store,
  render: h => h(App)
}).$mount('#app')
