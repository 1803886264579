<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="400"
    :height="230"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">客户编辑</span>
    <el-form ref="form" :model="form" :rules="rules" status-icon label-width="120px">
      <el-form-item label="用户姓名" prop="userId">
        <el-popover v-model="userPopover">
          <executor ref="excutor" @change="changeUser" @close="userPopover=false" ></executor>
          <el-input type="textarea" v-model="userName" slot="reference" :disabled="true"  @click="userPopover=true">选择执行人</el-input>
        </el-popover>
      </el-form-item>
      <el-form-item label="职位" prop="title">
        <el-radio-group v-model="form.title">
          <el-radio label="1">客服</el-radio>
          <el-radio label="2">销售</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button type="primary" @click="submit" :loading="submitting">{{isUpdate ? '更新数据' : '创建数据'}}</el-button>
      </el-form-item>
    </el-form>
  </slide-panel>

</template>

<script>
  import api, { API_KPI_USER } from '../../../../config/urls'
  import Executor from './select-user'

  export default {
    components: { Executor },
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    data () {
      let r = this.$elConfig.formRule
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        form: {
          title: null,
          userId: null
        },
        userPopover: false,
        userName: null,
        rules: {
          userId: [r.required()],
          title: [r.required()]
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.$refs['excutor'].load()
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = model[k]
          }
        }
      },
      submit () {
        this.$refs.form.validate(valid => {
          if (!valid) return
          this.submitting = true
          this.panelCloseable = false
          let method, path
          if (this.isUpdate) {
            method = 'put'
            /* path = api(API_KPI_CUSTOMER_UPDATE, { id: this.model.id }) */
          } else {
            method = 'post'
            path = api(API_KPI_USER)
          }
          this.$http[method](path, {
            data: this.form,
            context: this,
            completeBefore () {
              this.panelCloseable = true
              this.submitting = false
            },
            success (data) {
              this.$message.success('保存成功。')
              this.$emit('submit', data, this.isUpdate)
              this.panelVisible = false
            }
          })
        })
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
        this.userName = null
      },
      changeUser (val) {
        if (val.length > 0) {
          this.userName = val.map(item => item.name).join(',')
          this.form.userId = val.map(item => item.id).join(',')
        }
      }
    }
  }
</script>

<style scoped>

</style>
