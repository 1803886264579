import RebateReport from '../../pages/office-app/workflow/report/rebate'
/**
 * 充值数据报表的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'RebateReport',
    path: '/rebate_report',
    meta: { title: '返点数据报表' },
    component: RebateReport
  }
]
