<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="媒体政策编辑"
    @save="onSave"
    @close="onClose"
    :width="800"
  >
    <el-form ref="form" :model="formData" label-width="150px">
      <el-form-item label="区域" prop="area" class="app_required-input">
        <el-select v-model="formData.area" >
          <el-option
            v-for="item in [{id: 1, label: '海外'}, {id: 2, label: '国内'}]"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="平台" prop="platformId" class="app_required-input">
        <el-select v-model="formData.platformId" placeholder="请选择"  >
          <el-option
            v-for="item in platforms"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理级别" prop="level" class="app_required-input">
        <el-select v-model="formData.level" >
          <el-option
            v-for="item in [{id: 1, label: '一代'}, {id: 2, label: '二代'}]"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上游代理" prop="proxyCompanyId" class="app_required-input">
        <el-select
          v-model="formData.proxyCompanyId"
          filterable
          remote
          :remote-method="searchPolicyCompanyProxy"
          :loading="loading"
          placeholder="请输入代理公司名称的关键字进行搜索"
        >
          <el-option
            v-for="item in proxyCompanys"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="签约主体" prop="contractSubjectId" class="app_required-input">
        <el-select
          v-model="formData.contractSubjectId"
          filterable
          remote
          :remote-method="searchPolicyCompanyContract"
          :loading="loading"
          placeholder="请输入签约主体名称的关键字进行搜索"
        ><el-option
          v-for="item in contractSubjects"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算货币" prop="currency" class="app_required-input">
        <el-radio-group v-model="formData.currency">
          <el-radio label="USD">美元</el-radio>
          <el-radio label="CNY">人民币</el-radio>
          <el-radio label="HKD">港币</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="政策开始日" prop="dateStart" class="app_required-input">
        <el-date-picker
          v-model="formData.dateStart"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择政策开始日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="政策结束日" prop="dateEnd" class="app_required-input">
            <el-date-picker
              v-model="formData.dateEnd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择政策结束日期">
            </el-date-picker>
       </el-form-item>
       <el-form-item label="返点结算政策" prop="payPolicyId"  class="app_required-input">
            <el-select v-model="formData.payPolicyId" placeholder="请选择"  >
              <el-option
                v-for="item in payPolicys"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
       </el-form-item>
      <el-form-item label="合同类型" prop="contractType"  class="app_required-input">
          <el-select v-model="formData.contractType" >
            <el-option
              v-for="item in [{id: 1, label: '原始合同'}, {id: 2, label: '非原始合同'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="备注说明" prop="name" >
        <el-input v-model="formData.remark" ></el-input>
      </el-form-item>
      <el-form-item label="上游返点类型" prop="rebateType" class="app_required-input">
        <el-radio-group v-model="formData.rebateType">
          <el-radio :label="2">阶梯返点</el-radio>
          <el-radio :label="1">非阶梯返点</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上游返点" prop="rebate" class="app_required-input">
        <div v-if="formData.rebateType == 2">
          <el-row >
            <el-col :span="12">消耗
            </el-col>
            <el-col :span="5" >比例%
            </el-col>
            <el-col :span="6">分类名
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="12">
              <el-input-number v-model="rebate.min"
                               controls-position="right"
                               style=" width:120px;" size="mini" :step="1001" >
              </el-input-number>
              --
              <el-input-number v-model="rebate.max"
                               controls-position="right"
                               style=" width:120px;" size="mini" :step="1000" >
              </el-input-number>
            </el-col>
            <el-col :span="5" >
              <el-input-number v-model="rebate.percent"
                               controls-position="right"
                               style=" width:120px;" size="mini" :min="1" >
              </el-input-number>
            </el-col>
            <el-col :span="5">
              <el-input v-model="rebate.category" placeholder="请输入分类名" ></el-input>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" @click="handleAddRebateItem()">添加</el-button>
            </el-col>
          </el-row>
          <el-row  v-for="item in rebateList" :key="item.id" style="margin-top: 5px;">
            <el-col :span="12">
              <el-input-number v-model="item.min"
                               controls-position="right"
                               style=" width:120px;" size="mini"  :disabled="true">
              </el-input-number>
              --
              <el-input-number v-model="item.max"
                               controls-position="right"
                               style=" width:120px;" size="mini" :disabled="true">
              </el-input-number>
            </el-col>
            <el-col :span="5" >
              <el-input-number v-model="item.percent"
                               controls-position="right"
                               style=" width:120px;" size="mini"  :disabled="true">
              </el-input-number>
            </el-col>
            <el-col :span="5">
              <el-input v-model="item.category" placeholder="请输入分类名" class="app_required-input" :disabled="true"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button type="danger" icon="el-icon-delete" @click="handleDeleteRebateItem(item)"></el-button>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-row >
            <el-col :span="12" >比例%
            </el-col>
            <el-col :span="12">分类名
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="12" >
              <el-input-number v-model="rebate.percent"
                               controls-position="right"
                               style=" width:120px;" size="mini" >
              </el-input-number>
            </el-col>
            <el-col :span="12">
              <el-input v-model="rebate.category" placeholder="请输入分类名"></el-input>
            </el-col>
          </el-row>
        </div>
      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import {
    addUpperPolicy,
    updateUpperPolicy,
    getlistRebatesBypolicyId
  } from '../../../../http/api/upper-lower-policy'
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getFlowFormDictItemListEnabled } from '../../../../http/api/workflow-form-dict'
  import { DICT_PAY_POLICY } from '../../../../config/flow-form-dict-name'
  import { getPolicyCompanyByLikenameWithType } from '../../../../http/api/policy-company'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        loading: false,
        formData: {
          id: null,
          area: null,
          level: 1,
          currency: 'CNY',
          dateStart: null,
          dateEnd: null,
          rebateType: 1,
          remark: null,
          contractSubjectId: null,
          proxyCompanyId: null,
          platformId: null,
          payPolicyId: null,
          rebateStrategyList: [],
          createUserId: null,
          updateUserId: null,
          createTime: null,
          updateTime: null,
          contractType: null
        },
        rebate: {
          id: null,
          min: null,
          max: null,
          percent: null,
          category: null,
          payPolicyId: null
        },
        rebateList: [],
        platforms: [],
        contractSubjects: [],
        proxyCompanys: [],
        payPolicys: []
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    watch: {
    },
    methods: {
      open (model) {
        this.model = model
        this.visible = true
        if (model) {
          kit.obj.getProperties(this.formData, model, ['payPolicyId'])
          this.formData.payPolicyId = Number(model.payPolicyId)
          this.getRebateInfo(model.id, model.rebateType)
          if (model.proxyCompanyName != null) {
            kit.arr.pushOrReplace(this.proxyCompanys, {
              'id': model.proxyCompanyId,
              'name': model.proxyCompanyName
            })
            this.formData.proxyCompanyId = model.proxyCompanyId
          }
          if (model.contractSubjectName != null) {
            kit.arr.pushOrReplace(this.contractSubjects, {
              'id': model.contractSubjectId,
              'name': model.contractSubjectName
            })
            this.formData.contractSubjectId = model.contractSubjectId
          }
        }
        getAdPlatformListIdNameMode()
          .success(resp => {
            this.platforms = resp.data
          })
          .send()
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.payPolicys = resp.data
          })
          .send(DICT_PAY_POLICY)
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.formData.id = null
        this.rebate = {}
        this.rebateList = []
        this.formData.rebateStrategyList = []
      },
      onSave () {
        this.saveButtonLoading = true
        this.closable = false
        if (this.formData.rebateType === 1) {
          kit.arr.pushOrReplace(this.rebateList, this.rebate)
        }
        this.formData.rebateStrategyList = this.rebateList
        let api = this.isUpdate ? updateUpperPolicy() : addUpperPolicy()
        api
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.formData.id = resp.data.id
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data)
            this.visible = false
          })
          .send(this.formData)
      },
      searchPolicyCompanyProxy (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.proxyCompanys = resp.data
            })
            .send(keyword, 1)
        }
      },
      searchPolicyCompanyContract (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.contractSubjects = resp.data
            })
            .send(keyword, 2)
        }
      },
      handleAddRebateItem () {
        if (this.rebate.min == null || this.rebate.max == null || this.rebate.percent == null) {
          return this.$message.warning('请输入全部阶梯类信息。')
        }
        this.rebate.id = kit.str.id(5)
        let item = { ...this.rebate }
        kit.arr.pushOrReplace(this.rebateList, item)
        this.rebate = {}
      },
      handleDeleteRebateItem (item) {
        kit.arr.removeItem(this.rebateList, item)
      },
      getRebateInfo (policyId, type) {
        getlistRebatesBypolicyId()
          .success(resp => {
            if (type === 2) {
              this.rebateList = resp.data
            } else {
              for (let obj of resp.data) {
                this.rebate.category = obj.category
                this.rebate.percent = obj.percent
              }
            }
          })
          .send(policyId)
      }
    }
  }
</script>

<style scoped>

</style>
