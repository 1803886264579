<template>
  <!--工具按钮-->
  <div class="task-executor-component">
    <div class="top">
      <!--用户表格-->
      <el-table ref="multipleTable"
                class="table"
                :data="dataList"
                height="360"
                @selection-change="handleSelectionChange"
                reserve-selection="true"> <!-- reserve-selection="true"-->
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="id" label="" v-if="false"></el-table-column>
        <el-table-column prop="name" label="姓名" width="140"></el-table-column>
      </el-table>
    </div>

    <div class="bottom"  >
      <el-button type="primary" @click="change">{{ok}}</el-button>
      <el-button type="primary" @click="close">{{no}}</el-button>
    </div>
  </div>
</template>

<script>
  import api,
  {
    API_KPI_SELECT_USER
  } from '@/config/urls'
  export default {
    data () {
      return {
        searchName: '',
        dataList: [],
        ok: '确定',
        no: '关闭',
        selectedDataArray: []
      }
    },
    mounted () {
      this.load()
    },
    methods: {
      load () {
        this.$http.get(api(API_KPI_SELECT_USER), {
          params: {
            name: this.searchName
          },
          success: resp => {
            this.dataList = resp
          }
        })
      },
      change () {
        this.$emit('change', this.selectedDataArray)
        this.close()
      },
      close () {
        this.load()
        this.$emit('close')
      },
      handleSelectionChange (val) {
        this.selectedDataArray = val
      }
    }
  }
</script>

<style lang="less" scoped>
  .task-executor-component {
    height: 430px;
    display: flex;
    flex-direction: column;
    .top {
      flex: 1;
      display: flex;
      flex-direction: row;
      .dept {
        width: 250px;
      }
      .table {
        flex: 1;
        overflow-y:auto;
      }
    }

    .bottom {
      height: 30px;
      width: 100%;
      text-align: center;
      padding-top: 10px;
    }
  }
</style>
