import BgOrder from '../../pages/bg-order'
import ProcessingOrder from '../../pages/bg-order/processing-order/processing-index'
import SuccessOrder from '../../pages/bg-order/success-order/success-index'

export default [
  {
    name: 'BgOrder',
    path: '/bg_order',
    meta: { title: '棒谷订单' },
    component: BgOrder,
    children: [
      {
        name: 'ProcessingOrder',
        path: 'processing_order',
        meta: { title: '正在处理的订单' },
        component: ProcessingOrder
      },
      {
        name: 'SuccessOrder',
        path: 'success_order',
        meta: { title: '处理成功订单' },
        component: SuccessOrder
      }
    ]
  }
]
