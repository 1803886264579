import defineApi from '../define-api'
import http, { getAdminUrl } from '../application-api'

export const getGrossProfitDetailList = defineApi((config, params) => {
  config.params = params
  return http.load('/gross_profit_report_detail/list', config)
})

export const getGrossProfitDetailTotalList = defineApi((config, params) => {
  config.params = params
  return http.load('/gross_profit_report_detail/list/total', config)
})

export const getGrossProfitDetailDownloadTotalEexcel = params => {
  let arr = []
  for (let key of Object.keys(params)) {
    if (params[key]) {
      arr.push(key + '=' + params[key])
    }
  }
  return getAdminUrl('/gross_profit_report_detail/download/excel?' + arr.join('&'))
}
