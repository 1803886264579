import Kpi from '../../pages/kpi'
import KpiCustomer from '../../pages/kpi/customer'
import KpiUser from '../../pages/kpi/user'
export default [
  {
    name: 'Kpi',
    path: '/kpi',
    meta: { title: '绩效管理' },
    component: Kpi,
    children: [
      {
        name: 'KpiCustomer',
        path: 'kpiCustomer',
        meta: { title: '客户管理' },
        component: KpiCustomer
      },
      {
        name: 'KpiUser',
        path: 'kpiUser',
        meta: { title: '考核用户管理' },
        component: KpiUser
      }
    ]
  }
]
