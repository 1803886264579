import defineApi from '../define-api'
import http from '../application-api'

export const getPolicyCompanyList = defineApi((config, params) => {
  config.params = params
  return http.load('/policy_company/list', config)
})

/**
 * @param company
 *   name {string}
 *   type {int} 1或2
 * @type {Function}
 */
export const addPolicyCompany = defineApi((config, company) => {
  config.data = company
  http.post('/policy_company/add', config)
})

/**
 * @param company
 *   id {string}
 *   name {string}
 *   enabled {boolean}
 *   type {int} 1或2
 * @type {Function}
 */
export const updatePolicyCompany = defineApi((config, company) => {
  config.data = company
  http.post('/policy_company/update', config)
})

export const getPolicyCompanyByLikenameWithType = defineApi((config, name, type) => {
  config.params = {
    name: name,
    type: type
  }
  return http.load('/policy_company/list/byLikenameWithType', config)
})


