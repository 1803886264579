<template>
  <transition
    @before-enter="onPanelBeforeOpen"
    @enter="onPanelOpen"
    @leave="onPanelClose"
  >
    <div class="contracts-container" v-show="visible">
      <div class="header auto-layout">
        <span class="title auto-layout-full text-overflow text-normal font-normal">
          <i class="el-icon-document"></i>
          {{customer.name}}的合同
        </span>
        <div>
          <i class="el-icon-close" @click="visible = false"></i>
        </div>
      </div>
      <div class="body">
        <div v-if="contracts.length === 0" class="text-muted font-mini content-center">客户未签约任何合同</div>
        <ul class="list-group">
          <li class="list-group-item" v-for="c in contracts" :key="c.id">
            <div class="auto-layout" style="line-height: 24px;">
              <a href="javascript:void(0)"
                 class="font-small auto-layout-full"
                 @click="$router.push({name: 'CustomerWorkflowDetail', params: {flowType: c.flowType, flowId: c.workflowId}})"
              >
                {{c.title}}（{{c.code}}）
              </a>
              <file-download class="font-small" :url="c.url" :name="c.title">{{!!c.url ? '合同下载' : '无合同'}}</file-download>
            </div>
            <div class="service-doc-list">
              <div class="service-doc auto-layout" v-for="order in c.orders" :key="order.id">
                <div class="auto-layout-full text-overflow font-mini text-muted" style="line-height: 24px">
                  <a href="javascript:void(0)"
                     @click="$router.push({name: 'CustomerStageDetail', params: {flowType: order.flowType, flowId: order.workflowId, stageId: order.stageId}})"
                  >
                    <i class="el-icon-arrow-right"></i>
                    {{order.title}}（{{order.createDate}}）
                  </a>
                </div>
                <file-download :url="order.url" :name="order.title">下载</file-download>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
  import Velocity from 'velocity-animate'
  export default {
    data () {
      return {
        customer: {},
        contracts: [],
        visible: false
      }
    },
    methods: {
      open (customer) {
        this.visible = true
        if (this.customer !== customer || this.contracts.length === 0) {
          this.customer = customer
          this.contracts = []
          this.$http.get(`/ad_customer/${customer.id}/contracts`, {
            success: resp => (this.contracts = resp)
          })
        }
      },
      onPanelBeforeOpen (el) {
        el.style.left = '-400px'
      },
      onPanelOpen (el, done) {
        Velocity(el, { left: 0 }, { duration: 200, complete: done })
      },
      onPanelClose (el, done) {
        Velocity(el, { left: -400 }, { duration: 200, complete: done })
      }
    }
  }
</script>

<style lang="less" scoped>
  .contracts-container {
    display: flex;
    flex-flow: column;
    position: absolute;
    z-index: 800;
    width: 450px;
    height: 500px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    background-color: #fff;

    .header {
      display: flex;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      border-bottom: solid 1px #ddd;

      .title {
        padding-right: 10px;
      }

      i {
        cursor: pointer;
      }
    }

    .body {
      flex: 1;
      padding: 10px;
    }

    .service-doc-list {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
</style>
