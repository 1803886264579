<template>
  <modal-panel
    :visible.sync="panelVisible"
    :saveButtonLoading="submitting"
    :closable="panelCloseable"
    title="订单编辑"
    :disableFooterButton="true"
    @close="panelBeforeClose"
  >

    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="支付人姓名" prop="customerName" class="field-required">
        <el-input v-model="form.customerName"></el-input>
      </el-form-item>
      <el-form-item label="支付人手机号" prop="customerPhone" class="field-required">
        <el-input v-model="form.customerPhone" class="customer-phone"></el-input>
        <p class="text-muted font-mini" style="line-height: normal; margin-top: 5px;">
          客户凭借此手机号码查询支付订单，订单创建后系统发送短信消息到客户手机上，请务必检查手机号码是否填写正确。
        </p>
      </el-form-item>
      <el-form-item label="广告帐户ID" prop="mccId" class="field-required">
        <el-input v-model="form.mccId"></el-input>
        <p class="text-muted font-mini" style="line-height: normal; margin-top: 5px;">
          Mcc Id
        </p>
      </el-form-item>
      <el-form-item label="客户" prop="adCustomerId" class="field-required">
        <el-select
          v-model="form.adCustomerId"
          filterable
          remote
          :remote-method="loadAdCustomer"
          :loading="adCustomerLoading"
          placeholder="可输入关键字搜索"
          style="width: 100%;"
        >
          <el-option v-for="item in adCustomers" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="金额" prop="price" class="field-required">
        <el-input v-model="form.price">
          <span slot="append">人民币</span>
        </el-input>
        <p class="font-small text-danger" style="letter-spacing: 5px;">
          {{form.price | chinesePrice}}
        </p>
      </el-form-item>
      <el-form-item label="业务类型" prop="businessType" class="field-required">
        <el-select v-model="form.businessType" style="width: 100%;">
          <el-option label="预付" :value="0"></el-option>
          <el-option label="后付" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告平台" prop="adPlatform" class="field-required">
        <el-select v-model="form.adPlatform" style="width: 100%;">
          <el-option v-for="item in adPlatforms" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        <p class="font-small text-muted">备注不会展示给客户。</p>
      </el-form-item>
    </el-form>

    <div slot="footer" style="text-align: right;">
      <el-button @click="panelVisible = false" :loading="submitting">取&nbsp;&nbsp;消</el-button>
      <el-button
        v-if="isUpdate && model.status === 'created'"
        @click="cancelOrder"
        :loading="submitting"
        type="danger"
        icon="el-icon-remove">关闭订单</el-button>
      <el-button type="primary" @click="submit" :loading="submitting">保&nbsp;&nbsp;存</el-button>
    </div>
  </modal-panel>

</template>

<script>
  import {
    addOrder,
    updateOrder,
    cancelOrder
  } from '../../../../http/api/customer-order'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'

  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
    },
    computed: {
      panelCloseable () {
        return !this.submitting
      },
      isUpdate () {
        return !!this.model
      }
    },
    filters: {
      chinesePrice: function (price) {
        if (price && price > 0) {
          const fraction = ['角', '分']
          const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
          const unit = [
            ['元', '万', '亿'],
            ['', '拾', '佰', '仟'],
          ];
          let num = Math.abs(price);
          let s = ''
          fraction.forEach((item, index) => {
            s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '')
          })
          s = s || '整'
          num = Math.floor(num)
          for (let i = 0; i < unit[0].length && num > 0; i += 1) {
            let p = ''
            for (let j = 0; j < unit[1].length && num > 0; j += 1) {
              p = digit[num % 10] + unit[1][j] + p
              num = Math.floor(num / 10)
            }
            s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
          }
          return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整')
        } else {
          return ''
        }
      }
    },
    data () {
      return {
        panelVisible: false,
        submitting: false,
        model: null,
        adCustomerLoading: false,
        adCustomers: [],
        adPlatforms: [],
        form: {
          id: null,
          customerName: null,
          customerPhone: null,
          mccId: null,
          adCustomerId: null,
          adPlatform: null,
          businessType: null,
          price: null,
          remark: null
        }
      }
    },
    methods: {
      open (model, callback) {
        if (typeof model === 'function') {
          callback = model
          model = null
        }
        this.submitCallback = callback

        if (model) {
          if (this.adCustomers.filter(a => a.id === model.adCustomerId).length === 0) {
            this.adCustomers.push({
              id: model.adCustomerId,
              name: model.adCustomerName
            })
          }
          if (this.adPlatforms.filter(a => a.id === model.adPlatform).length === 0) {
            this.adPlatforms.push({
              id: model.adPlatform,
              name: model.adPlatformName
            })
          }

          if (model.id) {
            Object.assign(this.form, model)
            this.model = model
          } else {
            Object.assign(this.form, model)
            this.model = null
            this.form.id = null
            this.form.price = null
            this.form.remark = null
          }
        }
        this.panelVisible = true
        getAdPlatformListIdNameMode()
          .success(resp => {
            this.adPlatforms = resp.data
          })
          .send()
      },
      submit () {
        this.submitting = true
        let api
        if (this.isUpdate) {
          api = updateOrder
        } else {
          api = addOrder
        }
        api()
          .complete(() => (this.submitting = false))
          .success(resp => {
            this.$message.success('保存成功。')
            this.$emit('submit', resp.data, this.isUpdate)
            this.submitCallback && this.submitCallback(resp.data, this.isUpdate)
            this.panelVisible = false
          })
          .send(this.form)
      },
      cancelOrder () {
        this.$confirm('确定要关闭订单吗?', '确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitting = true
          cancelOrder()
            .complete(() => (this.submitting = false))
            .success(resp => {
              this.$message.success('订单已经取消。')
              this.$emit('submit', resp.data, this.isUpdate)
              this.submitCallback && this.submitCallback(resp.data, this.isUpdate)
              this.panelVisible = false
            })
            .send(this.model.id)
        }).catch(() => {})
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.form.id = null
        this.submitCallback = null
      },
      loadAdCustomer (query) {
        this.adCustomerLoading = true
        getAdCustomerListByKeyword()
          .complete(() => (this.adCustomerLoading = false))
          .success(resp => {
            this.adCustomers = resp.data
          })
          .send(query)
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .customer-phone @{deep} input {
    font-size: 18px;
    letter-spacing: 5px;
    color: #c33632;
  }
</style>
