import defineApi from '../define-api'
import http from '../application-api'

export const getUpperPolicyList = defineApi((config, params) => {
  config.params = params
  return http.load('/upper_policy/list', config)
})

/**
 * @param up
 *   area {int} 1或2
 *   level {int} 1或2
 *   currency {string}
 *   dateStart {date}
 *   dateEnd {date}
 *   rebateType {int} 1或2
 *   remark {string}
 *   contractSubjectId {string}
 *   proxyCompanyId {string}
 *   platformId {string}
 *   payPolicyId {string}
 *   rebateStrategyList:[
 *   min{dec}
 *   max{dec}
 *   percent{dec}
 *   category{string}
 *   ]
 * @type {Function}
 */
export const addUpperPolicy = defineApi((config, up) => {
  config.data = up
  http.post('/upper_policy/add', config)
})

/**
 * @param company
 *   id {string}
 *   area {int} 1或2
 *   level {int} 1或2
 *   currency {string}
 *   dateStart {date}
 *   dateEnd {date}
 *   rebateType {int} 1或2
 *   remark {string}
 *   contractSubjectId {string}
 *   proxyCompanyId {string}
 *   platformId {string}
 *   payPolicyId {string}
 *   rebateStrategyList:[
 *   min{dec}
 *   max{dec}
 *   percent{dec}
 *   category{string}
 *   ]
 * @type {Function}
 */
export const updateUpperPolicy = defineApi((config, up) => {
  config.data = up
  http.post('/upper_policy/update', config)
})

export const getlistRebatesBypolicyId = defineApi((config, policyId) => {
  config.params = { policyId: policyId }
  return http.get('/upper_policy/rebate_strategy', config)
})

export const deleteUpperPolicy = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/upper_policy/delete', config)
})
export const getUpperPolicyByProxyCompany = defineApi((config, name) => {
  config.params = {
    name: name
  }
  return http.load('/upper_policy/list/bynamelike', config)
})

export const getLowerPolicyDetailList = defineApi((config, params) => {
  config.params = params
  return http.load('/lower_policy_detail/list', config)
})

export const addLowerPolicyDetail = defineApi((config, up) => {
  config.data = up
  http.post('/lower_policy_detail/add', config)
})

export const updateLowerPolicyDetail = defineApi((config, up) => {
  config.data = up
  http.post('/lower_policy_detail/update', config)
})

export const deleteLowerPolicyDetail = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/lower_policy_detail/delete', config)
})
/**
 * 查询所有销售用户
 */
export const getSaleServiceUserList = defineApi((config) => {
  http.get('/lower_policy_detail/list/sale_service', config)
})

export const uploadExcel = defineApi((config, type, file) => {
  let form = new FormData()
  form.append('file', file)
  form.append('type', type)
  config.data = form
  http.post('/upper_policy/upload', config)
})
export const downloadExcelTemplate = defineApi((config, type) => {
  config.params = {
    type: type
  }
  http.post('/upper_policy/download/template', config)
})
export const getCategoryByPlatformId = defineApi((config, platformId) => {
  config.params = {
    platformId: platformId
  }
  return http.load('/upper_policy/category', config)
})
export const getByWorkflowIdAndProxyWithContract = defineApi((config, workflowId, proxy, contract) => {
  config.params = {
    workflowId: workflowId,
    proxy: proxy,
    contract: contract
  }
  http.get('/upper_policy/get', config)
})
