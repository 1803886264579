<!--
  广告渠道政策信息
-->
<template>
  <page route-name="AdChannelInfo">

    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-input placeholder="关键字查询" clearable v-model="query.content" style="width: 300px;">
          <el-button slot="append" icon="el-icon-search" @click="load()"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus"  type="primary" @click="$refs['channelInfoPanel'].open()">创建</el-button>
      </el-form-item>
    </el-form>

    <el-table
      slot="center"
      stripe
      :data="dataList">
      <el-table-column label="操作" width="110">
        <template slot-scope="scope">
          <el-button size="mini" @click="updateData(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteData(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="50">
        <template slot-scope="{ row }">
          <span style="color: #50a654;" v-if="row.enabled">可用</span>
          <span style="color: #b53639;" v-else>禁用</span>
        </template>
      </el-table-column>
      <el-table-column prop="platform" label="投放平台"></el-table-column>
      <el-table-column prop="proxy" label="上游代理性质"></el-table-column>
      <el-table-column prop="area" label="区域/行业"></el-table-column>
      <el-table-column prop="bp" label="返点返货"></el-table-column>
      <el-table-column prop="js" label="结算方式"></el-table-column>
      <el-table-column prop="invoice" label="发票开具"></el-table-column>
      <el-table-column prop="chongzhi" label="首笔充值"></el-table-column>
      <el-table-column prop="updateDate" label="最近更新" width="140"></el-table-column>
    </el-table>
    <!--编辑新增-->
    <channel-info-editor ref="channelInfoPanel" @submit="onEditorSubmit"></channel-info-editor>
  </page>
</template>

<script>
  import ChannelInfoEditor from './comp/editor'
  import kit from '../../../tools/kit'
  import { getAdChannelList, deleteAdChannelInfo } from '../../../http/api/ad-channel'
  import ElForm from "element-ui/packages/form/src/form";
  import ElFormItem from "element-ui/packages/form/src/form-item";

  export default {
    components: {
      ElFormItem,
      ElForm,
      ChannelInfoEditor },
    data () {
      return {
        dataList: [],
        query: {
          content: null
        }
      }
    },
    computed: {},
    methods: {
      load () {
        getAdChannelList()
          .success(resp => {
            this.dataList = resp.data
          })
          .send(this.query.content)
      },
      onEditorSubmit (data) {
        kit.arr.pushOrReplace(this.dataList, data)
      },
      updateData (data) {
        this.$refs['channelInfoPanel'].open(data)
      },
      deleteData (data) {
        this.$confirm(`确定要删除[${data.platform}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            deleteAdChannelInfo()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.remove(this.dataList, data)
              })
              .send(data.id)
          })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
