<template>
  <page route-name="PolicyCompany">

    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="$refs['companyPanel'].open()">添加上下游政策公司</el-button>
      </el-form-item>
    </el-form>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="名称">
          <el-input v-model="search.name" placeholder="代理公司或签约主体名称" style="width: 300px;" :clearable="true">
          </el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select
            v-model="search.type"
            :clearable="true"
          >
            <el-option
              v-for="item in [{id: 1, label: '代理公司'}, {id: 2, label: '签约主体'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
   <el-table
      slot="center"
      :data="dataList"
    >
      <el-table-column label="操作" width="110">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="updateCompany(row)">编辑</el-button>
          <el-button :type="row.enabled ? 'warning' : 'success'" @click="handleEnableDisable(row)">{{row.enabled ? '禁用' : '启用'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="主体名称" width="350"></el-table-column>
      <el-table-column prop="enabled" label="类型">
        <template slot-scope="{ row }">
          <span>{{row.type==1?'代理公司':'签约主体'}}</span>
        </template>
      </el-table-column>
     <el-table-column prop="accountName" label="户名" ></el-table-column>
     <el-table-column prop="accountBankName" label="开户行" ></el-table-column>
     <el-table-column prop="bankAccount" label="银行账号" ></el-table-column>
     <el-table-column prop="bankCode" label="银行代码" ></el-table-column>
     <el-table-column prop="swiftCode" label="swiftCode" ></el-table-column>
     <el-table-column prop="bankAddress" label="银行地址" ></el-table-column>
     <el-table-column prop="bankRemark" label="备注" ></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <company-editor ref="companyPanel" @saved="onEditorSubmit"></company-editor>
  </page>
</template>

<script>
  import CompanyEditor from './comp/policy-company-editor'
  import kit from '../../../tools/kit'
  import { getPolicyCompanyList, updatePolicyCompany } from '../../../http/api/policy-company'

  export default {
    components: { CompanyEditor },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          name: null,
          type: null
        }
      }
    },
    computed: {
    },
    mounted () {
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getPolicyCompanyList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      handleEnableDisable (row) {
        row.enabled = !row.enabled
        updatePolicyCompany()
          .success(() => {
            this.$message.success('更新成功。')
          })
          .send(row)
      },
      onEditorSubmit (pc) {
        kit.arr.pushOrReplace(this.dataList, pc)
      },
      updateCompany (pc) {
        this.$refs['companyPanel'].open(pc)
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
