<template>
  <page route-name="AdCustomerAd">

    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item label="平台" prop="type">
        <el-select v-model="type" >
          <el-option
            v-for="item in [{id: 'google', label: 'google'}, {id: 'facebook', label: 'facebook'}, {id: 'qt', label: '其它'}]"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button  type="primary" icon="el-icon-upload" @click="selectFile">Excel文件导入</el-button>
      </el-form-item>
      <el-form-item>
        <a :href ="downloadTemplate()"  class="button" ><i class="el-icon-download"></i>Excel模版文件下载</a>
      </el-form-item>
    </el-form>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上游代理" prop="proxyCompanyId">
          <el-select
            v-model="search.proxyCompanyId"
            filterable
            remote
            :remote-method="searchPolicyCompanyProxy"
            :loading="loading"
            placeholder="请输入代理公司名称的关键字进行搜索" :clearable="true"
          >
            <el-option
              v-for="item in proxyCompanies"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户ID" prop="adId">
           <el-input size="mini"
                placeholder="请输入客户ID"
                v-model="search.adId"
                :clearable="true"
          />
        </el-form-item>
        <el-form-item label="客户主体签约名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel()" class="button" v-authority="'DownloadExcel'" ><i class="el-icon-download"></i>导出excel</a>
        </el-form-item>
     </el-form>
    </div>
   <el-table
     ref="table"
      slot="center"
      :data="dataList"
    >
      <el-table-column label="操作" width="100" >
        <template slot-scope="{ row }">
          <el-button size="mini" @click="updateAdCustomerAd(row,false)" >编辑</el-button>
          <el-button size="mini" @click="updateAdCustomerAd(row,true)" v-if="row.platformName ==='Google' ">转户</el-button>
        </template>
      </el-table-column>
     <el-table-column prop="platformName" label="平台"></el-table-column>
     <el-table-column prop="proxyCompanyName" label="上游代理" ></el-table-column>
     <el-table-column prop="adId" label="客户ID" ></el-table-column>
     <el-table-column prop="adName" label="账户名称" ></el-table-column>
     <el-table-column prop="adCustomerName" label="客户主体签约名称" ></el-table-column>
     <el-table-column prop="openSubjectMcc" :label="openLabel" v-if="openLabel.length > 0"></el-table-column>
     <el-table-column prop="contractSubjectName" label="签约主休"></el-table-column>
     <el-table-column prop="payTypeName" label="结算方式"></el-table-column>
     <el-table-column prop="putTypeName" label="投放方式"></el-table-column>
     <el-table-column prop="currencyName" label="客户支付币种" ></el-table-column>
     <el-table-column prop="csUserName" label="客服人员">
       <template slot-scope="{ row }">
         <el-tooltip  effect="light"  placement="top-start">
           <div slot="content" v-html="csUsers" ></div>
           <span @mouseover='getAssignCsInfo(row.id,row.csUserName)'>{{row.csUserName}}</span>
         </el-tooltip>
       </template>
     </el-table-column>
     <el-table-column prop="saleUserName" label="销售人员"></el-table-column>
     <el-table-column prop="customerTypeName" label="客户类型"></el-table-column>
     <el-table-column prop="openDate" label="开户时间" ></el-table-column>
     <el-table-column prop="onlineDate" label="上线时间" ></el-table-column>
     <el-table-column prop="transDate" label="转户时间" v-if="openLabel ==='MCC账户名'"></el-table-column>
     <el-table-column prop="stopDate" label="停用时间" ></el-table-column>
     <el-table-column prop="backCustomer" label="是否退返客户">
       <template slot-scope="{ row }">
         <span>{{row.backCustomer==1?'是':'否'}}</span>
       </template>
     </el-table-column>
     <el-table-column prop="customBackAmount" label="退返客户金额" ></el-table-column>
     <el-table-column prop="remark" label="备注" ></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <ad-customer-ad-editor ref="adCustomerAdPanel" @saved="onEditorSubmit"></ad-customer-ad-editor>
    <uploader ref="uploader" :uploadExec="uploadHandle"></uploader>
  </page>
</template>

<script>
  import AdCustomerAdEditor from './comp/ad-customer-ad-editor'
  import kit from '../../../tools/kit'
  import { getPolicyCompanyByLikenameWithType } from '../../../http/api/policy-company'
  import { getAdPlatformListIdNameMode } from '../../../http/api/ad-platform'
  import { getAdCustomerAdList, getAssignCsUser, uploadExcel } from '../../../http/api/ad-customer-ad'
  import selectFile from '../../../tools/select-file'
  import { userStore } from '../../../store'
  import { getAdCustomerListByKeyword } from '../../../http/api/ad-customer'
  import { getAdminUrl } from '../../../http/application-api'

  export default {
    components: { AdCustomerAdEditor },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        type: 'google',
        openLabel: '开户主体',
        search: {
          platformId: null,
          adId: null,
          proxyCompanyId: null,
          adCustomerId: null
        },
        proxyCompanies: [],
        platforms: [],
        adCustomers: [],
        loading: false,
        csUsers: null
      }
    },
    computed: {
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getAdCustomerAdList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
            if (resp.data.records.length > 0) {
              if (resp.data.records[0].platformId === '1') {
                this.openLabel = 'MCC账户名'
              } else if (resp.data.records[0].platformId === '2') {
                this.openLabel = '开户主体'
              } else {
                this.openLabel = ''
              }
            }
          })
          .send(params)
      },
      onEditorSubmit (up) {
        kit.arr.pushOrReplace(this.dataList, up)
      },
      updateAdCustomerAd (up, trans) {
        this.$refs['adCustomerAdPanel'].open(up, trans)
      },
      searchPolicyCompanyProxy (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.proxyCompanies = resp.data
            })
            .send(keyword, 1)
        }
      },
    // 上传文件
      selectFile () {
        selectFile({
          accept: '.xlsx',
          multi: true,
          select: files => {
            this.$refs.uploader.upload(files)
          }
        })
      },
      uploadHandle (file, callback) {
        uploadExcel()
          .success(resp => {
            callback.success()
          })
          .error(resp => {
            callback.error(resp.message, resp.cancel)
          })
          .final(() => {
            callback.complete()
          })
          .config(cfg => {
            cfg.onUploadProgress = e => {
              callback.progress(Math.round((e.loaded * 100) / e.total))
            }
          })
          .send(file.file)
      },
      downloadTemplate () {
        return getAdminUrl('/ad_customer_ad/download/template?type=' + this.type + '&_token=' + userStore.state.token)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      downloadExcel () {
        let params = Object.assign({}, this.search)
        params.start = 0
        params.limit = 500 // mybatisplus分页时，每页最大是500，些值不能超过500
        let arr = []
        for (let key of Object.keys(params)) {
          if (params[key]) {
            arr.push(key + '=' + params[key])
          }
        }
        return getAdminUrl('/ad_customer_ad/download/excel?' + arr.join('&'))
      },
      getAssignCsInfo (adCustomerAdId, name) {
        getAssignCsUser()
          .success(resp => {
            this.csUsers = name
            for (let item of resp.data) {
              if (item.enabled) {
                this.csUsers = this.csUsers + '<br>' + item.csUserName
              }
            }
          })
          .send(adCustomerAdId)
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
