<!--
  https://gitee.com/perfectlogic/hehu-office-api/wikis/工作流程设计
-->
<template>
  <div class="form-container">
    <div class="form-title text-muted font-normal" style="margin-bottom: 20px;">{{submit.description}}</div>

    <div v-for="fg in fieldGroups" class="field-group" v-show="fg.formEnabled">
      <div class="group-title">
        表单：{{fg.title}}
        <el-tooltip placement="left-start" effect="light">
          <div slot="content">
            <div v-if="!pastable">没有可粘贴的数据</div>
            <div v-else class="form-copy-tip-content">
              <div v-for="field in copyFormData" :key="field.name" class="tip-item">
                <label>{{field.label}}：</label>
                <span>{{field.display | trimStr}}</span>
              </div>
            </div>
          </div>
          <el-button size="mini" type="primary" icon="fa fa-clipboard" class="past-form" @click="pastForm(fg)" :disabled="!pastable">粘贴表单数据</el-button>
        </el-tooltip>
      </div>
      <div class="field-layout">
        <div class="field-row" v-for="(row, rowIndex) in fg.layouts" :key="rowIndex">
          <div class="field-column" v-for="col in row" :key="col.name" :style="{flex: col.span / 24}">
            <div v-if="col.type === 'title'" class="field-title">{{col.name}}</div>
            <label v-if="col.type === 'field'" class="field-label">
              <span v-if="col.field.required" style="font-weight: bold; font-size: 14px; color: #c01b0a;">*</span>
              {{col.field.label}}
            </label>
            <div v-if="col.type === 'field'" class="field-input">
              <el-tooltip v-if="!!col.field.depend"
                          effect="dark"
                          :content="'当前不可编辑，【' + fieldMap[col.field.depend.name].label + '】的值不相符，此输入项不会被提交。'"
                          placement="bottom"
              >
                <div v-show="disabledFields[col.name]" class="disable-field-input"></div>
              </el-tooltip>
              <form-item
                  :ref="col.name"
                  :flow-id="flowId"
                  :flow-type="flowType"
                  :form-id="submit.id"
                  :field="col.field"
                  :form-data.sync="formData"
              >
              </form-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FormItem from './form-item'
  import FormCopy from '../../../../../tools/form-copy'
  import kit from '../../../../../tools/kit'
  import { getFormsData } from '../../../../../http/api/stage'
  import { adPolicyWorkflowCheck } from '../../../../../http/api/workflow'

  export default {
    components: { FormItem },
    props: {
      flowId: { type: String, required: true },
      flowType: { type: String, required: true },
      submit: { type: Object }, // 流程表单配置信息
      stage: { type: String, required: true }
    },
    filters: {
      trimStr (text) {
        const MAX_LENGTH = 20
        if (text && text.length > MAX_LENGTH) {
          return text.substr(0, MAX_LENGTH) + '...'
        } else {
          return text
        }
      }
    },
    computed: {
      pastable () {
        return !!FormCopy.getCopyData()
      },
      copyFormData () {
        let data = FormCopy.getCopyData()
        if (data) {
          let arr = []
          for (let key of Object.keys(data)) {
            arr.push(data[key])
          }
          return arr
        } else {
          return []
        }
      }
    },
    watch: {
      formData: {
        handler: function () {
          if (this.flowType === 'AdPolicy') {
            if (this.formData['customer'] && this.formData['platform']) {
              if (this.adPolicy.customerId !== this.formData['customer']['id'] || this.adPolicy.platformId !== this.formData['platform']['id']) {
                this.adPolicy.customerId = this.formData['customer']['id']
                this.adPolicy.platformId = this.formData['platform']['id']
                this.adPolicyWorkflowChecked(this.formData['customer']['id'], this.formData['platform']['id'], this.formData['customer']['title'], this.formData['platform']['title'])
              }
            }
            this.$emit('adPolicyChanged', this.formData)
          }
        },
        deep: true
      }
    },
    data () {
      let data = {
        formData: {},
        disabledFields: {}, // 控制字段的禁用状态，禁用的字段在提交时不发给服务端
        depends: {}, // 实现字段配置中的depend属性的配置
        autoCompleteData: {},
        fieldGroups: [],
        fieldMap: {},
        adPolicy: {
          customerId: null,
          platformId: null
        }
      }

      data.fieldGroups = this.submit.fieldGroups
      this.eachField(data.fieldGroups, f => {
        data.formData[f.name] = f.defaultValue || null
        if (f.depend) {
          data.disabledFields[f.name] = true
          // 收集有depend配置的字段信息
          let arr = data.depends[f.depend.name] || []
          arr.push({ name: f.name, value: f.depend.value })
          data.depends[f.depend.name] = arr
        } else {
          data.disabledFields[f.name] = false
        }
        data.fieldMap[f.name] = f
      })

      for (let group of data.fieldGroups) {
        group.formEnabled = !group.dependOnGroup
        for (let layouts of group.layouts) {
          for (let layout of layouts) {
            layout.field = data.fieldMap[layout.name]
          }
        }
      }

      return data
    },
    methods: {
      eachField (groups, callback) {
        for (let g of groups) {
          for (let f of g.fields) {
            callback(f)
          }
        }
      },
      validate (callback) {
        // this.$refs.form.validate(callback)
        callback.call(this, true) // 交给服务端校验
      },
      getForm () {
        return Object.assign({}, this.formData)
      },
      setForm (form) {
        for (let key of Object.keys(form)) {
          let comp = this.$refs[key][0]
          if (comp) {
            comp.setFieldData(form[key])
          }
        }
      },
      pastForm (fieldGroup) {
        this.$confirm('确定要粘贴吗？', '确认', {
          type: 'warning'
        })
          .then(() => {
            let map = {}
            for (let field of fieldGroup.fields) {
              map[field.originName] = field
            }
            let messageContent = ''
            let successCount = 0
            let failCount = 0
            for (let copyField of this.copyFormData) {
              let field = map[copyField.originName]
              if (field) {
                let fieldComp = this.$refs[field.name][0]
                let value = copyField.value
                if (value == null || value === undefined || (typeof value === 'string' && value.length === 0)) {
                  failCount++
                  messageContent += `<p><i class="el-icon-close" style="color: #c01b0a;"></i> ${copyField.label}<span style="font-size: 12px; color: #7d7d7d;">（没有数据）</span></p>`
                } else {
                  successCount++
                  messageContent += `<p><i class="el-icon-check" style="color: #45874d;"></i> ${copyField.label}</p>`
                  let flag = false
                  if (typeof value === 'string' && (value.startsWith('[') || value.startsWith('{'))) {
                    try {
                      let item = JSON.parse(value)
                      fieldComp.setFieldData(item)
                      flag = true
                    } catch (e) {
                      console && console.error(e)
                    }
                  }
                  if (!flag) {
                    fieldComp.setFieldData(value)
                  }
                }
              } else {
                failCount++
                messageContent += `<p><i class="el-icon-close" style="color: #c01b0a;"></i> ${copyField.label}<span style="font-size: 12px; color: #7d7d7d;">（找不到匹配）</span></p>`
              }
            }
            messageContent = `<h3>粘贴结束，成功${successCount}个，失败${failCount}个：</h3>` + messageContent
            this.$alert(messageContent, {
              dangerouslyUseHTMLString: true
            })
          })
          .catch(() => {})
      },
      autoPastForm () {
        getFormsData()
          .success(resp => {
            for (let fg of this.fieldGroups) {
              let index = 0
              let map = {}
              for (let field of fg.fields) {
                map[field.originName] = field
              }
              if (resp.data) {
                let arr = []
                let data = resp.data[index].fieldMap
                if (data) {
                  for (let key of Object.keys(data)) {
                    arr.push(data[key])
                  }
                }
                for (let copyField of arr) {
                  let field = map[copyField.originName]
                  if (field) {
                    let fieldComp = this.$refs[field.name][0]
                    let value = copyField.value
                    if (copyField.originName === 'dateEnd' || copyField.originName === 'dateStart') {
                      value = null
                    }
                    if (value == null || value === undefined || (typeof value === 'string' && value.length === 0)) {
                    } else {
                      let flag = false
                      if (typeof value === 'string' && (value.startsWith('[') || value.startsWith('{'))) {
                        try {
                          let item = JSON.parse(value)
                          fieldComp.setFieldData(item)
                          flag = true
                        } catch (e) {
                          console && console.error(e)
                        }
                      }
                      if (!flag) {
                        fieldComp.setFieldData(value)
                      }
                    }
                  }
                }
              }
              index++
            }
          })
          .send(this.flowId, this.stage)
      },
      adPolicyWorkflowChecked (customerId, platformId, customerName, platformName) {
        adPolicyWorkflowCheck()
          .success(resp => {
            if (resp.data) {
              this.$confirm(`${customerName}客户在${platformName}平台，已有政策数据，确定要创建政策吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
              }).catch(() => {
                this.$router.push({ name: 'LowerPolicyList' })
              })
            }
          })
          .send(this.flowType, customerId, platformId, this.flowId)
      }
    },
    mounted () {
      // 监听depend的字段，当值发生改变时，检查是否满足值相等条件，如果满足则将相应的字段解除禁用并显示
      for (let key of Object.keys(this.depends)) {
        (() => {
          let fields = this.depends[key]
          this.$watch(
            function () {
              return this.formData[key]
            },
            function (val) {
              for (let f of fields) {
                this.disabledFields[f.name] = f.value.indexOf(val) === -1
                if (this.disabledFields[f.name]) {
                  this.formData[f.name] = null
                }
              }
            }
          )
        })()
      }

      let watchDependOnGroups = {}
      for (let group of this.fieldGroups) {
        let dg = group.dependOnGroup
        if (dg) {
          let fieldName = dg.name
          if (watchDependOnGroups[fieldName] === undefined) {
            watchDependOnGroups[fieldName] = {}
          }
          if (watchDependOnGroups[fieldName][dg.value] === undefined) {
            watchDependOnGroups[fieldName][dg.value] = []
          }
          watchDependOnGroups[fieldName][dg.value].push(group)
        }
      }
      for (let fieldName of Object.keys(watchDependOnGroups)) {
        (() => {
          const watchName = fieldName.split('.')[0]
          this.$watch(
            function () {
              return this.formData[watchName]
            },
            function (val) {
              for (let group of this.fieldGroups) {
                group.formEnabled = !group.dependOnGroup
              }
              if (typeof val === 'object') {
                val = kit.obj.getObjectValue(this.formData, fieldName)
              }
              let groups = watchDependOnGroups[fieldName][val]
              if (groups) {
                groups.forEach(g => g.formEnabled = true)
              }
            }
          )
        })()
      }
      if (this.flowType === 'AdPolicy') {
        this.autoPastForm()
      }
    }
  }
</script>

<style lang="less" scoped>
  .form-container {
    padding: 0 20px 20px 20px;
    max-width: 1300px;
  }
  .field-group {
    &:not(:first-of-type) {
      margin-top: 20px;
    }
    .group-title {
      padding: 6px 0;
      font-size: 16px;
      font-weight: bold;
      .past-form {
        float: right;
      }
    }
    @borderColor: #b1d2ee;
    @border: solid 1px @borderColor;
    .field-row {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      border: @border;
      &:not(:first-of-type) {
        border-top: none;
      }
      .field-column:not(:first-of-type) {
        border-left: @border;
      }
      .field-column {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        .field-title {
          flex: 1;
          padding: 10px 0;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #6b6b6b;
          background-color: #f3faff;
        }
        .field-label {
          padding: 12px 10px 7px 10px;
          width: 100px;
          font-size: 12px;
          background-color: #d6eaf9;
          border-right: @border;
          color: #274a74;
        }
        .field-input {
          position: relative;
          flex: 1;
          padding: 7px 7px 2px 7px;
        }
        .disable-field-input {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(156, 65, 70, 0.6);
          cursor: not-allowed;
          z-index: 1;
        }
      }
    }
  }

  .form-copy-tip-content {
    .tip-item {
      display: flex;
      flex-direction: row;
      label {
        width: 100px;
        padding-right: 5px;
        text-align: right;
      }
      span {
        display: block;
        flex: 1;
      }
    }
  }
</style>
