<template>
  <page route-name="EstimateGrossProfit" :use-tcb-layout="false">
    <el-button type="primary" icon="el-icon-upload" @click="selectFile" slot="toolbar">Excel文件导入</el-button>
    <a :href ="downloadTemplate()" slot="toolbar" class="button"><i class="el-icon-download"></i>Excel模版文件下载</a>
    <div class="page-bg">
      <el-tabs class="tabs">
        <el-tab-pane label="预估毛利汇总">
          <total-page></total-page>
        </el-tab-pane>
        <el-tab-pane label="预估毛利明细">
          <detail-page></detail-page>
        </el-tab-pane>
      </el-tabs>
    </div>
    <uploader ref="uploader" :uploadExec="uploadHandle"></uploader>
  </page>
</template>

<script>
  import DetailPage from './detail'
  import TotalPage from './total'
  import selectFile from '../../../tools/select-file'
  import { uploadExcel } from '../../../http/api/upper-lower-policy'
  import { userStore } from '../../../store'
  import { getAdminUrl } from '../../../http/application-api'
  export default {
    components: {
      DetailPage, TotalPage
    },
    methods: {
      // 上传文件
      selectFile () {
        selectFile({
          accept: '.xlsx',
          multi: true,
          select: files => {
            this.$refs.uploader.upload(files)
          }
        })
      },
      uploadHandle (file, callback) {
        uploadExcel()
          .success(resp => {
            callback.success()
          })
          .error(resp => {
            callback.error(resp.message, resp.cancel)
          })
          .final(() => {
            callback.complete()
          })
          .config(cfg => {
            cfg.onUploadProgress = e => {
              callback.progress(Math.round((e.loaded * 100) / e.total))
            }
          })
          .send('estimate', file.file)
      },
      downloadTemplate () {
        return getAdminUrl('/upper_policy/download/template?type=estimate&_token=' + userStore.state.token)
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .page-bg{
    width:100%;
    height:100%;
    background-color: #f5f9fa;
  }
  .tabs {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-flow: column;
    background-color: #ffffff;
    border: solid 1px #becbcf;
    box-shadow: 0 1px 4px 0 rgba(205, 208, 209, 0.6);
  }
  .tabs @{deep} .el-tabs__nav-scroll {
    padding-left: 35px !important;
    background-color: #fafafa;
  }
  .tabs @{deep} .el-tabs__item {
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #999999;
  }
  .tabs @{deep} .is-active {
    color: #0486fe;
  }
  .tabs @{deep} .el-tabs__content {
    flex: 1;
    position: relative;
  }
  .tabs @{deep} .el-tab-pane {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
    margin-left: 5px;
  }
</style>
