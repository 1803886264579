<template>
  <page route-name="SuccessOrder">

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="订单日期">
          <el-date-picker
              label="日期范围"
              v-model="search.date"
              type="daterange"
              align="right"
              value-format="yyyy-MM-dd"
              :unlink-panels="true"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单业务号">
          <el-input v-model="search.bizNo" placeholder="订单业务号"  :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="流水号">
          <el-input v-model="search.serialNo" placeholder="流水号"  :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="收款信息">
          <el-input v-model="search.recieve" placeholder="收款人或帐号"  :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="支付状态" prop="recieve">
          <el-select v-model="search.payStatus" placeholder="请选择支付状态"  :clearable="true">
            <el-option
              v-for="item in payStatusOption"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="recieve">
          <el-select v-model="search.sortType" placeholder="请选择支付类型"  :clearable="true">
            <el-option
              v-for="item in sortTypeOption"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  prop="isOwner">
          <el-select v-model="search.isOwner"  :clearable="true">
            <el-option
              v-for="item in isOwnerOption"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      slot="center"
      :data="dataList"
      @selection-change="handleSelectionChange"
      class="data-table"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" @click="updateOrder(scope.row)">更新回单</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="bizNo" label="业务订单号" width="110"></el-table-column>
      <el-table-column label="处理状态" width="90">
        <template slot-scope="{ row }">
          <span v-if="row.processStatus === processStatusMap.enter" class="text-primary font-mini">已录入</span>
          <span v-else-if="row.processStatus === processStatusMap.verified" class="text-success font-mini">已校验</span>
          <span v-else-if="row.processStatus === processStatusMap.pending" class="text-muted font-mini">待处理</span>
          <span v-else-if="row.processStatus === processStatusMap.success" class="text-danger font-mini" style="font-weight: bold;">已提交</span>
        </template>
      </el-table-column>
      <el-table-column label="支付状态" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.payStatus === payStatusMap.success" class="text-success font-mini">支付成功</span>
          <span v-else-if="row.payStatus === payStatusMap.fail" class="text-danger font-mini">支付失败</span>
          <span v-else class="text-muted font-mini">待处理</span>
        </template>
      </el-table-column>
      <el-table-column label="收款金额" width="150">
        <template slot-scope="{ row }">
          {{row.currency}} <span style="font-size: 16px; font-weight: bold; color: #96342d;">{{row.price}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recieveName" label="收款人" width="150"></el-table-column>
      <el-table-column prop="recieveAccount" label="收款账户" width="250"></el-table-column>
      <el-table-column prop="tradeTypeStr" label="收款类型" width="100"></el-table-column>
      <el-table-column prop="processUserName" label="处理人" width="130"></el-table-column>
      <el-table-column prop="payTime" label="支付时间" width="150"></el-table-column>
      <el-table-column prop="sortTime" width="150" label="排序时间"></el-table-column>
      <el-table-column prop="bank" label="银行" width="100"></el-table-column>
      <el-table-column prop="bankDivision" label="银行支行" width="100"></el-table-column>
      <el-table-column prop="province" label="省份" ></el-table-column>
      <el-table-column prop="city" label="城市" ></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <order-editor ref="orderPanels" @submit="onEditorSubmit"></order-editor>
  </page>
</template>

<script>

  import { getSuccessList } from '../../../http/api/bg-order'
  import {
    PAY_STATUS_FAIL,
    PAY_STATUS_SUCCESS,
    payStatusOption,
    isOwnerOption,
    STATUS_ENTERED, STATUS_VERIFIED, STATUS_PENDING, STATUS_SUCCESS
  } from '../common-attributes'
  import kit from '../../../tools/kit'
  import OrderEditor from '../processing-order/comp/order-editor'
  export default {
    components: {
      OrderEditor
    },
    data () {
      return {
        dataList: [],
        multipleSelection: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          date: [],
          bizNo: null,
          serialNo: null,
          recieve: null,
          sortType: null,
          payStatus: null,
          isOwner: 'owner'
        },
        start: null,
        end: null,
        sortTypeOption: [{
          id: 'trade_type',
          label: '交易类型'
        }, {
          id: 'payee_name',
          label: '收款人姓名'
        }, {
          id: 'pay_time',
          label: '支付时间'
        }],
        payStatusOption: payStatusOption,
        payStatusMap: {
          success: PAY_STATUS_SUCCESS,
          fail: PAY_STATUS_FAIL
        },
        processStatusMap: {
          enter: STATUS_ENTERED,
          verified: STATUS_VERIFIED,
          pending: STATUS_PENDING,
          success: STATUS_SUCCESS
        },
        isOwnerOption: isOwnerOption
      }
    },
    watch: {
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        if (this.search.date.length === 2) {
          params.startTime = this.search.date[0]
          params.endTime = this.search.date[1]
          delete params.date
        }
        this.$pageLoading(true)
        getSuccessList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      updateOrder (row) {
        this.$refs['orderPanels'].open(row)
      },
      onEditorSubmit (data) {
        kit.arr.pushOrReplace(this.dataList, data)
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

</style>
