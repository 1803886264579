<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="500"
    :height="300"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">绑定客户编辑</span>
    <el-form ref="form" :model="form" :rules="rules" status-icon label-width="120px">
      <el-form-item label="广告平台" prop="platform">
        <el-radio-group v-model="form.platform">
          <el-radio label="google">google</el-radio>
          <el-radio label="bing">bing</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客户" prop="adCustomerId">
        <el-select v-model="form.adCustomerId" filterable placeholder="请选择客户">
          <el-option
            v-for="item in adCustomerName"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="销售" prop="saleId">
        <el-select v-model="form.saleId" filterable placeholder="请选择销售">
          <el-option
            v-for="item in saleName"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button type="primary" @click="submit" :loading="submitting">{{isUpdate ? '更新数据' : '创建数据'}}</el-button>
      </el-form-item>
    </el-form>
  </slide-panel>

</template>

<script>
  import api, { API_AD_CUSTOMER_PLATFORM, API_AD_CUSTOMER_PLATFORM_DATA } from '../../../../config/urls'
  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    data () {
      let r = this.$elConfig.formRule
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        form: {
          platform: null,
          saleId: null,
          adCustomerId: null
        },
        saleName: [],
        adCustomerName: [],
        rules: {
          platform: [r.required(), r.max(15)],
          adCustomerId: [r.required()],
          saleId: [r.required()]
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.$http.get(api(API_AD_CUSTOMER_PLATFORM_DATA), {
          success: resp => {
            this.saleName = resp.sale
            this.adCustomerName = resp.customer
          }
        })
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = model[k]
          }
        }
      },
      submit () {
        this.$refs.form.validate(valid => {
          if (!valid) return
          this.submitting = true
          this.panelCloseable = false
          let method, path
          if (this.isUpdate) {
            method = 'put'
           // path = api(API_AD_CUSTOMER_PLATFORM_UPDATE, { id: this.model.id })
          } else {
            method = 'post'
            path = api(API_AD_CUSTOMER_PLATFORM)
          }
          this.$http[method](path, {
            data: this.form,
            context: this,
            completeBefore () {
              this.panelCloseable = true
              this.submitting = false
            },
            success (data) {
              this.$message.success('保存成功。')
              this.$emit('submit', data, this.isUpdate)
              this.panelVisible = false
            }
          })
        })
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
      }
    }
  }
</script>

<style scoped>

</style>
