import AdPlatform from '../../pages/ad/ad-platform'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'AdPlatform',
    path: '/ad_platform',
    meta: { title: '平台管理' },
    component: AdPlatform
  }
]
