import AdsReport from '../../pages/ads-report'
import AdsReportDetail from '../../pages/ads-report/report-detail'
import AdsReportDataCharts from '../../pages/ads-report/report-datacharts'

export default [
  {
    name: 'AdsReport',
    path: '/ads/report',
    meta: { title: '广告日消耗报表' },
    component: AdsReport,
    children: [
      { name: 'AdsReportDetail', path: 'edit/:platform', meta: { title: '报表详情' }, component: AdsReportDetail },
      { name: 'AdsReportDataCharts', path: 'charts/:id', meta: { title: '广告消耗统计图' }, component: AdsReportDataCharts }
    ]
  }
]
