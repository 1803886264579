let props = {
  appName: '合鹄科技Office', // 应用系统的名称
  pageLimit: 30 // 分页，默认的每页行数
}

export default {
  install: function (Vue) {
    Vue.prototype.$shareProps = props
  }
}
