import defineApi from '../define-api'
import http from '../application-api'

export const getAdCustomerAdList = defineApi((config, params) => {
  config.params = params
  return http.load('/ad_customer_ad/list', config)
})

export const updateAdCustomerAd = defineApi((config, ad) => {
  config.data = ad
  http.post('/ad_customer_ad/update', config)
})

export const uploadExcel = defineApi((config, file) => {
  let form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/ad_customer_ad/upload', config)
})
export const downloadExcelTemplate = defineApi((config, type) => {
  config.params = {
    type: type
  }
  http.post('/ad_customer_ad/download/template', config)
})

export const updateTransAdCustomerAd = defineApi((config, ad) => {
  config.data = ad
  http.post('/ad_customer_ad/trans/update', config)
})

export const checkTransAdCustomerAd = defineApi((config, adId, proxyCompanyId) => {
  config.params = {
    adId: adId,
    proxyCompanyId: proxyCompanyId
  }
  http.get('/ad_customer_ad/check/trans', config)
})
export const getAssignCsUser = defineApi((config, adCustomerAdId) => {
  config.params = {
    adCustomerAdId: adCustomerAdId
  }
  http.get('/ad_customer_ad/cs_user', config)
})
