<template>
  <transition name="fade-fast">
    <div class="list-search" v-show="visible">
      <div class="search-bar" v-if="enableSearch">
        <el-input
          placeholder="输入关键字搜索"
          v-model="keyword"
          type="mini">
        </el-input>
      </div>
      <div v-show="loading" class="content-center text-muted font-large-x">
        <i class="el-icon-loading"></i>
      </div>
      <div class="text-muted font-mini content-center" v-show="!loading && resultTotal === 0">没有找到匹配的项</div>
      <ul class="results" v-show="!loading">
        <slot></slot>
      </ul>
      <div class="toolbar-bar" v-if="enableAddButton">
        <el-button style="width: 100%" icon="el-icon-plus" @click="$emit('addItem')">新增项目</el-button>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      visible: { type: Boolean, default: false },
      enableSearch: Boolean,
      enableAddButton: Boolean,
      loading: { type: Boolean, required: true },
      resultTotal: { type: Number, required: true }
    },
    data () {
      return {
        keyword: null
      }
    },
    watch: {
      keyword (key) {
        this.$emit('searchKeyChanged', key)
      }
    },
    methods: {
      getKeyword () {
        return this.keyword
      }
    }
  }
</script>

<style lang="less" scoped>
  .list-search {
    display: flex;
    flex-flow: column;
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    min-height: 120px;
    max-height: 350px;
    background-color: #fff;
    box-shadow: 0 2px 7px rgba(0,0,0,.2);
    border-radius: 5px;
  }

  .search-bar, .toolbar-bar {
    margin: 10px 10px 15px 10px;
  }

  @deep: ~'>>>';
  .results {
    flex: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;

    & @{deep} li {
      display: block;
      line-height: 1;
      padding: 7px 10px;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
      &.selected {
        color: #409eff;
        font-weight: bold;
      }
    }
  }
</style>
