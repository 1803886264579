export default function (field) {
  let value = field.display
  if (value) {
    value = value.replace(new RegExp('\n', 'g'), '<br/>')
  }
  let arr = []
  if (value) {
    arr = value.split('|')
  }
  if (arr && arr.length > 1) {
    let str = '<ul class="list-group">'
    for (let s of arr) {
      let itemSplit = s.split(',')
      if (itemSplit.length > 1) {
        s = ''
        for (let s1 of itemSplit) {
          s += `<span style="margin: 0 8px 5px 0; padding: 2px 5px; background-color: #fbfbfb; border-radius: 4px;border: solid 1px #ddd; display: inline-block;">${s1}</span>`
        }
      }
      str += `<li class="list-group-item">${s}</li>`
    }
    return str + '</ul>'
  } else {
    return value
  }
}
