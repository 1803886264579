import defineApi from '../define-api'
import http from '../application-api'

/**
 * 获取待办事项数量。
 * @type {Function}
 */
export const getMyTodoTotal = defineApi((config) => {
  http.get('/todo/my/total', config)
})

export const getMyTodoList = defineApi((config, params) => {
  config.params = params
  http.get('/todo/my/list', config)
})

export const markTodoAsCompleted = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/todo/mark_complete', config)
})
