var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"route-name":"AdPlatform"}},[_c('div',{attrs:{"slot":"toolbar"},slot:"toolbar"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$refs['platformPanel'].open()}}},[_vm._v("添加")])],1),_c('el-table',{ref:"table",attrs:{"slot":"center","data":_vm.dataList},on:{"row-dblclick":_vm.handleTableRowClick},slot:"center"},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"width":"400px","margin":"0 auto"}},[_c('el-table',{attrs:{"data":row.items,"stripe":""}},[_c('el-table-column',{attrs:{"label":"流程名称","prop":"id","width":"120"}}),_c('el-table-column',{attrs:{"label":"流程对应平台值","prop":"value","width":"150"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.updatePlatform(row)}}},[_vm._v("编辑")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"名称","width":"150"}}),_c('el-table-column',{attrs:{"prop":"position","label":"排序值","width":"150"}}),_c('el-table-column',{attrs:{"prop":"area","label":"区域","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.area==1?'海外':'国内'))])]}}])}),_c('el-table-column',{attrs:{"prop":"enabled","label":"可用状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.enabled)?_c('span',{staticStyle:{"color":"#50a654"}},[_vm._v("正常")]):_c('span',{staticStyle:{"color":"#b53639"}},[_vm._v("禁用")])]}}])})],1),_c('platform-editor',{ref:"platformPanel",on:{"saved":_vm.onEditorSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }