import defineApi from '../define-api'
import http from '../application-api'

export const getElasticsearchIndices = defineApi((config) => {
  http.get('/es_index/index/list', config)
})

/**
 * @param indices {String} 索引alias，多个用","分隔。
 * @param rebuild {boolean} true表示删除索引重构，false表示做elasticsearch的reindex。
 * @type {Function}
 */
export const rebuildElasticsearchIndex = defineApi((config, indices, rebuild) => {
  config.params = {
    indexAliases: indices,
    rebuild: rebuild
  }
  http.post('/es_index/rebuild', config)
})

/**
 * 同步数据库数据到索引库。
 * @param indexAlias 索引alias。
 * @param idList {String} 文档id，多个用","分隔。
 * @type {Function}
 */
export const updateDocument = defineApi((config, indexAlias, idList) => {
  config.params = {
    indexAlias: indexAlias,
    docIdList: idList
  }
  http.post('/es_index/index/update', config)
})

export const getIkWordFiles = defineApi((config) => {
  http.get('/es_index/ik_word/list', config)
})

export const getIkWordContent = defineApi((config, name, parentDir) => {
  config.params = {
    name: name,
    parentDir: parentDir
  }
  http.get('/es_index/ik_word/content', config)
})

export const updateIkWordFile = defineApi((config, name, parentDir, content) => {
  config.params = {
    name: name,
    parentDir: parentDir,
    content: content
  }
  http.post('/es_index/ik_word/update', config)
})

export const deleteIkWordFile = defineApi((config, name, parentDir) => {
  config.params = {
    name: name,
    parentDir: parentDir
  }
  http.post('/es_index/ik_word/delete', config)
})
