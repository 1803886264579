<template>
  <div class="absolute-full auto-layout flow-col template-container">
    <div style="padding: 5px 5px 5px;">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上游代理" prop="proxyCompanyId">
          <el-select
            v-model="search.proxyCompanyId"
            filterable
            remote
            :remote-method="searchPolicyCompanyProxy"
            :loading="loading"
            placeholder="请输入代理公司名称的关键字进行搜索" :clearable="true"
          >
            <el-option
              v-for="item in proxyCompanies"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户主体签约名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消耗月份" prop="costDate" >
          <el-date-picker style="width: 120px;"
                          v-model="search.costDate"
                          type="month"
                          value-format="yyyy-MM"
                          placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel()" class="button" v-authority="'DownloadExcel'" ><i class="el-icon-download"></i>导出excel</a>
        </el-form-item>
      </el-form>
    </div>
    <div class="auto-layout-full" style="position: relative;">
    <el-table
        ref="table"
        slot="center"
        :data="dataList"
      >
        <el-table-column prop="platformName" label="平台" width="80px;"></el-table-column>
        <el-table-column prop="adCustomerName" label="客户主体签约名称"></el-table-column>
        <el-table-column prop="costMonth" label="消耗月份" width="80px;" ></el-table-column>
        <el-table-column prop="downCost" label="消耗总金额" width="120px;"></el-table-column>
        <el-table-column prop="serviceTax" label="运营费或服务费" width="120px;"></el-table-column>
        <el-table-column prop="invalidClick" label="无效点击金额" width="120px;"></el-table-column>
        <el-table-column prop="rebate" label="客户返点抵扣金额" width="120px;"></el-table-column>
        <el-table-column prop="pay" label="账单金额" width="120px;">
          <template slot-scope="{ row }">
            {{(row.downCost + row.serviceTax + row.invalidClick - row.rebate).toFixed(2)}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </div>
  </template>
<script>
  import { getPolicyCompanyByLikenameWithType } from '../../../http/api/policy-company'
  import { getAdPlatformListIdNameMode } from '../../../http/api/ad-platform'
  import { getUpDownCostReportList } from '../../../http/api/up-down-cost-report'
  import { getAdCustomerListByKeyword } from '../../../http/api/ad-customer'
  import { getAdminUrl } from '../../../http/application-api'

  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          proxyCompanyId: null,
          costDate: null,
          adCustomerId: null
        },
        proxyCompanies: [],
        platforms: [],
        adCustomers: [],
        loading: false
      }
    },
    computed: {
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        params.type = 'down'
        this.$pageLoading(true)
        getUpDownCostReportList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      searchPolicyCompanyProxy (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.proxyCompanies = resp.data
            })
            .send(keyword, 1)
        }
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      downloadExcel () {
        let params = Object.assign({}, this.search)
        params.start = 0
        params.limit = 500 // mybatisplus分页时，每页最大是500，些值不能超过500
        params.type = 'down'
        let arr = []
        for (let key of Object.keys(params)) {
          if (params[key]) {
            arr.push(key + '=' + params[key])
          }
        }
        return getAdminUrl('/up_down_cost_report/download/excel?' + arr.join('&'))
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .template-container @{deep} .el-table {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
  }

  .template-container @{deep} .el-table__body-wrapper {
    flex: 1;
    overflow-y: auto !important;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
