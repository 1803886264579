<template>
  <page route-name="Role">
    <div slot="toolbar">
      <el-button type="primary" icon="aliyun-add" @click.native="$refs['editor'].open()">添加</el-button>
      <el-button slot="toolbar" icon="el-icon-refresh" @click="loadRoles"></el-button>
    </div>

    <el-table
      ref="table"
      slot="center"
      :data="tableData"
      stripe
      :height="tableHeight"
      @expand-change="handleTableExpand"
      @row-dblclick="handleTableRowClick"
    >
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div v-loading="row.loading" style="display: flex; flex-direction: row;">
            <div class="authority-container">
              <span class="text-muted font-mini" v-if="row.authorities.length === 0">没有分配权限。</span>
              <div class="authority-module" v-for="am in row.authorities" :key="am.title">
                <div class="module-title">{{am.title}}</div>
                <div class="authority" v-for="a in am.authorities" :key="a.id">
                  <span>{{a.title}}</span>
                </div>
              </div>
            </div>
            <div class="manager-container">
              <el-table
                :data="row.managers"
                stripe
                :height="300"
              >
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="账号" prop="username"></el-table-column>
                <el-table-column label="状态">
                  <template slot-scope="{ row }">
                    <span style="color: #50a654;" v-if="row.enabled">正常</span>
                    <span style="color: #b53639;" v-else>禁用</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="{ row }">
          <div>
            <el-button size="mini" @click="$refs['editor'].open(row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteRole(row)" v-if="!row.preDefine">删除</el-button>
          </div>
          <!--<div class="text-muted font-mini" v-else>预定义</div>-->
        </template>
      </el-table-column>
      <el-table-column label="角色" prop="name" width="150"></el-table-column>
      <el-table-column label="别名" prop="alias" width="150"></el-table-column>
      <el-table-column label="关联用户数" prop="userTotal" width="100"></el-table-column>
      <el-table-column label="状态" width="60">
        <template slot-scope="{ row }">
          <span v-if="row.enabled" style="color: #19be6b;">正常</span>
          <span v-else style="color: #ed4014;">禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="description"></el-table-column>
    </el-table>
    <role-editor ref="editor" @saved="onRoleSaved"></role-editor>

  </page>
</template>

<script>
  import {
    getAllRoles,
    deleteRole,
    getAuthorityAndManagerInfo
  } from '../../../../http/api/role'
  import kit from '../../../../tools/kit'
  import tableHeightFixMixins from '../../../../mixins/table-height-fix'
  import RoleEditor from './comps/role-editor'

  export default {
    components: { RoleEditor },
    mixins: [tableHeightFixMixins],
    data () {
      return {
        tableData: []
      }
    },
    methods: {
      loadRoles () {
        this.$loading(true)
        getAllRoles()
          .complete(() => (this.$loading(false)))
          .success(resp => {
            for (let role of resp.data) {
              role.authorities = []
              role.managers = []
            }
            this.tableData = resp.data
          })
          .send()
      },
      deleteRole (role) {
        this.$confirm(`删除角色后，拥有该角色的用户会被强制退出登录（不包含自己），是否确认删除【${role.name}】？`, '删除确认', {
          type: 'warning'
        })
          .then(() => {
            this.$loading(true)
            deleteRole()
              .complete(() => (this.$loading(false)))
              .success(() => {
                kit.arr.remove(this.tableData, role)
                this.$message.success('删除成功。')
              })
              .send(role.id)
          })
          .catch(() => {})
      },
      onRoleSaved (role) {
        kit.arr.pushOrReplace(this.tableData, role)
      },
      handleTableExpand (row) {
        if (row.expanded === undefined) {
          row.expanded = false
        }
        row.expanded = !row.expanded
        if (!row.loading && row.expanded) {
          row.loading = true
          getAuthorityAndManagerInfo()
            .complete(() => (row.loading = false))
            .success(resp => {
              row.authorities = resp.data.authorities
              row.managers = resp.data.managers
            })
            .send(row.id)
        }
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      }
    },
    mounted () {
      this.loadRoles()
    }
  }
</script>

<style lang="less" scoped>
  .authority-container {
    flex: .5;
    padding-right: 20px;
    .authority-module + .authority-module {
      margin-top: 10px;
    }
    .authority-module {
      .module-title {
        font-size: 14px;
        font-weight: bold;
      }
      .authority {
        display: inline-block;
        margin-top: 5px;
        font-size: 12px;
      }
      .authority + .authority {
        margin-left: 15px;
      }
    }
  }
  .manager-container {
    flex: .5;
  }
</style>
