var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0 20px"}},[_c('el-table',{ref:"table",attrs:{"slot":"center","data":_vm.dataList,"default-expand-all":true},slot:"center"},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"width":"400px","margin":"0 auto"}},[_c('el-table',{attrs:{"data":_vm.rebates,"stripe":""}},[(row.rebateType===2)?_c('el-table-column',{attrs:{"label":"消耗"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"#50a654","width":"60px","display":"inline-block"}},[_vm._v(_vm._s(row.min))]),_c('span',{staticStyle:{"color":"#50a654","width":"20px","display":"inline-block"}},[_vm._v("--")]),_c('span',{staticStyle:{"color":"#50a654","width":"60px","display":"inline-block"}},[_vm._v(_vm._s(row.max))])]}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"label":"比例%","prop":"percent","width":"80"}}),_c('el-table-column',{attrs:{"label":"分类名","prop":"category","width":"80"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"area","label":"区域"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.area==1?'海外':'国内'))])]}}])}),_c('el-table-column',{attrs:{"prop":"platformName","label":"平台"}}),_c('el-table-column',{attrs:{"prop":"level","label":"代理级别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.level==1?'一代':'二代'))])]}}])}),_c('el-table-column',{attrs:{"prop":"contractSubjectName","label":"签约主休"}}),_c('el-table-column',{attrs:{"prop":"proxyCompanyName","label":"上游代理"}}),_c('el-table-column',{attrs:{"prop":"currency","label":"结算币种"}}),_c('el-table-column',{attrs:{"prop":"dateStart","label":"政策开始日期"}}),_c('el-table-column',{attrs:{"prop":"dateEnd","label":"政策结束日期"}}),_c('el-table-column',{attrs:{"prop":"payPolicyName","label":"返点结算政策"}}),_c('el-table-column',{attrs:{"prop":"rebateType","label":"返点类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.rebateType==1?'比例返点':'阶梯返点'))])]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status==1)?_c('span',{staticStyle:{"color":"#b53639"}},[_vm._v("无效")]):(row.status==2)?_c('span',{staticStyle:{"color":"#50a654"}},[_vm._v("生效中")]):_c('span',{staticStyle:{"color":"#1c30a6"}},[_vm._v("到期")])]}}])}),_c('el-table-column',{attrs:{"label":"创建时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.createUserName))]),_c('br'),_c('span',[_vm._v(_vm._s(row.createTime))])]}}])}),_c('el-table-column',{attrs:{"label":"修改时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.updateUserName))]),_c('br'),_c('span',[_vm._v(_vm._s(row.updateTime))])]}}])}),_c('el-table-column',{attrs:{"label":"全同类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.contractType==1)?_c('span',{staticStyle:{"color":"#b53639"}},[_vm._v("原始合同")]):(row.contractType==2)?_c('span',{staticStyle:{"color":"#50a654"}},[_vm._v("非原始合同")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }