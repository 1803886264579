<template>
  <div class="stage-list" :style="{'height': contentHeight + 'px'}" v-show="stages.length > 0">
    <div class="stages">
      <div class="stage" :class="{'selected': s.selected}" v-for="s in stages" :key="s.id" @click="handleSelectStage(s)">
        {{s.title}}
      </div>
    </div>
    <div class="content" v-show="contentVisible">
      <div class="content-loading" v-show="detailLoading">
        <i class="el-icon-loading"></i>
      </div>
      <div ref="description" class="description font-small" v-show="!!description" v-html="description"></div>
      <div v-if="!description" class="text-muted font-mini">- 这位同学很懒，什么都没说 -</div>

      <div class="form-container" v-if="selectedStage && selectedStage.hasForm">
        <div v-for="fg in fieldGroups" class="field-group">
          <div class="group-title">
            表单：{{fg.title}}
          </div>
          <div class="field-layout">
            <div class="field-row" v-for="(row, rowIndex) in fg.fieldLayouts" :key="rowIndex">
              <div class="field-column" v-for="col in row" :key="col.name" :style="{flex: col.span / 24}">
                <div v-if="col.type === 'title'" class="field-title">{{col.name}}</div>
                <label v-if="col.type === 'field'" class="field-label">{{col.field.label}}</label>
                <div v-if="col.type === 'field'" class="field-input">
                  <span v-html="formatFormValue(col.field)"></span>
                  <span class="font-mini text-muted">{{col.field.suffix}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getSuccessStages, getStageDetail } from '../../../../../http/api/stage'
  import formFieldFormat from '../stage-detail/form-format'

  export default {
    data () {
      return {
        stages: [],
        description: null,
        fieldGroups: [],
        contentHeight: 30,
        contentVisible: false,
        detailLoading: false
      }
    },
    watch: {
      contentVisible (v) {
        if (v) {
          this.contentHeight = 200
        } else {
          this.contentHeight = 30
        }
      }
    },
    computed: {
      selectedStage () {
        return this.stages.find(item => item.selected)
      }
    },
    methods: {
      loadStages (flowId) {
        getSuccessStages()
          .success(resp => {
            for (let s of resp.data) {
              s.selected = false
            }
            this.stages = resp.data
          })
          .send(flowId)
      },
      handleSelectStage (stage) {
        if (stage.selected) {
          stage.selected = false
          this.contentVisible = false
          return
        } else {
          if (this.selectedStage) {
            this.selectedStage.selected = false
          }
          stage.selected = true
        }
        this.contentVisible = true
        if (stage.detailData) {
          this.renderDetail(stage)
        } else {
          this.detailLoading = true
          getStageDetail()
            .complete(() => (this.detailLoading = false))
            .success(resp => {
              stage.detailData = resp.data
              this.renderDetail(stage)
            })
            .send(stage.id)
        }
      },
      renderDetail (stage) {
        this.fieldGroups = []
        this.description = stage.detailData['description']
        if (stage.hasForm) {
          for (let form of stage.detailData['formData']) {
            this.fieldGroups.push(form)
            for (let layouts of form.fieldLayouts) {
              for (let layout of layouts) {
                layout.field = form.fieldMap[layout.name]
              }
            }
          }
        }
      },
      formatFormValue (field) {
        return formFieldFormat(field)
      }
    },
    mounted () {
    }
  }
</script>

<style lang="less" scoped>
  .stage-list {
    display: flex;
    flex-direction: column;
    border: solid 1px #e2e2e2;
  }

  @borderLine: solid 1px #e2e2e2;
  .content {
    flex: 1;
    padding: 10px;
    overflow: auto;
    position: relative;
    .content-loading {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding-top: 30px;
      background-color: rgba(0,0,0,.2);
      color: #fff;
      font-size: 16px;
      text-align: center;
    }
  }
  .stages {
    //width: @stagesWidth;
    border-bottom: @borderLine;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .stage {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    &.selected {
      background-color: #eaeaea;
    }
    &:not(:first-child) {
      border-left: @borderLine;
    }
  }

  @import "../stage-detail/form-style";
</style>
