<!--
  让用户可以从远程数据中查询并选择的组件，类似"自动完成"组件。
  数据项的结构可以是任意的，但必须是简单对象，你可以通过idName和textName来定义数据的结构，通过displayText来定义显示数据的哪个信息。
  你需要提供一个数据源函数（findData配置项），当用户点击"选择"或输入关键字搜索时，要返回数据项。

  你可以通过配置项"selected-item.sync"来同步获取用户的选择项，也可以通过selectItem事件来获取。

  事件：
    selectItem(item)
    clearItem()
    addItem(extras, _this) extras是外部传来的属性，_this是组件本身

  列表项模板：
  默认的，列表项只显示一个文本信息，如果你希望自己定义数据展现，可以像下面这样做。
  <auto-complete>
    <template slot-scope="scope">
      <div>{{scope.item.text}}</div>
    </template>
  </auto-complete>
-->
<template>
  <div class="auto-complete" @click.stop=""><!--click.stop阻止事件传递，避免事件到body上导致触发关闭搜索面板组件-->
    <div class="input-box" :style="inputBoxStyle">
      <div class="content" :class="multiSelect ? 'multi' : 'single'">
        <div v-if="isItemEmpty && placeholder" class="placeholder">{{placeholder}}</div>

        <el-tag v-if="multiSelect"
                :key="i[idName]"
                v-for="i in item"
                :disable-transitions="true"
                type="info"
                size="mini"
                @close="item.splice(item.indexOf(i), 1)"
                closable
        >
          {{getItemText(i)}}
        </el-tag>

        <span v-if="!multiSelect">{{getItemText(item)}}</span>
        <span v-if="!multiSelect" v-show="!!item" class="clear" @click="setSelectedItem(null)">
          <i class="fas fa-times-circle"></i>
        </span>
      </div>
      <div class="select" @click="onSelectButtonClick" :class="{disabled: disabled}">{{selectButtonText}}</div>
    </div>

    <list-search
      ref="listSearcher"
      v-show="searcherVisible"
      :data-list="dataList"
      :loading="loading"
      :enable-search="enableSearch"
      :enable-add-button="enableAddButton"
      :result-total="dataListTotal"
      @addItem="handleAddItem"
      @searchKeyChanged="onSearchKeyChanged"
    >
      <li
        :key="d[idName]"
        v-for="d in dataList"
        class="text-main font-small"
        :class="{selected: multiSelect && !!item.find(i => i[idName] === d[idName])}"
        @click="onListSearchItemClick(d)"
      >
        <slot :item="d">
          <i class="el-icon-star-off" style="color: #4575b2; font-size: 14px;" />
          {{getItemText(d)}}
        </slot>
      </li>
    </list-search>
  </div>
</template>

<script>
  import debounce from 'throttle-debounce/debounce'
  import ListSearch from './list-search'

  export default {
    components: { ListSearch },
    props: {
      extras: Object,
      disabled: { type: Boolean, default: false },
      multiSelect: { type: Boolean, default: false },
      idName: { type: String, default: 'id' }, // 数据的id属性
      textName: { type: String, default: 'title' }, // 数据的文本属性，与displayText不同的是，xxxName配置决定数据的结构，如果你提供一个{id: xxx, name: xxx}的结构，会被转成{id: xxx, title: xxx}的结构
      displayText: { type: String | Array, default: () => ['text', 'name', 'title'] }, // 显示数据关键信息
      selectedItem: Object | Array,
      size: {
        type: String,
        default: 'small',
        validate (value) {
          return value === 'mini' || value === 'small' || value === 'normal' || value === 'large'
        }
      },
      placeholder: String,
      enableSearch: { type: Boolean, default: true }, // 是否启用关键字搜索输入框
      enableAddButton: { type: Boolean, default: false }, // 是否显示"添加项目"的按钮
      selectButtonText: { type: String, default: '选择' },
      /*
        数据源
        参数1是搜索关键字
        参数2是一个回调函数，调用者需要将数据源给该回调函
        参数3是extras属性
       */
      findData: { type: Function, required: true },
      findOnSelectButtonClick: { type: Boolean, default: true }, // 点击"选择"按钮之后，立即执行findData
      findDelay: { type: Number, default: 500 }, // 当搜索输入改变时，延迟多少毫秒触发搜索操作
      clearSearchResultOnClose: { type: Boolean, default: false }
    },
    data () {
      let item
      if (this.selectedItem) {
        if (Array.isArray(this.selectedItem)) {
          for (let i = 0; i < item.length; i++) {
            item[i] = this.convertData(item[i])
          }
        } else {
          item = this.convertData(this.selectedItem)
        }
      } else {
        item = (this.multiSelect ? [] : null)
      }
      this.$emit('update:selectedItem', item)
      return {
        loading: false,
        searcherVisible: false,
        item: item,
        dataList: [] // 远程搜索结果
      }
    },
    watch: {
      selectedItem (v) {
        this.item = v
      },
      item (val) {
        this.$emit('update:selectedItem', val)
        this.$refs.listSearcher.$el.style.top = this.$el.offsetHeight
      }
    },
    computed: {
      inputBoxStyle () {
        let height
        let fontSize
        switch (this.size) {
        case 'mini':
          height = '26px'
          fontSize = '12px'
          break
        case 'small':
          height = '30px'
          fontSize = '13px'
          break
        case 'normal':
          height = '34px'
          fontSize = '14px'
          break
        case 'large':
          height = '38px'
          fontSize = '14px'
          break
        }
        let style = {
          'font-size': fontSize
        }
        if (this.multiSelect) {
          style['min-height'] = height
        } else {
          style['height'] = height
          style['line-height'] = height
        }
        return style
      },
      selectedItemText () {
        return this.getItemText(this.item)
      },
      dataListTotal () {
        return this.dataList.length
      },
      isItemEmpty () {
        return this.item === null || this.item === undefined || this.item.length === 0
      }
    },
    methods: {
      search (keyword) {
        this.loading = true
        this.dataList = []
        this.findData(keyword, (list) => {
          this.loading = false
          if (this.searcherVisible) {
            this.dataList = list || []
          }
        }, this.extras)
      },
      closeSearcher () {
        this.searcherVisible = false
        this.loading = false
        this.$nextTick(() => {
          if (this.clearSearchResultOnClose) {
            this.dataList = []
          }
        })
      },
      getItemText (item) {
        if (item) {
          let val = null
          if (Array.isArray(this.displayText)) {
            for (let t of this.displayText) {
              val = item[t]
              if (val) {
                break
              }
            }
          } else {
            val = item[this.displayText]
          }
          return val || item
        } else {
          return null
        }
      },
      getSelectedItem () {
        return this.item
      },
      /**
       * @param item {Array | Object}
       */
      setSelectedItem (item) {
        if (item === undefined || item === null) {
          this.item = (this.multiSelect ? [] : null)
        } else if (Array.isArray(item)) {
          for (let i = 0; i < item.length; i++) {
            item[i] = this.convertData(item[i])
          }
          this.item = item
        } else {
          this.item = this.convertData(item)
        }
      },
      convertData (obj) {
        let data = {}
        for (const key of Object.keys(obj)) {
          if (key === this.textName) {
            data[key] = this.getItemText(obj)
          } else {
            data[key] = obj[key]
          }
        }
        return data
      },
      onSelectButtonClick () {
        if (this.disabled) return
        if (this.searcherVisible === true) {
          this.closeSearcher()
        } else {
          this.searcherVisible = true
          document.addEventListener('click', () => this.closeSearcher(), { once: true }) // 点击页面任何地方关闭搜索面板
          if (this.findOnSelectButtonClick && this.dataList.length === 0) {
            this.search(this.$refs.listSearcher.getKeyword())
          }
        }
      },
      onSearchKeyChanged (keyword) {
        this.debouncedFindData(keyword)
      },
      onListSearchItemClick (item) {
        if (this.multiSelect) {
          let exists = !!this.item.find(i => i[this.idName] === item[this.idName])
          if (!exists) {
            this.item.push(item)
          }
        } else {
          this.setSelectedItem(item)
        }
        this.$emit('selectItem', this.item)
        !this.multiSelect && this.closeSearcher()
      },
      handleAddItem () {
        this.$emit('addItem', this.extras, this)
        this.closeSearcher()
      }
    },
    mounted () {
      this.debouncedFindData = debounce(this.findDelay, (keyword) => {
        this.search(keyword)
      })
    }
  }
</script>

<style lang="less" scoped>

  @deep: ~'>>>';

  .auto-complete {
    position: relative;
    overflow: visible;
  }

  @borderRadius: 5px;

  .input-box {
    display: flex;
    border-radius: @borderRadius;
    border: solid 1px #dcdfe6;
    background-color: #fff;
    .content {
      flex: 1;
      height: inherit;
      min-height: inherit;
      border-top-left-radius: @borderRadius;
      border-bottom-left-radius: @borderRadius;
      background-color: #f9f9f9;
      &.multi {
        padding: 0 10px;
        & @{deep} .el-tag {
          margin: 5px 0;
        }
      }
      &.single {
        position: relative;
        padding: 0 30px 0 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .clear {
          @size: 20px;
          position: absolute;
          right: 5px;
          top: 50%;
          margin-top: -(@size / 2);
          width: @size;
          height: @size;
          line-height: @size;
          text-align: center;
          cursor: pointer;
          color: #aeaeae;
        }
      }
      .placeholder {
        height: inherit;
        min-height: inherit;
        display: flex;
        flex-flow: column;
        justify-content: center;
        color: #b4b4b4;
      }
    }
    .select {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 0 8px;
      color: #6a6a6a;
      user-select: none;
      border-left: solid 1px #dcdfe6;
      background-color: #efefef;
      border-top-right-radius: @borderRadius;
      border-bottom-right-radius: @borderRadius;
      &.disabled {
        cursor: not-allowed;
      }
      &:not(.disabled) {
        cursor: pointer;
        &:active {
          background-color: #f4f4f4;
        }
      }
    }
  }
</style>
