<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="用户编辑"
    @save="onSave"
    @close="onClose"
    :disableFooterButton="!editable"
  >
    <div v-show="!editable" class="text-danger font-mini content-center" style="margin-bottom: 10px;">该用户只能分配角色，无法修改信息。</div>
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="邮箱" prop="username" class="app_required-input">
        <el-input v-model="formData.username" :readonly="!isUsernameEditable || !editable"/>
      </el-form-item>
      <el-form-item label="密码" prop="password" :class="{'app_required-input': isUsernameEditable}">
        <el-input type="password" v-model="formData.password" :readonly="!editable"/>
      </el-form-item>
      <el-form-item label="姓名" prop="name" class="app_required-input">
        <el-input v-model="formData.name" :readonly="!editable"/>
      </el-form-item>
      <el-form-item label="是否可用" prop="enabled">
        <el-switch v-model="formData.enabled" :disabled="!editable"></el-switch>
      </el-form-item>
    </el-form>
    <div v-if="formData.id != null">
      <split-line text="为用户分配角色" align="center"></split-line>
      <el-alert type="warning">⚠️用户的角色被移除后，该用户会被强制登出。</el-alert>
      <ul class="list-group" style="margin-top: 10px;">
        <li class="list-group-item hover-item" v-for="r in role.dataList" :key="r.id">
          <div style="display: flex; flex-direction: row;">
            <div style="flex-grow: 1;">
              <div class="">
                {{r.name}}
                <span class="text-muted" v-if="r.alias">（{{r.alias}}）</span>
              </div>
              <div class="text-muted font-mini">{{r.description}}</div>
            </div>
            <el-switch v-model="r.enabled" @change="onRoleEnabledStatusChange(r)"></el-switch>
          </div>
        </li>
      </ul>
    </div>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import {
    addManager,
    updateManager,
    getAvailableRoles,
    applyRoleToManager,
    removeRoleFromManager
  } from '../../../../http/api/manager'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        formData: {
          id: null,
          username: null,
          password: null,
          name: null,
          enabled: true
        },
        role: { // 角色分配和移除相关
          dataList: [],
          roleLoading: false
        }
      }
    },
    watch: {
      'formData.id' (id) {
        if (id) {
          this.loadAvailableRoles(id)
        }
      }
    },
    computed: {
      editable () {
        if (this.model) {
          return this.model.editable
        } else {
          return true
        }
      },
      isUpdate () {
        return !!this.formData.id
      },
      isUsernameEditable () {
        return !this.isUpdate
      }
    },
    methods: {
      open (model) {
        this.model = model
        this.visible = true
        if (model) {
          kit.obj.getProperties(this.formData, model)
        }
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.formData.id = null
      },
      onSave () {
        if (!this.formData.enabled) {
          this.$confirm('禁用用户会使该用户被强制登出，是否继续？', '警告', {
            type: 'warning'
          })
            .then(() => (this.save()))
            .catch(() => {})
        } else {
          this.save()
        }
      },
      save () {
        this.closable = false
        this.saveButtonLoading = true
        let api = this.isUpdate ? updateManager() : addManager()
        api
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.formData.id = resp.data.id
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data)
          })
          .send(this.formData)
      },
      loadAvailableRoles (managerId) {
        this.role.roleLoading = true
        getAvailableRoles()
          .complete(() => (this.role.roleLoading = false))
          .success(resp => {
            for (let r of resp.data) {
              r.loading = false
            }
            this.role.dataList = resp.data
          })
          .send(managerId)
      },
      onRoleEnabledStatusChange (r) {
        let api = r.enabled ? applyRoleToManager() : removeRoleFromManager()
        api
          .error(resp => {
            r.enabled = !r.enabled
            this.$message.error({
              content: resp.message,
              duration: 3
            })
          })
          .send(this.formData.id, r.id)
      }
    }
  }
</script>

<style scoped>

</style>
