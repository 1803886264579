/**
 * @deprecated use modal-panel.vue
 */
import Panel from './slide-panel'

export default {
  install (Vue) {
    Vue.component('SlidePanel', Panel)
  }
}
