/*
  流程表单数据字典的key
  @see src/http/api/workflow-form-dict.js
 */

export const DICT_AD_PLATFORM = 'adPlatform'
export const DICT_PAY_POLICY = 'payPolicy'
export const DICT_PAY_TYPE = 'payType'
export const DICT_PUT_TYPE = 'putType'
export const DICT_CURRENCY = 'currency'
export const DICT_CUSTOMER_TYPE = 'customerType'
export const DICT_INDUSTRY_TYPE = 'industryType'
