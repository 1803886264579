<template>
  <page route-name="NotFound" :use-tcb-layout="false">
    <div class="container">
      <img src="/404.png">
      <div class="info text-muted font-mini">找不到地址：<span class="text-primary">{{$route.query['from']}}</span></div>
    </div>
  </page>
</template>

<script>
  export default {
    created () {
    }
  }
</script>

<style scoped>
  .container {
    width: 300px;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
    border: solid 1px #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  img {
    display: block;
    margin: 0 auto 20px auto;
  }
  .info {
    display: inline-block;
    padding: 5px 10px;
    border: solid 1px #ddd;
    border-radius: 5px;
    background-color: #fff;
    word-break: break-all;
  }
</style>
