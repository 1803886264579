/**
 * 某些父路由配置了"流程详情"作为子路由，使用navToWorkflowDetail函数来便捷的跳转到"流程详情"路由页面。
 * @see /src/router/config/workflow.js #createWorkflowDetailRouters
 */
export default {
  methods: {
    /**
     * @param flowIdName 对应路由配置在使用createWorkflowDetailRouters()时的第一个参数。
     * @param flowId
     * @param [flowType]
     */
    navToWorkflowDetail (flowIdName, flowId, flowType) {
      let params = {}
      if (flowIdName.startsWith('_CUSTOM_')) {
        params['flowType'] = flowType
        params['flowId'] = flowId
      } else if (flowIdName === 'Auto') {
        params['id'] = flowId
      } else {
        params['flowId'] = flowId
      }
      this.$router.push({ name: `${flowIdName}WorkflowDetail`, params: params })
    }
  }
}
