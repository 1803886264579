<template>
  <div>
    <el-input
      v-if="field.type === 'string'"
      :ref="field.name"
      :type="field.multiLine ? 'textarea' : 'text'"
      :autosize="field.multiLine"
      v-model="fieldData">
      <template slot="append" v-if="!!field.suffix">{{field.suffix}}</template>
    </el-input>


    <el-switch
      v-if="field.type === 'boolean'"
      :ref="field.name"
      v-model="fieldData"
      :active-text="field.trueText"
      :inactive-text="field.falseText">
    </el-switch>

    <el-date-picker
      v-if="field.type === 'date' && field.dateType !== 'time'"
      :ref="field.name"
      v-model="fieldData"
      :type="field.dateType"
      :format="field.format"
      :value-format="field.format"
      style="width: 100%;">
    </el-date-picker>

    <el-time-picker
      v-if="field.type === 'date' && field.dateType === 'time'"
      :ref="field.name"
      v-model="fieldData"
      :format="field.format"
      :value-format="field.format"
      style="width: 100%;">
    </el-time-picker>

    <el-date-picker
      v-if="field.type === 'multidate'"
      :ref="field.name"
      v-model="fieldData"
      :format="field.format"
      :value-format="field.format"
      type="dates"
      style="width: 100%;">
    </el-date-picker>

    <el-select
      v-if="field.type === 'enum' || field.type === 'dict'"
      :ref="field.name"
      v-model="fieldData"
      :clearable="!field.required"
      style="width:100%;">
      <el-option
        v-for="item in field.items"
        :key="item.value"
        :label="item.title"
        :value="item.value">
      </el-option>
    </el-select>

    <auto-complete
      v-if="field.type === 'autocomplete'"
      :multi-select="field.multi"
      :extras="{
        source: field.source,
        name: field.name,
        dependQuery: field.fullPrefix?field.fullPrefix+field.dependQuery:field.dependQuery,
        cascade: field.cascade,
        params: field.params
      }"
      :selected-item.sync="fieldData"
      :formData="formData"
      :clearSearchResultOnClose="!!field.dependQuery"
      :find-data="findAutoCompleteDataSource"
      @addItem="handleAddItem"
      :enable-add-button="field.newItem">
    </auto-complete>

    <table-field-item
      v-if="field.type === 'table'"
      ref="tableFieldItem"
      :columns="field.columns"
      :amounts="field.amounts"
      :columnsRemark="field.columnsRemark"
      :formData="formData"
      @rowsChanged="handleTableRowChanged"
    >
    </table-field-item>

    <number-field-item
      v-if="field.type === 'int' || field.type === 'double' || field.type === 'currency'"
      ref="numberFieldItem"
      :field="field"
      :formData="formData"
      v-model="fieldData"
    >
    </number-field-item>

    <div class="text-muted font-mini" style="margin-top: 5px;">{{field.description}}</div>

    <ad-customer-editor
      :ref="field.source"
      v-if="field['newItem'] && field.source === 'customer'">
    </ad-customer-editor>
    <ad-sub-customer-editor
      :ref="field.source"
      v-if="field['newItem'] && field.source === 'subCustomer'">
    >
    </ad-sub-customer-editor>
  </div>
</template>

<script>
  import kit from '../../../../../tools/kit'
  import AdCustomerEditor from '../../../../ad/customer/comp/editor'
  import AdSubCustomerEditor from '../../../../ad/customer/comp/sub-customer-editor'
  import TableFieldItem from './field-comps/table-field-item'
  import NumberFieldItem from './field-comps/number-field-item'
  import { findFlowSubmitData } from '../../../../../http/api/workflow'
  import { getRemoteFormValueForInit } from '@/http/api/form-value-init'

  export default {
    components: { NumberFieldItem, AdCustomerEditor, AdSubCustomerEditor, TableFieldItem },
    props: {
      flowType: { type: String, required: true },
      flowId: { type: String, required: true },
      formId: { type: String, required: true },
      field: { type: Object, required: true },
      formData: { type: Object, required: true }
    },
    data () {
      return {
        editorView: null,
        fieldData: null
      }
    },
    watch: {
      fieldData (val) {
        this.formData[this.field.name] = val
      }
    },
    methods: {
      setFieldData (data) {
        if (this.field.multi === false && Array.isArray(data)) {
          this.fieldData = data[0]
        } else {
          this.fieldData = data
        }
        if (this.field.type === 'table') {
          this.$refs['tableFieldItem'].updateFieldData(this.fieldData)
        } else if (this.field.type === 'int' || this.field.type === 'double' || this.field.type === 'currency') {
          this.$refs['numberFieldItem'].updateFieldData(this.fieldData)
        }
      },
      findAutoCompleteDataSource (keyword, cb, extras) {
        let p
        if (extras.dependQuery) {
          p = this.formData[extras.dependQuery]
          if (kit.obj.isPlainObject(p) || Array.isArray(p)) {
            p = JSON.stringify(p)
          }
        }
        findFlowSubmitData()
          .success(resp => {
            cb(resp.data)
          })
          .error(() => {
            cb()
          })
          .send(this.flowType, extras.source, keyword, p, extras.params)
      },
      handleAddItem (extras, autoComplete) {
        this.$refs[extras.source].open(data => {
          autoComplete.setSelectedItem(data)
        })
      },
      handleTableRowChanged (rows) {
        this.fieldData = rows
      }
    },
    mounted () {
      if (this.field.remoteInit != null) {
        getRemoteFormValueForInit()
          .success(resp => {
            if (this.field.type === 'table') {
              this.$refs['tableFieldItem'].updateFieldData(resp.data)
            } else if (this.field.type === 'dict') {
              if (typeof resp.data === 'number') {
                this.fieldData = resp.data + ''
              } else {
                this.fieldData = resp.data
              }
            } else {
              this.fieldData = resp.data
            }
          })
          .send(this.flowId, this.formId, this.field.name, this.field.remoteInit)
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .form-item @{deep} label {
    font-size: 13px;
    height: 22px;
    line-height: 22px;
  }
  .form-item.required @{deep} label {
    &::before {
      content: '*';
      float: left;
      padding-right: 5px;
      padding-top: 2px;
      font-size: 16px;
      color: #d13025;
    }
  }
</style>
