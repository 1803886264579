<template>
  <modal-panel
    :visible.sync="visible"
    :saveButtonLoading="saveButtonLoading"
    :closable="closable"
    :disableFooterButton="!editable"
    title="角色编辑"
    @close="onClose"
    @save="onSave"
  >
    <div v-show="!editable" class="text-danger font-mini content-center" style="margin-bottom: 10px;">预定义角色只能编辑权限。</div>
    <el-form ref="form" :model="form" label-width="70px">
      <el-form-item label="名称" prop="name" class="app_required-input">
        <el-input v-model="form.name" :readonly="!editable" />
      </el-form-item>
      <el-form-item label="别名" prop="alias">
        <el-input v-model="form.alias" :readonly="!editable" />
        <p class="text-muted font-mini">别名在工作流程的配置中起作用。</p>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input type="textarea" :autosize="{minRows: 2, maxRows: 5}" v-model="form.description" :readonly="!editable"/>
      </el-form-item>
      <el-form-item label="是否可用" prop="enabled">
        <el-switch v-model="form.enabled" :disabled="!editable"></el-switch>
      </el-form-item>
    </el-form>
    <div v-if="form.id != null">
      <split-line text="为角色分配权限" align="center"></split-line>
      <el-alert type="warning">⚠️角色的权限被移除后，拥有该角色的用户会被强制登出。</el-alert>
      <div v-for="am of authorities" :key="am.title">
        <split-line :text="am.title"></split-line>
        <ul class="list-group">
          <li class="list-group-item hover-item" v-for="a in am.authorities" :key="a.id">
            <div style="display: flex; flex-direction: row;">
              <div style="flex-grow: 1;">
                <div class="">{{a.title}}</div>
                <div class="text-muted font-mini">{{a.description}}</div>
              </div>
              <el-switch v-model="a.applied" @change="onAuthorityApplyStatusChange(a)"></el-switch>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </modal-panel>
</template>

<script>
  import {
    addRole,
    updateRole,
    listAuthorities,
    getRoleAuthorityIds,
    applyAuthorityToRole,
    removeAuthorityFromRole
  } from '../../../../../http/api/role'
  import kit from '../../../../../tools/kit'
  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        form: {
          id: null, // 提供id表示更新操作，不提供id表示新增操作
          name: null,
          alias: null,
          description: null,
          enabled: true
        },
        authorities: [], // 所有权限，首先是权限组，权限组下面才是权限
        authoritiesMap: {} // 将所有权限组下的权限以{authorityId: authority}结构保存，方便更新权限里面的applied和loading状态
      }
    },
    watch: {
      'form.id' (id) {
        if (id != null) {
          this.resetAuthoritiesApplyStatus()
          this.loadRoleAuthorityIds(id)
        }
      }
    },
    computed: {
      editable () {
        if (this.model) {
          return !this.model.preDefine
        } else {
          return true
        }
      }
    },
    methods: {
      open (model) {
        this.resetAuthoritiesApplyStatus()
        this.model = model
        this.visible = true
        if (model) {
          kit.obj.getProperties(this.form, model)
        }
      },
      resetAuthoritiesApplyStatus () {
        for (let a of Object.values(this.authoritiesMap)) {
          a.applied = false
          a.loading = false
        }
      },
      loadAuthorities () {
        listAuthorities()
          .success(resp => {
            for (let am of resp.data) {
              for (let a of am.authorities) {
                a.applied = false // switch开关状态
                a.loading = false // switch的分配和移除权限时的请求等待状态
                this.authoritiesMap[a.id] = a
              }
            }
            this.authorities = resp.data
            this.resetAuthoritiesApplyStatus()
          })
          .send()
      },
      // 加载角色拥有的权限ID
      loadRoleAuthorityIds (roleId) {
        for (let a of Object.values(this.authoritiesMap)) {
          a.loading = true
        }
        this.getRoleAuthorityIdsCancel = getRoleAuthorityIds()
          .complete(() => {
            for (let a of Object.values(this.authoritiesMap)) {
              a.loading = false
            }
          })
          .success(resp => {
            for (let id of resp.data) {
              let a = this.authoritiesMap[id]
              if (a) {
                a.applied = true
              }
            }
          })
          .send(roleId)
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.form.id = null
        this.getRoleAuthorityIdsCancel && this.getRoleAuthorityIdsCancel.cancel()
      },
      onSave () {
        if (!this.form.enabled) {
          this.$confirm(`禁用角色会使拥有该角色的用户被强制登出，是否继续？`, '确认', {
            type: 'warning'
          })
            .then(() => {
              this.saveRole()
            })
            .catch(() => {})
        } else {
          this.saveRole()
        }
      },
      saveRole () {
        this.saveButtonLoading = true
        this.closable = false
        let api = this.form.id == null ? addRole() : updateRole()
        api
          .complete(() => {
            this.saveButtonLoading = false
            this.closable = true
          })
          .success(resp => {
            this.$message.success('保存成功。')
            kit.obj.getProperties(this.form, resp.data)
            this.$emit('saved', resp.data)
          })
          .send(this.form)
      },
      // 为角色分配权限或移除权限
      onAuthorityApplyStatusChange (authority) {
        authority.loading = true
        let api = authority.applied ? applyAuthorityToRole() : removeAuthorityFromRole()
        api
          .complete(() => (authority.loading = false))
          .error(resp => {
            authority.applied = !authority.applied
            this.$message.error({
              content: resp.message,
              duration: 3
            })
          })
          .send(this.form.id, authority.id)
      }
    },
    mounted () {
      this.loadAuthorities()
    }
  }
</script>

<style scoped>

</style>
