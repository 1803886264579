/*
  如何管理模块化状态：
  在./modules目录下新建一个js文件，定义一个类继承./modules/module.js，将模块的状态以及其他操作
  定义在里面，然后在这里导入注册到modules属性中。
  /// 所有的模块状态都会被注入到vue组件 ///
 */

import Vuex from 'vuex'
import Vue from 'vue'
import user, { USER_MODULE, UserStore } from './modules/user'
import page, { PAGE_TITLE_MODULE, PageTitleStore } from './modules/page-title'
import todo, { TODO_MODULE, TodoStore } from './modules/todo'

Vue.use(Vuex)

export const SET_LOADING = 'setLoading'
export const SET_ROOT_ROUTE_NAME = 'setRootRouteName'

const store = new Vuex.Store({
  state: {
    // 在根组件（#app）中设置一个v-loading指令，通过该状态来控制"加载动画"的显示和隐藏，
    // 你可以在任何组件中通过this.$loading()函数来显示和隐藏加载动画，在main.js中使用插件方式
    // 为所有组件实例增加了$loading函数。
    loading: false,
    // page组件级别的加载动画
    loadingPage: false,
    loadingText: '',
    // 当路由改变时，设置路由的根的名称，用于系统菜单的高亮显示
    rootRouteName: null
  },
  mutations: {
    /**
     * @param state
     * @param config {Object}
     *   loading {boolean}
     *   text {string}
     *   area {string} 不提供表示显示隐藏全屏的加载动画，'page'表示在components/page组件中显示隐藏加载动画。
     */
    [SET_LOADING] (state, config) {
      if (config.area === 'page') {
        state.loadingPage = config.loading
      } else {
        state.loading = config.loading
      }
      state.loadingText = config.text || ''
    },
    [SET_ROOT_ROUTE_NAME] (state, name) {
      state.rootRouteName = name
    }
  },
  modules: {
    [USER_MODULE]: user,
    [PAGE_TITLE_MODULE]: page,
    [TODO_MODULE]: todo
  }
})

export default store
export const userStore = new UserStore(store)
export const pageTitleStore = new PageTitleStore(store)
export const todoStore = new TodoStore(store)

// 将模块化状态管理器注入到vue组件。
Vue.use((Vue) => {
  Object.defineProperty(Vue.prototype, '$userStore', { get () { return userStore } })
  Object.defineProperty(Vue.prototype, '$pageTitleStore', { get () { return pageTitleStore } })
  Object.defineProperty(Vue.prototype, '$todoStore', { get () { return todoStore } })
})
