import defineApi from '../define-api'
import http from '../application-api'

export const getRemoteFormValueForInit = defineApi((config, workflowId, formId, fieldId, param) => {
  config.params = {
    workflowId: workflowId,
    formName: formId,
    fieldName: fieldId,
    remoteParam: param
  }
  http.get('/form_value_init', config)
})
