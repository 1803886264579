import Profile from '../../pages/profile/profile-index'
import ProfileTemplate from '../../pages/profile/profile-template'
import ProfileDetail from '../../pages/profile/profile-detail'
export default [
  {
    name: 'Profile',
    path: '/profile',
    meta: { title: '档案管理' },
    component: Profile,
    children: [
      {
        name: 'ProfileTemplate',
        path: 'profileTemplate',
        meta: { title: '模板管理' },
        component: ProfileTemplate
      }, {
        name: 'ProfileDetail',
        path: 'profileDetail/:id',
        meta: { title: '档案详情' },
        component: ProfileDetail
      }
    ]
  }
]
