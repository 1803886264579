<template>
  <div class="absolute-full auto-layout flow-col template-container">
    <div style="padding: 5px 5px 5px;">
      <el-form :inline="true" :model="search">
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户主体签约名称" prop="adCustomerId">
          <el-select v-model="search.adCustomerId"
                     filterable
                     remote
                     :remote-method="searchAdCustomer"
                     :loading="loading"
                     clearable
                     placeholder="请输入客户名称的关键字进行搜索"
          >
            <el-option
              v-for="item in adCustomers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告账户Id" prop="adId" >
          <el-input v-model="search.adId" clearable style="width: 150px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="auto-layout-full" style="position: relative;">
      <div class="mlu-table">
      <el-table
        ref="table"
        slot="center"
        :data="dataList"
        @expand-change="handleTableExpand"
        @row-dblclick="handleTableRowClick"
        :max-height="500"
      >
        <el-table-column type="expand">
          <template slot-scope="{ row }">
              <el-table :data="row.items"  stripe style="width: 500px; margin-left: 40px;">
                <el-table-column label="客户Id" prop="adId" width="150"></el-table-column>
                <el-table-column label="充值金额" prop="price" width="120"></el-table-column>
              </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="platformName" label="平台" width="80"></el-table-column>
        <el-table-column prop="adCustomerName" label="客户主体签约名称" width="200" ></el-table-column>
        <el-table-column label="付款类型" width="80">
          <template slot-scope="{ row }">
            <span  v-if="row.type === 'payLater'">账期</span>
            <span  v-else-if="row.type === 'imprest2'">垫款</span>
            <span  v-else>预付</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="充值金额" ></el-table-column>
        <el-table-column prop="paymentDays" label="预计到账日期"></el-table-column>
        <el-table-column prop="createUserName" label="创建人" ></el-table-column>
        <el-table-column prop="createTime" label="创建时间" ></el-table-column>
      </el-table>
      </div>
    </div>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </div>
  </template>
<script>
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import { getDetail, getTransferRechargeReportList } from '../../../../http/api/refund-Transfer'

  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          adCustomerId: null,
          adId: null
        },
        platforms: [],
        adCustomers: [],
        loading: false
      }
    },
    computed: {
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    filters: {
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getTransferRechargeReportList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            for (let obj of resp.data.records) {
              this.initDetail(obj)
            }
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      initDetail (detail) {
        detail.items = []
        detail.itemLoading = false
        return detail
      },
      handleTableExpand (row) {
        if (row.expanded === undefined) {
          row.expanded = false
        }
        row.expanded = !row.expanded
        if (!row.itemLoading && row.expanded) {
          row.itemLoading = true
          getDetail()
            .complete(() => (row.itemLoading = false))
            .success(resp => {
              row.items = resp.data
            })
            .send(row.id)
        }
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      }
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .template-container @{deep} .mlu-table {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
  }

  .template-container @{deep} .el-table__body-wrapper {
    flex: 1;
    overflow-y: auto !important;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
