<template>
  <div class="wechat-login">
    <div class="message">
      请使用微信扫描二维码进行登录操作
    </div>
    <div class="qr-code-wrapper" :style="{'background-image': `url(${qrCodeUrl})`}">
      <i class="qr-loading fa fa-spin fa-spinner" v-show="loading"></i>
    </div>
    <div class="button">
      <el-button type="primary" @click="close" style="width: 100%;">账号密码登录</el-button>
    </div>
  </div>
</template>

<script>
  import Velocity from 'velocity-animate'
  import QRCode from 'qrcode'
  import { buildClient } from '../../components/stomp'
  import { HOME_PAGE } from '../../router'
  import { getWeChatLoginInfo } from '../../http/api/manager'

  export default {
    data () {
      return {
        visible: false,
        qrCodeUrl: null,
        loading: false
      }
    },
    computed: {
    },
    methods: {
      open () {
        let height = this.$el.parentNode.clientHeight
        this.$el.style.height = height + 'px'
        this.$el.style.top = -height + 'px'
        Velocity(this.$el, { 'top': 0 }, { duration: 150, complete: () => this.loadQrCodeInfo() })
      },
      close () {
        let height = this.$el.parentNode.clientHeight
        Velocity(this.$el, { 'top': -height }, { duration: 150 })
        if (this.cancelRequest) {
          this.cancelRequest.cancel()
        }
        this.qrCodeUrl = null
        this.stompClient && this.stompClient.disconnect()
      },
      loadQrCodeInfo () {
        this.loading = true
        this.cancelRequest = getWeChatLoginInfo()
          .complete(() => (this.loading = false))
          .success(resp => {
            this.connectStomp(resp.data.sessionId)
            QRCode.toDataURL(resp.data.url).then(url => {
              this.qrCodeUrl = url
            })
          })
          .send()
      },
      connectStomp (sessionId) {
        this.stompClient = buildClient()
        this.stompClient.connect(
          { 'X-User-Token': sessionId },
          () => {
            this.stompClient.subscribe('/user/wechat/login/status', msg => {
              let userInfo = JSON.parse(msg.body).data
              console.log(userInfo)
              this.$userStore.setUserInfo(userInfo.user)
              let from = this.$route.query['from']
              if (from) {
                this.$router.push(from)
              } else {
                this.$router.push({ name: HOME_PAGE })
              }
            })
          },
          () => {
            this.$alert('服务器连接失败，请刷新页面。', '错误', { type: 'error' })
          }
        )
      }
    },
    beforeDestroy () {
      this.stompClient && this.stompClient.disconnect()
    }
  }
</script>
<style lang="less" scoped>
  .wechat-login {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 0;
    background-color: #fff;
    overflow: hidden;
    .message {
      padding: 10px 0;
      font-size: 12px;
      color: #6b6b6b;
      text-align: center;
    }
    .qr-code-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      .qr-loading {
        font-size: 16px;
        color: #737373;
      }
    }
    .button {
      padding: 10px;
    }
  }
</style>
