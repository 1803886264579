<!--
  表格类型的表单输入项。
  特性：
    1、以表格形式展现输入项目。
    2、可自由增加行和移除行。
    3、输入项支持文本和数字。

  与其他表单项不同，table-field-item不对外部的fieldData进行属性传递，而是使用updateFieldData函数来
  更新内部值，内部发生变化时，通过rowsChanged事件来通知外部。

  props:
  -----------------------------------
  columns
    表格的列

  amounts
    在表格最底部展示一行，用于汇总

  columnsRemark
    在表格头部行的下面出现一行，用于对头部行的描述补充，用户输入的值，会存在columnsRemark[x].data属性中。
-->
<template>
  <div class="table-field-container">
    <div class="table-wrapper">
      <div class="header">
        <div class="header-item" style="width: 59px; flex: none;"></div>
        <div class="header-item" v-for="col in columns" :key="'table-field-col-' + col.name">{{col.label}}</div>
      </div>
      <div class="header remark-row" v-if="remarkArray.length > 0">
        <div class="header-item" style="width: 59px; flex: none; border-top: solid 1px #d5d5d5;">备注</div>
        <div class="header-item" style="border-top: solid 1px #d5d5d5;" v-for="rm in remarkArray" :key="'table-field-remark-' + rm.columnName">
          <div v-if="!rm.empty" style="margin: 2px 10px;">
            <el-input v-if="rm.type === 'manual'" v-model="columnsRemarkData[rm.columnName]" :placeholder="rm.placeholder" :maxlength="10"></el-input>
            <el-select v-model="columnsRemarkData[rm.columnName]" clearable :placeholder="rm.placeholder" v-else>
              <el-option v-for="item in rm.items" :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="row" v-for="row in rows" :key="row._id">
          <div class="cell remove-cell" @click="handleRemoveRow(row)">移除</div>
          <div class="cell" v-for="col in columns" :key="'table-row-cell-' + col.name">

            <el-tooltip effect="dark"
                        :content="col.formulaRemark"
                        placement="bottom"
                        :disabled="!col.formula"
            >
              <el-input v-if="col.dataType === COL_TYPE.string"
                        v-model="row[col.name]"
                        size="mini"
              >
              </el-input>

              <el-input v-else-if="col.dataType === COL_TYPE.number"
                        v-model="row[col.name]"
                        type="number"
                        size="mini"
              >
              </el-input>
              <div v-else-if="col.dataType === COL_TYPE.calculate" class="auto-calculate">
                {{calculateColumns[row._id] ? calculateColumns[row._id][col.name].value : ''}}
              </div>
              <auto-complete
                v-if="col.dataType === COL_TYPE.autocomplete"
                :multi-select="col.multi"
                :extras="{
                  source: col.source,
                  name: col.name,
                  dependQuery: col.dependQuery,
                  cascade: col.cascade,
                  params: col.params
                }"
                :selected-item.sync="row[col.name]"
                :clearSearchResultOnClose="!!col.dependQuery"
                :find-data="findAutoCompleteDataSource"
                :enable-add-button="col.newItem">
              </auto-complete>
            </el-tooltip>
          </div>
        </div>
        <div class="row amount-row">
          <div class="cell amount-cell">合计</div>
          <div class="cell" v-for="col in columns" :key="'table-row-cell-amount-' + col.name">
            {{amountColumns[col.name] !== undefined ? amountColumns[col.name] : ''}}
          </div>
        </div>
      </div>
    </div>
    <el-button type="primary" size="mini" @click="handleNewRow" style="margin-top: 10px;">增加新行</el-button>
  </div>
</template>

<script>
  import kit from '../../../../../../tools/kit'
  import { findFlowSubmitData } from '../../../../../../http/api/workflow'
  let ROW_ID = 0
  let generateRowId = function () {
    return 'ROW_' + (ROW_ID++)
  }
  const ROW_ID_NAME = '_id'

  export default {
    props: {
      columns: { required: true, type: Array },
      amounts: { required: false, type: Array },
      columnsRemark: { required: false, type: Object },
      // 整个表单对象，如果有公式，需要监听表单中的其他字段数据变化
      formData: { required: false, type: Object }
    },
    data () {
      let amounts = {}
      if (this.amounts) {
        for (let name of this.amounts) {
          amounts[name] = 0
        }
      }
      let columnsRemarkData = {}
      if (this.columnsRemark) {
        for (let colName of Object.keys(this.columnsRemark)) {
          columnsRemarkData[colName] = ''
        }
      }
      return {
        COL_TYPE: {
          string: 'string',
          number: 'number',
          calculate: 'calculate',
          autocomplete: 'autocomplete'
        },
        rows: [],
        /**
         * {
         *   rowId: {
         *     calculateProp1: { formula: , decimal: , value:  },
         *     calculateProp2: { formula: , decimal: , value:  }
         *   }
         * }
         * 将rows中的计算列单独放在这里，避免计算列发生改变导致watch通知重复计算。
         */
        calculateColumns: {},
        /**
         * 总计行
         * {
         *   prop1: Number
         *   prop2: Number
         * }
         */
        amountColumns: amounts,
        /**
         * 用于存储columnsRemark用户输入的数据
         */
        columnsRemarkData: columnsRemarkData
      }
    },
    watch: {
      rows: {
        handler: function () {
          this.$emit('rowsChanged', this.rowsToFieldData(this.rows))
          this.updateCalculateColumnValue()
        },
        deep: true
      }
    },
    computed: {
      columnMap () {
        let colMap = {}
        for (let col of this.columns) {
          colMap[col.name] = col
        }
        return colMap
      },
      remarkArray () {
        let arr = []
        if (this.columnsRemark) {
          this.columns.forEach(col => {
            let remarkCfg = this.columnsRemark[col.name]
            if (!remarkCfg) {
              remarkCfg = {
                columnName: kit.str.id(12),
                empty: true
              }
            } else {
              remarkCfg.empty = false
            }
            arr.push(remarkCfg)
          })
        }
        return arr
      }
    },
    methods: {
      updateFieldData (fieldData) {
        if (fieldData) {
          this.rows = this.fieldDataToRows(fieldData)
          this.columnsRemarkData = fieldData.remarks || {}
        } else {
          this.rows = []
        }
      },
      /**
       * 与rowsToFieldData函数相反，将外部数据转换成内部可渲染的格式。
       */
      fieldDataToRows (fieldData) {
        let tmp = []
        if (fieldData.rows) {
          for (let data of fieldData.rows) {
            tmp.push(this.newRow(data))
          }
        }
        return tmp
      },
      /**
       * 将数据转换成外部表单的数据，用于提交给服务端。
       * 数据格式：
       * {
       *   rows: [{name: value}, ...],
       *   remarks: {
       *     name1: text1,
       *     name2: text2,
       *     ...
       *   }
       * }
       */
      rowsToFieldData (rows) {
        let tableRows = []
        for (let row of rows) {
          let obj = {}
          for (let col of this.columns) {
            obj[col.name] = row[col.name]
          }
          tableRows.push(obj)
        }
        return {
          rows: tableRows,
          remarks: this.columnsRemarkData
        }
      },
      newRow (data) {
        let row = {}
        row[ROW_ID_NAME] = generateRowId()
        for (let col of this.columns) {
          row[col.name] = data ? data[col.name] : null
        }
        this.buildCalculateColumn(row)
        return row
      },
      handleNewRow () {
        this.rows.push(this.newRow())
      },
      handleRemoveRow (row) {
        for( let i = 0; i < this.rows.length; i++){
          if ( this.rows[i] === row) {
            this.rows.splice(i, 1)
            return
          }
        }
      },
      /**
       * 将"计算"列单独放在一个对象中，不直接更新row中的计算列的值，否则会触发多一次watch。
       */
      buildCalculateColumn (row) {
        let hasCal = false
        for (let col of this.columns) {
          if (col.dataType === this.COL_TYPE.calculate) {
            hasCal = true
            this.calculateColumns[row[ROW_ID_NAME]] = {
              [col.name]: {
                formula: col.formula,
                decimal: col.decimal,
                value: null
              }
            }
          }
        }
      },
      /**
       * 表格内容有变化时，重新计算"计算"列的值和总计。
       */
      updateCalculateColumnValue () {
        let amounts = {}
        if (this.rows.length === 0) {
          this.calculateColumns = {}
          for (let name of Object.keys(this.amountColumns)) {
            this.amountColumns[name] = 0
          }
        }
        for (let row of this.rows) {

          // 计算"calculate"列
          let calCols = this.calculateColumns[row[ROW_ID_NAME]]
          if (calCols && Object.keys(calCols).length > 0) {
            for (let name of Object.keys(calCols)) {
              let calCol = calCols[name]
              let formula = calCol.formula
              for (let c of this.columns) {
                let val = row[c.name]
                if (val === undefined || val == null || val === '') {
                  val = 0
                }
                formula = formula.replace('$' + c.name, val)
              }
              calCol.value = parseFloat(eval(formula).toFixed(calCol.decimal))
            }
          }

          // 计算总计
          if (this.amountColumns && Object.keys(this.amountColumns).length > 0) {
            for (let name of Object.keys(this.amountColumns)) {
              if (amounts[name] === undefined) {
                amounts[name] = 0
              }
              if (this.columnMap[name].dataType === this.COL_TYPE.calculate) {
                if (this.calculateColumns[row[ROW_ID_NAME]]) {
                  amounts[name] += this.calculateColumns[row[ROW_ID_NAME]][name].value
                }
              } else {
                amounts[name] += row[name]
              }
            }
          }
        }
        Object.assign(this.amountColumns, amounts)
      },
      findAutoCompleteDataSource (keyword, cb, extras) {
        let p
        if (extras.dependQuery) {
          p = this.formData[extras.dependQuery]
          if (kit.obj.isPlainObject(p) || Array.isArray(p)) {
            p = JSON.stringify(p)
          }
        }
        findFlowSubmitData()
          .success(resp => {
            cb(resp.data)
          })
          .error(() => {
            cb()
          })
          .send(this.flowType, extras.source, keyword, p, extras.params)
      }
    }
  }
</script>

<style lang="less" scoped>
  .table-field-container {
  }

  @border: solid 1px #d5d5d5;
  .table-wrapper {
    border: @border;
    .table-cell {
      flex: 1;
      box-sizing: border-box;
      padding: 3px 0;
      text-align: center;
    }
    .header {
      display: flex;
      .header-item {
        .table-cell();
        font-size: 12px;
        background-color: #f2f2f2;
      }
      .header-item:not(:first-of-type) {
        border-left: @border;
      }
    }
    .remark-row {
      .header-item {
        background-color: #fff;
      }
    }
    .body {
      .row {
        position: relative;
        display: flex;
      }
      .amount-row {
        font-size: 12px;
        font-weight: bold;
        color: #458545;
        background-color: #eaf2ed;
      }
      .remove-cell, .amount-cell {
        display: flex;
        flex: none !important;
        width: 59px;
        font-size: 12px;
        align-items: center;
        justify-content: center;
      }
      .remove-cell {
        color: #b63a36;
        cursor: pointer;
      }
      .amount-cell {
        color: #2a4686;
      }
      .cell {
        position: relative;
        .table-cell();
        padding: 5px 10px;
        border-top: @border;
      }
      .cell:not(:first-of-type) {
        border-left: @border;
      }
      .auto-calculate {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #458545;
        font-size: 13px;
      }
    }
  }
</style>
