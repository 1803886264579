<!--
  上游政策数据以表格显示。
-->
<template>
  <div style="padding: 0 20px;">
    <el-table
      ref="table"
      slot="center"
      :data="dataList"
      :default-expand-all ="true"
    >
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div style="width: 400px; margin: 0 auto;">
            <el-table
              :data="rebates"
              stripe
            >
              <el-table-column label="消耗" v-if="row.rebateType===2">
                <template slot-scope="{ row }">
                  <span style="color: #50a654;width: 60px;display:inline-block" >{{row.min}}</span>
                  <span style="color: #50a654;width: 20px;display:inline-block" >--</span>
                  <span style="color: #50a654;width: 60px;display:inline-block" >{{row.max}}</span>
                </template>
              </el-table-column>
              <el-table-column label="比例%" prop="percent" width="80"></el-table-column>
              <el-table-column label="分类名" prop="category" width="80"></el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="area" label="区域">
        <template slot-scope="{ row }">
          <span>{{row.area==1?'海外':'国内'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="platformName" label="平台"></el-table-column>
      <el-table-column prop="level" label="代理级别">
        <template slot-scope="{ row }">
          <span>{{row.level==1?'一代':'二代'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="contractSubjectName" label="签约主休" ></el-table-column>
      <el-table-column prop="proxyCompanyName" label="上游代理" ></el-table-column>
      <el-table-column prop="currency" label="结算币种" ></el-table-column>
      <el-table-column prop="dateStart" label="政策开始日期" ></el-table-column>
      <el-table-column prop="dateEnd" label="政策结束日期" ></el-table-column>
      <el-table-column prop="payPolicyName" label="返点结算政策" ></el-table-column>
      <el-table-column prop="rebateType" label="返点类型">
        <template slot-scope="{ row }">
          <span>{{row.rebateType==1?'比例返点':'阶梯返点'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" ></el-table-column>
      <el-table-column  label="状态">
        <template slot-scope="{ row }">
          <span style="color: #b53639;" v-if="row.status==1">无效</span>
          <span style="color: #50a654;" v-else-if="row.status==2">生效中</span>
          <span style="color: #1c30a6;" v-else>到期</span>
        </template>
      </el-table-column>
      <el-table-column  label="创建时间">
        <template slot-scope="{ row }">
          <span>{{row.createUserName}}</span><br/>
          <span>{{row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="修改时间">
        <template slot-scope="{ row }">
          <span>{{row.updateUserName}}</span><br/>
          <span>{{row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="全同类型">
        <template slot-scope="{ row }">
          <span style="color: #b53639;" v-if="row.contractType==1">原始合同</span>
          <span style="color: #50a654;" v-else-if="row.contractType==2">非原始合同</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getByWorkflowIdAndProxyWithContract } from '../../../../http/api/upper-lower-policy'

  export default {
    props: {
      flowId: { type: String, required: true },
      formData: { type: Object, required: true }
    },
    data () {
      return {
        dataList: [],
        rebates: []
      }
    },
    watch: {
      formData: {
        handler: function () {
          if (this.formData['proxy'] && this.formData['contract']) {
            this.load(this.formData['proxy']['id'], this.formData['contract']['id'])
          }
        },
        deep: true
      }
    },
    computed: {
    },
    methods: {
      load (proxy, contract) {
        getByWorkflowIdAndProxyWithContract()
          .success(resp => {
            this.dataList = []
            if (resp.data.upper) this.dataList.push(resp.data.upper)
            if (resp.data.rebates) this.rebates = resp.data.rebates
          })
          .send(this.flowId, proxy, contract)
      }
    },
    mounted () {
      if (this.formData['proxy'] && this.formData['contract']) {
        this.load(this.formData['proxy']['id'], this.formData['contract']['id'])
      }
    }
  }
</script>

<style lang="less" scoped>
</style>
