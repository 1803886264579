import defineApi from '../define-api'
import http from '../application-api'

/**
 * 获取用户的系统未读消息数量。
 * @type {Function}
 */
export const getUnReadMessageTotal = defineApi((config) => {
  return http.load('/message/unread/total', config)
})

export const markMessageAsReaded = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/message/mark_read', config)
})

export const getMessageList = defineApi((config, start, limit, readStatus) => {
  config.params = {
    start: start,
    limit: limit,
    readStatus: readStatus
  }
  return http.load('/message/list', config)
})
