<template>
  <el-aside width="250px" class="app-menu">
    <el-menu :default-openeds="defaultOpenMenus" :default-active="activeMenuId" :router="true">

      <el-submenu :key="m.id" :index="m.id" v-for="m in menus" v-show="m.visible">
        <template slot="title">
          <i class="iconfont" :class="m.icon"></i>
          <span style="margin-left: 10px;">{{m.title}}</span>
        </template>
        <el-menu-item :key="sm.id" :index="sm.id" :route="sm.route" v-for="sm in m.menus" :disabled="sm.dev" v-show="sm.visible" v-if="sm.id!=='FavoriteFlows'">
          <i class="iconfont" :class="sm.icon" style="margin-right: 5px;"></i>
          <span>{{sm.title}}<span v-if="sm.dev">（未开发）</span></span>
          <el-badge v-if="sm.badge > 0" :value="sm.badge" style="margin-top: -4px;"></el-badge>
        </el-menu-item>
        <folder v-else-if="sm.id==='FavoriteFlows' && sm.visible === true"
          ref="folder"
          class="folder-container"
          >
        </folder>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>
<script>
  import { MSG_TYPE_TODO } from '../components/stomp'
  import { userStore, todoStore } from '../store'
  import * as flows from '../config/flows'
  import Folder from '../pages/office-app/workflow/dir/folder'
  import { getMyTodoTotal } from '../http/api/todo'

  export default {
    components: { Folder },
    data () {
      let menus = [
        {
          id: 'Customer',
          title: '广告客户',
          icon: 'icon-menu-ad-mgr',
          menus: [
            { id: 'AdCustomer', title: '客户管理', route: '/ad/customer' },
            { id: 'CsCustomer', title: '分配客服', route: '/cs_customer' },
            { id: 'AdCustomerPayOrder', title: '支付订单', route: '/ad/pay_order' },
            { id: 'AdCustomerAd', title: '平台客户管理', route: '/ad_customer_ad' },
            { id: 'AdPlatform', title: '平台管理', route: '/ad_platform' }
          ]
        },
        {
          id: 'Ad',
          title: '广告业务',
          icon: 'icon-menu-ad-mgr',
          menus: [
            { id: 'AdChannelInfo', title: '上游渠道政策', route: '/ad_channel_info' },
            { id: flows.FLOW_AD_POLICY, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_AD_POLICY], route: `/flow/${flows.FLOW_AD_POLICY}` },
            { id: flows.FLOW_SIGN_CONTRACT, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_SIGN_CONTRACT], route: `/flow/${flows.FLOW_SIGN_CONTRACT}` },
            { id: flows.FLOW_ORDER_POLICY, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_ORDER_POLICY], route: `/flow/${flows.FLOW_ORDER_POLICY}` },
            { id: flows.FLOW_OPEN_ACCOUNT, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_OPEN_ACCOUNT], route: `/flow/${flows.FLOW_OPEN_ACCOUNT}` },
            { id: flows.FLOW_RECHARGE, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_RECHARGE], route: `/flow/${flows.FLOW_RECHARGE}` },
            { id: flows.FLOW_RECEIPT, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_RECEIPT], route: `/flow/${flows.FLOW_RECEIPT}` },
            { id: flows.FLOW_UP_PAY, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_UP_PAY], route: `/flow/${flows.FLOW_UP_PAY}` },
            { id: 'RechargePaymentRemind', title: '催收列表', route: '/payment_remind' },
            { id: flows.FLOW_REFUND, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_REFUND], route: `/flow/${flows.FLOW_REFUND}` },
            { id: flows.FLOW_BALANCE_TRANSFER, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_BALANCE_TRANSFER], route: `/flow/${flows.FLOW_BALANCE_TRANSFER}` },
            { id: flows.FLOW_REBATE, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_REBATE], route: `/flow/${flows.FLOW_REBATE}` },
            { id: 'FavoriteFlows', title: '流程收藏夹', route: '' }
          ]
        },
        {
          id: 'Material',
          title: '素材业务',
          icon: 'icon-menu-ad-mgr',
          menus: [
            { id: flows.FLOW_MATERIAL_POLICY, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_MATERIAL_POLICY], route: `/flow/${flows.FLOW_MATERIAL_POLICY}` },
            { id: flows.FLOW_MATERIAL_CONTRACT, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_MATERIAL_CONTRACT], route: `/flow/${flows.FLOW_MATERIAL_CONTRACT}` }
          ]
        },
        {
          id: 'Policy',
          title: '政策',
          icon: 'icon-menu-mgr',
          menus: [
            { id: 'PolicyCompany', title: '代理/主体管理', route: '/policy_company' },
            { id: 'UpperPolicyList', title: '媒体政策列表', route: '/upper_policy_list' },
            { id: 'LowerPolicyList', title: '客户政策列表', route: '/lower_policy_list' }
          ]
        },
        {
          id: 'AdReport',
          title: '广告盈利报表',
          icon: 'icon-menu-mgr',
          menus: [
            { id: 'EstimateGrossProfit', title: '预估毛利', route: '/estimate-gross-profit' },
            { id: 'ActualGrossProfit', title: '实际毛利', route: '/actual-gross-profit' },
            { id: 'UpDownCostReport', title: '上下游结算数据', route: '/up_down_cost_report' },
            { id: 'RechargeReport', title: '充值数据报表', route: '/recharge_report' },
            { id: 'RefundReport', title: '清零消耗退款数据报表', route: '/refund_report' },
            { id: 'RebateReport', title: '返点数据报表', route: '/rebate_report' }
      ]
        },
        {
          id: 'Bg',
          title: '棒谷',
          icon: 'icon-menu-mgr',
          menus: [
            { id: 'BgOrder', title: '棒谷订单', route: '/bg_order' }
          ]
        },
        {
          id: 'Aliyun',
          title: '阿里',
          icon: 'icon-menu-mgr',
          menus: [
            { id: 'AliyunData', title: '阿里云数据', route: '/aliyun_data' }
          ]
        },
        {
          id: 'Office',
          title: '办公应用',
          icon: 'icon-menu-app',
          menus: [
            { id: 'Todo', title: '待办事项', route: '/todo', badge: 0 },
            { id: flows.FLOW_ABSENT, title: flows.FLOW_NAMES_MAPPING[flows.FLOW_ABSENT], route: `/flow/${flows.FLOW_ABSENT}` },
            { id: 'CustomerPlatform', title: '绩效报表', route: '/customerPlatform' },
            { id: 'CustomerService', title: '绩效用户维护', route: '/customerService' }
          ]
        },
        {
          id: 'DataQuery',
          title: '数据查询',
          icon: 'icon-menu-data-query',
          menus: [
            { id: 'AbsentQuery', title: '请假申请单', route: '/absents' },
            { id: 'FlowAttachmentSearch', title: '附件（合同）查询', route: '/flow_attachment/search' },
            { id: 'DataCollectSearch', title: '珠宝数据查询', route: '/data_collect_list' }
          ]
        },
        {
          id: 'Ads',
          title: '广告报表',
          icon: 'icon-menu-data-query',
          menus: [
            { id: 'AdsReport', title: '报表模版', route: '/ads/report' },
            { id: 'AdsConsumption', title: '日消耗数据', route: '/ads/AccountConsumption' },
            { id: 'AdsAbnormal', title: '报表下载异常记录', route: '/ads/AccountAbnormal' }
          ]
        },
        {
          id: 'Manager',
          title: '系统管理',
          icon: 'icon-menu-mgr',
          menus: [
            { id: 'User', title: '用户管理', route: '/user' },
            { id: 'WorkflowFormDict', title: '工作流表单数据字典', route: '/workflow_form_dict' },
            { id: 'Elasticsearch', title: 'Elasticsearch索引', route: '/es_index' }
          ]
        },
        {
          id: 'Kpis',
          title: '评价管理',
          icon: 'icon-menu-mgr',
          menus: [ { id: 'Kpi', title: '评价', route: '/kpi' } ]
        },
        {
          id: 'Profile',
          title: '档案管理',
          icon: 'icon-menu-mgr',
          menus: [ { id: 'Profile', title: '档案', route: '/profile' } ]
        }
      ]
      return {
        menus: this.resetMenus(menus)
      }
    },
    computed: {
      ...(userStore.mapGetters(['isLogin'])),
      ...(userStore.mapState({
        userMenus: 'menus'
      })),
      ...(todoStore.mapGetters({
        todoTotal: 'allTotal'
      })),
      defaultOpenMenus () {
        let arr = []
        for (let o of this.menus) {
          arr.push(o.id)
        }
        return arr
      },
      activeMenuId () {
        let routeName = this.$store.state.rootRouteName
        if (routeName === 'Workflow') {
          return this.$route.params['type']
        } else {
          return routeName
        }
      }
    },
    watch: {
      isLogin (v) {
        if (v) {
          this.loadTodoTotals()
          this.initUserMenus()
        } else {
          this.$todoStore.clear()
          this.resetMenus(this.menus)
        }
      },
      todoTotal (total) {
        this
          .menus.find(m => m.id === 'Office')
          .menus.find(sm => sm.id === 'Todo')
          .badge = total
      }
    },
    stomp: {
      [MSG_TYPE_TODO] (msg) {
        this.$todoStore.setTotals(msg.data)
      }
    },
    methods: {
      loadTodoTotals () {
        if (!this.isLogin) return
        getMyTodoTotal()
          .success(resp => {
            this.$todoStore.setTotals(resp.data)
          })
          .send()
      },
      resetMenus (menus) {
        menus.forEach(mg => {
          mg.visible = false
          mg.menus.forEach(m => (m.visible = false))
        })
        return menus
      },
      initUserMenus () {
        if (this.userMenus && this.userMenus.length > 0) {
          let map = {}
          this.userMenus.forEach(um => (map[um] = true))
          this.menus.forEach(mg => {
            let hasMenu = false
            mg.menus.forEach(m => {
              if (m.alwaysVisible === true) {
                m.visible = true
                hasMenu = true
              } else {
                if (map[m.id]) {
                  m.visible = true
                  hasMenu = true
                }
              }
            })
            mg.visible = hasMenu
          })
        }
      }
    },
    mounted () {
      this.loadTodoTotals()
      this.initUserMenus()
    }
  }
</script>

<style lang="less" scoped>
  .app-menu {
    background-color: #f9f9f9;
    overflow-y: auto;
    border-right: solid 1px #e6e6e6;
  }
  .el-menu {
    background-color: #f9f9f9 !important;
    border-right: none;
  }
  .el-menu-item {
    height: 30px !important;
    line-height: 30px !important;
    background-color: #f9f9f9 !important;
  }
</style>
