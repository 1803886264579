<template>
  <page route-name="FinanceDetail">
    <div slot="top">
      <div style="margin-top: 15px">填写收款信息</div>
      <div class="group-title">收到客户款项</div>
      <div class="field-row">
          <div class="field-column" :style="{flex: 24 / 24}">
            <label  class="field-label">
              <span  style="font-weight: bold; font-size: 14px; color: #c01b0a;">*</span>
              客户名称
            </label>
            <div  class="field-input">
              <el-input v-model="formData.customerName" :disabled="true"></el-input>
            </div>
        </div>
      </div>
      <div class="field-row">
        <div class="field-column" :style="{flex: 12 / 24}">
          <label  class="field-label">
            <span  style="font-weight: bold; font-size: 14px; color: #c01b0a;">*</span>
            收到金额
          </label>
          <div  class="field-input">
            <el-input  v-model="formData.actualPrice" type="number" :disabled="true">  </el-input>
          </div>
        </div>
        <div class="field-column" :style="{flex: 12 / 24}">
          <label  class="field-label">
            <span  style="font-weight: bold; font-size: 14px; color: #c01b0a;">*</span>
            收到币种
          </label>
          <div  class="field-input">
            <div>
              <el-select v-model="formData.currency" :disabled="true" >
                <el-option
                  v-for="item in [{id: 'USD', label: '美元'}, {id: 'CNY', label: '人民币'}, {id: 'HKD', label: '港币'}]"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
    </div>
    </div>
    <div  slot="center"   v-html="description"></div>
    <div slot="bottom" >
      <div style="text-align: center">
        <el-button  @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </page>
</template>

<script>
  import { processDetailPaymentRemind } from '../../http/api/recharge-payment-remind'

  export default {
    data () {
      return {
        formData: {
          actualPrice: null,
          currency: null,
          customerName: null
        },
        description: null
      }
    },
    methods: {
      load () {
        this.$pageLoading(true)
        processDetailPaymentRemind()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.formData = resp.data['remind']
             this.description = resp.data['description']
          })
          .send(this.$route.params['id'])
      }
    },
    mounted () {
     this.load()
    }
  }
</script>

<style lang="less" scoped>
 .group-title {
      padding: 6px 0;
      font-size: 16px;
      font-weight: bold;
 }
  @borderColor: #b1d2ee;
  @border: solid 1px @borderColor;
  .field-row {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: @border;
    margin-top:-1px ;
    .field-column {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      .field-label {
        padding: 12px 10px 7px 10px;
        width: 100px;
        font-size: 12px;
        background-color: #d6eaf9;
        border-right: @border;
        color: #274a74;
      }
      .field-input {
        position: relative;
        flex: 1;
        padding: 7px 7px 2px 7px;
      }
    }
  }
</style>
