<!--
  评价页面管理
-->
<template>
  <page route-name="Kpi">
    <el-button slot="toolbar" @click="$router.push({name: 'KpiCustomer'})">客户管理</el-button>
    <el-button slot="toolbar" @click="$router.push({name: 'KpiUser'})">考核用户管理</el-button>
    <el-button slot="toolbar" icon="el-icon-refresh" @click="load"></el-button>
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="姓名查询">
          <el-input v-model="query.name" style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item>
        <el-select v-model="query.kpiCustomerId" clearable  filterable placeholder="请选择公司名称">
          <el-option
            v-for="item in kpiCustomers"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item>
        <el-date-picker
          v-model="query.month"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择日期">
        </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      stripe
      :data="dataList">
      <el-table-column prop="kpiUserName" label="员工" width="100"></el-table-column>
      <el-table-column prop="kpiCustomerName" label="客户" width="120"></el-table-column>
      <el-table-column prop="ywzsStr" label="业务知识熟练度" width="120">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.ywzs)}">{{scope.row.ywzsStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gtjsxStr" label="沟通及时性" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.gtjsx)}">{{scope.row.gtjsxStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gtnlStr" label="沟通能力" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.gtnl)}">{{scope.row.gtnlStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gttdStr" label="沟通态度" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.gttd)}">{{scope.row.gttdStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jjwtxlStr" label="解决问题效率" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.jjwtxl)}">{{scope.row.jjwtxlStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="wtfknlStr" label="问题反馈及解决能力" width="130">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.wtfknl)}">{{scope.row.wtfknlStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="zhyhclStr" label="账户优化策略" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.zhyhcl)}">{{scope.row.zhyhclStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="zhzhStr" label="账户转化情况是否达到要求" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.zhzh)}">{{scope.row.zhzhStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ljStr" label="能否充分了解您的需求" width="100">
        <template slot-scope="scope">
          <span :style="{ color: colorSelct(scope.row.lj)}">{{scope.row.ljStr}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="adAdvise" label="广告优化方面的建议" width="300"></el-table-column>
      <el-table-column prop="advise" label="建议" width="300"></el-table-column>
      <el-table-column prop="date" label="时间" width="100"></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>
  import api, { API_KPIS, API_KPI_CUSTOMERS_SELECT } from '../../config/urls'
  import PaginationMixins from '@/mixins/pagination'
  export default {
    mixins: [PaginationMixins],
    data () {
      return {
        dataList: [],
        kpiCustomers: [],
        query: {
          name: null,
          kpiCustomerId: null,
          month: ''
        }
      }
    },
    computed: {},
    methods: {
      load () {
        this.$http.get(api(API_KPIS), {
          params: {
            month: this.query.month,
            kpiCustomerId: this.query.kpiCustomerId,
            name: this.query.name,
            start: this.page.start,
            limit: this.page.limit
          },
          success: resp => {
            this.dataList = resp.data
            this.page.total = resp.total
            // 给公司赋值
            this.$http.get(api(API_KPI_CUSTOMERS_SELECT), {
              params: {
                name: '',
                start: 0,
                limit: 10000
              },
              success: resp => {
                this.kpiCustomers = resp.data
              }
            })
          }
        })
      },
      colorSelct (value) {
        if (value === 1) {
          return 'blue'
        } else if (value === 2) {
          return 'green'
        } else if (value === 3) {
          return 'orange'
        } else if (value === 4) {
          return 'pink'
        } else if (value === 5) {
          return 'red'
        } else {
          return 'black'
        }
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
