<template>
  <modal-panel
    :visible.sync="panelVisible"
    :saveButtonLoading="submitting"
    :closable="panelCloseable"
    :title=title
    @close="panelBeforeClose"
    @save="submit"
    :width="800"
  >
    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="档案名称" prop="name" class="app_required-input">
        <el-input v-model="form.name" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="description" class="app_required-input">
        <el-input v-model="form.description" type="textarea" :rows="3" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="标签" prop="tag" class="app_required-input">
        <el-tag
          :key="tag"
          v-for="tag in tags"
          closable
          :disable-transitions="false"
          @close="handleTagRemove(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-show="tagInputVisible"
          v-model="tagInputValue"
          ref="tagInput"
          size="small"
          style="width: 120px; margin-left: 10px;"
          @keyup.enter.native="handleTagInputConfirm"
          @blur="handleTagInputConfirm"
        >
        </el-input>
        <el-button v-show="!tagInputVisible" size="mini" @click="showTagInput" style="margin-left: 10px;">添加标签</el-button>
      </el-form-item>
      <el-form-item label="查阅角色" prop="roles" class="app_required-input">
        <el-select v-model="form.roleIdsToArray" value-key="t_id" filterable multiple placeholder="请选择查阅角色" style="width: 100%;">
          <el-option
            v-for="item in rolesOption"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <split-line text="档案属性" align="center"></split-line>

    <div style="margin: 10px 0;">
      <el-button icon="el-icon-plus"  type="primary"  @click="$refs.propEditor.open()">增加属性</el-button>
      <el-button icon="el-icon-plus"  style="margin-left: 5px" type="primary" @click="$refs.templatePropsSelectDialog.open()">从模板添加</el-button>
    </div>

    <div class="props-template-list" v-if="propsOnSorted.length > 0">
      <div
          v-for="r in propsOnSorted" :key="r.groups"
          class="props-group"
      >
        <div class="group-name">{{r.name}}</div>
        <div class="props-detail">
          <div v-for="p in r.props" :key="p.vueKey" class="prop-item" :class="{'prop-delete': p.handleStatus === 'delete'}">
            <div class="item-block" style="width: 30px;">
              <div class="prop-status" :class="'status-' + p.handleStatus"></div>
            </div>
            <div class="item-block" style="width: 70px;">
                <span v-show="p.handleStatus === 'delete'">
                  <el-button size="mini" @click.stop="p.handleStatus = 'modify'">恢复</el-button>
                </span>
              <span v-show="p.handleStatus !== 'delete'">
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDeleteProp(p)"></el-button>
                </span>
            </div>
            <div class="item-block" style="width: auto; text-align: right; padding-right: 10px;">{{p.label}}</div>
            <div class="item-block" style="flex: 1;">
              <el-input v-if="propTypeMapping.text === p.type" v-model="p.value"></el-input>
              <el-input v-if="propTypeMapping.textMulti === p.type" v-model="p.value" type="textarea"></el-input>
              <el-select v-if="propTypeMapping.enum === p.type" v-model="p.value" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in p.typeDataArray"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 属性编辑对话框 -->
    <prop-editor @onSave="handlePropSave" ref="propEditor"></prop-editor>

    <!-- 模版属性选择对话框 -->
    <template-props-select-dialog @selected="handleSelectPropsFromTemplate" ref="templatePropsSelectDialog"></template-props-select-dialog>

  </modal-panel>

</template>

<script>
  import { addProfile, updateProfile, getTemplatePropsByProfileId, getRoles } from '../../../http/api/profile'
  import kit from '../../../tools/kit'
  import PropEditor from './prop-editor'
  import { PropTypeMapping } from '@/pages/profile/type-constants'
  import TemplatePropsSelectDialog from './template-props-select-dialog'
  import PropsSortMixins from '../props-sort-mixins'

  export default {
    components: { PropEditor, TemplatePropsSelectDialog },
    mixins: [PropsSortMixins],
    data () {
      return {
        propTypeMapping: PropTypeMapping,
        search: {
          name: null,
          ids: [],
          profileTemplateId: null
        },
        title: '档案编辑',
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        type: null,
        model: null, // 更新操作时的数据模型
        form: {
          id: null,
          name: null,
          description: null,
          tag: null,
          position: null,
          profileCategoryId: null,
          roleIdsToArray: []
        },
        tags: [],
        tagInputVisible: false,
        tagInputValue: null,
        inputTypeVisible: false,
        inputTypeValue: '',
        roles: [],
        rolesOption: [],
        profileTemplatePropsId: null,
        addPropsFormVisible: false,
        addPropsFormListVisible: false,
        propsFormList: [],
        groupPropsList: [],
        selectPropsFormList: [],
        propsTemplateFormList: []
      }
    },
    watch: {
      tags () {
        if (this.tags.length > 0) {
          this.form.tag = this.tags.join(',')
        } else {
          this.form.tag = null
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      /**
       * @param model 为null表示新增操作，此时必须传categoryId过来。
       * @param categoryId 如果model不为空，该参数不需要提供。
       */
      open (model, categoryId) {
        this.form.profileCategoryId = categoryId
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          kit.obj.getProperties(this.form, model)
          if (model.tag) {
            this.tags = model.tag.split(',')
          }
          getTemplatePropsByProfileId()
            .success(resp => {
              this.propTemplates = []
              for (let p of resp.data) {
                p.handleStatus = 'modify'
                this.propTemplates.push(p)
              }
            })
            .send(this.form.id)
          if (this.rolesOption.length === 0) {
            getRoles()
              .success(resp => {
                this.rolesOption = resp.data
              })
              .send()
          }
        }
      },
      submit () {
        let data = Object.assign({}, this.form)
        data.roleIds = data.roleIdsToArray.join(',')
        delete data.roleIdsToArray
        data.propsTemplateValueForInsert = []
        data.propsForInsert = []
        data.propsForModify = []
        data.propsForDelete = []
        for (let p of this.propTemplates) {
          switch (p.handleStatus) {
          case 'modify':
            data.propsForModify.push({
              id: p.profilePropId,
              templatePropId: p.id,
              value: p.value
            })
            break
          case 'delete':
            data.propsForDelete.push(p.profilePropId)
            break
          case 'newFromAdd':
            data.propsTemplateValueForInsert.push(p)
            break
          case 'newFromTemplate':
            data.propsForInsert.push({
              templatePropId: p.id,
              value: p.value
            })
            break
          }
        }

        this.submitting = true
        this.panelCloseable = false
        let api
        if (this.isUpdate) {
          api = updateProfile
        } else {
          api = addProfile
        }
        api()
          .complete(() => {
            this.panelCloseable = true
            this.submitting = false
          })
          .success(resp => {
            this.$message.success('保存成功。')
            this.$emit('handleSaved', resp.data, this.isUpdate)
            this.panelVisible = false
          })
          .send(data)
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
        this.form.id = null
        this.form.roles = []
        this.tags = []
        this.propTemplates = []
      },
      handleTagRemove (tag) {
        kit.arr.removeItem(this.tags, tag)
      },
      showTagInput () {
        this.tagInputVisible = true
        this.$nextTick(() => {
          this.$refs.tagInput.$refs.input.focus()
        })
      },
      handleTagInputConfirm () {
        let inputValue = this.tagInputValue
        if (inputValue) {
          if (!this.tags.find(item => item === inputValue)) {
            this.tags.push(inputValue)
          }
        }
        this.tagInputVisible = false
        this.tagInputValue = null
      },
      handleDeleteProp (prop) {
        if (prop.handleStatus.startsWith('new')) {
          kit.arr.remove(this.propTemplates, prop)
        } else {
          prop.handleStatus = 'delete'
        }
      },
      handlePropSave (prop) {
        prop.handleStatus = 'newFromAdd' // 自定义添加的
        prop.value = null
        this.propTemplates.push(prop)
      },
      handleSelectPropsFromTemplate (props) {
        for (let p of props) {
          if (this.propTemplates.find(item => item.id === p.id) == null) {
            this.propTemplates.push({
              id: p.id,
              handleStatus: 'newFromTemplate', // 从模版选择的新属性
              label: p.label,
              type: p.type,
              typeData: p.typeData,
              typeDataArray: p.typeDataArray,
              groups: p.groups,
              position: p.position,
              value: null
            })
          }
        }
      }
    },
    mounted () {
    }
  }
</script>

<style lang="less" scoped>
  .props-list {
    margin: 10px 0;
  }
  .props-group + .props-group {
    margin-top: 20px;
  }
  .props-group {
    border: solid 1px #e9e9e9;
    .group-name {
      font-size: 14px;
      font-weight: bold;
      padding: 5px 10px;
      border-bottom: solid 1px #e9e9e9;
      background-color: #f2f2f2;
    }
    .props-detail {
      padding: 5px 0;
    }
    .prop-item {
      display: flex;
      padding: 2px 10px;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      color: #3b3b1f;
      &.prop-delete {
        background-color: #ffdfdf !important;
      }
      &:hover {
        background-color: #f9f9f9;
      }
      .prop-status {
        display: inline-block;
        @size: 10px;
        width: @size;
        height: @size;
        border-radius: @size;
        margin-top: 7px;
        margin-left: 5px;
        &.status-newFromAdd {
          background-color: #29801f;
        }
        &.status-newFromTemplate {
          border-radius: 0;
          background-color: #29801f;
        }
        &.status-modify {
          background-color: #305e8a;
        }
        &.status-delete {
          background-color: #ac3030;
        }
      }
    }
    .prop-item + .prop-item {
      margin-top: 5px;
    }
  }
</style>
