<template>
  <div class="panel forgot-panel">
    <div class="form-item input-item">
      <label>邮箱</label>
      <input type="text" v-model="username" :disabled="disabled">
    </div>
    <div class="form-item input-item">
      <label>新密码</label>
      <input type="password" v-model="password" :disabled="disabled">
    </div>
    <div class="form-item input-item">
      <label>确认密码</label>
      <input type="password" v-model="confirmPassword" :disabled="disabled">
    </div>
    <div class="form-item input-item">
      <label>验证码</label>
      <input type="text" v-model="validateCode" maxlength="6" :disabled="disabled">
      <el-button @click="getValidateCode" :disabled="disabled || validateCodeButtonDisabled">{{getValidateCodeButtonText}}</el-button>
    </div>
    <div class="form-item button-bar">
      <el-button type="primary" @click="updatePassword" :disabled="disabled">修改密码</el-button>
      <el-button @click="$emit('changePanel', 'login')" :disabled="disabled">返回登录</el-button>
    </div>
  </div>
</template>

<script>
  import { getValidateCodeForForgotPassword, updateForgotPassword } from '../../http/api/manager'

  export default {
    data () {
      return {
        disabled: true,
        validateCodeButtonDisabled: false,
        getValidateCodeButtonText: '获取验证码',
        username: '',
        password: '',
        confirmPassword: '',
        code: '' // 修改密码的验证码
      }
    },
    computed: {
      validateCode: {
        get () {
          return this.code
        },
        set (v) {
          this.code = v.toUpperCase()
        }
      }
    },
    methods: {
      getValidateCode () {
        getValidateCodeForForgotPassword()
          .success(() => {
            this.startValidateCodeCounter()
            this.$message.success('我们已经将验证码发送到您提供的邮箱，请注意查收。')
          })
          .send(this.username)
      },
      startValidateCodeCounter () {
        let sec = 59
        this.validateCodeButtonDisabled = true
        this.getValidateCodeButtonText = `${sec} 秒`
        this.validateCodeCounterId = setInterval(() => {
          if (--sec <= 0) {
            this.stopValidateCodeCounter()
          } else {
            this.getValidateCodeButtonText = `${sec} 秒`
          }
        }, 1000)
      },
      stopValidateCodeCounter () {
        clearInterval(this.validateCodeCounterId)
        this.validateCodeButtonDisabled = false
        this.getValidateCodeButtonText = '获取验证码'
      },
      updatePassword () {
        if (this.password !== this.confirmPassword) {
          this.$message.error('两次输入的密码不一样。')
        } else {
          this.$loading(true)
          updateForgotPassword()
            .complete(() => (this.$loading(false)))
            .success(() => {
              this.$message.success('修改成功，请登录。')
              this.password = ''
              this.validateCode = ''
              this.stopValidateCodeCounter()
              this.$emit('changePanel')
            })
            .send(this.username, this.password, this.validateCode)
        }
      }
    },
    beforeDestroy () {
      this.stopValidateCodeCounter()
    }
  }
</script>
