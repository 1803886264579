<template>
  <page route-name="Profile">
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button icon="el-icon-plus"  type="primary" :disabled="!selectedCategory" @click="$refs['profileEditor'].open(null, selectedCategory.id)">添加档案</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.push({name: 'ProfileTemplate'})">档案模板管理</el-button>
      </el-form-item>
    </el-form>
    <div slot="center" class="wrapper">
      <div class="category-container app_hide-scrollbar">
        <div class="panel-toolbar">
          <div style="width: 30%;float: left;">档案分类</div>
          <el-button icon="el-icon-plus" size="mini" type="primary" style="float: right;text-align: center;margin-top: 8px" @click="$refs['profileCategory'].open()"></el-button></div>
        <div>
          <div class="category-item"
               :class="{selected: d.selected}"
               @click="handleCategoryClick(d)"
               v-for="d in categories"
               :key="d.id"
          >
            <div class="info">
              <div>{{d.name}}</div>
            </div>
            <div class="button-bar">
              <el-button size="mini"  type="default" icon="el-icon-edit" @click.stop="$refs['profileCategory'].open(d)"></el-button>
              <el-button size="mini"  type="danger" icon="el-icon-delete" @click.stop="handleDeleteCategory(d)"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="code-container">
        <div class="panel-toolbar">
          档案
        </div>

        <el-table
            :data="codes"
            style="width: 100%"
        >
          <el-table-column label="操作" width="130">
            <template slot-scope="{row}">
              <div v-if="row.hasPrivilegeForCurrentUser">
                <el-button size="mini" type="default" @click="$router.push({name: 'ProfileDetail', params:{'id':row.id}})">详情</el-button>
                <el-button size="mini" type="default" icon="el-icon-edit" @click="editProfile(row)"></el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" @click.stop="handleDeleteCode(row)"></el-button>
              </div>
              <div v-else>
                无权限
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称" width="220"></el-table-column>
          <el-table-column prop="createName" label="创建人" width="100"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
          <el-table-column prop="description" label="描述"></el-table-column>
        </el-table>
      </div>
    </div>
    <ProfileCategoryEditor  ref="profileCategory" @handleCategorySaved="handleCategorySaved"></ProfileCategoryEditor>
    <ProfileEditor ref="profileEditor" @handleSaved="handleSaved"></ProfileEditor>
  </page>
</template>

<script>
  import kit from '../../tools/kit'
  import { getProfileCategoryList, deleteProfileCategory } from '../../http/api/profile-category'
  import { getProfileList, deleteProfile, updateProfilePosition } from '../../http/api/profile'
  import ProfileCategoryEditor from './profile-category/comp/editor'
  import ProfileEditor from './comp/profile-editor'
  export default {
    components: {
      ProfileCategoryEditor, ProfileEditor
    },
    data () {
      return {
        param: {
          name: null
        },
        categories: [],
        codes: [],
        codeLoading: false,
        dragging: false,
        profileDetail: {
          dataList: [],
          propsList: {
            name: null,
            description: null,
            tag: null,
            roles: null
          },
          groupPropsList: []
        },
        profileDetailVisible: false
      }
    },
    computed: {
      selectedCategory () {
        return this.categories.find(d => d.selected)
      }
    },
    methods: {
      loadCategory () {
        getProfileCategoryList()
          .success(resp => {
            resp.data.forEach(d => {
              d.selected = false
            })
            this.categories = resp.data
          })
          .send(this.param)
      },
      loadCode (category) {
        this.codeLoading = true
        getProfileList()
          .complete(() => (this.codeLoading = false))
          .success(resp => {
            this.codes = resp.data
          })
          .send(category.id)
      },
      editProfile (row) {
        this.$refs['profileEditor'].open(row, null)
      },
      handleCategorySaved (category) {
        if (category.selected === undefined) {
          category.selected = false
        }
        kit.arr.pushOrReplace(this.categories, category)
        this.categories.sort(this.arraySort('position', 'Asc'))
        console.log(this.categories)
      },
      handleSaved (code) {
        kit.arr.pushOrReplace(this.codes, code)
      },
      handleCategoryClick (category) {
        let selectedCategory = this.categories.find(d => d.selected)
        if (selectedCategory === category) {
          category.selected = !category.selected
          selectedCategory = null
        } else {
          if (selectedCategory) {
            selectedCategory.selected = false
          }
          category.selected = true
          selectedCategory = category
        }

        if (selectedCategory) {
          this.codes = []
          this.loadCode(selectedCategory)
        }
      },
      handleDeleteCategory (model) {
        this.$confirm('确定要删除吗？', '提示')
          .then(() => {
            deleteProfileCategory().complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.remove(this.categories, model)
              })
              .send(model.id)
          })
      },
      handleDeleteCode (model) {
        this.$confirm('确定要删除吗？', '提示')
          .then(() => {
            deleteProfile().complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.remove(this.codes, model)
              })
              .send(model.id)
          })
      },
      checkMove (e) {
        updateProfilePosition().send(JSON.stringify(this.codes))
      },
      closeDetail () {
        this.profileDetail.groupPropsList = []
        this.profileDetail.dataList = []
        this.profileDetailVisible = false
      },
      groupProps () {
        let map = {}
        let dest = []
        this.profileDetail.dataList.sort(this.arraySort('position', 'Asc'))
        for (let i = 0; i < this.profileDetail.dataList.length; i++) {
          let ai = this.profileDetail.dataList[i]
          if (!map[ai.groups]) {
            dest.push({
              groups: ai.groups,
              data: [ai]
            })
            map[ai.groups] = ai
          } else {
            for (let j = 0; j < dest.length; j++) {
              let dj = dest[j]
              if (dj.groups === ai.groups) {
                dj.data.push(ai)
                break
              }
            }
          }
        }
        this.profileDetail.groupPropsList = dest
      },
      arraySort (property, type) {
        return (firstobj, secondobj) => {
          const firstValue = firstobj[property]
          const secondValue = secondobj[property]
          switch (type) {
          case 'Desc':
            return secondValue - firstValue // 降序
          case 'Asc':
            return firstValue - secondValue // 升序
          default:
            return firstValue - secondValue // 升序
          }
        }
      }
    },
    mounted () {
      this.loadCategory()
    }
  }
</script>

<style lang="less" scoped>
  @colorContent: #f2f2f2;
  @colorBorder: #EBEEF5;
  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .panel-toolbar {
    padding: 0 8px;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #ddd;
    background-color: #f9f9f9;
  }

  @categoryContainerWidth: 250px;

  .category-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: @categoryContainerWidth;
    overflow-y: auto;
    overflow-x: visible;
    background-color: #fff;

    .category-item {
      display: flex;
      padding: 10px 10px;
      align-items: center;
      border-bottom: solid 1px #dddddd;
      cursor: pointer;
      .icon {
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .info {
        flex: 1;
        padding-left: 10px;
        font-size: 12px;
        color: #8f8f8f;
      }

      .button-bar {
        padding-left: 15px;
      }

      &.selected {
        background-color: @colorContent;
        .info {
          color: #3a3a3a !important;
          font-weight: bold;
        }
      }
    }
  }

  .code-container {
    position: absolute;
    left: @categoryContainerWidth;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    border-left: solid 1px @colorBorder;
  }
  .ghost {
    opacity: 0.5;
    background: #dddddd;
  }
  .form_label{
    width: 100%;
    overflow: hidden;
    .label_name{
      width: 20%;
      float: left;
      .label_name_right{
        width: 100px;
        text-align: right;
      }
    }
    .label_value{
      width: 80%;
      float: left;
    }
  }
  .draggable-style {
    width: 28%;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    cursor:pointer;
  }
</style>
