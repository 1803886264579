import FlowAttachmentSearch from '../../pages/flow-attachment-search'
import { createWorkflowDetailRouters } from './workflow'

/**
 * 请假申请单路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'FlowAttachmentWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'FlowAttachmentSearch',
    path: '/flow_attachment/search',
    meta: { title: '附件（合同）查询' },
    component: FlowAttachmentSearch,
    children: [
      createWorkflowDetailRouters('flowId', '_CUSTOM_FlowAttachment')
    ]
  }
]
