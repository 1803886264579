import defineApi from '../define-api'
import http from '../application-api'

/**
 * 获取bing搜索引擎页面每天都更新的那个背景图。
 * @type {Function}
 */
export const getBingBackground = defineApi((config) => {
  config.disableBaseUrl = true
  http.load('/api/bing/background', config)
})
