/*
    对系统各页面进行路由配置，路由必须都提供唯一的名称，路由跳转建议使用name而不是path。
    每个相关模块的路由都新建一个配置文件在./config目录下，然后在本文件集中配置。
    /////////////////////////////////////
    /////////  路由的特殊设置  ////////////
    /////////////////////////////////////
    安全验证：
    ------------------------
    默认所有路由都是需要验证用户是否登录，如果你需要将某个路由跳过安全验证，请做如下配置：
    meta: {auth: false}

    路由访问：
    ------------------------
    有时候用户第一次打开系统时就直接访问深度路由，如：#/a/b/c，该路由前面还有两级父路由#/a和#/a/b，如果
    你不希望某个路由可以直接被用户打开，而是必须从它的父路由过来的，你可以做如下设置：
    meta: {fromParent: true}
    比如，用户必须在#/a/b跳转到#/a/b/c，如果从其他路由如：#/1/2 -> #/a/b/c 或者在浏览器直接打开#/a/b/c
    的话，页面就会被跳转到#/a/b/c的上一级路由，也就是#/a/b
 */

import Vue from 'vue'
import Router from 'vue-router'
import RouterInfo from './router-info'
import Login from '../pages/login'
import Config from '../pages/manager/config'
import NotFound from '../pages/not-found'
import Home from '../pages/home'
import UserConfigRouter from './config/user'
import WorkflowConfigRouter from './config/workflow'
import Absents from './config/absents'
import Todo from './config/todo'
import Ad from './config/ad'
import AdsReport from './config/ads-report'
import WorkflowFormDict from '../pages/form-dict'
import AdChannelInfo from '../pages/ad/ad-channel-info'
import ApiConfigRouter from './config/api'
import AustomerService from './config/customer-service'
import AdsAccountConsumption from './config/account-consumption'
import AdsAccountAbnormal from './config/account-abnormal'
import FavoriteFlows from './config/favorite-flows'
import CsCustomer from './config/cs-customer'
import FlowAttachmentSearch from './config/flow-attachment-search'
import Elasticsearch from './config/elasticsearch'
import RechargePaymentRemind from './config/recharge-payment-remind'
import PolicyCompany from './config/policy-company'
import LowerPolicyList from './config/lower-policy-list'
import UpperPolicyList from './config/upper-policy-list'
import EstimateGrossProfit from './config/estimate-gross-profit'
import ActualGrossProfit from './config/actual-gross-profit'
import Profile from './config/profile'
import BgOrder from './config/bg-order'
import AdCustomerAd from './config/ad-customer-ad'
import UPDownCostReport from './config/up-down-cost-report'
import AdPlatform from './config/ad-platform'
import RechargeReport from './config/recharge-report'
import RefundReport from './config/refund-report'
import AliyunData from './config/aliyun-data'
import RebateReport from './config/rebate-report'
import DataCollectSearch from './config/data-collect'
import store, { SET_ROOT_ROUTE_NAME } from '../store'

Vue.use(Router)

export let HOME_PAGE = 'Home'

let router = new Router({
  mode: 'history',
  routes: [
    { name: 'Home', path: '/', component: Home },
    { name: 'NotFound', path: '/404', component: NotFound, meta: { auth: false } },
    { name: 'Login', path: '/login', component: Login, meta: { auth: false } },
    { name: 'Config', path: '/config', meta: { title: '系统设置' }, component: Config },
    { name: 'AdChannelInfo', path: '/ad_channel_info', meta: { title: '上游渠道政策' }, component: AdChannelInfo },
    { name: 'WorkflowFormDict', path: '/workflow_form_dict', meta: { title: '数据字典' }, component: WorkflowFormDict },
    ...Ad,
    ...UserConfigRouter,
    ...WorkflowConfigRouter,
    ...Absents,
    ...Todo,
    ...AdsReport,
    ...ApiConfigRouter,
    ...AustomerService,
    ...AdsAccountAbnormal,
    ...AdsAccountConsumption,
    ...FavoriteFlows,
    ...CsCustomer,
    ...FlowAttachmentSearch,
    ...Elasticsearch,
    ...RechargePaymentRemind,
    ...PolicyCompany,
    ...LowerPolicyList,
    ...UpperPolicyList,
    ...EstimateGrossProfit,
    ...ActualGrossProfit,
    ...Profile,
    ...BgOrder,
    ...AdCustomerAd,
    ...UPDownCostReport,
    ...AdPlatform,
    ...RechargeReport,
    ...AliyunData,
    ...RefundReport,
    ...RebateReport,
    ...DataCollectSearch
  ]
})

// 导航守卫做如下事情：
// 1. 检查路由是否存在，不存在跳转到404页面。
// 2. 将路由的根的名称更新到状态管理器中。
// 3. 检查路由的fromParent属性，决定是否能打开该路由或进行上级跳转。
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    store.commit(SET_ROOT_ROUTE_NAME, null)
    return next({ name: 'NotFound', query: { from: to.path } })
  } else {
    store.commit(SET_ROOT_ROUTE_NAME, to.matched[0].name)
  }
  if (to.meta.fromParent === true) {
    let targetRouter = new RouterInfo(to)
    let parentRouter = targetRouter.getParent()
    if (parentRouter.name === from.name) {
      next()
    } else {
      next({ name: parentRouter.name, params: targetRouter.params })
    }
  } else {
    next()
  }
})

export default router
