/**
 * 为了使"流程详情"路由组件以及其下面的子路由组件能够被其他父路由组件复用，
 * 我们使用/router/config/workflow.js > createWorkflowDetailRouters
 * 来将这些子路由设置到其他父路由下面，那么在"流程详情"路由组件里面使用时，
 * 不能直接从$route中获取参数，而应该从props获取，我们固定拥有三个属性参数：
 * flowType, flowId, flowIdName。
 * 注意flowType属性可能不会给你正确的流程类型值，你必须使用getFlowType计算属性
 * 来获取正确的值。
 * 我们应该避免直接使用$router来跳转到子页面（至少在流程详情路由以及子路由下不这么做），
 * 而应该使用我们提供的函数来跳转。
 *
 * @see /src/router/config/workflow.js #createWorkflowDetailRouters
 */
export default {
  props: {
    flowType: { type: String, required: true },
    flowId: { type: String, required: true },
    flowIdName: { type: String, required: true }
  },
  computed: {
    getFlowType () {
      if (this.flowType === 'Auto') {
        return this.$route.params['type']
      } else if (this.flowType.startsWith('_CUSTOM_')) {
        return this.$route.params['flowType']
      } else {
        return this.flowType
      }
    }
  },
  methods: {
    getRouteName (name) {
      return this.flowType + name
    },
    toFlowDetailPage (reloadStages) {
      let cfg = {
        name: this.getRouteName('WorkflowDetail'),
        params: { [this.flowIdName]: this.flowId }
      }
      if (reloadStages === true) {
        cfg['query'] = { reloadStages: true }
      }
      this.$router.push(cfg)
    },
    toStageDetailPage (stageId, anchor) {
      this.$router.push({
        name: this.getRouteName('StageDetail'),
        params: {
          [this.flowIdName]: this.flowId,
          stageId: stageId
        },
        query: {
          anchor: anchor || '' // 如果提供，会将页面滚动到相应位置，可选值：form, attach
        }
      })
    },
    toCombineStageDetailPage (combineId) {
      this.$router.push({
        name: this.getRouteName('CombineStageDetail'),
        params: {
          [this.flowIdName]: this.flowId,
          combineId: combineId
        }
      })
    },
    toProcessStagePage (conditionId) {
      this.$router.push({
        name: this.getRouteName('ProcessStage'),
        params: { [this.flowIdName]: this.flowId },
        query: { conditionId: conditionId }
      })
    }
  }
}
