var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"route-name":"Role"}},[_c('div',{attrs:{"slot":"toolbar"},slot:"toolbar"},[_c('el-button',{attrs:{"type":"primary","icon":"aliyun-add"},nativeOn:{"click":function($event){return _vm.$refs['editor'].open()}}},[_vm._v("添加")]),_c('el-button',{attrs:{"slot":"toolbar","icon":"el-icon-refresh"},on:{"click":_vm.loadRoles},slot:"toolbar"})],1),_c('el-table',{ref:"table",attrs:{"slot":"center","data":_vm.tableData,"stripe":"","height":_vm.tableHeight},on:{"expand-change":_vm.handleTableExpand,"row-dblclick":_vm.handleTableRowClick},slot:"center"},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(row.loading),expression:"row.loading"}],staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticClass:"authority-container"},[(row.authorities.length === 0)?_c('span',{staticClass:"text-muted font-mini"},[_vm._v("没有分配权限。")]):_vm._e(),_vm._l((row.authorities),function(am){return _c('div',{key:am.title,staticClass:"authority-module"},[_c('div',{staticClass:"module-title"},[_vm._v(_vm._s(am.title))]),_vm._l((am.authorities),function(a){return _c('div',{key:a.id,staticClass:"authority"},[_c('span',[_vm._v(_vm._s(a.title))])])})],2)})],2),_c('div',{staticClass:"manager-container"},[_c('el-table',{attrs:{"data":row.managers,"stripe":"","height":300}},[_c('el-table-column',{attrs:{"label":"姓名","prop":"name"}}),_c('el-table-column',{attrs:{"label":"账号","prop":"username"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.enabled)?_c('span',{staticStyle:{"color":"#50a654"}},[_vm._v("正常")]):_c('span',{staticStyle:{"color":"#b53639"}},[_vm._v("禁用")])]}}],null,true)})],1)],1)])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$refs['editor'].open(row)}}},[_vm._v("编辑")]),(!row.preDefine)?_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.deleteRole(row)}}},[_vm._v("删除")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"label":"角色","prop":"name","width":"150"}}),_c('el-table-column',{attrs:{"label":"别名","prop":"alias","width":"150"}}),_c('el-table-column',{attrs:{"label":"关联用户数","prop":"userTotal","width":"100"}}),_c('el-table-column',{attrs:{"label":"状态","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.enabled)?_c('span',{staticStyle:{"color":"#19be6b"}},[_vm._v("正常")]):_c('span',{staticStyle:{"color":"#ed4014"}},[_vm._v("禁用")])]}}])}),_c('el-table-column',{attrs:{"label":"描述","prop":"description"}})],1),_c('role-editor',{ref:"editor",on:{"saved":_vm.onRoleSaved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }