<!--
  广告客户编辑。

  事件：
    submit 添加或更新成功后触发事件，并传递一个服务器返回的数据。
      data {Object} 服务器返回的数据
      isUpdate {boolean} true表示更新操作，false表示新增操作

  方法：
    open(model, [callback]) 打开编辑器。
      model 如果提供，表示更新操作，否则为新增操作
      [callback] 提交成功后的回调，你也可以通过submit事件来监听通知。

-->
<template>
  <modal-panel
    :visible.sync="panelVisible"
    :closeable="panelCloseable"
    @close="panelBeforeClose"
    :saveButtonLoading="submitting"
    @save="submit"
    title="客户编辑"
  >

    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="客户签约主体全称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="form.contactPhone"></el-input>
      </el-form-item>
      <el-form-item label="客户简称" prop="name">
        <el-input v-model="form.shortName"></el-input>
      </el-form-item>
      <el-form-item label="所属行业" prop="industryId"  >
        <el-select v-model="form.industryId" placeholder="请选择"  >
          <el-option
            v-for="item in industries"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户类型" prop="customerTypeId"  >
        <el-select v-model="form.customerTypeId" placeholder="请选择"  >
          <el-option
            v-for="item in customerTypes"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="政策条数" prop="policyNum">
        <el-input-number v-model="form.policyNum" :min="1" ></el-input-number>
      </el-form-item>
    </el-form>
  </modal-panel>

</template>

<script>

  import { addAdCustomer, updateAdCustomer } from '../../../../http/api/ad-customer'
  import { getFlowFormDictItemListEnabled } from '../../../../http/api/workflow-form-dict'
  import { DICT_CUSTOMER_TYPE, DICT_INDUSTRY_TYPE } from '../../../../config/flow-form-dict-name'

  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
    },
    computed: {
      panelCloseable () {
        return !this.submitting
      }
    },
    data () {
      return {
        panelVisible: false,
        submitting: false,
        isUpdate: false,
        form: {
          name: null,
          address: null,
          contact: null,
          contactPhone: null,
          shortName: null,
          industryId: null,
          customerTypeId: null,
          policyNum: 1
        },
        industries: [],
        customerTypes: []
      }
    },
    methods: {
      open (model, callback) {
        if (typeof model === 'function') {
          callback = model
          model = undefined
        }
        this.submitCallback = callback
        this.isUpdate = !!model
        if (this.isUpdate) {
          Object.assign(this.form, model)
        }
        this.panelVisible = true
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.industries = resp.data
          })
          .send(DICT_INDUSTRY_TYPE)
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.customerTypes = resp.data
          })
          .send(DICT_CUSTOMER_TYPE)
      },
      submit () {
        this.submitting = true
        let api
        if (this.isUpdate) {
          api = updateAdCustomer
        } else {
          api = addAdCustomer
        }
        api()
          .complete(() => (this.submitting = false))
          .success(resp => {
            let data = resp.data
            this.$message.success('保存成功。')
            this.$emit('submit', data, this.isUpdate)
            this.submitCallback && this.submitCallback(data, this.isUpdate)
            this.submitCallback = null
            this.panelVisible = false
          })
          .send(this.form)
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.form.id = undefined
      }
    }
  }
</script>

<style scoped>

</style>
