<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="多用户角色分配"
    :width="800"
    @save="handleSave"
    @close="handleClose"
  >
    <split-line text="正在编辑的用户"></split-line>
    <div class="user-list">
      <div class="user" v-for="u in users" :key="u.id">{{u.name}}</div>
    </div>

    <el-alert type="info" style="margin: 10px 0;" :closable="false">
      右边的角色为用户分配角色，开关按钮没打开表示移除角色，打开了表示分配角色。
      默认一开始右边的是所有用户的共同拥有的角色，可以从左边拉取其他角色加入编辑。
      <span style="font-weight: bold; color: #bb3e30;">注：移除角色会导致上面的用户被踢出登录状态。</span>
    </el-alert>

    <div class="role-edit-panel">
      <div class="left">
        <div class="header">可选角色</div>
        <div class="content">
          <div class="role-item option" v-for="r in roleOptions">
            <div class="role-name">{{r.name}}</div>
            <div>
              <el-button @click="handleTransfer(r, 'toRight')"
                         type="primary" size="mini" icon="el-icon-arrow-right"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="header">角色分配</div>
        <div class="content">
          <div class="role-item option" v-for="r in roleEditing">
            <div>
              <el-button @click="handleTransfer(r, 'toLeft')"
                         type="danger" size="mini" icon="el-icon-arrow-left"></el-button>
            </div>
            <div class="role-name">
              {{r.name}}
            </div>
            <div>
              <el-switch v-model="r.switch" active-color="#13ce66" ></el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>

  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import { getRolesForMultiUsersEdit, applyRoleToMultiUsers } from '../../../../http/api/manager'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        users: [],
        roleOptions: [],
        roleEditing: []
      }
    },
    watch: {
    },
    computed: {

    },
    methods: {
      open (users) {
        let idList = []
        for (let u of users) {
          idList.push(u.id)
        }
        this.users = users
        this.visible = true
        getRolesForMultiUsersEdit()
          .success(resp => {
            if (resp.data) {
              for (let r of resp.data.roles) {
                r.switch = false
              }
              for (let r of resp.data.userRoles) {
                r.switch = true
              }
              this.roleOptions = resp.data.roles
              this.roleEditing = resp.data.userRoles
            }
          })
          .send(idList)
      },
      handleClose () {
        this.users = []
        this.roleOptions = []
        this.roleEditing = []
      },
      handleSave () {
        if (this.roleEditing.length > 0) {
          let userIds = this.users.map(u => u.id)
          let roles = this.roleEditing.map(r => {
            return {id: r.id, enabled: r.switch}
          })
          this.saveButtonLoading = true
          applyRoleToMultiUsers()
            .complete(() => (this.saveButtonLoading = false))
            .success(() => {
              this.$message.success('保存成功。')
            })
            .send(userIds, roles)
        } else {
          this.$alert('角色分配面板中没有编辑的角色，请从左边选择角色。', { type: 'warning' })
        }
      },
      handleTransfer (role, type) {
        if (type === 'toLeft') {
          role.switch = false
          this.roleOptions.push(role)
          kit.arr.remove(this.roleEditing, role)
        } else {
          this.roleEditing.push(role)
          kit.arr.remove(this.roleOptions, role)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .user-list {

  }
  .user {
    display: inline;
    color: #818181;
    font-size: 12px;
    &:not(:first-of-type)::before {
      content: '，';
    }
  }
  .user + .user {
    margin-right: 2px;
  }


  .role-edit-panel {
    display: flex;
    flex-direction: row;
    max-height: 400px;
    .left, .right {
      flex: .5;
      display: flex;
      flex-direction: column;
      border: solid 1px #ddd;
      overflow: hidden;
      border-radius: 5px;
    }
    .left {
      margin-right: 10px;
    }
    .header {
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      border-bottom: solid 1px #eeeeee;
      background-color: #f2f2f2;
      font-weight: bold;
      font-size: 13px;
    }
    .content {
      flex: 1;
      overflow: auto;
      padding: 10px 0;
    }
    .role-item {
      display: flex;
      flex-direction: row;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      &:hover {
        background-color: #f9f9f9;
      }
      .role-name {
        flex: 1;
        font-size: 14px;
      }
      .role-name + .role-name {
        margin-top: 5px;
      }
    }
    .left .role-name {
      margin-right: 10px;
    }
    .right .role-name {
      margin: 0 10px;
    }
  }
</style>
