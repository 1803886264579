import defineApi from '../define-api'
import http from '../application-api'

export const getProfileList = defineApi((config, profileCategoryId) => {
  config.params = { profileCategoryId: profileCategoryId }
  return http.load('/profile/list', config)
})

export const addProfile = defineApi((config, data) => {
  config.data = data
  http.post('/profile/add', config)
})

export const updateProfile = defineApi((config, data) => {
  config.data = data
  http.post('/profile/update', config)
})

export const deleteProfile = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/profile/delete', config)
})
export const updateProfilePosition = defineApi((config, data) => {
  config.params = { data: data }
  return http.post('/profile/update/position', config)
})

export const getTemplatePropsByProfileId = defineApi((config, profileId) => {
  config.params = { profileId: profileId }
  return http.load('/profile/props', config)
})

export const getProfileTemplateProps = defineApi((config) => {
  config.params = {
    start: 0,
    limit: 1000
  }
  return http.post('/profile/props/list', config)
})

export const getRoles = defineApi((config) => {
    return http.load('/profile/props/role/list', config)
})


export const getProfileAndPropsById = defineApi((config, profileId) => {
  config.params = { profileId: profileId }
  return http.load('/profile/detail', config)
})

export const getProfileTemplateForm = defineApi((config) => {
  return http.load('/profile/template/form/list', config)
})

export const getPropHistory = defineApi((config, propId) => {
  config.params = {
    propId: propId
  }
  http.load('/profile/prop/histories', config)
})
