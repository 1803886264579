import CsCustomer from '../../pages/ad/cs-customer'

export default [
  {
    name: 'CsCustomer',
    path: '/cs_customer',
    meta: { title: '分配客服' },
    component: CsCustomer
  }
]
