<!--
  提供统一的文件下载链接组件。
-->
<template>
  <a v-if="urlValid" :href="urlWithToken" :download="name">
    <slot>{{name}}</slot>
  </a>
  <span style="cursor: not-allowed; color: #c2c2c2;" v-else>
    <slot>{{name}}</slot>
  </span>
</template>

<script>
  import { userStore } from '../../store'

  export default {
    props: {
      url: { type: String, default: 'empty_url' },
      name: { type: String, default: '未知文件' }
    },
    computed: {
      ...(userStore.mapState(['token'])),
      urlValid () {
        return !!this.url
      },
      urlWithToken () {
        if (this.url.indexOf('_token') === -1) {
          return this.url + '?_token=' + this.token
        } else {
          return this.url
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .disabled {
    cursor: not-allowed !important;
    color: #bababa;
  }
</style>
