import defineApi from '../define-api'
import http from '../application-api'

export const getCsCustomerList = defineApi((config, params) => {
  config.params = params
  return http.load('/cs_customer/list', config)
})

/**
 * 查询所有客服用户
 * @type {Function}
 */
export const getCustomerServiceUserList = defineApi((config) => {
  http.get('/cs_customer/list/customer_service', config)
})

/**
 * 根据客户id获取CsCustomer
 * @type {Function}
 */
export const getCsCustomerByCustomerId = defineApi((config, customerId) => {
  config.params = { customerId: customerId }
  http.get('/cs_customer/list/by_customer', config)
})

export const applyCustomerToUser = defineApi((config, model) => {
  config.data = model
  http.post('/cs_customer/apply_customer', config)
})

export const cancelMaintain = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/cs_customer/cancel_maintain', config)
})


