<!--
  开户客户编辑。

  事件：
    submit 添加或更新成功后触发事件，并传递一个服务器返回的数据。
      data {Object} 服务器返回的数据
      oldCustomerId {String} 更新之前（在open的时候）保存的adCustomerId
      isUpdate {boolean} true表示更新操作，false表示新增操作

  方法：
    open(model, [callback]) 打开编辑器。
      model 如果提供，表示更新操作，否则为新增操作
      [callback] 提交成功后的回调，你也可以通过submit事件来监听通知。

-->
<template>
  <modal-panel
    :visible.sync="panelVisible"
    :closeable="panelCloseable"
    title="开户客户编辑"
    @save="submit"
    :saveButtonLoading="submitting"
    @close="panelBeforeClose"
  >

    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="开户名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="广告平台" prop="platform">
        <el-select v-model="form.platform" placeholder="请选择" style="width: 100%;">
          <el-option
            v-for="item in platforms"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属签约主体" prop="adCustomerId">
        <el-select
          v-model="form.adCustomerId"
          filterable
          remote
          :remote-method="searchAdCustomer"
          :loading="loading"
          placeholder="请输入签约主体名称的关键字进行搜索"
          style="width: 100%;"
        >
          <el-option
            v-for="item in adCustomers"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </modal-panel>

</template>

<script>
  import { addSubCustomer, updateSubCustomer, getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'

  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
    },
    computed: {
      panelCloseable () {
        return !this.submitting
      }
    },
    data () {
      return {
        panelVisible: false,
        submitting: false,
        isUpdate: false,
        loading: false,
        form: {
          name: null,
          platform: null,
          adCustomerId: null
        },
        platforms: [],
        adCustomers: [],
        oldCustomerId: null
      }
    },
    methods: {
      open (model, callback) {
        if (typeof model === 'function') {
          callback = model
          model = undefined
        }
        this.submitCallback = callback
        this.isUpdate = !!model
        if (this.isUpdate) {
          Object.assign(this.form, model)
          this.oldCustomerId = model.adCustomerId
          if (!this.adCustomers.find(c => c.id === model.adCustomerId)) {
            this.adCustomers.push({
              id: model.adCustomerId,
              name: model.adCustomerName
            })
          }
        }
        this.panelVisible = true

        getAdPlatformListIdNameMode()
          .success(resp => {
            this.platforms = resp.data
          })
          .send()
      },
      submit () {
        this.submitting = true
        let api
        if (this.isUpdate) {
          api = updateSubCustomer
        } else {
          api = addSubCustomer
        }
        api()
          .complete(() => (this.submitting = false))
          .success(resp => {
            let data = resp.data
            this.$message.success('保存成功。')
            this.$emit('submit', data, this.oldCustomerId, this.isUpdate)
            this.submitCallback && this.submitCallback(data, this.oldCustomerId, this.isUpdate)
            this.submitCallback = null
            this.panelVisible = false
          })
          .send(this.form)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.form.id = undefined
        this.oldCustomerId = null
      }
    }
  }
</script>

<style scoped>

</style>
