<template>
  <page route-name="User">

    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="$refs['userPanel'].open()">添加用户</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.push({name: 'Role'})">角色管理</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleApplyRoleToUsers">批量分配用户角色</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleApplySearchAuthorityToUsers">查询权限管理</el-button>
      </el-form-item>
    </el-form>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="关键字">
          <el-input v-model="search.keyword" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="包含角色">
          <el-select
            style="width: 400px;"
            v-model="search.roleIdList"
            :clearable="true"
            :multiple="true"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="search.enabled"
            :clearable="true"
          >
            <el-option
              v-for="item in [{id: true, label: '正常'}, {id: false, label: '禁用'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>


    <!--用户表格-->
    <el-table
      slot="center"
      :data="dataList"
      @selection-change="handleUserSelected"
    >
      <el-table-column label="操作" width="110">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="updateUser(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteUser(row)" v-if="row.editable">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column prop="username" label="账号" width="170"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="createDate" label="创建日期"></el-table-column>
      <el-table-column prop="disableDate" label="禁用日期"></el-table-column>
      <el-table-column prop="enabled" label="可用状态" width="150">
        <template slot-scope="{ row }">
          <span style="color: #50a654;" v-if="row.enabled">正常</span>
          <span style="color: #b53639;" v-else>禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="微信">
        <template slot-scope="{ row }">
          <span class="text-success" v-if="row.weChatBind">已绑定</span>
          <span class="text-muted" v-else>未绑定</span>
          /
          <span class="text-success" v-if="row.wechatSub">已关注</span>
          <span class="text-muted" v-else>未关注</span>
        </template>
      </el-table-column>
      <el-table-column label="会话" width="100">
        <template slot-scope="s">
          <span v-if="s.row.sessionCount === 0" style="color: #9e9e9e;">未登录</span>
          <span v-else style="cursor: pointer; color: #2d68c2;" @click="$refs.sessionPanel.open(s.row.username)">{{s.row.sessionCount}}</span>
        </template>
      </el-table-column>
    </el-table>

    <!--用户编辑面板-->
    <user-editor ref="userPanel" @saved="onEditorSubmit"></user-editor>
    <multi-user-role-editor ref="userRoleEditor"></multi-user-role-editor>
    <search-authority-editor ref="searchAuthorityEditor"></search-authority-editor>
    <session-panel ref="sessionPanel" @sessionCount="handleSessionCountUpdate"></session-panel>

  </page>
</template>

<script>
  import UserEditor from './comps/user-editor'
  import MultiUserRoleEditor from './comps/multi-user-role-editor'
  import SearchAuthorityEditor from './comps/search-authority-editor'
  import SessionPanel from './comps/user-session'
  import kit from '../../../tools/kit'
  import {
    managerList,
    deleteManager
  } from '../../../http/api/manager'
  import { getAllRoles } from '../../../http/api/role'

  export default {
    components: { UserEditor, MultiUserRoleEditor, SearchAuthorityEditor, SessionPanel },
    data () {
      return {
        dataList: [],
        selectedUsers: [],
        roleOptions: [],
        search: {
          keyword: null,
          roleIdList: [],
          enabled: null
        }
      }
    },
    computed: {
    },
    mounted () {
      this.load()
      getAllRoles()
        .success(resp => {
          this.roleOptions = resp.data
        })
        .send()
    },
    methods: {
      load () {
        this.$pageLoading(true)
        managerList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data
          })
          .send(this.search)
      },
      enableUser (user) {
        this.$pageLoading(true)
        this.$http.put(`/user/${user.id}/enable/${user.enabled}`, {
          context: this,
          completeBefore: () => this.$pageLoading(false),
          success: newUser => Object.assign(user, newUser),
          complete: success => !success && (user.enabled = user.enabled ? 0 : 1)
        })
      },
      onEditorSubmit (user) {
        kit.arr.pushOrReplace(this.dataList, user)
      },
      updateUser (user) {
        this.$refs['userPanel'].open(user)
      },
      deleteUser (user) {
        this.$confirm(`确定要删除[${user.name}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            deleteManager()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.removeItem(this.dataList, user)
              })
              .send(user.id)
          })
      },
      handleUserSelected (val) {
        this.selectedUsers = val
      },
      handleApplyRoleToUsers () {
        if (this.selectedUsers.length === 0) {
          this.$message.warning('请选择用户。')
        } else {
          this.$refs.userRoleEditor.open(this.selectedUsers)
        }
      },
      handleApplySearchAuthorityToUsers () {
        if (this.selectedUsers.length === 0) {
          this.$message.warning('请选择用户。')
        } else {
          this.$refs.searchAuthorityEditor.open(this.selectedUsers)
        }
      },
      handleSessionCountUpdate (username, count) {
        let user = this.dataList.find(u => u.username === username)
        if (user) {
          user.sessionCount = count
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
