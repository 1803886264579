<!--
  工作流列表页面。
-->
<template>
  <page :page-title="pageTitle" route-name="Workflow">

    <el-button slot="toolbar" icon="el-icon-plus" @click="$refs.editor.open()" type="primary">创建</el-button>
    <el-button slot="toolbar" icon="el-icon-news" @click="$refs.diagram.open($route.params['type'], 0)">流程图</el-button>
    <el-button slot="toolbar" icon="el-icon-refresh" @click="load()"></el-button>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="关键字">
          <el-input v-model="search.keyword" :clearable="true" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="search.status" :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in [{id: 'open', title: '进行中'}, {id: 'completed', title: '已完成'}, {id: 'close', title: '已作废'}]"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程阶段">
          <el-select v-model="search.stage" :clearable="true">
            <el-option
              v-for="item in flowStageConfigs"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
          <el-badge :value="moreSearchOptionTotal">
            <el-button size="small" @click="$refs.search.open(search)">更多选项</el-button>
          </el-badge>
        </el-form-item>
      </el-form>
    </div>

    <div slot="center" class="main-content">

      <div class="aggs">
        <div class="aggs-item" v-for="agg in aggregations" :key="agg.name">
          <split-line :text="agg.title" align="center"></split-line>
          <div class="bucket-item" v-for="b in agg.buckets" :key="b.key">
            <div class="bucket-item-key">{{b.title}}</div>
            <div class="bucket-item-total">{{b.total}}</div>
          </div>
        </div>
      </div>

      <div class="flow-list">

        <div class="text-muted font-mini content-center" style="margin-top: 50px;" v-if="dataList.length === 0">没有找到匹配项</div>

        <div class="flow-item" :class="'status-' + f.status" v-for="f in dataList" :key="f.id">

          <div v-if="f.orderPolicyExpire === true" class="order-policy-expire">下单政策失效与{{f.orderPolicyExpireDate}}</div>

          <div class="version">ver.{{f.version}}</div>

          <div class="status">
            <span v-if="f.status === 'open'">进行中</span>
            <span v-else-if="f.status === 'completed'">已结束</span>
            <span v-else-if="f.status === 'close'">已关闭</span>
          </div>
          <div class="content">
            <div class="title" @click="navToWorkflowDetail('Auto', f.id)">
              <span class="remark" v-if="f.remark"> [{{f.remark}}]</span>{{f.title}}
            </div>

            <div class="recharge-info"
                 v-if="f.type === flowRechargeName && f.flowRecharge.payLater && f.version >= 5"
            >
              <div>
                账期
                <span :class="{'payed': f.flowRecharge.payed, 'un-pay': !f.flowRecharge.payed}">
                  （{{f.flowRecharge.payed ? '已回款' : '未回款'}}）
                </span>
                ，预计付款日（{{f.flowRecharge.paymentDays}}）
                <span v-if="f.flowRecharge.payed">
                  ，{{f.flowRecharge.price}} {{f.flowRecharge.currency}}，回款日期（{{f.flowRecharge.payedDate}}）。
                </span>
              </div>
            </div>

            <div class="stage-info" v-if="f.status === 'open'">
              <div class="block-info">
                <span v-if="f.processable" style="color: #b53639; font-weight: bold;">(我可以处理)</span>
                <label>下个阶段</label>
                <span>{{f.nextProcessStageTitle}}</span>
              </div>
              <div class="block-info">
                <label>处理候选人</label>
                <span v-html="nextProcessUsersFilter(f.nextProcessUsers)"></span>
              </div>
            </div>


            <div class="complete-info" v-if="f.completed">
              <div class="block-info">
                <label>完成日期</label>
                <span>{{f.completedDate}}</span>
              </div>
              <div class="block-info">
                <label>审批结果</label>
                <span style="font-weight: bold; color: #429957;" v-if="f.reject === false">通过</span>
                <span style="font-weight: bold; color: #bb3e30;" v-if="f.reject === true">拒绝</span>
              </div>
            </div>

            <div class="create-info">
              <div class="block-info">
                <label>创建时间</label>
                <span>{{f.createDate}}</span>
              </div>
              <div class="block-info">
                <label>创建用户</label>
                <span v-if="f.createByMySelf" style="font-weight: bold; color: #324474;">我自己</span>
                <span v-else>{{f.createUserName}}</span>
              </div>
              <div class="block-info" v-if="f.ads">
                <label>广告平台</label>
                <span>{{f.ads.platformName}}</span>
              </div>
              <div class="block-info" v-if="f.upPayLatestPayDate">
                <label>最迟付款日期</label>
                <span v-html="upPayLatestPayDateProcess(f.upPayLatestPayDate)"></span>
              </div>
              <div class="block-info" v-if="f.customerPolicyExpireDate && f.version>4 && f.status === 'completed'">
                <label>政策结束日期</label>
                <span style="font-weight: bold; color: #bb3e30;">{{f.customerPolicyExpireDate}}</span>
                <span v-if="f.policyStatus" style="margin-left: 15px;">{{f.policyStatus}}</span>
                <span v-else>
                  <span style="font-weight: bold; color: #429957;margin-left: 15px;" v-if="f.customerPolicyExpire === false">生效中
                  <el-button size="mini" type="primary" style="margin-left: 15px;" @click="adPolicyWorkflowChangedCreate(3, f.id,'变更')">变更政策</el-button></span>
                  <span style="font-weight: bold; color: #bb3e30;margin-left: 15px;" v-else-if="f.customerPolicyExpire === true">合约到期
                  <el-button size="mini" type="primary" style="margin-left: 15px;" @click="adPolicyWorkflowChangedCreate(2, f.id,'续签')">续签政策</el-button></span>
                </span>
              </div>
            </div>

            <div class="bottom-info">
              <div>
                <el-button size="mini" @click="navToWorkflowDetail('Auto', f.id)">查看详情</el-button>
                <el-button size="mini" @click="$refs.editor.open(f)">修改标题</el-button>
                <el-button size="mini" @click="dialogFormVisibleOpen(f.id, f.remark)">备注</el-button>
              </div>
              <div style="flex: 1; text-align: right;">
                <div class="block-info" v-if="f.tags.length > 0">
                  <label>Tags</label>
                  <span class="tag-item" v-for="t in f.tags">{{t}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

    <flow-editor
      ref="editor"
      :flow-type="flowType"
      :title-advise="titleAdvise"
      @save="handleFlowSave">
    </flow-editor>

    <search-panel ref="search"
                  @search="handleSearch"
                  @clear="handleSearchClear"
                  :flowType="flowType"
                  :flowStageConfigs="flowStageConfigs"
    >
    </search-panel>

    <flow-diagram ref="diagram"></flow-diagram>

    <el-dialog title="备注信息录入" :visible.sync="dialogFormVisible" :modal = "false" :show-close="false">
      <el-form :model="form">
        <el-input v-model="form.remark" type="textarea" :rows="3"></el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleClose">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisibleSave">确 定</el-button>
      </div>
    </el-dialog>

  </page>
</template>

<script>
  import FlowEditor from './comps/editor'
  import SearchPanel from './comps/search-panel'
  import FlowDiagram from './flow-diagram'
  import flowDetailNavMixin from '../../../mixins/flow-detail-nav'
  import kit from '../../../tools/kit'
  import {
    getFlowConfig,
    getFlowStageConfigs,
    getFlow,
    searchFlow,
    updateFlowRemark,
    adPolicyWorkflowChanged
  } from '../../../http/api/workflow'
  import { FLOW_RECHARGE } from '../../../config/flows'

  export default {
    mixins: [flowDetailNavMixin],
    components: { FlowEditor, SearchPanel, FlowDiagram },
    data () {
      return {
        pageTitle: '',
        titleAdvise: '',
        dataList: [],
        aggregations: [],
        flowType: this.$route.params['type'],
        flowStageConfigs: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        flowRechargeName: FLOW_RECHARGE,
        search: {
          keyword: null,
          stage: null,
          status: 'open',
          reject: null,
          processable: null,
          platformId: null,
          orderName: null,
          orderDir: null,
          createDate: [],
          customerId: null,
          rechargePayType: null
        },
        dialogFormVisible: false,
        form: {
          remark: '',
          flowId: ''
        }
      }
    },
    computed: {
      moreSearchOptionTotal () {
        let total = 0
        if (this.search.reject === true || this.search.reject === false) {
          total++
        }
        if (this.search.processable === true) {
          total++
        }
        if (!kit.str.isBlank(this.search.platformId)) {
          total++
        }
        if (!kit.str.isBlank(this.search.orderName)) {
          total++
        }
        if (!kit.str.isBlank(this.search.orderDir)) {
          total++
        }
        if (!kit.str.isBlank(this.search.customerId)) {
          total++
        }
        if (!kit.str.isBlank(this.search.rechargePayType)) {
          total++
        }
        if (this.search.createDate && this.search.createDate.length > 0) {
          total++
        }
        return total
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    filters: {
      nextStage (flow) {
        switch (flow.status) {
        case 'close': return '已作废'
        case 'completed': return '已完成'
        default: return flow['nextStageTitle']
        }
      }
    },
    methods: {
      nextProcessUsersFilter(users) {
        let limit = 6
        let count = 0
        let results = ''
        for (let u of users) {
          let isProcessSpan = ''
          if (u.processed) {
            isProcessSpan = '<span style="color: #429957">（已处理）</span>'
          }
          results += '<span style="margin-right: 2px;">' + (count > 0 ? '，' : '') + u.name + isProcessSpan + '</span>'
          if (++count >= limit) {
            break
          }
        }
        if (users.length > limit) {
          results += '...'
        }
        return results
      },
      handleFlowSave(flow, isUpdate) {
        kit.arr.insertOrReplace(this.dataList, 0, flow)
        if (!isUpdate) {
          this.$router.push({name: 'AutoWorkflowDetail', params: {id: flow.id}})
        }
      },
      handleSearch(params) {
        kit.obj.merge(this.search, params)
        this.reload()
      },
      handleSearchClear(params) {
        kit.obj.merge(this.search, params)
      },
      reload() {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load() {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        if (this.search.createDate && this.search.createDate.length === 2) {
          params.startCreateTime = this.search.createDate[0] + ' 00:00:00'
          params.endCreateTime = this.search.createDate[1] + ' 23:59:59'
        }
        params.createDate = undefined
        this.$pageLoading(true)
        searchFlow()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.page.total = resp.data.total
            this.dataList = resp.data.records
            this.aggregations = resp.data.aggregations
          })
          .send(this.flowType, params)

        getFlowConfig()
          .success(resp => {
            this.pageTitle = resp.data.title
            this.titleAdvise = resp.data.titleAdvise
          })
          .send(this.flowType)
      },
      loadFlowStageConfigs() {
        getFlowStageConfigs()
          .success(resp => {
            this.flowStageConfigs = resp.data
          })
          .send(this.flowType)
      },
      upPayLatestPayDateProcess (payDate) {
        let days = parseInt((new Date(payDate).getTime() - new Date(kit.date.format(new Date())).getTime()) / (1000 * 60 * 60 * 24))
        let results = '<span style="color: #F52C34">' + payDate + '</span>'
        if (days >= 0) {
          results += '<span style="margin-left: 6px;">还剩下</span>' + '<span style="color: #F52C34;font-size: 16px;">' + days + '</span>' + '<span>天</span>'
        }
        return results
      },
      dialogFormVisibleOpen (flowId, remark) {
        this.dialogFormVisible = true
        this.form.remark = remark
        this.form.flowId = flowId
      },
      dialogFormVisibleClose () {
        this.dialogFormVisible = false
        this.form.remark = ''
        this.form.flowId = ''
      },
      dialogFormVisibleSave () {
        updateFlowRemark()
          .success(resp => {
            kit.arr.pushOrReplace(this.dataList, resp.data)
          })
        .send(this.flowType, this.form.remark, this.form.flowId)
        this.dialogFormVisibleClose()
      },
      adPolicyWorkflowChangedCreate (policyType, workflowId, info) {
        this.$confirm(`确定要${info}政策吗？${info}政策会重新发起新流程`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          adPolicyWorkflowChanged()
            .success(resp => {
              kit.arr.pushOrReplace(this.dataList, resp.data.workflowDoc)
              kit.arr.pushOrReplace(this.dataList, resp.data.oldWorkflowDoc)
              this.navToWorkflowDetail('Auto', resp.data.workflowDoc.id)
            })
            .send(this.flowType, policyType, workflowId)
        }).catch(() => {})
      }
    },
    beforeRouteUpdate (target, source, next) {
      if (target.params['type'] !== this.flowType) {
        this.flowType = target.params['type']
        this.reload()
        this.loadFlowStageConfigs()
      }
      next()
    },
    mounted () {
      this.load()
      this.loadFlowStageConfigs()
      if (process.env.NODE_ENV !== 'production') {
        console && console.log('Create FlowUpdate dispatch listener')
      }
      this.$dispatcher.listen('FlowUpdate', 'FlowListenerId', id => {
        let flow = this.dataList.find(item => item.id === id)
        if (flow) {
          getFlow()
            .success(resp => {
              kit.arr.pushOrReplace(this.dataList, resp.data)
            })
            .send(id)
        }
      })
    },
    beforeDestroy () {
      if (process.env.NODE_ENV !== 'production') {
        console && console.log('Remove FlowUpdate dispatch listener')
      }
      this.$dispatcher.removeListen('FlowUpdate', 'FlowListenerId')
    }
  }
</script>

<style scoped lang="less">
  @deep: ~'>>>';
  .table @{deep} .danger-row {
    background: #ffefe7;
  }
  .table @{deep} .success-row {
    background: #f0f9eb;
  }
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

  .main-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #eeeeee;

    .aggs {
      width: 260px;
      padding: 4px 8px;
      border-right: solid 1px #eeeeee;
      overflow: auto;
    }

    .flow-list {
      flex: 1;
      overflow: auto;
    }
  }

  .aggs-item + .aggs-item {
    margin-top: 10px;
  }
  .aggs-item {
    .bucket-item + .bucket-item {
      margin-top: 4px;
    }
    .bucket-item {
      display: flex;
      flex-direction: row;
      font-size: 13px;
    }
    .bucket-item-key {
      flex: 1;
      color: #324474;
    }
    .bucket-item-total {
      width: 40px;
      font-weight: bold;
    }
  }



  .flow-item {
    display: flex;
    position: relative;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 15px;
    &:first-of-type {
      margin-top: 8px;
    }
    margin-bottom: 8px;
    min-height: 80px;
    max-width: 1200px;
    overflow: hidden;
    border-radius: 5px;
    border: solid 1px #e8e8e8;

    .order-policy-expire {
      position: absolute;
      top: 50%;
      right: 50px;
      margin-top: -16px;
      color: rgba(175,58,38,.3);
      font-size: 28px;
      font-weight: bold;
      z-index: 0;
      user-select: none;
    }

    .version {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2px 5px;
      border-bottom-left-radius: 5px;
      background-color: #fff;
      font-size: 12px;
    }
    .status {
      width: 30px;
      line-height: 30px;
      text-align: center;
      writing-mode: vertical-lr;
      letter-spacing: 3px;
      font-size: 12px;
      color: #fff;
    }
    &.status-close {
      border-color: #bb3e30;
      .status {
        background-color: #bb3e30;
      }
      .content {
        background-color: #f3e1dd;
      }
    }
    &.status-open {
      border-color: #3169a8;
      .status {
        background-color: #3169a8;
      }
      .content {
        background-color: rgba(150,180,210,.2);
      }
    }
    &.status-completed {
      border-color: #50a654;
      .status {
        background-color: #50a654;
      }
      .content {
        background-color: #f0fcf3;
      }
    }
    .content {
      flex: 1;
      padding: 5px 8px;
      .block-info {
        display: inline-block;
        font-size: 12px;
        > label {
          color: #3c3c3c;
          &::after {
            content: '：';
          }
        }
        > span {
          color: #818181;
        }
      }
      .block-info + .block-info {
        margin-left: 20px;
      }
      .title {
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        .remark {
          font-weight: bold;
          color: #4aaa67;
        }
      }
      .stage-info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .bottom-info {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        .tag-item:not(:first-of-type)::before {
          content: '，';
        }
      }
    }
    .recharge-info {
      font-size: 13px;
      .payed {
        font-weight: bold;
        color: #417952;
      }
      .un-pay {
        font-weight: bold;
        color: #a94442;
      }
    }
  }
  .flow-item:hover {
    box-shadow: 0 0 8px rgba(0,0,0,.3);
  }
</style>
