<template>
  <page route-name="RechargePaymentRemind">
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="排序">
          <el-select v-model="query.orderName" :clearable="true" style="width: 120px;">
            <el-option
              v-for="item in orderNameOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="query.orderDir" :clearable="true" style="width: 80px; margin-left: 5px;">
            <el-option
              v-for="item in orderDirOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款日期">
          <el-date-picker
                  v-model="query.payedDate"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="query.status" :clearable="true" style="width: 100px;">
            <el-option
                    v-for="item in [{id: 'wait', title: '未回款'}, {id: 'payed', title: '已回款'}, {id: 'checking', title: '查账中'}, {id: 'cancel', title: '已取消'}]"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户">
          <el-input v-model="query.customerName" style="width: 150px;" clearable placeholder="输入客户名称关键字"></el-input>
        </el-form-item>
        <el-form-item label="流程">
          <el-input v-model="query.flowTitle" style="width: 150px;" clearable placeholder="输入流程标题关键字"></el-input>
        </el-form-item>
        <el-form-item label="平台" prop="platform">
          <el-select v-model="query.platformId" placeholder="请选择" :clearable="true" style=" width:120px;">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售人员" prop="name">
          <el-select style="width: 120px;" v-model="query.saleUserId" clearable>
            <el-option v-for="item in allSaleServiceUsers" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服人员" prop="csUserId">
          <el-select style="width: 120px;" v-model="query.csUserId" clearable>
            <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      :data="dataList"
    >
<!--      <el-table-column label="操作" width="70">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-button size="mini" @click="handleCancelRemind(row)">取消提醒</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="收款操作" width="120">
        <template slot-scope="{ row }">
          <el-button size="mini" v-if="row.status === 'checking'" @click="handleGoFinance(row)">收款确认</el-button>
          <el-button size="mini" v-if="row.status === 'wait'" @click="handleGoCheck(row.id)">申请查帐</el-button>
          <el-button size="mini" v-if="row.status === 'payed'" @click="handleGoFinanceDetail(row.id)">财务收款详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="platformName" label="平台" width="80px;"></el-table-column>
      <el-table-column prop="customerName" label="客户签约主体名称" width="200"></el-table-column>
      <el-table-column label="付款类型" width="80">
        <template slot-scope="{ row }">
          <span  v-if="row.payType === 'payLater'">账期</span>
          <span  v-else-if="row.payType === 'imprest2'">垫款</span>
        </template>
      </el-table-column>
      <el-table-column label="款项状态" width="80">
        <template slot-scope="{ row }">
          <span style="color: #3C763C" v-if="row.status === 'payed'">已付</span>
          <span style="color: #6a6a6a" v-else-if="row.status === 'wait'">待付</span>
          <span style="color: #1c30a6" v-else-if="row.status === 'checking'">查帐中</span>
          <span style="color: #a94442" v-else-if="row.status === 'cancel'">取消</span>
        </template>
      </el-table-column>
      <el-table-column prop="currency" label="币种" width="100"></el-table-column>
      <el-table-column label="充值金额" width="160">
        <template slot-scope="{ row }">
          <span style="font-weight: bold; color: #a94442">{{row.price | formatCurrency}}</span>
          {{row.currency}}
        </template>
      </el-table-column>
      <el-table-column prop="paymentDays" label="预计付款日期" width="120"></el-table-column>
      <el-table-column prop="payedDate" label="实际付款日期" width="120"></el-table-column>
      <el-table-column label="实收金额" prop="actualPrice" width="160">
        <template slot-scope="{ row }">
          <span style="font-weight: bold; color: #a94442">{{row.actualPrice | formatCurrency}}</span>
          {{row.currency}}
        </template></el-table-column>
      <el-table-column prop="csUserName" label="客服"></el-table-column>
      <el-table-column prop="saleUserName" label="销售"></el-table-column>
      <el-table-column label="充值流程" width="300">
        <template slot-scope="{ row }">
          <a style="cursor: pointer;"
             v-if="row.flowRechargeId"
             @click.stop="handleGoFlow('Recharge', row.flowRechargeId)"
          >
            {{row.flowRechargeTitle}}
            <span style="color: #3b61b6">（进入流程）</span>
          </a>
        </template>
      </el-table-column>
<!--      <el-table-column label="收款流程" width="300">-->
<!--        <template slot-scope="{ row }">-->
<!--          <a style="cursor: pointer;"-->
<!--             v-if="row.flowReceiptId"-->
<!--             @click.stop="handleGoFlow('Receipt', row.flowReceiptId)">-->
<!--            {{row.flowReceiptTitle}}-->
<!--            <span style="color: #3b61b6">（进入流程）</span>-->
<!--          </a>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="remark" label="备注" width="250"></el-table-column>-->
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>
  import flowDetailNavMixin from '../../mixins/flow-detail-nav'
  import kit from '../../tools/kit'
  import { getPaymentRemindList, cancelPaymentRemind } from '../../http/api/recharge-payment-remind'
  import { getAdPlatformListIdNameMode } from '../../http/api/ad-platform'
  import { getSaleServiceUserList } from '../../http/api/upper-lower-policy'
  import { getCustomerServiceUserList } from '../../http/api/cs-customer'

  export default {
    mixins: [flowDetailNavMixin],
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        query: {
          status: 'wait',
          customerName: null,
          flowTitle: null,
          payedDate: [],
          orderName: null,
          orderDir: 'desc',
          platformId: null,
          saleUserId: null,
          csUserId: null
        },
        orderNameOptions: [
          { id: 'c.name', title: '客户' },
          { id: 'u.name', title: '负责人' },
          { id: 'payment_days', title: '预计付款日期' },
          { id: 'status', title: '状态' },
          { id: 'payed_date', title: '付款日期' },
          { id: 'price', title: '金额' }
        ],
        orderDirOptions: [
          { id: 'desc', title: '降序' },
          { id: 'asc', title: '升序' }
        ],
        platforms: [],
        allCustomerServiceUsers: [],
        allSaleServiceUsers: []
      }
    },
    computed: {
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    filters: {
      formatCurrency: function (price) {
        return kit.str.fmtCurrency(price, 2, '')
      }
    },
    methods: {
      handleGoFlow (flowType, flowId) {
        this.navToWorkflowDetail('_CUSTOM_PaymentRemind', flowId, flowType)
      },
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        let params = kit.obj.merge({}, {
          start: this.page.start,
          limit: this.page.limit
        }, this.query)
        delete params.payedDate
        if (this.query.payedDate && this.query.payedDate.length === 2) {
          params.payedDateStart = this.query.payedDate[0]
          params.payedDateEnd = this.query.payedDate[1]
        }

        this.$pageLoading(true)
        getPaymentRemindList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            let page = resp.data
            this.dataList = page.records
            this.page.total = page.total
          })
          .send(params)
      },
      handleCancelRemind (row) {
        this.$confirm('确定要取消该提醒吗？', '确认')
          .then(() => {
            this.$pageLoading(true)
            cancelPaymentRemind()
              .complete(() => (this.$pageLoading(false)))
              .success(resp => {
                this.$message.success('取消成功。')
                kit.arr.pushOrReplace(this.dataList, resp.data)
              })
              .send(row.id)
          })
          .catch(() => {})
      },
      handleGoCheck (id) {
        this.$router.push({ name: 'Checking', params: { id: id } })
      },
      handleGoFinance (row) {
        this.$router.push({ name: 'Finance', params: { id: row.id, customerName: row.customerName, currency: row.currency } })
      },
      handleGoFinanceDetail (id) {
        this.$router.push({ name: 'FinanceDetail', params: { id: id } })
      }
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      getSaleServiceUserList()
        .success(resp => {
          this.allSaleServiceUsers = resp.data
        })
        .send()
      getCustomerServiceUserList()
        .success(resp => {
          this.allCustomerServiceUsers = resp.data
        })
        .send()
      this.load()
      this.$dispatcher.listen('check_account_submit', 'PaymentRemindUpdateListenerDes', data => {
        kit.arr.pushOrReplace(this.dataList, data)
      })
      this.$dispatcher.listen('finance_submit', 'PaymentRemindUpdateListenerFin', data => {
        kit.arr.pushOrReplace(this.dataList, data)
      })
    },
    beforeDestroy () {
      this.$dispatcher.removeListen('check_account_submit', 'PaymentRemindUpdateListenerDes')
      this.$dispatcher.removeListen('finance_submit', 'PaymentRemindUpdateListenerFin')
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
