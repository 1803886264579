import defineApi from '../define-api'
import http from '../application-api'

/**
 * 获取某个"combine"类型的阶段的已处理记录，该阶段是未完成的。
 * @param flowId
 * @param stage
 *          如果是阶段配置（StageConfig）的ID，表示获取还未关联到Stage上的处理记录。
 *          如果是阶段（Stage）的ID，表示获取关联到这个阶段的处理记录。
 * @type {Function}
 */
export const getCombineStages = defineApi((config, flowId, stage) => {
  config.params = {
    flowId: flowId,
    stage: stage
  }
  http.get('/flow_stage/combine_stages', config)
})

/**
 * 获取流程下个要处理的阶段的需提交表单配置、附件配置和简要信息
 * @param flowId
 * @param [conditionId] 如果阶段的conditionType为ExpressionConditionConfig的，则不需要提供此参数。
 * @type {Function}
 */
export const getNextStageInfo = defineApi((config, flowId, conditionId) => {
  config.params = {
    flowId: flowId,
    conditionId: conditionId
  }
  http.get('/flow_stage/next_stage', config)
})

export const getStageDetail = defineApi((config, stageId) => {
  config.params = {
    stageId: stageId
  }
  return http.load('/flow_stage/detail', config)
})

export const getFlowStages = defineApi((config, flowId) => {
  config.params = {
    flowId: flowId
  }
  http.get('/flow_stage/stages', config)
})

/**
 * 处理流程的下个阶段。
 * @param flowId {@link Workflow#getId()}
 * @param stage {@link StageConfig#getId()}
 * @param description 不是必须的。
 * @param formJsonString 不是必须的，表单的JSON字符串。
 * @param conditionId 如果不提供，表示下一个阶段的条件由表达式决定，参考ExpressionConditionConfig。
 * @param selectedUsers 不是必须的，表示指定可以处理下下一步的人，多个ID用","符号分隔。
 * @type {Function}
 */
export const processNextStage = defineApi((config, flowId, stage, conditionId, description, formJsonString, selectedUsers) => {
  config.params = {
    flowId: flowId,
    stage: stage,
    conditionId: conditionId,
    description: description,
    form: formJsonString,
    selectedUsers: selectedUsers
  }
  http.post('/flow_stage/process_next', config)
})

export const getSuccessStages = defineApi((config, flowId) => {
  config.params = {
    flowId: flowId
  }
  http.get('/flow_stage/stages/success', config)
})

// ======================== 附件 ========================

export const deleteStageAttachment = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/flow_stage/attachment/delete', config)
})

export const getStageProcessingAttachments = defineApi((config, flowId, stage) => {
  config.params = {
    flowId: flowId,
    stage: stage
  }
  http.get('/flow_stage/attachment/processing', config)
})

export const uploadStageAttachment = defineApi((config, flowId, stage, name, conditionId, formJson, file) => {
  let form = new FormData()
  form.append('file', file)
  form.append('flowId', flowId)
  form.append('stage', stage)
  form.append('name', name)
  form.append('conditionId', conditionId)
  form.append('formJson', formJson)
  config.data = form
  http.post('/flow_stage/attachment/upload', config)
})


/**
 * 取得处理流程的某个阶段form 数据。
 * @param flowId {@link Workflow#getId()}
 * @param stage {@link StageConfig#getId()}
 */
export const getFormsData = defineApi((config, flowId, stage) => {
  config.params = {
    flowId: flowId,
    stage: stage
  }
  http.get('/flow_stage/forms_data', config)
})
