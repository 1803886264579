<template>
  <el-card>
    <div slot="header">
      <div style="cursor: pointer;" @click="$router.push({name: 'Todo'})">
        <i class="fas fa-list"></i>
        待办事项
      </div>
    </div>
    <div>
      <div v-if="todoList.length === 0" class="text-muted font-mini">所有事项都已处理</div>
      <div class="todo-item" :key="t.id" v-for="t in todoList" style="margin-bottom: 5px;">
        <el-row>
          <el-col :span="18">
            <router-link
              :to="{name: 'Todo', query: {tag: t.id}}"
              class="title font-normal text-main">{{t.name}}</router-link>
          </el-col>
          <el-col :span="6">
            <el-badge :value="t.total" style="float: right; height: 20px;"></el-badge>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-card>
</template>

<script>
  export default {
    computed: {
      todoList () {
        return this.$todoStore.getTotals()
      }
    }
  }
</script>

<style lang="less" scoped>
</style>
