<template>
  <page route-name="AdsAbnormal">

    <!--工具按钮-->
    <el-button slot="toolbar"  icon="el-icon-download" @click="downloadAllAbnormal()">下载所有异常账户</el-button>
    <el-button slot="toolbar" icon="el-icon-refresh" type="primary" @click="load">查询</el-button>

    <div slot="top">
      <el-form :inline="true" :model="query" style="margin: 5px 10px;">
        <el-form-item label="广告账户">
          <el-input style="width: 150px;" clearable size="mini"  @blur="query.adAccountId = query.adAccountId.trim()" v-model="query.adAccountId" ></el-input>
        </el-form-item>
        <el-form-item label="下载失败时间">
          <el-date-picker size="mini"
                          v-model="query.failTime"
                          type="datetimerange"
                          align="right"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <br>
        <el-form-item label="广告平台">
          <el-select v-model="query.platform" size="mini" clearable placeholder="请选择" style="width: 150px;">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select  v-model="query.solved" placeholder="请选择" size="mini"  style="width:80px;">
            <el-option
              v-for="item in [{id: '1', name: '已解决'}, {id: '0', name: '未解决'}]"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常信息">
          <el-input style="width: 300px;" clearable size="mini" v-model="query.message" ></el-input>
        </el-form-item>
      </el-form>
    </div>


    <el-table
      slot="center"
      :data="dataList"
      stripe
    >
      <el-table-column label="操作"　width="150" >
        <template slot-scope="scope">
          <el-button :disabled="!!(scope.row.solved)" size="mini"  @click="solvedAbnormal(scope.row)">标记解决</el-button>
          <el-button size="mini"   @click="downloadAbnormal(scope.row)">重新下载</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="platform" label="广告平台" width="100"></el-table-column>,
      <el-table-column prop="adAccountId" label="广告账户ID"　width="100"></el-table-column>,
      <el-table-column prop="failTime" label="下载失败的时间"　width="150" sortable ></el-table-column>
      <el-table-column prop="downloadDateStart" label="报表日期起"　width="100" ></el-table-column>
      <el-table-column prop="downloadDateEnd" label="报表日期止"　width="100"  ></el-table-column>
      <el-table-column prop="timeSpend" label="耗时"　width="80"  ></el-table-column>
      <el-table-column prop="message" label="异常信息" ></el-table-column>
      <el-table-column>
        <template slot-scope="{ row }">
          <span style="color: #3c763c" v-if="row.solved">已解决</span>
          <span style="color: #b73f37;" v-else>未解决</span>
        </template>
      </el-table-column>

    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>

  import api, {
    API_ADS_REPORT_DOWNLOAD_FAIL_LOGS,
    API_ADS_REPORT_DOWNLOAD_FAIL_LOG_SET_SOLVED,
    API_ADS_REPORT_DOWNLOAD_FAIL_LOG_RETTY,
    API_ADS_REPORT_DOWNLOAD_FAIL_LOG_RETTY_ALL
  } from '../../../config/urls'
  import kit from '@/tools/kit'
  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        query: {
          adAccountId: '',
          failTime: null,
          platform: '',
          solved: '',
          message: ''
        },
        options: [{
          value: 'bing',
          label: 'Bing Platform'
        }, {
          value: 'google',
          label: 'Google Platform'
        }]
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        this.$pageLoading(true)
        this.$http.load(api(API_ADS_REPORT_DOWNLOAD_FAIL_LOGS), {
          params: {
            start: this.page.start,
            limit: this.page.limit,
            adAccountId: this.query.adAccountId,
            failTimeStart: this.query.failTime === null ? null : this.query.failTime[0],
            failTimeEnd: this.query.failTime === null ? null : this.query.failTime[1],
            platform: this.query.platform,
            solved: this.query.solved,
            message: this.query.message
          },
          completeBefore: () => this.$pageLoading(false),
          success: resp => {
            this.dataList = resp['data']
            this.page.total = resp['total']
          }
        })
      },
      solvedAbnormal(r) {
        this.$confirm(`请确认异常已解决，是否标记为已解决？`, {type: 'warning'})
          .then(() => {
            this.$pageLoading(true)
            this.$http.put(api(API_ADS_REPORT_DOWNLOAD_FAIL_LOG_SET_SOLVED, {id: r.id, solved: 1}), {
              context: this,
              completeBefore: () => this.$pageLoading(false),
              success: () => {
                r.solved=1
                kit.arr.pushOrReplace(this.dataList, r)
              }
            })
          })
      },
      downloadAbnormal(r) {
            this.$pageLoading(true)
            this.$http.get(api(API_ADS_REPORT_DOWNLOAD_FAIL_LOG_RETTY, {id: r.id}), {
              completeBefore: () => this.$pageLoading(false),
              success: resp => {
                kit.arr.pushOrReplace(this.dataList, resp)
              }
            })
      },
      downloadAllAbnormal(r) {
        this.$pageLoading(true)
        this.$http.get(api(API_ADS_REPORT_DOWNLOAD_FAIL_LOG_RETTY_ALL), {
          completeBefore: () => this.$pageLoading(false),
          success: () => {
            this.load()
          }
        })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style scoped>

</style>
