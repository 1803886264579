<template>
  <li class="list-group-item hover-item" :class="{'is-parent': !acc.parent, 'is-children': !!acc.parent}">
    <div class="auto-layout">
      <div style="width: 25px;">
        <i class="text-success font-mini fa fa-check" v-if="acc.added"></i>
        <el-checkbox v-model="acc.checked" v-else></el-checkbox>
      </div>
      <div class="auto-layout-full">
        <div class="account-name font-normal font-bold" style="width: 100%; word-break: break-all;">
          {{acc.name}}（{{acc.accountId}}）
        </div>
        <div class="text-muted font-mini">{{acc.fullName}}</div>
      </div>
        <!---->

        <!--<el-button v-else-->
                   <!--type="primary"-->
                   <!--icon="el-icon-plus"-->
                   <!--@click="$emit('on-add', acc)"-->
                   <!--:loading="adding"-->
        <!--&gt;-->
        <!--</el-button>-->
    </div>
    <slot></slot>
  </li>
</template>

<script>
  export default {
    props: {
      acc: { type: Object, required: true },
      adding: { type: Boolean, default: false }
    }
  }
</script>

<style lang="less" scoped>
  .is-parent {
    .account-name {
      color: #a01f09;
    }
  }
  .is-children {
    padding-left: 40px !important;
    .account-name {
      color: #797979;
    }
  }
</style>
