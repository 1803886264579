<!--
  客服销售绩效报表页面查询
-->
<template>
  <page route-name="CustomerPlatform">
    <el-button slot="toolbar" icon="el-icon-refresh" @click="load"></el-button>
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="query.startDate"
            type="date"
            placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            v-model="query.endDate"
            type="date"
            placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="广告平台">
          <el-select v-model="query.platform" placeholder="广告平台">
            <el-option
              v-for="item in  [{ value: 'google',label: 'google'}, {value: 'bing',label: 'bing'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户">
          <el-select v-model="query.adCustomerIdStr" filterable multiple placeholder="客户" @change="customerChange">
            <el-option
              v-for="item in customers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户" >
          <el-select v-model="query.saleIdStr" filterable multiple placeholder="用户" @change="userChange">
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div slot="center" style="width: 100%">

      <el-table
        stripe
        :data="serviceDataList"
        default-expand-all
      >
        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <div>
              <div v-if="row.adCustomerPlatformList.length === 0" class="text-muted" style="text-align: center;">暂无关联客户</div>
              <el-table :data="row.adCustomerPlatformList"
                        style="margin: 0 10px 10px 50px; position: relative;"
                        :summary-method="getSummaries"
                        show-summary
              >
                <el-table-column label="客户" prop="adCustomerName" width="250"></el-table-column>
                <el-table-column label="投放平台" prop="platform" width="100"></el-table-column>
                <el-table-column label="消耗" prop="consume" width="140"></el-table-column>
                <el-table-column label="广告账户数量" prop="number" width="140"></el-table-column>
                <el-table-column label="货币" prop="currency" width="140">
                  <template slot-scope="scope">
                    <span v-if="scope.row.currency!=null">  {{scope.row.currency}}</span>
                    <span v-else>无消耗</span>
                  </template>
                </el-table-column>
                <el-table-column v-if="row.type === '0'" label="客服维护周期" prop="maintenanceTime"></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="saleName" label="用户" width="120">
          <template slot-scope="{ row }">
            <div style="font-size: 16px; font-weight: bold; color: #96342d;">{{row.saleName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="职务">
          <template slot-scope="scope">
            {{scope.row.type === '1'? '销售': '客服'}}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </page>
</template>

<script>
  import api, { API_AD_CUSTOMER_PLATFORM_ACHIEVEMENT } from '../../config/urls'
  export default {
    data () {
      return {
        serviceDataList: [],
        saleDataList: [],
        kpiCustomers: [],
        users: [],
        customers: [],
        query: {
          startDate: null,
          endDate: null,
          platform: 'bing',
          adCustomerId: null,
          saleId: null,
          adCustomerIdStr: null,
          saleIdStr: null
        }
      }
    },
    methods: {
      load () {
        // 指定日期
        let fixedStart = null
        let fixedEnd = null
        if (this.query.startDate != null) {
          let s = this.query.startDate
          fixedStart = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate()
        }
        if (this.query.endDate != null) {
          let e = this.query.endDate
          fixedEnd = e.getFullYear() + '-' + (e.getMonth() + 1) + '-' + e.getDate()
        }
        this.$http.get(api(API_AD_CUSTOMER_PLATFORM_ACHIEVEMENT), {
          params: {
            startDate: fixedStart,
            endDate: fixedEnd,
            platform: this.query.platform,
            adCustomerId: this.query.adCustomerId,
            saleId: this.query.saleId
          },
          completeBefore: () => this.$pageLoading(false),
          success: resp => {
            this.serviceDataList = resp.service
            // this.saleDataList = resp.sale
            this.customers = resp.customers
            this.users = resp.users
          }
        })
      },
      customerChange (val) {
        this.query.adCustomerId = val.join(',')
      },
      userChange (val) {
        this.query.saleId = val.join(',')
      },
      getSummaries (param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          if (column.property === 'consume') {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ''
            } else {
              sums[index] = ''
            }
          } else {
            sums[index] = ''
          }
        })
        return sums
      }
    },
    mounted () {
      this.$http.get(api(API_AD_CUSTOMER_PLATFORM_ACHIEVEMENT), {
        params: {
          startDate: null,
          endDate: null,
          platform: null,
          adCustomerId: null,
          saleId: null
        },
        completeBefore: () => this.$pageLoading(false),
        success: resp => {
          this.customers = resp.customers
          this.users = resp.users
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .content_all{
    width: 50%;
    font-size: 20px;
    float: left;
    overflow-y: auto
  }
  .user_content{
    width: 20%;
  }
  .account{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
    .title{
      font-size: 20px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    ul li{
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
  .title_style{
    margin-right:100px;
    float: left;
    width: 200px;
  }
  .lie_style{
    width: 16%;
    float: left;
    overflow: hidden;
   // border: 1px red solid;
  }
  .small{
    width: 10%;
  }
  ul,li{width: 100%}
  .lie_style_xun_huan{
    width: 52%;
    float: left;
  }
  .customer_style{
    width: 100%;
    height: 40px;
  }
  .customer_style_inner{
    height: 100%;
    width: 33%;
    float: left;
  }
</style>
