import defineApi from '../define-api'
import http from '../application-api'

export const getAdPlatformList = defineApi((config) => {
  return http.load('/ad_platform/list', config)
})

export const updateAdPlatform = defineApi((config, ad) => {
  config.data = ad
  http.post('/ad_platform/update', config)
})

export const addAdPlatform = defineApi((config, ad) => {
  config.data = ad
  http.post('/ad_platform/add', config)
})

export const getAdPlatformListIdNameMode = defineApi((config) => {
  http.get('/ad_platform/idname/list', config)
})

export const getDependPlatformValue = defineApi((config) => {
  http.get('/ad_platform/depend/platform/value', config)
})
