<!--
  文件上传组件，本组件不提供"文件选择"操作，只提供文件上传，并且不会验证文件类型和大小等。
  用法：
  <uploader ref="uploader"></uploader>
  this.$refs.uploader.upload(files)

  files是一个File数组，upload函数可多次调用，新的文件会被追加到上传列表。
  File对象参考https://developer.mozilla.org/zh-CN/docs/Web/API/File。
  你可以给file对象设置两个额外属性，如下：
  ---------------------
  let file = ...
  file.description = '我的文件夹'
  file.params = {'foo': 'bar'}
  files.push(file)
  upload(files)
  ---------------------
    description 在上传列表中的一个额外描述
    params 作为额外参数发送到服务端

  事件：
    upload(resp, file) 当文件上传成功时，resp是服务端返回的数据，file是上传的源文件。

  属性：
    uploadName 上传文件的参数名，默认file。
    uploadUrl 上传地址，组件使用this.$http作为上传工具，所以uploadUrl应该要满足上传工具的格式要求。

  文件选择：参考/src/utils/select-file.js
-->
<template>
  <transition name="slideRight">
    <div class="uploader" v-transfer-dom :data-transfer="true" v-show="visible" style="animation-duration: 300ms;">
      <div class="upload-panel">
        <div class="head">
          <div class="title">
            {{title}}
            <span v-if="isUploading">
              <span v-if="isMaximize">
                （第 {{files.length - filesWaitForUpload.length}} 个，共 {{files.length}} 个）
              </span>
              <span v-else>
                （第 {{files.length - filesWaitForUpload.length}} 个...{{uploadingFile.percent}}%，共 {{files.length}} 个）
              </span>
            </span>
          </div>
          <div class="head-toolbar">
            <i class="fa" :class="isMaximize ? 'fa-minus-square' : 'fa-window-maximize'" @click="changeWinStatus"></i>
            <i class="close el-icon-close" @click="close"></i>
          </div>
        </div>
        <div class="body">
          <div class="upload-item" :key="f.id" v-for="f in files">
            <div class="file-item">
              <div><i @click="abortUploadFile(f)" class="el-icon-close" style="cursor: pointer;"></i></div>
              <div class="file-name">{{f.name}}</div>
              <div class="file-description text-muted">{{f.description}}</div>
              <div class="file-icon"><file-icon :file-name="f.name"></file-icon></div>
              <div class="file-size">{{f.size | fmtSize}}</div>
              <div class="upload-percent">
                <span v-if="f.status === 'wait'"><i class="el-icon-time"></i></span>
                <span v-else-if="f.status === 'uploading'" class="text-primary">{{f.percent}}%</span>
                <span v-else-if="f.status === 'completed'" class="text-success">完成</span>
                <span v-else-if="f.status === 'cancel'" class="text-muted">取消</span>
                <el-tooltip v-else-if="f.status === 'error'" effect="dark"  placement="left-start">
                  <div slot="content" v-html="f.error.message"></div>
                  <span class="text-error">错误</span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>

        <div class="toolbar">
          <el-button type="primary" @click="startUpload()" :disabled="isUploading || filesWaitForUpload.length === 0">开始上传</el-button>
          <el-button type="danger" @click="abortUploadFile()" :disabled="!isUploading">取消上传</el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import kit from '../tools/kit'
  import Velocity from 'velocity-animate'
  import TransferDom from '../directives/transfer-dom'

  const STATUS_WAIT = 'wait'
  const STATUS_UPLOADING = 'uploading'
  const STATUS_COMPLETED = 'completed'
  const STATUS_CANCEL = 'cancel'
  const STATUS_ERROR = 'error'

  export default {
    name: 'uploader',
    directives: { TransferDom },
    props: {
      uploadExec: { type: Function, required: true },
      title: { type: String, default: '上传文件' }
    },
    data () {
      return {
        visible: false,
        isMaximize: true, // 窗口的状态，false会只显示标题栏
        files: [],
        uploadingFile: null,
        continueUpload: false, // 当一个文件上传完成后（不管成功或失败），是否继续上传下一个文件
        abortUpload: null // 一个终止上传当前文件的函数，由上传工具返回
      }
    },
    filters: {
      fmtSize (size) {
        return kit.str.fmtSize(size)
      }
    },
    computed: {
      isUploading () {
        return this.uploadingFile !== null
      },
      filesWaitForUpload () {
        return this.files.filter(f => f.status === STATUS_WAIT)
      }
    },
    methods: {
      open () {
        this.visible = true
      },
      /**
       * @public
       * @param files {Array} File of Array
       */
      upload (files) {
        if (!Array.isArray(files)) {
          files = [files]
        }
        if (files.length > 0) {
          this.visible = true
        }
        for (let file of files) {
          this.files.push({
            id: kit.str.id(),
            name: file.name,
            description: file.description,
            size: file.size,
            file: file,
            params: file.params,
            status: STATUS_WAIT,
            percent: 0,
            error: null
          })
        }
        console.log('upload files count > ' + this.files.length)
        this.startUpload()
      },
      startUpload () {
        if (this.isUploading || this.filesWaitForUpload.length === 0) return
        this.uploadingFile = this.filesWaitForUpload[0]
        this.uploadingFile.status = STATUS_UPLOADING
        this.continueUpload = true
        this.abortUpload = this.uploadExec(this.uploadingFile, {
          progress: percent => {
            this.uploadingFile.percent = percent
          },
          success: () => {
            this.uploadingFile.status = STATUS_COMPLETED
          },
          error: (msg, isCancel) => {
            if (isCancel) {
              this.uploadingFile.status = STATUS_CANCEL
            } else {
              this.uploadingFile.status = STATUS_ERROR
              this.uploadingFile.error = { message: msg }
            }
          },
          complete: () => {
            this.uploadingFile = null
            if (this.continueUpload) {
              this.startUpload()
            }
          }
        })
      },
      execAbortUpload () {
        this.abortUpload && this.abortUpload()
        this.abortUpload = null
      },
      abortUploadFile (file) {
        if (file === undefined) {
          this.continueUpload = false
          this.execAbortUpload()
        } else if (file === this.uploadingFile) {
          this.execAbortUpload()
        } else if (file.status !== STATUS_UPLOADING) {
          kit.arr.remove(this.files, file)
        }
      },
      changeWinStatus (status) {
        if (status === true || status === false) {
          this.isMaximize = status
        } else {
          this.isMaximize = !this.isMaximize
        }
        Velocity(
          this.$el,
          {
            bottom: this.isMaximize ? 0 : -360,
            width: this.isMaximize ? 700 : 300
          },
          { duration: 300 }
        )
      },
      close () {
        let close = () => {
          this.continueUpload = false
          this.execAbortUpload()
          this.visible = false
          this.uploadingFile = null
          this.files = []
        }
        if (this.isUploading || this.filesWaitForUpload.length > 0) {
          this.$confirm('关闭窗口也会停止上传，确定关闭吗？')
            .then(() => close())
            .catch(() => {})
        } else {
          close()
        }
      }
    }
  }
</script>

<style lang="less" scoped>

  @panelWidth: 700px;
  @panelHeight: 400px;
  .uploader {
    position: absolute;
    z-index: 2001;
    height: @panelHeight;
    width: @panelWidth + 20;
    bottom: 0;
    right: 0;
  }

  .upload-panel {
    position: absolute;
    left: 0;
    right: 20px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    margin-right: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    .head {
      display: flex;
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      background-color: #515a6e;
      color: #fff;
      font-size: 13px;
      .title {
        flex: 1;
      }
      .head-toolbar {
        width: 50px;
        text-align: right;
        i + i {
          margin-left: 7px;
        }
        i {
          cursor: pointer;
        }
      }
    }
    .body {
      flex: 1;
      padding: 10px 0 10px 0;
      overflow-y: auto;
      .upload-item {
        position: relative;
        border-bottom: solid 1px #f2f2f2;
        .file-item {
          display: flex;
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          div:not(:first-of-type):not(:last-of-type) {
            padding: 0 5px;
          }
          .file-name {
            flex: 1;
            font-size: 14px;
          }
          .file-description {
            max-width: 100px;
          }
          .file-icon {
            width: 20px;
          }
          .file-size {
            width: 60px;
            text-align: right;
          }
          .upload-percent {
            width: 30px;
            text-align: center;
            color: #aeaeae;
          }
        }
      }
    }

    .toolbar {
      padding: 10px 0;
      text-align: center;
    }
  }

  .text-success {
    color: #27a956;
  }
  .text-muted {
    color: #9e9e9e;
  }
  .text-error {
    color: #fb3610;
  }
  .text-primary {
    color: #3a7ed6;
  }
</style>
