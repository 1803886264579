<template>
  <transition name="fade">
    <div class="file-preview" style="animation-duration: 0.2s" v-transfer-dom :data-transfer="true" v-show="visible">
      <div class="header">
        <div class="toolbar">
          <el-button type="primary" icon="el-icon-download" @click="handleDownloadFile">下载文件</el-button>
          <el-button icon="el-icon-refresh" @click="handleRefreshFile">刷新缓存</el-button>
        </div>
        <div class="close" @click="close">
          <i class="el-icon-close"></i>
        </div>
        <div class="file-name">{{fileName}}</div>
      </div>
      <div class="content">
        <div class="loading-message" v-show="loading">
          <i class="el-icon-loading"></i>
          正在转换文档，第一次可能需要花一点时间...
        </div>
        <div class="content-body">

          <iframe ref="ifr"
                  :src="fileUrl"
                  v-show="showType === 'file' && fileUrl"
          >
          </iframe>

          <div class="image-wrapper"
               v-show="showType === 'image' && imageUrl"
          >
            <img :src="imageUrl">
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import TransferDom from '../directives/transfer-dom'
  import { getAdminUrl } from '../http/application-api'

  export default {
    name: "file-preview",
    directives: { TransferDom },
    data () {
      return {
        loading: false,
        visible: false,
        fileObject: null,
        showType: null,
        fileUrl: null,
        imageUrl: null
      }
    },
    computed: {
      isImage () {
        return this.fileObject && this.fileObject.image === true
      },
      fileName () {
        if (this.fileObject) {
          return this.fileObject.title
        } else {
          return null
        }
      }
    },
    methods: {
      /**
       * @param fileObject 结构必须包含以下属性：
       *   id {string}
       *   title {string} 文件名
       *   url {string} 文件的下载地址
       *   image {boolean} 文件是否是图片
       */
      open (fileObject) {
        this.fileObject = fileObject
        this.visible = true
        this.loadFileContent(false)
      },
      loadFileContent (refresh) {
        this.loading = true
        let url = getAdminUrl('/file/' + this.fileObject.id + '/preview?_v=' + Math.random() + '&refresh=' + (refresh ? 'refresh' : ''))
        if (this.isImage) {
          this.showType = 'image'
          let img = new Image()
          img.onload = () => {
            this.loading = false
            this.imageUrl = url
          }
          img.onerror = () => (this.loading = false)
          img.src = url
        } else {
          this.showType = 'file'
          this.fileUrl = url
          this.$refs.ifr.onload = () => {
            this.loading = false
          }
        }
      },
      close () {
        this.visible = false
        this.fileObject = null
        this.showType = null
        this.fileUrl = null
        this.imageUrl = null
      },
      handleDownloadFile () {
        window.open(getAdminUrl(this.fileObject.url, true))
      },
      handleRefreshFile () {
        this.showType = null
        this.fileUrl = null
        this.imageUrl = null
        setTimeout(() => {
          this.$nextTick(() => {
            this.loadFileContent(true)
          })
        }, 300)
      }
    },
    mounted () {
    }
  }
</script>

<style lang="less" scoped>
  .file-preview {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    flex-direction: column;
    background-color: rgba(255,255,255,.9);
    z-index: 999999;
  }

  .header {
    height: 60px;
    line-height: 60px;
    box-shadow: 0 5px 5px rgba(0,0,0,.3);
    border-bottom: solid 1px #eeeeee;
    background-color: #fff;
    .toolbar {
      float: left;
      margin-left: 20px;
    }
    .file-name {
      width: 1000px;
      margin: 0 auto;
      font-weight: bold;
      text-align: center;
    }
    .close {
      @size: 40px;
      float: right;
      margin-top: 10px;
      margin-right: 20px;
      font-size: 18px;
      width: @size;
      height: @size;
      line-height: @size;
      text-align: center;
      background-color: #d2452d;
      color: #fff;
      border-radius: 100%;
      cursor: pointer;
      &:active {
        background-color: #af3a26;
      }
    }
  }

  .content {
    flex: 1;
    position: relative;
    width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 10px 10px rgba(0,0,0,.5);
    padding: 10px 40px !important;

    .loading-message {
      margin-top: 50px;
      font-size: 12px;
      color: #818181;
      text-align: center;
    }

    .content-body {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 20px 30px;
      iframe {
        width: 100%;
        height: 99%;
        border: none;
      }
      .image-wrapper {
        position: absolute;
        left: 30px;
        right: 30px;
        top: 10px;
        bottom: 10px;
        text-align: center;
        color: #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          border: none;
          outline: 0;
        }
      }
    }
  }
</style>
