import AdCustomer from '../../pages/ad/customer'
import AdCustomerPayOrder from '../../pages/ad/pay-order'
import { createWorkflowDetailRouters } from './workflow'

/**
 * 待办事项的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'AdCustomer',
    path: '/ad/customer',
    meta: { title: '广告客户管理' },
    component: AdCustomer,
    children: [
      createWorkflowDetailRouters('flowId', 'Customer')
    ]
  },
  {
    name: 'AdCustomerPayOrder',
    path: '/ad/pay_order',
    meta: { title: '广告客户支付' },
    component: AdCustomerPayOrder
  }
]
