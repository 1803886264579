<template>
  <modal-panel
    :visible.sync="panelVisible"
    :saveButtonLoading="submitting"
    :closable="panelCloseable"
    :title=title
    :width="650"
    @close="panelBeforeClose"
    @save="submit"
  >
    <el-form ref="form" :model="form" status-icon label-width="100px">
      <el-form-item label="模板名称" prop="name" class="app_required-input">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <div>
      <split-line text="属性" align="center"></split-line>
      <el-button icon="el-icon-plus"  type="primary"  @click="$refs.propEditor.open()">增加属性</el-button>

      <div class="props-template-list" v-if="propsOnSorted.length > 0">
        <div
            v-for="r in propsOnSorted" :key="r.groups"
            class="props-group"
        >
          <div class="group-name">{{r.name}}</div>
          <div class="props-detail">
            <Draggable v-model="r.props"
                       :loading="false"
                       @start="dragging = true"
                       @end="handleMoveProp"
                       class="list-group"
                       ghost-class="ghost">
              <transition-group>
                <div v-for="p in r.props" :key="p.vueKey" class="prop-item" :class="{'prop-delete': p.handleStatus === 'delete'}">
                  <div class="item-block draggable-style" style="width: 30px;">
                    <div class="prop-status" :class="'status-' + p.handleStatus"></div>
                  </div>
                  <div class="item-block draggable-style" style="width: 70px;">
                    <span v-show="p.handleStatus === 'delete'">
                      <el-button size="mini" @click.stop="p.handleStatus = 'modify'">恢复</el-button>
                    </span>
                    <span v-show="p.handleStatus !== 'delete'">
                      <el-button size="mini" type="danger" icon="el-icon-delete" @click.stop="handleDeleteProp(p)"></el-button>
                      <el-button size="mini" icon="el-icon-edit" @click.stop="$refs.propEditor.open(p)"></el-button>
                    </span>
                  </div>
                  <div class="item-block draggable-style">{{p.label}}</div>
                  <div class="item-block draggable-style" style="width: 70px;">{{propTypesContent[p.type]}}</div>
                  <div class="item-block draggable-style" style="flex: 1;">{{p.typeData}}</div>
                </div>
              </transition-group>
            </Draggable>
          </div>
        </div>
      </div>
      <prop-editor @onSave="handlePropSave" ref="propEditor"></prop-editor>
    </div>
  </modal-panel>

</template>

<script>

  /*
    propTemplates数组中的元素会自定义一个handleStatus属性，表示该元素处于哪种状态。
    由于属性的编辑是在本地编辑完所有再一次提交给服务端处理，所以需要一个状态来区分这三种状态的元素如何处理。
      new：新增
      delete：删除
      modify：已经存在，需要更新
   */

  import { addProfileTemplate, updateProfileTemplate, getProfileTemplatePropsList, getProfileTemplatePropsListById } from '../../../../http/api/profile-template'
  import kit from '../../../../tools/kit'
  import Draggable from 'vuedraggable'
  import propTypes, { PropTypeMapping } from '@/pages/profile/type-constants'
  import PropEditor from '../../comp/prop-editor'
  import PropsSortMixins from '../../props-sort-mixins'

  export default {
    mixins: [PropsSortMixins],
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    components: {
      Draggable, PropEditor
    },
    data () {
      return {
        title: '添加模板',
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        type: null,
        model: null, // 更新操作时的数据模型
        form: {
          id: null,
          name: null
        },
        propTypesContent: propTypes,
        propTypeConstants: PropTypeMapping
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.model = model
        this.panelVisible = true
        if (this.isUpdate) {
          kit.obj.getProperties(this.form, model)
          this.title = '编辑模板'
          // 查询模板子数据
          getProfileTemplatePropsList()
            .success(resp => {
              this.propTemplates = []
              for (let p of resp.data) {
                p.handleStatus = 'modify'
                this.propTemplates.push(p)
              }
            })
            .send(this.form.id)
        }
      },
      submit () {
        this.submitting = true
        this.panelCloseable = false
        let api
        if (this.isUpdate) {
          api = updateProfileTemplate
        } else {
          api = addProfileTemplate
        }
        let data = Object.assign({}, this.form)
        data.propsForInsert = []
        data.propsForModify = []
        data.propsForDelete = []
        for (let p of this.propTemplates) {
          if (p.handleStatus === 'delete') {
            data.propsForDelete.push(p)
          } else if (p.handleStatus === 'modify') {
            data.propsForModify.push(p)
          } else if (p.handleStatus === 'new') {
            data.propsForInsert.push(p)
          }
        }
        api()
          .complete(() => {
            this.panelCloseable = true
            this.submitting = false
          })
          .success(resp => {
            this.$emit('handleSaved', resp.data)
            this.panelVisible = false
            this.$message.success('保存成功。')
          })
          .send(data)
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.form.id = null
        this.model = null
        this.propTemplates = []
      },
      handleMoveProp () {
        for (let group of this.propsOnSorted) {
          let i = 0
          for (let prop of group.props) {
            prop.position = i++
          }
        }
      },
      handleDeleteProp (prop) {
        // 判断是否被应用,新增就不需要判断了
        let doDelete = p => {
          if (p.handleStatus === 'new') {
            kit.arr.remove(this.propTemplates, p, 'vueKey')
          } else {
            p.handleStatus = 'delete'
          }
        }
        if (this.isUpdate) {
          getProfileTemplatePropsListById()
            .success(resp => {
              if (resp.data) {
                this.$alert('该属性已经被文档引用，无法删除。', { type: 'warning' })
              } else {
                doDelete(prop)
              }
            })
            .send(prop.id)
        } else {
          doDelete(prop)
        }
      },
      handlePropSave (prop) {
        if (!prop.id) {
          prop.id = kit.str.id(32) // 给一个临时的id，否则无法进行编辑操作
          prop.handleStatus = 'new'
          this.propTemplates.push(prop)
        } else {
          kit.arr.pushOrReplace(this.propTemplates, prop)
        }
      }
    }
  }
</script>

<style lang="less" scoped>

  .props-template-list {
    margin: 10px 0;
  }
  .props-group + .props-group {
    margin-top: 20px;
  }
  .props-group {
    border: solid 1px #e9e9e9;
    .group-name {
      font-size: 14px;
      font-weight: bold;
      padding: 5px 10px;
      border-bottom: solid 1px #e9e9e9;
      background-color: #f2f2f2;
    }
    .props-detail {
      padding: 5px 0;
    }
    .prop-item {
      display: flex;
      padding: 2px 10px;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      color: #3b3b1f;
      &:hover {
        background-color: #f9f9f9;
      }
      &.prop-delete {
        background-color: #ffdfdf;
      }
    }
    .prop-item + .prop-item {
      margin-top: 5px;
    }
    .prop-status {
      display: inline-block;
      @size: 10px;
      width: @size;
      height: @size;
      border-radius: @size;
      margin-top: 7px;
      margin-left: 5px;
      &.status-new {
        background-color: #29801f;
      }
      &.status-modify {
        background-color: #305e8a;
      }
      &.status-delete {
        background-color: #ac3030;
      }
    }
  }

  .draggable-style {
    width: 28%;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    cursor:pointer;
  }
</style>
