<template>
  <modal-panel
    :visible.sync="panelVisible"
    :saveButtonLoading="submitting"
    :closable="panelCloseable"
    :title=title
    @close="panelBeforeClose"
    @save="submit"
  >

    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="名称" prop="platform" class="app_required-input">
        <el-input v-model="form.name" maxLength='40' @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item label="位置" prop="platform" class="app_required-input">
        <el-input-number  :min="1" :max="100" v-model="form.position" @keyup.enter.native="submit"></el-input-number>
      </el-form-item>
    </el-form>
  </modal-panel>

</template>

<script>
  import { addProfileCategory, updateProfileCategory } from '../../../../http/api/profile-category'
  import kit from '../../../../tools/kit'

  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    data () {
      return {
        title: '添加档案分类',
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        form: {
          id: null,
          name: null,
          position: 0
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          this.title = '编辑档案分类'
          kit.obj.getProperties(this.form, model)
        }
      },
      submit () {
        this.submitting = true
        this.panelCloseable = false
        let api
        if (this.isUpdate) {
          api = updateProfileCategory
        } else {
          api = addProfileCategory
        }
        api()
          .complete(() => {
            this.panelCloseable = true
            this.submitting = false
          })
          .success(resp => {
            this.$message.success('保存成功。')
            this.$emit('handleCategorySaved', resp.data, this.isUpdate)
            this.panelVisible = false
          })
          .send(this.form)
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
        this.form.id = null
        this.form.name = null
        this.form.position = 0
      }
    }
  }
</script>

<style scoped>

</style>
