import { userStore } from '../store'
import { HOME_PAGE } from '../router'

export default function (router) {
  router.beforeEach((to, from, next) => {
    let isLogin = userStore.get('isLogin')
    if (to.name === 'Login' && isLogin) {
      return next({ name: HOME_PAGE })
    }
    if (to.meta.auth === false) {
      return next()
    }
    if (isLogin) {
      next()
    } else {
      next({ name: 'Login', query: { from: to.fullPath } })
    }
  })
  return router
}
