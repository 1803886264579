<template>
  <page route-name="AdsConsumption">

    <!--工具按钮-->
    <el-button slot="toolbar" icon="el-icon-download" @click="downloadReport">下载报表数据</el-button>
    <el-button slot="toolbar" icon="el-icon-refresh" type="primary" @click="load">查询</el-button>
    <div slot="top">

      <el-form :inline="true" :model="query" style="margin: 5px 10px;">
        <el-form-item label="广告账户">
          <el-input style="width: 150px;" clearable size="mini"  @blur="query.adAccountId = query.adAccountId.trim()" v-model="query.adAccountId" ></el-input>
        </el-form-item>
        <el-form-item label="报表日期">
          <el-date-picker size="mini"
                          v-model="query.reportDate"
                          type="daterange"
                          align="right"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="广告平台">
          <el-select v-model="query.platform" size="mini" clearable placeholder="请选择" style="width: 150px;">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>


    <el-table
      slot="center"
      :data="dataList"
      stripe
    >
      <el-table-column prop="reportDate" label="日期"　width="150" sortable ></el-table-column>
      <el-table-column prop="cost" label="花费" width="150"></el-table-column>
      <el-table-column prop="platform" label="平台" width="100"></el-table-column>
      <el-table-column prop="currency" label="货币" width="100"></el-table-column>
      <el-table-column prop="adAccountId" label="广告账户"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>

  import api, {
    API_ADS_ACCOUNT_CONSUMPTION_PERFORMANCES,
    API_ADS_PERFORMANCE_REPORT_DOWNLOAD
  } from '../../../config/urls'
  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        query: {
          adAccountId: '',
          reportDate: null,
          platform: ''
        },
        options: [{
          value: 'bing',
          label: 'Bing Platform'
        }, {
          value: 'google',
          label: 'Google Platform'
        }]
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        this.$pageLoading(true)
        this.$http.load(api(API_ADS_ACCOUNT_CONSUMPTION_PERFORMANCES), {
          params: {
            start: this.page.start,
            limit: this.page.limit,
            adAccountId: this.query.adAccountId,
            reportDateStart: this.query.reportDate === null ? null : this.query.reportDate[0],
            reportDateEnd: this.query.reportDate === null ? null : this.query.reportDate[1],
            platform: this.query.platform,
          },
          completeBefore: () => this.$pageLoading(false),
          success: resp => {
            this.dataList = resp['data']
            this.page.total = resp['total']
          }
        })
      },
      downloadReport () {
        if (this.query.reportDate && this.query.reportDate.length === 2) {
          this.$pageLoading(true)
          this.$http.post(api(API_ADS_PERFORMANCE_REPORT_DOWNLOAD), {
            timeout: 1000 * 60 * 15,
            params: {
              startDate: this.query.reportDate[0],
              endDate: this.query.reportDate[1]
            },
            completeBefore: () => this.$pageLoading(false),
            success: () => {
              this.$alert('下载完成，可以在【广告账户下载异常】菜单查看下载是否有错误。', {type: 'success'})
            }
          })
        } else {
          this.$alert('请选择日期范围。', {type: 'warning'})
        }
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style scoped>

</style>
