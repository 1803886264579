<template>
  <div class="folder-panel" v-loading="loading">
    <div class="header">
      <i class="fa fa-folder-open-o"></i>
      我的流程收藏
      <div class="header-toolbar">
        <i class="header-icon-button el-icon-plus" @click="onAddRootNodeClick"></i>
      </div>
    </div>
    <div class="body">
      <el-tree
        ref="tree"
        class="folder-tree"
        empty-text="没有收藏夹"
        node-key="id"
        :data="treeData"
        :indent="10"
        :props="{label: 'title'}"
        :expand-on-click-node="false"
        @node-click="nodeClick">
        <div class="tree-node-item font-small"
             style="display: flex; flex: 1; line-height: 26px; overflow-x: hidden;margin-left: 30px;"
             slot-scope="{ node }"
        >
          <div style="flex: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
            <i class="fa" :class="{'fa-folder': !node.expanded, 'fa-folder-open': node.expanded}"></i>
            {{ node.label }}
          </div>
          <div class="tree-node-buttons">
            <i class="el-icon-delete" @click.stop="onDeleteNodeClick(node)"></i>
            <i class="el-icon-edit" @click.stop="onUpdateNodeNameClick(node)"></i>
          </div>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<script>
  import kit from '../../../../tools/kit'
  import api, {
    API_WORKFLOW_DIR_ADD,
    API_WORKFLOW_DIR_UPDATE,
    API_WORKFLOW_DIR_DELETE,
    API_WORKFLOW_DIR_LIST_BY_USER
  } from '@/config/urls'

  export default {
    data () {
      return {
        loading: false,
        treeData: []
      }
    },
    computed: {
    },
    methods: {
      loadTree () {
        // todo workflow favorite load
        // this.loading=true
        // this.$http.get(api(API_WORKFLOW_DIR_LIST_BY_USER), {
        //   completeBefore: () => this.loading=false,
        //   success: resp => (this.treeData = resp)
        // })
      },
      // 选中和取消选中节点
      nodeClick (a, node) {
         this.$router.push({ name: 'FavoriteFlows', params: {dirId: node.key } })
      },
      onAddRootNodeClick () {
        this.$prompt('请输入流程收藏夹名称', '提示', {
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          inputValidator: val => kit.str.isBlank(val) ? '请输入流程收藏夹名称。' : undefined
        }).then(({ value }) => {
          this.addNode(value)
        }).catch(() => {})
      },
      addNode (name) {
        this.$http.post(api(API_WORKFLOW_DIR_ADD), {
          dataType: 'form',
          data: {
            title: name,
          },
          success: (resp) => {
            this.$refs['tree'].append(resp)
          }
        })
      },
      onUpdateNodeNameClick (node) {
        this.$prompt('请输入流程收藏夹名称', '提示', {
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          inputValue: node.data.title,
          inputValidator: val => kit.str.isBlank(val) ? '请输入流程收藏夹名称。' : undefined
        }).then(({ value }) => {
          this.$http.put(api(API_WORKFLOW_DIR_UPDATE, { id: node.key }), {
            dataType: 'form',
            data: {
              title: value,
            },
            success: (resp) => {
              node.data.title = value
            }
          })
        }).catch(() => {})
      },
      onDeleteNodeClick (node) {
        this.$confirm(`确定要删除[${node.data.title}]吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.del(api(API_WORKFLOW_DIR_DELETE, { id: node.key }), {
            success: () => {
              this.$refs['tree'].remove(node)
            }
          })

        }).catch(() => {})
      }
    },
    mounted ()  {
      this.loadTree()
    }
  }
</script>

<style lang="less" scoped>

  .folder-panel {
    display: flex;
    flex-direction: column;
  }

  .header {
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    color: #6c6c6c;
    font-weight: bold;
    font-size: 14px;
    background-color: #f9f9f9;
    border-bottom: solid 1px #e8e8e8;
    .header-toolbar {
      float: right;
      height: inherit;
      line-height: inherit;
    }
    .header-icon-button {
      padding: 3px;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: #f9f9f9;
        border-radius: 3px;
      }
      &:active {
        background-color: #f9f9f9;
      }
    }
  }

  .body {
    flex: 1;
  }

  @deep: ~'>>>';

  .folder-tree @{deep} .el-tree-node__content {
    box-sizing: border-box !important;
    background-color: #f9f9f9;
  }
  .folder-tree @{deep} .el-tree-node__expand-icon {
    box-sizing: border-box !important;
  }

  .folder-tree @{deep} .tree-node-item:hover .tree-node-buttons {
    visibility: visible;
  }

  .folder-tree @{deep} .tree-node-buttons {
    visibility: hidden;
    padding-right: 10px;
    i + i {
      margin-left: 2px;
    }
    i {
      width: auto !important;
      padding: 3px 0;
      font-size: 12px;
      border-radius: 3px;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
</style>
