import defineApi from '../define-api'
import http, { getAdminUrl } from '../application-api'

export const getApiList = defineApi((config, params) => {
  config.params = params
  return http.get('/bg_order/list/from_banggu', config)
})

export const getList = defineApi((config, params) => {
  config.params = params
  return http.load('/bg_order/list', config)
})

export const getPendingList = defineApi((config, ids) => {
  config.data = ids
  return http.post('/bg_order/select_process_pending', config)
})
export const getProcessingList = defineApi((config, params) => {
  config.params = params
  return http.get('/bg_order/processing_list', config)
})
export const downloadProcessingListToExcel = params => {
  let arr = []
  for (let key of Object.keys(params)) {
    if (params[key]) {
      arr.push(key + '=' + params[key])
    }
  }
  return getAdminUrl('/bg_order/processing_list/excel/download?' + arr.join('&'))
}
export const getSuccessList = defineApi((config, params) => {
  config.params = params
  return http.get('/bg_order/success_list', config)
})

export const releaseOrder = defineApi((config, ids) => {
  config.data = ids
  return http.post('/bg_order/release_order', config)
})

export const updateOrder = defineApi((config, form) => {
  config.data = form
  return http.post('/bg_order/update_order', config)
})

export const uploadImg = defineApi((config, id, file) => {
  let forms = new FormData()
  forms.append('file', file)
  forms.append('id', id)
  config.data = forms
  return http.post('/bg_order/upload_img', config)
})

export const uploadImgFromScreenshot = defineApi((config, id, file, screenshotType, account, accountName) => {
  let forms = new FormData()
  forms.append('file', file)
  forms.append('id', id)
  forms.append('screenshotType', screenshotType)
  forms.append('account', account || '')
  forms.append('accountName', accountName || '')
  config.data = forms
  return http.post('/bg_order/upload_img/ocr', config)
})

export const updateFromZip = defineApi((config, file, form) => {
  let forms = new FormData()
  forms.append('zip', file)
  forms.append('screenshotType', form.screenshotType)
  forms.append('payeeAccount', form.payeeAccount)
  forms.append('payeeName', form.payeeName)
  config.data = forms
  return http.post('/bg_order/update/from_zip', config)
})

export const sendOrderBangList = defineApi((config, ids) => {
  config.data = ids
  return http.post('/bg_order/submit_to_banggu', config)
})

export const updateTransferStatus = defineApi((config, id, status) => {
  config.params = {
    id: id,
    transfer: status
  }
  http.post('/bg_order/transfer_status/mark', config)
})

export const getProcessUsers = defineApi((config) => {
  http.load('/bg_order/process_users', config)
})

