export const TEXT = '0'
export const TEXT_MULTI = '1'
export const ENUM = '2'

export const PropTypeMapping = {
  text: TEXT,
  textMulti: TEXT_MULTI,
  enum: ENUM
}

export default {
  [TEXT]: '文本',
  [TEXT_MULTI]: '多行文本',
  [ENUM]: '枚举'
}
