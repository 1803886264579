import defineApi from '../define-api'
import http from '../application-api'

export const getRefundReportList = defineApi((config, params) => {
  config.params = params
  return http.load('/refund_report/list', config)
})

export const getDetail = defineApi((config, refundTransferId) => {
  config.params = {
    refundTransferId: refundTransferId
  }
  http.get('/refund_report/detail', config)
})

export const getTransferRechargeReportList = defineApi((config, params) => {
  config.params = params
  return http.load('/transfer_recharge_report/list', config)
})

export const getRebateReportList = defineApi((config, params) => {
  config.params = params
  return http.load('/rebate_report/list', config)
})
