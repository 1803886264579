<template>
  <div>
    <div v-if="fileItems" style="margin-top: 10px;">
      <div v-for="item in fileItems" :key="item.id" class="render-item">
        <div class="file-name">{{item.fileName}}</div>
        <div v-if="item.fileRender.fileContentRender.renderType === 'table'">
          <el-table border :data="item.fileRender.fileContentRender.data" style="width: 100%" height="350">
            <el-table-column :key="h.prop"
                             v-for="h in item.fileRender.fileContentRender.headers"
                             :prop="h.prop"
                             :label="h.label"
            >
              <template slot-scope="{ row }">
                <span :style="row[h.prop].style">{{row[h.prop].value}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      fileItems: { type: Array }
    },
    data () {
      return {}
    }
  }
</script>

<style lang="less" scoped>
.render-item + .render-item {
  margin-top: 20px;
}
.file-name {
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
  font-weight: bold;
}
</style>
