/**
 * 流程的一些常量定义。
 */

// 流程ID
export const FLOW_AD_POLICY = 'AdPolicy' // 广告合作政策
export const FLOW_SIGN_CONTRACT = 'SignContract' // 广告客户新签
export const FLOW_ORDER_POLICY = 'OrderPolicy' // 广告下单政策
export const FLOW_RECHARGE = 'Recharge' // 广告客户充值续费
export const FLOW_ABSENT = 'Absent' // 请假
export const FLOW_REIMBURSEMENT = 'Reimbursement' // 报销
export const FLOW_OFFICE_STATIONERY = 'OfficeStationery' // 办公用品申请
export const FLOW_MATERIAL_POLICY = 'MaterialPolicy' // 素材业务，合作政策
export const FLOW_MATERIAL_CONTRACT = 'MaterialContract' // 素材业务，签约合同
export const FLOW_RECEIPT = 'Receipt' // 收款流程
export const FLOW_UP_PAY = 'UpPay' // 上游付款
export const FLOW_OPEN_ACCOUNT = 'OpenAccount' // 开户
export const FLOW_REFUND = 'Refund' // 退款
export const FLOW_BALANCE_TRANSFER = 'BalanceTransfer' // 余额转移
export const FLOW_REBATE = 'Rebate' // 客户申请返点

export const FLOW_NAMES_MAPPING = {
  [FLOW_AD_POLICY]: '申请客户合作政策',
  [FLOW_SIGN_CONTRACT]: '合同/服务单',
  [FLOW_ORDER_POLICY]: '下单政策',
  [FLOW_RECHARGE]: '申请充值续费',
  [FLOW_ABSENT]: '请假',
  [FLOW_REIMBURSEMENT]: '报销',
  [FLOW_OFFICE_STATIONERY]: '办公用品申请',
  [FLOW_MATERIAL_POLICY]: '素材合作政策',
  [FLOW_MATERIAL_CONTRACT]: '素材签约合同',
  [FLOW_RECEIPT]: '收款',
  [FLOW_UP_PAY]: '申请上游付款',
  [FLOW_OPEN_ACCOUNT]: '申请媒体开户',
  [FLOW_REFUND]: '退款',
  [FLOW_BALANCE_TRANSFER]: '余额转移',
  [FLOW_REBATE]: '客户申请返点'
}
