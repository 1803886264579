import Elasticsearch from '../../pages/manager/es/elasticsearch'
import IkWord from '../../pages/manager/es/ik-word'


export default [
  {
    name: 'Elasticsearch',
    path: '/es_index',
    meta: { title: 'Elasticsearch索引管理' },
    component: Elasticsearch,
    children: [
      {
        name: 'IkWord',
        path: 'ik_word',
        meta: { title: 'IK分词' },
        component: IkWord
      }
    ]
  }
]
