<template>
  <page route-name="ProfileTemplate">

    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-input placeholder="模板名称查询" clearable v-model="query.name" style="width: 300px;">
          <el-button slot="append" icon="el-icon-search" @click="load()"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus"  type="primary" @click="$refs['profileTemplate'].open()">添加模板</el-button>
      </el-form-item>
    </el-form>

    <el-table
      slot="center"
      stripe
      :data="dataList">
      <el-table-column label="操作" width="110">
        <template slot-scope="scope">
          <el-button size="mini" @click="updateData(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteData(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="createName" label="创建人"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="140"></el-table-column>
    </el-table>
    <!--编辑新增-->
    <profile-template ref="profileTemplate" @handleSaved="handleSaved"></profile-template>
  </page>
</template>

<script>
  import kit from '../../../tools/kit'
  import { getProfileTemplateList, deleteProfileTemplate } from '../../../http/api/profile-template'
  import ProfileTemplate from './comp/profile-template-editor'
  export default {
    components: { ProfileTemplate },
    data () {
      return {
        dataList: [],
        query: {
          name: null
        }
      }
    },
    computed: {},
    methods: {
      load () {
        getProfileTemplateList()
          .success(resp => {
            this.dataList = resp.data
          })
          .send(this.query.name)
      },
      handleSaved (data) {
        kit.arr.pushOrReplace(this.dataList, data)
      },
      updateData (data) {
        this.$refs['profileTemplate'].open(data)
      },
      deleteData (data) {
        this.$confirm(`确定要删除[${data.name}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            deleteProfileTemplate()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.remove(this.dataList, data)
              })
              .send(data.id)
          })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
