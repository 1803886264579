<template>
  <el-dialog title="增加属性" :visible.sync="dialogVisible" @close="handleClose" :modal-append-to-body='false'>
    <el-form :model="prop" ref="form">
      <el-form-item label="属性名称" :label-width="formLabelWidth" prop="label" class="app_required-input">
        <el-input  maxlength="20" v-model="prop.label"></el-input>
      </el-form-item>
      <el-form-item label="属性类型" :label-width="formLabelWidth" prop="type" class="app_required-input">
        <el-select v-model="prop.type" placeholder="请选择属性类型" style="width: 100%;" :disabled="!!prop.id">
          <el-option label="文本" :value="propTypeMapping.text"></el-option>
          <el-option label="多行文本" :value="propTypeMapping.textMulti"></el-option>
          <el-option label="枚举列表" :value="propTypeMapping.enum"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="属性组别" :label-width="formLabelWidth" prop="groups">
        <el-input maxlength="20" v-model="prop.groups" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="位置" :label-width="formLabelWidth" prop="position">
        <el-input type='number' v-model="prop.position"></el-input>
      </el-form-item>
      <el-form-item label="附加选项" :label-width="formLabelWidth" v-if="prop.type === propTypeMapping.enum">
        <el-tag
            :key="tag"
            v-for="tag in tags"
            closable
            :disable-transitions="false"
            @close="handleRemoveTag(tag)">
          {{tag}}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-show="tagInputVisible"
            v-model="tagInputValue"
            ref="saveTagInput"
            size="small"
            style="width: 120px; margin-left: 10px;"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <el-button class="button-new-tag" size="small" @click="showTagInput" style="margin-left: 10px;">添加选项</el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import kit from '../../../tools/kit'
  import { PropTypeMapping } from '@/pages/profile/type-constants'

  export default {
    data () {
      return {
        dialogVisible: false,
        prop: {
          id: null,
          label: null,
          type: null,
          typeData: null,
          groups: null,
          position: 0
        },
        formLabelWidth: '80',
        tagInputVisible: false,
        tagInputValue: null,
        tags: [],
        propTypeMapping: PropTypeMapping
      }
    },
    methods: {
      open (prop) {
        if (prop) {
          kit.obj.getProperties(this.prop, prop)
          if (prop.typeData) {
            this.tags = prop.typeData.split(',')
          }
        } else {
          this.prop.type = PropTypeMapping.text
        }
        this.dialogVisible = true
      },
      showTagInput () {
        this.tagInputVisible = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },
      handleInputConfirm () {
        let inputValue = this.tagInputValue
        if (inputValue && !this.tags.includes(inputValue)) {
          this.tags.push(inputValue)
        }
        this.tagInputVisible = false
        this.tagInputValue = null
      },
      handleRemoveTag (tag) {
        kit.arr.removeItem(this.tags, tag)
      },
      handleClose () {
        this.$refs.form.resetFields()
        this.prop.id = null
        this.tags = []
        this.prop.typeData = null
      },
      handleOk () {
        let data = Object.assign({}, this.prop)
        if (this.tags && this.tags.length > 0) {
          data.typeData = this.tags.join(',')
          data.typeDataArray = [...this.tags]
        } else {
          data.typeDataArray = []
        }
        this.$emit('onSave', data)
        this.dialogVisible = false
      }
    }
  }
</script>

<style scoped>
</style>
