import defineApi from '../define-api'
import http from '../application-api'

export const getProfileCategoryList = defineApi((config, param) => {
  config.params = { name: param.name }
  return http.load('/profile_category/list', config)
})

export const addProfileCategory = defineApi((config, info) => {
  config.data = info
  http.post('/profile_category/add', config)
})

export const updateProfileCategory = defineApi((config, info) => {
  config.data = info
  http.post('/profile_category/update', config)
})

export const deleteProfileCategory = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/profile_category/delete', config)
})
export const updateProfileCategoryPosition = defineApi((config, data) => {
  config.params = { data: data }
  http.post('/profile_category/delete', config)
})
