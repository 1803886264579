/**
 * 全局事件分发工具，此工具需要内置到vue组件，在组件内部可直接调用。
 *
 * 组件A
 * -----------------------
 * this.$dispatcher.dispatch('eventName', obj)
 *
 * 组件B
 * -----------------------
 * mounted () {
 *   this.$dispatcher.listen('eventName', 'listenerId', data => {
 *
 *   })
 * }
 */
class Dispatcher {
  constructor () {
    /*
     * {
     *   [name]: {
     *     [listenerId]: Function
     *   }
     * }
     */
    this.listener = {}
  }

  /**
   * 广播发送事件。
   * @param name {String} 事件名称。
   * @param data
   */
  dispatch (name, data) {
    let listeners = this.listener[name] || {}
    for (let id of Object.keys(listeners)) {
      if (typeof listeners[id] === 'function') {
        listeners[id](data)
      }
    }
  }

  /**
   * 监听事件。
   * @param name {String} 事件名称。
   * @param listenerId {String} 监听者标识。
   * @param callback {Function}
   */
  listen (name, listenerId, callback) {
    let listeners = this.listener[name] || {}
    listeners[listenerId] = callback
    this.listener[name] = listeners
  }

  /**
   * 移除监听器。
   * @param name {String} 如果listenerId不提供，则name表示listenerId，并移除listenerId的所有事件。
   * @param [listenerId] 如果提供，表示只移除listenerId的name事件。
   */
  removeListen (name, listenerId) {
    if (listenerId === undefined) {
      listenerId = name
      for (let key of Object.keys(this.listener)) {
        (this.listener[key] || {})[listenerId] = undefined
      }
    } else {
      (this.listener[name] || {})[listenerId] = undefined
    }
  }
}

export default {
  install (Vue) {
    let _instance = new Dispatcher()
    Object.defineProperties(Vue.prototype, {
      $dispatcher: {
        get () {
          return _instance
        }
      }
    })
  }
}
