<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="平台编辑"
    @save="onSave"
    @close="onClose"
    :width="800"
  >
    <el-form ref="form" :model="formData" label-width="150px">
      <el-form-item label="名称" prop="name"  class="app_required-input">
        <el-input v-model="formData.name" ></el-input>
      </el-form-item>
      <el-form-item label="排序值" prop="name"  class="app_required-input">
        <el-input-number v-model="formData.position"
                                           controls-position="right"
                                           style=" width:120px;" size="mini" :step="1" >
       </el-input-number>
      </el-form-item>
      <el-form-item label="区域" prop="area" class="app_required-input">
        <el-select v-model="formData.area" style="width: 100%;" >
          <el-option
            v-for="item in [{id: 1, label: '海外'}, {id: 2, label: '国内'}]"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否可用" prop="enabled" class="app_required-input">
        <el-radio-group v-model="formData.enabled">
          <el-radio :label=1>是</el-radio>
          <el-radio :label=0>否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="流程表单的关联数据" prop="flowFromData" >
        <div >
          <el-row >
            <el-col :span="12">流程Id(如充值流程Recharge)
            </el-col>
            <el-col :span="12" >平台(如流程配dependOnGroup.value的值）
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="12">
              <el-select v-model="flowFromData.id" @change="dependPlatformFlowChange" >
                <el-option
                  v-for="item in dependPlatformFlow"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="10" >
              <el-select v-model="flowFromData.value">
                <el-option
                  v-for="item in dependPlatformValues"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" @click="handleAddFlowFromData()">添加</el-button>
            </el-col>
          </el-row>
          <el-row  v-for="item in flowFromDataList" :key="item.id" style="margin-top: 5px;">
            <el-col :span="12">
              <el-input v-model="item.id" :disabled="true"></el-input>
            </el-col>
            <el-col :span="10" >
              <el-input v-model="item.value" :disabled="true"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button type="danger" icon="el-icon-delete" @click="handleDeleteFlowFromData(item)"></el-button>
            </el-col>
          </el-row>
        </div>

      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import {
    updateAdPlatform,
    addAdPlatform, getDependPlatformValue
  } from '../../../../http/api/ad-platform'
  import * as flows from '../../../../config/flows'
  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        loading: false,
        formData: {
          id: null,
          area: null,
          name: null,
          position: null,
          enabled: 1,
          flowFromDataList: []
        },
        flowFromData: {
          id: null,
          value: null
        },
        flowFromDataList: [],
        dependPlatformFlow: [ { id: flows.FLOW_RECHARGE, name: flows.FLOW_NAMES_MAPPING[flows.FLOW_RECHARGE] }, {id: flows.FLOW_OPEN_ACCOUNT, name: flows.FLOW_NAMES_MAPPING[flows.FLOW_OPEN_ACCOUNT] } ],
        dependPlatformValues: [],
        dependPlatformValueData: null
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    watch: {
    },
    methods: {
      open (model) {
        this.visible = true
        this.model = model
        if (model) {
          kit.obj.getProperties(this.formData, model)
          if (this.model.enabled == null) {
            this.formData.enabled = 0
          }
          if (model.flowFormData) {
            let flow = JSON.parse(model.flowFormData)
            for (const [key, value] of Object.entries(flow)) {
              let item = {}
              item.id = key
              item.value = value
              this.flowFromDataList.push(item)
            }
          }
        }
        getDependPlatformValue()
          .success(resp => {
          this.dependPlatformValueData = resp.data
        })
          .send()
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.formData.id = null
        this.flowFromData = {}
        this.flowFromDataList = []
        this.formData.flowFromDataList = []
      },
      onSave () {
        this.saveButtonLoading = true
        this.closable = false
        this.formData.flowFromDataList = this.flowFromDataList
        let api = this.isUpdate ? updateAdPlatform() : addAdPlatform()
        api
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.formData.id = resp.data.id
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data)
            this.visible = false
          })
          .send(this.formData)
      },
      handleAddFlowFromData () {
        if (this.flowFromData.id == null || this.flowFromData.value == null) {
          return this.$message.warning('请输入全部流程关联数据信息。')
        }
        let item = { ...this.flowFromData }
        kit.arr.pushOrReplace(this.flowFromDataList, item)
        this.flowFromData = {}
      },
      handleDeleteFlowFromData (item) {
        kit.arr.removeItem(this.flowFromDataList, item)
      },
      dependPlatformFlowChange (v) {
        this.dependPlatformValues = this.dependPlatformValueData[v]
      }
    }
  }
</script>

<style scoped>

</style>
