/*
  基于sockjs和stomp的消息协议，主要用于接收服务器的消息推送。\

  连接服务器：
  ---------------------------
  连接的时候需要带上用户ID，所以需要在用户登录后才能进行连接服务器，并在用户退出系统时关闭连接。
  建议监听用户的登录状态，判断登录状态来连接或关闭消息服务器。
  watch: {
    isLogin (v) {
      if (v) stomp.connect()
      else stomp.close()
    }
  }

  在组件中如何使用：
  ---------------------------
  import {MSG_TYPE_ALL} from 'stomp'
  export default {
    stomp: {
      [MSG_TYPE_ALL]: function (msg) {
        console.log(msg)
      }
    }
  }
  >> 请不要在stomp配置项中使用箭头函数，否则无法将this指向到组件本身。
 */
import Stomp from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { userStore } from '../store'
import { Message } from 'element-ui'
import Vue from 'vue'

// 消息类型，组件订阅消息时，需要指定订阅哪些消息
export const MSG_TYPE_ALL = '/*' // 所有的消息都需要收到
export const MSG_TYPE_MESSAGE = '/message' // 系统内部消息
export const MSG_TYPE_TODO = '/todo' // 待办事项
export const MSG_TYPE_WORKFLOW_STAGE_TO_NEXT = '/flow_stage_to_next' // 处理下个流程的提醒
export const MSG_TYPE_WECHAT_BIND_STATUS = '/wechat/bind/status' // 微信扫描二维码绑定账号的结果通知
export const MSG_TYPE_ELASTICSEARCH_FULL_INDEX = '/elasticsearch/full_index' // 微信扫描二维码绑定账号的结果通知
export const MSG_TYPE_BANGGU_ZIP_UPLOAD = '/banggu/upload/zip' // 棒谷订单截图压缩包上传处理通知
// 缓存订阅者
const SUBSCRIBERS = {
  [MSG_TYPE_ALL]: [],
  [MSG_TYPE_MESSAGE]: [],
  [MSG_TYPE_TODO]: [],
  [MSG_TYPE_WORKFLOW_STAGE_TO_NEXT]: [],
  [MSG_TYPE_WECHAT_BIND_STATUS]: [],
  [MSG_TYPE_ELASTICSEARCH_FULL_INDEX]: [],
  [MSG_TYPE_BANGGU_ZIP_UPLOAD]: []
}
// 收到消息后，将消息转发给所有订阅者
let receiveMessage = (type, msg) => {
  let data = null
  try { data = JSON.parse(msg.body) } catch (e) {}
  SUBSCRIBERS[type].forEach(cfg => {
    cfg.callback.call(cfg.context, data)
  })
  SUBSCRIBERS[MSG_TYPE_ALL].forEach(cfg => {
    cfg.callback.call(cfg.context, type, data)
  })
}

function createClient () {
  return Stomp.over(() => new SockJS(process.env.VUE_APP_WEB_SOCKET_URL))
}

let client = createClient()
const MAX_RETRY_COUNT = 999999
let stomp = {
  connected: false,
  retryCount: 0,
  connect () {
    if (!process.env.VUE_APP_USE_SOCKET || this.connected) return
    client.connect(
      { 'X-User-Token': userStore.state.token, 'X-User-ID': userStore.state.id },
      () => {
        this.connected = true
        for (let type of Object.keys(SUBSCRIBERS)) {
          if (type === MSG_TYPE_ALL) continue
          this.subscribeUser(type, (msg) => {
            receiveMessage(type, msg)
          })
        }
      },
      () => {
        this.connected = false
        if (this.retryCount++ >= MAX_RETRY_COUNT) return
        let sec = 60
        Message.warning(`消息服务连接断开，${sec}秒后重新连...`)
        setTimeout(() => this.connect(), sec * 1000)
      }
    )
  },
  close () {
    if (this.connected) {
      client.disconnect()
    }
    this.connected = false
  },
  send (path, message) {
    if (this.connected) {
      client.send(
        '/stomp' + path,
        {},
        typeof message === 'object' ? JSON.stringify(message) : message
      )
    }
  },
  subscribe (path, callback) {
    if (this.connected) {
      return client.subscribe(path, callback)
    }
  },
  subscribeUser (path, callback) {
    if (this.connected) {
      return client.subscribe('/user' + path, callback)
    }
  }
}

export default stomp
export const buildClient = createClient

Vue.use({
  install: () => {
    Vue.mixin({
      beforeCreate () {
        if (typeof this.$options.stomp === 'object') {
          for (let key of Object.keys(this.$options.stomp)) {
            let func = this.$options.stomp[key]
            if (typeof func === 'function') {
              let arr = SUBSCRIBERS[key]
              arr && arr.push({ context: this, callback: func })
            }
          }
        }
      },
      beforeDestroy () {
        if (typeof this.$options.stomp === 'object') {
          for (let key of Object.keys(SUBSCRIBERS)) {
            let index = 0
            for (let o of SUBSCRIBERS[key]) {
              if (o.context === this) {
                SUBSCRIBERS[key].splice(index, 1)
                break
              }
              index++
            }
          }
        }
      }
    })
  }
})
