<template>
  <page route-name="UpDownCostReport" :use-tcb-layout="false">
    <div class="page-bg">
      <el-tabs class="tabs">
        <el-tab-pane label="上游结算数据">
          <up-page></up-page>
        </el-tab-pane>
        <el-tab-pane label="下游结算数据">
          <down-page></down-page>
        </el-tab-pane>
        <el-tab-pane label="旧上下游结算数据">
          <up-down-page></up-down-page>
        </el-tab-pane>
      </el-tabs>
    </div>
  </page>
</template>

<script>
  import UpPage from './up-cost'
  import DownPage from './down-cost'
  import UpDownPage from './up-down-cost'
  export default {
    components: {
      UpPage, DownPage, UpDownPage
    },
    methods: {
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .page-bg{
    width:100%;
    height:100%;
    background-color: #f5f9fa;
  }
  .tabs {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-flow: column;
    background-color: #ffffff;
    border: solid 1px #becbcf;
    box-shadow: 0 1px 4px 0 rgba(205, 208, 209, 0.6);
  }
  .tabs @{deep} .el-tabs__nav-scroll {
    padding-left: 35px !important;
    background-color: #fafafa;
  }
  .tabs @{deep} .el-tabs__item {
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #999999;
  }
  .tabs @{deep} .is-active {
    color: #0486fe;
  }
  .tabs @{deep} .el-tabs__content {
    flex: 1;
    position: relative;
  }
  .tabs @{deep} .el-tab-pane {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
</style>
