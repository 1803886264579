/**
 * 富文本编辑器。
 *
 * Usage：
 * var RichEditor = ...;
 * var editor = new RichEditor(domId, opt);
 *
 * opt：
 *   readOnly: {boolean} 默认false。
 *   content: {String} 编辑内容。
 *   onReady: {function} 编辑器初始化完成。
 *   onDestroy: {function} 编辑器销毁后。
 *
 * @author jiangxingshang
 * @date 17/4/10
 */

import loadScript from 'scriptjs'
import { descriptionImageUploadUrl } from '../http/api/description-image'

export default class RichEditor {
  static _readyListeners = {}
  constructor (id, opt) {
    opt = Object.assign({
      readOnly: false,
      content: '',
      onReady: null,
      onDestroy: null
    }, opt)

    this.id = id
    this.ready = false

    loadScript('/script/ckeditor/ckeditor.js', () => {
      let EDITOR = window.CKEDITOR
      EDITOR.once('instanceReady', e => {
        this.ready = true
        this.setReadOnly(opt.readOnly)
        e.editor.once('destroy', function () {
          opt.onDestroy && opt.onDestroy()
        })
        opt.content && this.setContent(opt.content)
        opt.onReady && opt.onReady(e.editor)
        RichEditor._readyListeners[id] && RichEditor._readyListeners[id](this)
        delete RichEditor._readyListeners[id]
      })
      EDITOR.replace(id, {
        customConfig: '/script/ckeditor-extend/config.js',
        imageUploadUrl: descriptionImageUploadUrl() // 拖拽上传图片
      })
    })
  }
  static onReady (editorId, callback) {
    RichEditor._readyListeners[editorId] = callback
  }
  _proxy (method, args) {
    if (this.ready) {
      return this.get()[method].apply(this.get(), args)
    }
  }
  get () {
    return window.CKEDITOR.instances[this.id]
  }
  isReady () {
    return this.ready
  }
  getContent () {
    return this._proxy('getData')
  }
  setContent (content) {
    this._proxy('setData', [content])
  }
  insertContent (html) {
    this._proxy('insertHtml', [html])
  }
  setReadOnly (isReadOnly) {
    this._proxy('setReadOnly', [isReadOnly])
  }
  destroy () {
    this._proxy('destroy')
  }
  on (name, callback) {
    this._proxy('on', [name, callback])
  }
}
