/*
 * 定义后台服务提供的API接口地址。
 *
 * 使用方式：
 * ---------------------------
 * import api, {API_1, API_2} from ...
 * let url1 = api(API_1)
 * let url2 = api(API_2, {id: '123'})
 *
 * API地址的命名方式：
 * ---------------------------
 * API_[BEAN_NAME]_[ACTION]
 * API_ 是固定前缀
 * BEAN_NAME 是实体名称或者模块名称
 * ACTION 是操作或操作+对象
 *
 * 例子：
 * ---------------------------
 * API_USERS 获取用户列表
 * API_USER_ADD 添加用户
 * API_USER_UPDATE 修改用户
 *
 * API地址的路径参数：
 * ---------------------------
 * 有些地址是需要动态参数，如：
 * let API_USER_UPDATE = '/user/{id}'
 * let url = api(API_USER_UPDATE, {id: '123'})
 * api函数的第二个参数可以填充路径中的动态参数。
 *
 * API地址的前缀：
 * ---------------------------
 * api函数已经为你的url添加了'/api'前缀，所以你定义的url无需再添加这个前缀，但必须是'/'开头。
 */
const API_PREFIX = '/api'

export default function (url, params) {
  url = API_PREFIX + url
  params = params || {}
  for (let key of Object.keys(params)) {
    let val = params[key]
    url = url.replace(`{${key}}`, val)
  }
  return url
}

export const URL_ADS_AUTH = '/ads_report/auth/{platform}'

export const API_ADS_REPORT_LIST = '/ads_report/list'
export const API_ADS_REPORT_ADD = '/ads_report'
export const API_ADS_REPORT_UPDATE = '/ads_report/{id}'
export const API_ADS_REPORT_DELETE = '/ads_report/{id}'
export const API_ADS_REPORT_GET_BY_ID = '/ads_report/{id}'
export const API_ADS_REPORT_BINDING_CUSTOMER = '/ads_report/{reportTemplateId}/customer/binding'
export const API_ADS_REPORT_CUSTOMERS = '/ads_report/{reportTemplateId}/customers'
export const API_ADS_REPORT_REPORTS = '/ads_report/{id}/report'
export const API_ADS_REPORT_AUTHENTICATED_ACCOUNTS = '/ads_report/authenticated/{platform}/accounts'
export const API_ADS_REPORT_AUTHENTICATED_ACCOUNT_AD_ACCOUNTS = '/ads_report/authenticated/{platform}/account/{id}'

export const API_ADS_REPORT_TEMPLATE_CUSTOMER_ADD = '/ads_report/customer'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_UPDATE = '/ads_report/customer/{id}'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_DELETE = '/ads_report/customer/{id}'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_LIST_BY_TEMPLATE_ID = '/ads_report/customers'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_ADD = '/ads_report/customer/ad'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_DELETE = '/ads_report/customer/ad/{id}'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_ADS = '/ads_report/customer/{customerId}/ad/list'
export const API_ADS_REPORT_ADS_ACCOUNT_BIND = '/ads_report/ads_account/bind'
export const API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_SET_ENABLED = '/ads_report/customer/ad/{id}/downloadenabled/{enabled}'

export const API_FORM_DICT_LIST = '/flow_form_dict/list'
export const API_FORM_DICT_ADD = '/flow_form_dict'
export const API_FORM_DICT_UPDATE = '/flow_form_dict/{id}'
export const API_FORM_DICT_DELETE = '/flow_form_dict/{id}'
export const API_FORM_DICT_ITEM_LIST_BY_DICT = '/flow_form_dict/{id}/items'
export const API_FORM_DICT_ENABLED_ITEM_LIST_BY_DICT = '/flow_form_dict/{id}/enabled_items'
export const API_FORM_DICT_ITEM_ADD = '/flow_form_dict/item'
export const API_FORM_DICT_ITEM_UPDATE = '/flow_form_dict/item/{id}'
export const API_FORM_DICT_ITEM_DELETE = '/flow_form_dict/item/{id}'

/* 上游渠道政策 */
export const API_AD_CHANNEL_INFOS = '/ad_channel_infos'
export const API_AD_CHANNEL_INFO = '/ad_channel_info'
export const API_AD_CHANNEL_INFO_UPDATE = '/ad_channel_info/{id}'
export const API_AD_CHANNEL_INFO_DELETE = '/ad_channel_info/{id}'

/* KPI评价管理 */
export const API_KPIS = '/kpis'
export const API_KPI_CUSTOMERS = '/kpi/customers' // 查询数据
export const API_KPI_CUSTOMER = '/kpi/customer' // 添加数据
export const API_KPI_CUSTOMER_DELETE = '/kpi/customer/{id}' // 删除数据
export const API_KPI_USERS = '/kpi/users' // 查询数据
export const API_KPI_USER = '/kpi/user' // 添加数据
export const API_KPI_USER_DELETE = '/kpi/user/{id}' // 删除数据
export const API_KPI_SELECT_USER = '/kpi/user/select' // 获取用户
export const API_KPI_CUSTOMERS_SELECT = '/kpi/customers/select' // kpi查询客户列表


/* 微信用户 */
export const API_WECHAT_USER_BIND_INFO = '/wechat_user/bind_info/get' // 获取微信绑定的二维码信息
export const API_WECHAT_USER_UN_BIND = '/wechat_user/unbind' // 解除微信绑定

/* 登录 */
export const API_LOGIN_WECHAT_QR_CODE = '/login/wechat/authorize' // 获取微信登录的信息，用于生成二维码

/* 绩效管理 */
export const API_AD_CUSTOMER_PLATFORMS = '/ad_customer_platforms' // 查询（销售客户表）数据
export const API_AD_CUSTOMER_PLATFORM = '/ad_customer_platform' // 添加（销售客户表）数据
export const API_AD_CUSTOMER_PLATFORM_UPDATE = '/ad_customer_platform_update' // 修改（销售客户表）数据
export const API_AD_CUSTOMER_PLATFORM_DATA = '/ad_customer_platform_data' // 添加数据时需要查询销售和客户的信息
export const API_AD_CUSTOMER_PLATFORM_DELETE = '/ad_customer_platform_delete/{id}'
// 添加客服
export const API_AD_CUSTOMER_PLATFORM_OWNER = '/ad_customer_platforms_owner' // 保存（客服客户）数据
export const API_AD_CUSTOMER_PLATFORM_SERVICE = '/ad_customer_platforms_service' // 查询当前客户绑定的客服
export const API_AD_CUSTOMER_PLATFORM_ACCOUNTS = '/ad_customer_platforms_accounts' // 查询（账户）数据
export const API_AD_CUSTOMER_PLATFORM_ACCOUNT = '/ad_customer_platforms_account/{adId}/{ownerId}/{userId}' // 解绑数据
export const API_AD_CUSTOMER_PLATFORM_OWNER_CHANGE = '/ad_customer_platforms_owner_change/{newUserId}/{platFormId}/{userId}' // 客服移交数据
export const API_AD_CUSTOMER_PLATFORM_ACCOUNT_FIND = '/ad_customer_platforms_account_find'
// 绩效查询

export const API_AD_CUSTOMER_PLATFORM_ACHIEVEMENT = '/ad_customer_platform/achievement' // 查询绩效

// 广告报表异常和已下载数据
export const API_ADS_ACCOUNT_CONSUMPTION_PERFORMANCES = '/ads_daily_performances'
export const API_ADS_PERFORMANCE_REPORT_DOWNLOAD = '/ads_daily_performance/report/download'
export const API_ADS_REPORT_DOWNLOAD_FAIL_LOGS = '/ads_report_download_fail_logs'
export const API_ADS_REPORT_DOWNLOAD_FAIL_LOG_SET_SOLVED = '/ads_report_download_fail_log/{id}/solve/{solved}' // 更新解决异常标志
export const API_ADS_REPORT_DOWNLOAD_FAIL_LOG_RETTY = '/ads_report_download_fail_log/{id}/report' // 根据失败日志记录重新下载广告报表数据
export const API_ADS_REPORT_DOWNLOAD_FAIL_LOG_RETTY_ALL = '/ads_report_download_fail_log/all/report'// 根据失败日志记录重新下载所有未解决异常的广告报表数据

// 流程收藏目录
export const API_WORKFLOW_DIR_ADD = '/workflow_dir' // 添加数据
export const API_WORKFLOW_DIR_UPDATE = '/workflow_dir/{id}'
export const API_WORKFLOW_DIR_DELETE = '/workflow_dir/{id}' // 删除数据
export const API_WORKFLOW_DIR_LIST_BY_USER = '/workflow_dir/by/user'

// 流程收藏
export const API_WORKFLOW_FAVORITE_ADD = '/workflow_favorite' // 添加数据
export const API_WORKFLOW_FAVORITE_UPDATE = '/workflow_favorite/{id}'
export const API_WORKFLOW_FAVORITE = '/workflow_favorite/{id}'
export const API_WORKFLOW_FAVORITE_LIST = '/workflow_favorite/list'
export const API_WORKFLOW_FAVORITE_DELETE = '/workflow_favorite/{id}'

// 广告客户（签约主体），开户客户
export const API_ADWORKS_PLATFORM_LIST = '/adworks_platform/list'
export const API_AD_SUB_CUSTOMER_LIST = '/ad_customer/sub/list'
export const API_AD_SUB_CUSTOMER_ADD = '/ad_customer/sub'
export const API_AD_SUB_CUSTOMER_UPDATE = '/ad_customer/sub/{id}'
export const API_AD_CUSTOMER_SEARCH = '/ad_customer/search'

// 广告客户，支付订单
export const API_AD_CUSTOMER_ORDER_ADD = '/pay_order/add'
export const API_AD_CUSTOMER_ORDER_UPDATE = '/pay_order/update'
export const API_AD_CUSTOMER_ORDER_CANCEL = '/pay_order/cancel'
export const API_AD_CUSTOMER_ORDER_SEND_SMS = '/pay_order/send_sms'
export const API_AD_CUSTOMER_ORDER_LIST = '/pay_order/list'

// 请假
export const API_ABSENT_LIST = '/absent/list'
