import defineApi from '../define-api'
import http from '../application-api'

export const getWeChatBindInfo = defineApi((config) => {
  return http.load('/wechat_user/bind_info/get', config)
})

export const unBindWeChat = defineApi((config) => {
  http.post('/wechat_user/unbind', config)
})

