import AdsAccountAbnormal from '../../pages/ads-report/account-abnormal'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'AdsAbnormal',
    path: '/ads/AccountAbnormal',
    meta: { title: '广告账户下载异常' },
    component: AdsAccountAbnormal
  }
]
