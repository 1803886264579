<template>
  <page :route-name="getRouteName('StageDetail')" :page-title="pageTitle" :use-tcb-layout="false">

    <span class="round-text-button" style="background-color: #57955d" slot="toolbar" title="工作描述" @click="scrollTo('STAGE-DETAIL-HEAD-DESC')" v-if="contentItemCount > 1">文</span>
    <span class="round-text-button" style="background-color: #3b61b6" slot="toolbar" title="表单" @click="scrollTo('STAGE-DETAIL-HEAD-FORM')" v-if="contentItemCount > 1 && stage.hasForm">表</span>
    <span class="round-text-button" style="background-color: #474747" slot="toolbar" title="附件" @click="scrollTo('STAGE-DETAIL-HEAD-FILE')" v-if="contentItemCount > 1 && attachments.length > 0">附</span>

    <div ref="stageDetail" class="stage-detail">

      <!--描述-->
      <div id="STAGE-DETAIL-HEAD-DESC" class="head">
        <span class="title">
          <span class="icon" style="background-color: #57955d">
            <i class="fas fa-book"></i>
          </span>
          {{stage.title}}
        </span>
      </div>
      <div class="process-detail">
        <label>处理人：</label>
        <span>{{stage.processUserName}}</span>
        <label>处理时间：</label>
        <span>{{stage.processDate}}</span>
        <label>处理结果：</label>
        <el-tag type="danger">{{stage.processResultText}}</el-tag>
      </div>
      <div ref="description" class="description font-small" v-show="!!description" v-html="description"></div>
      <div v-if="!description" class="text-muted font-mini">- 这位同学很懒，什么都没说 -</div>

      <!--表单-->
      <div id="STAGE-DETAIL-HEAD-FORM" class="head" v-if="stage.hasForm">
        <span class="title">
          <span class="icon" style="background-color: #3b61b6">
            <i class="fas fa-list-alt"></i>
          </span>
          表单信息
        </span>

      </div>
      <div class="form-container" v-if="stage.hasForm">

        <div v-for="(fg, fgIndex) in fieldGroups" class="field-group">
          <div class="group-title">
            表单：{{fg.title}}
            <el-button size="mini" type="primary" icon="fa fa-clipboard" class="copy-form" @click="copyForm(fg.fieldMap)">复制表单数据</el-button>
          </div>
          <div class="field-layout">
            <div class="field-row" v-for="(row, rowIndex) in fg.fieldLayouts" :key="rowIndex">
              <div class="field-column" v-for="col in row" :key="col.name" :style="{flex: col.span / 24}">
                <div v-if="col.type === 'title'" class="field-title">{{col.name}}</div>
                <label v-if="col.type === 'field'" class="field-label">{{col.field.label}}</label>
                <div v-if="col.type === 'field'" class="field-input">
                    <span v-html="formatFormValue(col.field)"></span>
                    <span class="font-mini text-muted">{{col.field.suffix}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--附件-->
      <div id="STAGE-DETAIL-HEAD-FILE" class="head" v-if="attachments.length > 0">
        <span class="title">
          <span class="icon" style="background-color: #474747">
            <i class="fas fa-file"></i>
          </span>
          附件
        </span>
      </div>
      <div v-for="attach in attachments" :key="attach.title" style="margin-bottom: 8px;">
        <div class="font-normal text-main">{{attach.title}}：</div>
        <div style="margin-left: 15px;">
          <div v-if="attach.files.length === 0" class="font-mini text-muted">无上传附件</div>
          <div style="line-height: 24px;" class="font-small file-item" v-for="file in attach.files" :key="file.id">
            <file-icon :file-name="file.fileName"></file-icon>
            <span class="font-small text-muted">{{file.fileName}}</span>
            <a href="javascript:void(0)" @click.stop="handleFileClick(file, 'preview')" style="margin-left: 20px;">预览</a>
            <a href="javascript:void(0)" @click.stop="handleFileClick(file, 'download')" style="margin-left: 6px;">下载</a>
            <a v-if="file.fileRender" href="javascript:void(0)" @click.stop="handleFileClick(file, 'fileRender')" style="margin-left: 6px; color: #bb3e30;">查看详情</a>
          </div>
        </div>
      </div>
    </div>

    <file-preview ref="preview"></file-preview>


    <el-dialog
        title="文件数据"
        :visible.sync="fileRenderVisible"
        :modal="false"
        :fullscreen="true"
    >
      <file-content-render :file-items="fileRenderItems"></file-content-render>
    </el-dialog>
    <UpPolicyTable
      :flow-id="stage.workflowId"
      :form-data="adPolicyFormDataObject"
      v-if="stage.flowType === 'AdPolicy' && stage.stage === 'cooperate_policy'"
    >
    </UpPolicyTable>
  </page>
</template>

<script>
  import mixins from '../mixins'
  import FormCopy from '../../../../../tools/form-copy'
  import { getStageDetail } from '../../../../../http/api/stage'
  import FilePreview from '../../../../../components-business/file-preview'
  import formFieldFormat from './form-format'
  import FileContentRender from '../../comps/file-content-render'
  import { getAdminUrl } from '../../../../../http/application-api'
  import UpPolicyTable from '../../../../policy/upper/comp/up-policy-table'

  export default {
    mixins: [mixins],
    components: {
      FilePreview,
      FileContentRender,
      UpPolicyTable
    },
    data () {
      return {
        stage: {},
        description: null,
        attachments: [],
        fieldGroups: [],
        imageBrowserUrl: null,
        imageBrowserTitle: null,
        fileRenderVisible: false,
        currentFileRenderFileItem: null,
        adPolicyFormDataObject: {}
      }
    },
    computed: {
      pageTitle () {
        return this.stage ? this.stage.title : null
      },
      /**
       * 表示"描述"、"表单"和"附件"的数量，如果这三个都有内容，则返回3，最少会返回1。
       * @return {number}
       */
      contentItemCount () {
        let c = 1
        c += this.form !== null ? 1 : 0
        c += this.attachments.length > 0
        return c
      },
      fileRenderItems () {
        if (this.currentFileRenderFileItem) {
          return [this.currentFileRenderFileItem]
        } else {
          return []
        }
      }
    },
    watch: {
      fileRenderVisible () {
        if (!this.fileRenderVisible) {
          this.currentFileRenderFileItem = null
        }
      }
    },
    methods: {
      scrollTo (target) {
        document.getElementById(target).scrollIntoView({ behavior: 'smooth' })
      },
      loadData () {
        this.$pageLoading(true)
        getStageDetail()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.stage = resp.data['stage']
            this.description = resp.data['description']
            this.attachments = resp.data['attachments']
            if (this.stage.hasForm) {
              for (let form of resp.data['formData']) {
                this.fieldGroups.push(form)
                for (let layouts of form.fieldLayouts) {
                  for (let layout of layouts) {
                    layout.field = form.fieldMap[layout.name]
                  }
                }
              }
              if (this.stage.flowType === 'AdPolicy' && this.stage.stage === 'cooperate_policy') {
                let contract = JSON.parse(this.fieldGroups[0].fieldMap['contract']['value'])
                let proxy = JSON.parse(this.fieldGroups[0].fieldMap['proxy']['value'])
                if (Array.isArray(contract)) {
                  this.adPolicyFormDataObject.contract = contract[0]
                }
                if (Array.isArray(proxy)) {
                  this.adPolicyFormDataObject.proxy = proxy[0]
                }
              }
            }
            let _this = this
            this.$nextTick(() => {
              for (let img of this.$refs.description.getElementsByTagName('img')) {
                img.onclick = function () {
                  _this.imageBrowserUrl = this.src
                  if (this.attributes && this.attributes.alt) {
                    _this.imageBrowserTitle = this.attributes.alt.textContent || ''
                  }
                }
                img.ondblclick = function () {
                  let downloadUrl = this.src
                  window.open(downloadUrl.replace('description/image', 'description/image/download'))
                }
              }
              if (this.$route.query.anchor === 'form') {
                this.stage.hasForm && this.scrollTo('STAGE-DETAIL-HEAD-FORM')
              } else if (this.$route.query.anchor === 'attach') {
                this.attachments.length > 0 && this.scrollTo('STAGE-DETAIL-HEAD-FILE')
              }
            })
          })
          .send(this.$route.params.stageId)
      },
      formatFormValue (field) {
        return formFieldFormat(field)
      },
      copyForm (fieldMap) {
        FormCopy.copy(fieldMap)
        this.$message.success('复制成功，可以在其他表单编辑器中粘贴。')
      },
      handleFileClick (file, action) {
        if (action === 'download') {
          window.open(getAdminUrl(file.url, true))
        } else if (action === 'preview') {
          this.$refs.preview.open({
            id: file.id,
            title: file.fileName,
            image: file.image,
            url: file.url
          })
        } else if (action === 'fileRender') {
          this.currentFileRenderFileItem = file
          this.fileRenderVisible = true
        }
      }
    },
    mounted () {
      this.loadData()
    }
  }
</script>

<style lang="less" scoped>

  @deep: ~'>>>';

  .round-text-button {
    display: inline-block;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
  }

  .stage-detail {
    position: relative;
    margin: 20px 100px;

    .head {
      display: flex;
      flex-direction: row;
      height: 35px;
      padding-bottom: 5px;
      margin-bottom: 8px;
      border-bottom: solid 1px #ddd;
      overflow: hidden;
      &:not(:first-of-type) {
        margin-top: 50px;
      }
      .title {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        color: #555555;
        line-height: 25px;
        .icon {
          float: left;
          display: flex;
          margin-right: 10px;
          width: 25px;
          height: 25px;
          flex-flow: column;
          justify-content: center;
          font-size: 12px;
          text-align: center;
          border-radius: 100%;
          color: #fff;
        }
      }
    }

    .process-detail {
      margin-top: 5px;
      text-align: right;
      font-size: 12px;
      label {
        margin-left: 20px;
        color: #292929;
        font-weight: bold;
      }
    }
    @import './form-style';

    //.form-panel {
    //  display: inline-block;
    //  margin-right: 20px;
    //  margin-top: 20px;
    //  width: 400px;
    //  padding: 10px 20px;
    //  border-radius: 5px;
    //  box-shadow: 0 0 10px rgba(0,0,0,.3);
    //  .form-field {
    //    display: table-row;
    //    > label {
    //      display: table-cell;
    //      width: 1px;
    //      white-space: nowrap;
    //      padding: 5px 0;
    //      text-align: right;
    //    }
    //    > span {
    //      display: table-cell;
    //      padding: 5px 0;
    //    }
    //  }
    //}
  }
</style>
