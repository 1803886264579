/**
 * 当你使用components/app-layout/page.vue组件时，希望放置一个Table在slot="center"中，又
 * 希望这个表格能填满这个center容器，可以使用这个mixins来减少你的代码工作量。
 * template
 * --------------------
 * <app-page :tcbLayout="true">
 *   <Table slot="center" :height="tableHeight"></Table>
 * </app-page>
 *
 * script
 * --------------------
 * import tableHeightFixMixins from ...
 * export default {
 *   mixins: [tableHeightFixMixins]
 * }
 */
export default {
  data () {
    return {
      tableHeight: 300
    }
  },
  methods: {
    getTableContainerElement () {
      return this.$el.getElementsByClassName('center-content')[0]
    },
    updateTableHeight () {
      let container = this.getTableContainerElement()
      this.tableHeight = container.clientHeight - 20 // 20是上下的padding
    }
  },
  mounted () {
    let ctx = this
    this._windowResizeEventHandler = function () {
      ctx.updateTableHeight()
    }
    window.addEventListener('resize', this._windowResizeEventHandler)
    this.$nextTick(() => {
      this._windowResizeEventHandler()
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this._windowResizeEventHandler)
  }
}
