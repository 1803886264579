<template>
  <slide-panel ref="panel"
               :visible.sync="visible"
               :width="400"
               :height="430"
               position="top"
               @beforeClose="beforeClose"
  >
    <span slot="title">微信绑定</span>

    <div style="text-align: center; line-height: normal;" v-loading="loading">
      <div class="text-muted" style="margin: 10px 0;" v-show="!bindSuccess">
        使用微信扫描，关注公众号，绑定您现在的账号
      </div>
      <div class="text-success" style="margin: 10px 0; font-weight: bold;" v-show="bindSuccess">
        绑定成功，关注下面的公众号可以接收流程状态提醒消息。
      </div>

      <img v-if="qrUrl != null" :src="qrUrl" style="width: 300px; height: 300px;" />
    </div>
  </slide-panel>
</template>

<script>
  import url, { API_WECHAT_USER_BIND_INFO } from '../config/urls'
  import QRCode from 'qrcode'
  import { MSG_TYPE_WECHAT_BIND_STATUS } from '../components/stomp'
  import { userStore } from '../store'
  import { getWeChatBindInfo } from '../http/api/wechat'

  export default {
    data () {
      return {
        visible: false,
        loading: false,
        qrUrl: null,
        bindSuccess: false
      }
    },
    methods: {
      open () {
        this.visible = true
        this.reloadQrInfo()
      },
      beforeClose () {
        clearInterval(this.clearExpireSeconds)
        this.clearExpireSeconds = null
        this.qrUrl = null
        this.loading = false
        this.bindSuccess = false
      },
      reloadQrInfo () {
        this.qrUrl = null
        this.loading = true
        getWeChatBindInfo()
          .complete(() => (this.loading = false))
          .success(resp => {
            if (this.visible) {
              QRCode.toDataURL(resp.data).then(url => {
                this.qrUrl = url
              })
            }
          })
          .send()
      }
    },
    mounted () {
    },
    stomp: {
      [MSG_TYPE_WECHAT_BIND_STATUS]: function (msg) {
        if (msg && msg.scene === 'success') {
          userStore.updateWeChatBindStatus(true)
          this.bindSuccess = true
          this.qrUrl = '/wechat/hehu-qr.jpg'
        }
      }
    }
  }
</script>

<style scoped>

</style>
