<!--
  数据模型编辑器，基于iView的Drawer优化。
  建议为数据模型单独创建一个vue文件，将数据模型的编辑逻辑都放在那里，例如：
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    @save="onSave"
    @close="$refs['form'].resetFields()"
  >
    <form></form>
  </modal-panel>

  属性：
  ----------------------
  visible {boolean} 控制编辑器显示隐藏
  title {string} 编辑器的标题，默认"编辑"
  width {Number} 编辑器宽度，默认500
  disableFooterButton {boolean} 禁用底部的"保存"和"取消"按钮，默认false，如果你想自己放置按钮，设置为true
  saveButtonText {string} 默认底部的保存按钮文本
  closeButtonText {string} 关闭编辑器按钮的文本
  saveButtonLoading {boolean} 如果你使用默认的底部按钮时，可以使用这个属性来控制按钮的加载状态
  closable {boolean} 编辑器是否可以被关闭，false后，头部的关闭图标和底部的关闭按钮都不可用
  loading {Boolean} 默认false，是否显示一个加载动画（覆盖面板的body和footer）

  slot
  ------------------------
  slot="footer"：底部位置，如果你需要自己写底部的组件，请将disableFooterButton设置为true

  事件
  ------------------------
  close：编辑器被关闭
  cancel：点击了底部默认的"取消"按钮，该按钮默认会关闭编辑器
  save：点击了底部默认的"保存"按钮
-->
<template>
  <div v-transfer-dom :data-transfer="true" class="drawer-container">
    <transition name="fade">
      <div class="drawer-mask" style="animation-duration: 0.2s" v-show="isVisible" @click="handleMask"></div>
    </transition>
    <transition :name="slideDirection">
      <div
        v-show="isVisible"
        :style="drawerStyle"
        style="animation-duration: 0.3s"
        class="drawer"
      >
        <div class="header">
          <span class="title">{{title}}</span>
          <i class="aliyun-close close-icon" @click="handleCancel"></i>
        </div>
        <div class="body" v-loading="loading">
          <slot></slot>
        </div>
        <div class="footer" v-if="!(disableFooterButton && !$slots.footer)">
          <slot name="footer"></slot>
          <el-button
            v-if="!disableFooterButton"
            type="primary"
            :loading="saveButtonLoading"
            @click="$emit('save')"
          >
            {{saveButtonText}}
          </el-button>
          <el-button
            v-if="!disableFooterButton"
            @click="handleCancel"
            :disabled="!closable"
          >
            {{closeButtonText}}
          </el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import TransferDom from '../directives/transfer-dom'
  export default {
    name: 'modal-panel',
    directives: { TransferDom },
    props: {
      placement: { type: String, default: 'right' },
      visible: { type: Boolean, default: true },
      width: { type: Number, default: 500 },
      title: { type: String, default: '编辑' },
      disableFooterButton: { type: Boolean, default: false },
      saveButtonText: { type: String, default: '保存' },
      closeButtonText: { type: String, default: '取消' },
      saveButtonLoading: { type: Boolean, default: false },
      closable: { type: Boolean, default: true },
      loading: { type: Boolean, default: false }
    },
    computed: {
      drawerStyle () {
        let style = {
          width: this.width + 'px'
        }
        if (this.placement === 'left') {
          style.left = 0
        } else {
          style.right = 0
        }
        return style
      },
      isVisible: {
        get () {
          return this.visible
        },
        set (v) {
          this.$emit('update:visible', v)
        }
      },
      slideDirection () {
        return this.placement === 'left' ? 'slideLeft' : 'slideRight'
      }
    },
    watch: {
      visible (v) {
        this.isVisible = v
        if (!v) {
          this.$emit('close')
        }
      }
    },
    data () {
      return {
      }
    },
    methods: {
      close () {
        if (this.closable) {
          this.isVisible = false
          return true
        }
      },
      handleCancel () {
        this.close() && this.$emit('cancel')
      },
      handleMask () {
        this.close()
      }
    },
    mounted () {
    }
  }
</script>

<style lang="less" scoped>
  .drawer-mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,.3);
  }
  .drawer {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 1000;
  }
  .header {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    border-bottom: solid 1px #EBEEF5;
    background-color: #f8f8f9;
    .title {
      flex: 1;
      font-size: 14px;
    }
    .close-icon {
      cursor: pointer;
    }
  }
  .body {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
  }
  .footer {
    position: relative;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    border-top: solid 1px #EBEEF5;
    text-align: right;
    background-color: #f8f8f9;
    button + button {
      margin-left: 10px;
    }
  }
</style>
