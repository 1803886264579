<template>
  <page route-name="Config" :use-tcb-layout="false">
  </page>
</template>

<script>
  export default {
  }
</script>

<style lang="less" scoped>
</style>
