<template>
  <page route-name="ProfileDetail">

    <div slot="toolbar">
      <el-button size="mini" type="primary" @click="$refs.profileEditor.open(profile)">修改档案</el-button>
    </div>

    <div slot="top" class="profile-detail" v-if="profile">
      <div class="left">
        <div class="profile-info">
          <label>名称</label>
          <span>{{profile.name}}</span>
        </div>
        <div class="profile-info">
          <label>分类</label>
          <span>{{profile.profileCategoryName}}</span>
        </div>
        <div class="profile-info">
          <label>描述</label>
          <span>{{profile.description}}</span>
        </div>
      </div>
      <div class="right">
        <div class="profile-info">
          <label>标签</label>
          <span>{{profile.tag}}</span>
        </div>
        <div class="profile-info">
          <label>权限</label>
          <span>{{profile.roleNames}}</span>
        </div>
        <div class="profile-info">
          <label>创建人</label>
          <span>{{profile.createName}}</span>
        </div>
        <div class="profile-info">
          <label>创建时间</label>
          <span>{{profile.createTime}}</span>
        </div>
      </div>
    </div>

    <div slot="center">
      <split-line text="档案属性" align="center"></split-line>

      <div class="props-template-list" v-if="propsOnSorted.length > 0">
        <div
            v-for="r in propsOnSorted" :key="r.groups"
            class="props-group-wrapper"
        >
          <div class="props-group">
            <div class="group-name">{{r.name}}</div>
            <div class="props-detail">
              <div v-for="p in r.props" :key="p.id" class="prop-item" :class="{'prop-delete': p.handleStatus === 'delete'}">
                <div class="item-block history" style="width: 30px;">
                  <i v-if="p.valueHistoryTotal > 0" class="el-icon-time" style="font-size: 16px;" @click="openHistory(p)"></i>
                </div>
                <div class="item-block label" style="width: auto; text-align: right; padding-right: 10px;">{{p.label}}</div>
                <div class="item-block value" style="flex: 1;">{{p.value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="历史详情" @close="handleHistoryClose" :visible.sync="propHistoryVisible" :modal-append-to-body='false'>
      <el-table
          stripe
          :height="300"
          :data="propHistories"
      >
        <el-table-column prop="groups" label="属性组别"></el-table-column>
        <el-table-column prop="label" label="属性名称"></el-table-column>
        <el-table-column prop="value" label="值"></el-table-column>
        <el-table-column prop="updateTime" label="被修改时间"></el-table-column>
        <el-table-column prop="updateUserName" label="修改人"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="propHistoryVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <ProfileEditor ref="profileEditor" @handleSaved="handleSaved"></ProfileEditor>
  </page>
</template>

<script>
  import { getProfileAndPropsById, getPropHistory } from '../../http/api/profile'
  import PropsSortMixins from './props-sort-mixins'
  import { PropTypeMapping } from './type-constants'
  import ProfileEditor from './comp/profile-editor'

  export default {
    mixins: [PropsSortMixins],
    components: { ProfileEditor },
    data () {
      return {
        profile: null,
        propTypeMapping: PropTypeMapping,
        propHistoryVisible: false,
        propHistories: []
      }
    },
    methods: {
      loadDetail () {
        this.$loading(true)
        getProfileAndPropsById()
          .complete(() => {
            this.$loading(false)
          })
          .success(resp => {
            this.profile = resp.data.profile
            this.propTemplates = resp.data.props
          })
          .send(this.$route.params.id)
      },
      openHistory (prop) {
        this.propHistoryVisible = true
        getPropHistory()
          .success(resp => {
            for (let item of resp.data) {
              item.label = prop.label
              item.groups = prop.groups
            }
            this.propHistories = resp.data
          })
          .send(prop.id)
        this.profileDetailVisible = true
      },
      handleHistoryClose () {
        this.propHistories = []
      },
      handleSaved () {
        this.loadDetail()
      }
    },
    mounted () {
      this.loadDetail()
    }
  }
</script>

<style lang="less" scoped>

  .profile-detail {
    display: flex;
    flex-direction: row;
    padding: 10px;
    .left, .right {
      flex: .5;
    }
    .profile-info {
      display: flex;
      flex-direction: row;
      padding: 3px 0;
      font-size: 13px;
      label {
        width: 80px;
        text-align: right;
        &::after {
          content: '：';
        }
      }
      span {
        flex: 1;
        color: #5c5c5c;
      }
    }
  }

  .props-template-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .props-group-wrapper {
    padding: 10px;
  }

  .props-group {
    min-width: 500px;
    border: solid 1px #e9e9e9;
    .group-name {
      font-size: 14px;
      font-weight: bold;
      padding: 5px 10px;
      border-bottom: solid 1px #e9e9e9;
      background-color: #f2f2f2;
    }
    .props-detail {
      padding: 15px 0;
    }
    .prop-item {
      display: flex;
      padding: 2px 10px;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      color: #3b3b1f;
      .label {
        font-weight: bold;
        &::after {
          content: '：';
        }
      }
      .history {
        cursor: pointer;
        color: #96342d;
      }
    }
    .prop-item + .prop-item {
      margin-top: 5px;
    }
  }
</style>
