<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    :loading="loading"
    title="IK分词编辑"
    @save="onSave"
    @close="onClose"
  >
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="名称" prop="name" class="app_required-input">
        <el-input :readonly="isUpdate" v-model="formData.name" placeholder="文件名，不要包含后缀"/>
      </el-form-item>
      <el-form-item label="类型" prop="type" class="app_required-input">
        <el-radio :disabled="isUpdate" v-model="formData.type" label="dict">自定义词项</el-radio>
        <el-radio :disabled="isUpdate" v-model="formData.type" label="stop">停用词</el-radio>
      </el-form-item>
      <el-form-item label="内容" prop="content" class="app_required-input">
        <el-input
          type="textarea" :rows="20" placeholder="每个词项一行" v-model="formData.content">
        </el-input>
      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import {
    getIkWordContent,
    updateIkWordFile
  } from '../../../../http/api/elasticsearch'

  export default {
    data () {
      return {
        visible: false,
        closable: true,
        loading: false,
        saveButtonLoading: false,
        model: null,
        formData: {
          name: null,
          type: null,
          content: null
        }
      }
    },
    watch: {
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.model = model
        this.visible = true
        if (this.isUpdate) {
          this.formData.name = model.name
          this.formData.type = model.type
          this.loading = true
          getIkWordContent()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.formData.content = resp.data
            })
            .send(model.name, model.type)
        }
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.model = null
      },
      onSave () {
        this.saveButtonLoading = true
        this.closable = false
        updateIkWordFile()
          .complete(() => {
            this.saveButtonLoading = false
            this.closable = true
          })
          .success(() => {
            this.$message.success('保存成功。')
            this.$emit('saved')
          })
          .send(this.formData.name, this.formData.type, this.formData.content)
      }
    }
  }
</script>

<style scoped>

</style>
