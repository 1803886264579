<template>
  <page route-name="ProcessingOrder">
    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
          <el-button @click="handleExportToExcel">导出Excel</el-button>
      </el-form-item>
      <el-form-item>
        <el-tooltip content="释放订单后，订单会回到待处理列表，其他人可以继续选择该订单操作。" placement="bottom">
          <el-button type="warning"  @click="releaseOrder()">释放订单</el-button>
        </el-tooltip>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$refs.screenshotZipUpload.open()">批量上传截图</el-button>
      </el-form-item>
      <el-form-item>
          <el-button type="success"  @click="sendOrderBang()">提交订单到棒谷</el-button>
      </el-form-item>
    </el-form>
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="订单日期">
          <el-date-picker
              style="width: 260px;"
              label="日期范围"
              v-model="search.dates"
              type="daterange"
              align="right"
              :unlink-panels="true"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="业务订单号">
          <el-input v-model="search.bizNo"  :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="转账流水号">
          <el-input v-model="search.serialNo"  :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="收款信息">
          <el-input v-model="search.recieve" placeholder="收款人或帐号"  :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="支付状态" prop="recieve">
          <el-select v-model="search.payStatus" placeholder="请选择支付状态"  :clearable="true">
            <el-option
              v-for="item in payStatusOption"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付类型" prop="recieve">
          <el-select v-model="search.tradeType" placeholder="请选择支付类型"  :clearable="true">
            <el-option
              v-for="item in tradeTypes"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="processStatus">
          <el-select v-model="search.processStatus" multiple style="width: 360px;">
            <el-option
              v-for="item in processStatusOption"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      slot="center"
      :data="dataList"
      @selection-change="handleSelectionChange"
      class="data-table"
      show-summary
      :summary-method="getSummaries">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
          type="index"
          width="50">
      </el-table-column>
      <el-table-column label="操作" width="170">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="handleEditOrder(row)">更新回单</el-button>
          <el-button size="mini" type="warning" @click="handleUpdateTransfer(row, true)" v-show="!row.transferStatus">标记已转账</el-button>
          <el-button size="mini" type="success" @click="handleUpdateTransfer(row, false)" v-show="row.transferStatus">撤销已转账</el-button>
        </template>
      </el-table-column>
      <el-table-column label="业务订单号" width="110">
        <template slot-scope="{ row }">
          <el-button type="text" size="mini" @click="handleCopyText(row.bizNo)">复制</el-button>
          {{row.bizNo}}
        </template>
      </el-table-column>
      <el-table-column label="收款账户" width="220">
        <template slot-scope="{ row }">
          <el-button type="text" size="mini" @click="handleCopyText(row.recieveAccount)">复制</el-button>
          {{row.recieveAccount}}
        </template>
      </el-table-column>
      <el-table-column label="收款金额" width="170">
        <template slot-scope="{ row }">
          <el-button type="text" size="mini" @click="handleCopyText(row.price)">复制</el-button>
          {{row.currency}} <span style="font-size: 16px; font-weight: bold; color: #96342d;">{{row.price}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recieveName" label="收款人" width="150"></el-table-column>
      <el-table-column label="处理状态" width="90">
        <template slot-scope="{ row }">
          <span v-if="row.processStatus === processStatusMap.enter" class="text-primary font-mini">已录入</span>
          <span v-else-if="row.processStatus === processStatusMap.verified" class="text-success font-mini">已校验</span>
          <span v-else-if="row.processStatus === processStatusMap.pending" class="text-muted font-mini">待处理</span>
          <span v-else-if="row.processStatus === processStatusMap.success" class="text-danger font-mini" style="font-weight: bold;">已提交</span>
        </template>
      </el-table-column>
      <el-table-column label="支付状态" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.payStatus === payStatusMap.success" class="text-success font-mini">支付成功</span>
          <span v-else-if="row.payStatus === payStatusMap.fail" class="text-danger font-mini">支付失败</span>
          <span v-else class="text-muted font-mini">待处理</span>
        </template>
      </el-table-column>
      <el-table-column prop="tradeTypeStr" label="收款类型" width="100"></el-table-column>
      <el-table-column prop="sortTime" label="排序时间" width="140"></el-table-column>
      <el-table-column prop="bank" label="银行" width="100"></el-table-column>
      <el-table-column prop="bankDivision" label="银行支行" width="100"></el-table-column>
      <el-table-column prop="province" label="省份" ></el-table-column>
      <el-table-column prop="city" label="城市" ></el-table-column>
    </el-table>
    <order-editor ref="orderPanel" @submit="onEditorSubmit"></order-editor>
    <screenshot-zip-upload
        ref="screenshotZipUpload"
        @processCompleted="handleScreenshotZipProcessed"
        @onItemClick="handleScreenshotZipOnItemClick"
    >
    </screenshot-zip-upload>
  </page>
</template>

<script>

  import {
    getProcessingList,
    releaseOrder,
    sendOrderBangList,
    updateTransferStatus,
    downloadProcessingListToExcel
  } from '../../../http/api/bg-order'
  import kit from '@/tools/kit'
  import OrderEditor from './comp/order-editor'
  import {
    payStatusOption,
    tradeTypesOption,
    PAY_STATUS_SUCCESS,
    PAY_STATUS_FAIL,
    processStatusOption,
    STATUS_PENDING,
    STATUS_ENTERED,
    STATUS_VERIFIED,
    STATUS_SUCCESS,
    STATUS_WAITING
  } from '../common-attributes'
  import ScreenshotZipUpload from './comp/screenshot-zip-upload'
  export default {
    components: {
      OrderEditor, ScreenshotZipUpload
    },
    data () {
      return {
        dataList: [],
        multipleSelection: [],
        search: {
          bizNo: null,
          serialNo: null,
          recieve: null,
          tradeType: null,
          payStatus: null,
          processStatus: [STATUS_PENDING, STATUS_ENTERED, STATUS_VERIFIED],
          dates: []
        },
        start: null,
        end: null,
        tradeTypes: tradeTypesOption,
        payStatusOption: payStatusOption,
        payStatusMap: {
          success: PAY_STATUS_SUCCESS,
          fail: PAY_STATUS_FAIL
        },
        processStatusOption: processStatusOption.filter(item => item.id !== STATUS_WAITING),
        processStatusMap: {
          enter: STATUS_ENTERED,
          verified: STATUS_VERIFIED,
          pending: STATUS_PENDING,
          success: STATUS_SUCCESS
        },
        dialogFormVisible: false,
        formUpload: {
          type: null,
          multiFile: null,
          name: null,
          account: null,
          accountName: null
        },
        failId: [],
        successId: [],
        dealing: false
      }
    },
    watch: {
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.processStatus = params.processStatus.join(',')
        if (params.dates && params.dates.length === 2) {
          params.startTime = params.dates[0]
          params.endTime = params.dates[1]
        }
        delete params.dates
        this.$pageLoading(true)
        getProcessingList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data
          })
          .send(params)
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
      },
      releaseOrder () {
        if (this.multipleSelection.length > 0) {
          this.$confirm('订单释放后，其他人可以继续选择该订单处理, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            releaseOrder().complete(() => (this.$pageLoading(false)))
              .success(resp => {
                this.multipleSelection.forEach(element => kit.arr.remove(this.dataList, element))
                this.$message.success('释放成功！')
              })
              .send(this.multipleSelection.map(item => item.id))
          }).catch(() => {})
        } else {
          this.$alert('请选择要释放的订单数据！', { type: 'warning' })
        }
      },
      handleEditOrder (row) {
        this.$refs['orderPanel'].open(row, this.dataList)
      },
      onEditorSubmit (data) {
        kit.arr.pushOrReplace(this.dataList, data)
      },
      handleScreenshotZipProcessed (items) {
        for (let item of items) {
          kit.arr.pushOrReplace(this.dataList, item)
        }
      },
      handleScreenshotZipOnItemClick (row) {
        let found = this.dataList.find(item => item.id === row.id)
        if (found) {
          this.$refs['orderPanel'].open(found, this.dataList)
        } else {
          this.$message.error('找不到数据。')
        }
      },
      getSummaries (param) {
        if (this.dataList.length > 0) {
          let tmp = ['', '', '', '', '']
          let amount = 0
          let currency = null
          for (let row of this.dataList) {
            amount += row.price
            if (currency == null) {
              currency = row.currency
            } else {
              if (currency !== row.currency) {
                currency = '存在多种货币，请检查'
              }
            }
          }
          tmp.push('合计：' + kit.str.fmtCurrency(amount, 2, currency))
          return tmp
        } else {
          return ['', '', '', '', '']
        }
      },
      sendOrderBang () {
        if (this.multipleSelection.length > 0) {
          let idList = this.multipleSelection.map(item => item.id)
          this.$pageLoading(true)
          sendOrderBangList()
            .complete(() => (this.$pageLoading(false)))
            .success(resp => {
              let successTotal = resp.data.success.length
              let errorIdList = Object.keys(resp.data.error)
              this.$alert(`提交完成，成功提交 ${successTotal} 条，失败 ${errorIdList.length} 条。`, { type: 'info' })
              this.load()
            })
            .send(idList)
        } else {
          this.$alert('请选择要提交的订单数据！', { type: 'warning' })
        }
      },
      handleUpdateTransfer (row, status) {
        updateTransferStatus()
          .success(() => {
            row.transferStatus = status
          })
          .send(row.id, status ? 1 : 0)
      },
      handleCopyText (message) {
        this.$copyText(message).then(() => {
          this.$message.success(`复制 ${message} 成功`)
        }, function (e) {
        })
      },
      handleExportToExcel () {
        let params = Object.assign({}, this.search)
        params.processStatus = params.processStatus.join(',')
        let url = downloadProcessingListToExcel(params)
        window.open(url)
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

</style>
