<template>
  <transition name="fade">
    <div class="login-page fixed-full">

      <transition name="fade">
        <div class="background-image absolute-full" :style="{'background-image': `url(${backgroundImageUrl})`}" v-show="backgroundImageUrl != null"></div>
      </transition>
      <div class="background-image-description">{{backgroundImageDescription}}</div>

      <div class="overlay absolute-full">

        <div class="panel-wrapper">
          <img class="logo" src="/logo2.png">
          <h3>
            {{pageTitle}}
            <span v-show="version" style="font-weight: normal; font-size: 12px; color: #a94442; text-align: center;">{{version}}</span>
          </h3>
          <div class="animate-wrapper">
            <login-panel ref="login" @changePanel="switchPanel"></login-panel>
            <forgot-password-panel ref="password" @changePanel="switchPanel"></forgot-password-panel>
          </div>

          <we-chat-login-panel ref="wechat"></we-chat-login-panel>
        </div>
        <div class="copyright">Copyright @ 2012 合鹄科技（深圳）有限公司 All Rights Reserved power by</div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Velocity from 'velocity-animate'
  import LoginPanel from './login'
  import WeChatLoginPanel from './wechat-login'
  import ForgotPasswordPanel from './forgot-password'
  import { getBingBackground } from '../../http/api/bing-background'
  import { getAppVersion } from '../../http/api/version'

  export default {
    components: { LoginPanel, WeChatLoginPanel, ForgotPasswordPanel },
    data () {
      return {
        pageTitle: this.$shareProps.appName,
        showNiceWords: false,
        niceWordsChinese: null,
        niceWordsEnglish: null,
        backgroundImageUrl: null,
        backgroundImageDescription: null,
        version: null
      }
    },
    mounted () {
      getBingBackground()
        .success(resp => {
          this.backgroundImageUrl = resp.data.dataUrl
          this.backgroundImageDescription = resp.data.text
        })
        .send()

      getAppVersion()
        .success(resp => {
          this.version = resp.data
        })
        .send()
    },
    methods: {
      switchPanel (name) {
        if (name === 'wechat') {
          this.$refs.wechat.open()
        } else {
          let styleName = '0px'
          this.pageTitle = this.$shareProps.appName
          if (name === 'password') {
            styleName = '-400px'
            this.pageTitle = '忘记密码'
            this.$refs.password.username = this.$refs.login.username
            this.$refs.password.disabled = false
          } else {
            this.$refs.password.disabled = true
            this.$refs.login.username = this.$refs.password.username
          }
          Velocity(
            this.$el.getElementsByClassName('animate-wrapper')[0],
            { 'margin-left': styleName },
            { duration: 150 }
          )
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .nice-words {
    width: 600px;
    margin-top: 200px;
    margin-left: 100px;
    font-size: 24px;
    color: #d7d7d7;
    text-shadow: 2px 2px 2px rgba(0,0,0,.3);
    .chinese {
      font-family: STKaiti,sans-serif;
      font-weight: bold;
    }
    .english {
      margin-top: 20px;
      font-size: 18px;
      font-family: Arial,sans-serif;
    }
  }
</style>

<style lang="less">

  .login-page {
    background-color: #fff;
    .background-image {
      z-index: 0;
      background: no-repeat center;
      background-size: 100%;
    }
    .background-image-description {
      position: absolute;
      top: 50%;
      margin-top: -150px;
      left: 80px;
      right: 600px;
      color: #fff;
      font-size: 22px;
      z-index: 999999;
      letter-spacing: 5px;
      line-height: 40px;
    }

    .overlay {
      z-index: 1;
      background-color: rgba(0,0,0,.3);
      .logo {
        display: block;
        margin: 0 auto;
        width: 120px;
        height: 120px;
      }
      h3 {
        text-align: center;
        color: #b63d2d;
      }
    }

    @panelWrapperWidth: 400px;
    @panelWidth: @panelWrapperWidth - 2; // 2px边框，20左间距
    @panelBorderColor: #efefef;
    .panel-wrapper {
      position: absolute;
      top: 50%;
      right: 40px;
      margin-top: -237px;
      width: @panelWrapperWidth;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 15px rgba(0,0,0,.8);
      border-radius: 3px;
    }
    .animate-wrapper {
      width: @panelWrapperWidth * 2;
      white-space: nowrap;
      .panel {
        display: block;
        float: left;
        min-height: 100px;
        width: @panelWidth;
        padding: 10px 0;
        margin: 0 !important;
        vertical-align: top;
      }
    }

    .login-panel {
      .code-image {
        width: 100px;
        height: 29px !important;
        cursor: pointer;
        img {
          width: inherit;
          height: inherit;
        }
      }
      .clear-username {
        display: flex;
        flex-flow: column;
        justify-content: center;
        color: #949494;
        cursor: pointer;
      }
    }

    .panel {
      .form-item {
        display: flex;
        padding: 5px 15px;
        &:not(:first-of-type) {
          margin-top: 10px;
        }
        &.input-item {
          border-bottom: solid 1px @panelBorderColor;
          margin-left: 15px;
          margin-right: 15px;
          padding-left: 0;
          padding-right: 0;
        }
        &.button-bar {
          display: block;
          text-align: right !important;
        }
        label {
          display: flex;
          width: 60px;
          flex-flow: column;
          justify-content: center;
          text-align: right;
          margin-right: 10px;
          color: #474747;
          font-size: 14px;
        }
        input {
          flex: 1;
          padding: 4px 0;
          background-color: transparent;
          border: none;
          color: #414141;
          font-weight: bold;
          font-size: 16px;
          &:focus {
            outline: none;
          }
        }
      }
    }

    .copyright {
      position: absolute;
      width: 100%;
      bottom: 10px;
      text-align: center;
      color: #fff;
      font-size: 12px;
    }
  }
</style>
