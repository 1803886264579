<template>
  <ul class="processed-combine-conditions" v-if="stages.length > 0">
    <li :key="stage.id" v-for="stage in stages">
      <i class="fas fa-check text-success"></i>
      <span class="text-muted">
        {{stage.processDate}}
        【{{stage.processUserName}}】已处理，<span class="text-primary">{{stage.processResultText}}</span>。
      </span>
      <a class="font-mini text-main"
         href="javascript:void(0)"
         @click="toStageDetailPage(stage.id)">查看详情</a>
      <div class="description" @click="toStageDetailPage(stage.id)">
        <span v-if="stage.pureDescription">内容描述：{{stage.pureDescription}}</span>
        <span v-else>无描述</span>
      </div>
    </li>
  </ul>
</template>

<script>
  import mixins from './mixins'
  import { getCombineStages } from '../../../../http/api/stage'

  export default {
    mixins: [mixins],
    props: {
      stageId: {
        type: String, required: true
      }
    },
    data () {
      return {
        stages: []
      }
    },
    mounted () {
      getCombineStages()
        .success(resp => {
          this.stages = resp.data
        })
        .send(this.flowId, this.stageId)
    }
  }
</script>

<style lang="less" scoped>
  .processed-combine-conditions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 12px;
    li {
    }
    i {
      margin-right: 5px;
    }
  }
  .description {
    margin-left: 40px;
    margin-bottom: 10px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
