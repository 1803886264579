<template>
  <page route-name="AdCustomer">

    <!--<el-button size="mini" type="primary" icon="el-icon-plus" slot="toolbar" @click="$refs.editor.open()">添加</el-button>-->
    <div slot="toolbar" style="display: inline-block; width: 300px; margin-left: 10px;">
      <el-input size="mini"
                placeholder="输入名称关键字查询"
                v-model="query.keyword"
                :clearable="true"
                @keyup.enter.native="reload"
      >
        <el-button slot="append" icon="el-icon-search" @click="reload"></el-button>
      </el-input>
    </div>

    <el-table
      slot="center"
      :data="dataList"
      @expand-change="handleTableExpand"
    >
      <el-table-column label="操作" width="50">
        <template slot-scope="scope">
          <!--<el-button size="mini" icon="el-icon-document" @click="$refs.contracts.open(scope.row)">合同</el-button>-->
          <el-button size="mini" @click="$refs.editor.open(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <!-- 开户账户名称 -->
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div class="info-detail">
            <div class="detail-item">
              <label>联系人</label>
              <span>{{ row.contact }}</span>
            </div>
            <div class="detail-item">
              <label>联系电话</label>
              <span>{{ row.contactPhone }}</span>
            </div>
            <div class="detail-item">
              <label>地址</label>
              <span>{{ row.address }}</span>
            </div>
          </div>
          <div>
            <el-table
              style="width: 400px; height: 250px;"
              :data="row.subCustomers"
            >
              <el-table-column label="操作" width="80">
                <template slot-scope="sub">
                  <el-button size="mini" icon="el-icon-edit" @click="handleEditSubCustomer(sub.row, row)"></el-button>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="开户名称"></el-table-column>
              <el-table-column prop="platform" label="平台" width="120"></el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="客户签约主体全称" width="350"></el-table-column>
      <el-table-column prop="shortName" label="客户简称" width="250"></el-table-column>
      <el-table-column prop="createUserName" label="创建人" width="80"></el-table-column>
      <el-table-column label="维护人">
        <template slot-scope="{ row }">
          <span v-for="u in filterMaintainUsers(row.maintainUsers)" style="margin-right: 8px;">{{u.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="industryName" label="所属行业" ></el-table-column>
      <el-table-column prop="customerTypeName" label="客户类型" ></el-table-column>
      <el-table-column prop="policyNum" label="政策条数" ></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

    <editor ref="editor" @submit="onEditorSave"></editor>
    <sub-customer-editor ref="subCustomerEditor" @submit="onSubCustomerSave"></sub-customer-editor>
    <contracts ref="contracts"></contracts>
  </page>
</template>

<script>
  import Editor from './comp/editor'
  import SubCustomerEditor from './comp/sub-customer-editor'
  import Contracts from './comp/contracts'
  import kit from '../../../tools/kit'
  import {
    getAdCustomerList,
    getSubCustomerList
  } from '../../../http/api/ad-customer'

  export default {
    components: {
      SubCustomerEditor,
      Editor,
      Contracts
    },
    data () {
      return {
        dataList: [],
        page: {
          start: 0,
          limit: this.$shareProps.pageLimit,
          total: 0
        },
        query: {
          keyword: null
        }
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      },
      query () {
        this.reload()
      }
    },
    methods: {
      filterMaintainUsers (users) {
        return users.filter(u => u.enabled)
      },
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        this.$pageLoading(true)
        let params = kit.obj.merge({}, this.query, {
          start: this.page.start,
          limit: this.page.limit
        })
        getAdCustomerList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            resp.data.records.forEach(row => {
              row.subCustomers = []
            })
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      onEditorSave (model) {
        kit.arr.pushOrReplace(this.dataList, model)
      },
      onSubCustomerSave (subCustomer, oldCustomerId, isUpdate) {
        if (isUpdate) {
          let oldCustomer = this.dataList.find(c => c.id === oldCustomerId)
          if (oldCustomerId !== subCustomer.adCustomerId) {
            kit.arr.remove(oldCustomer.subCustomers, subCustomer)

            let newCustomer = this.dataList.find(c => c.id === subCustomer.adCustomerId)
            if (newCustomer) {
              kit.arr.pushOrReplace(newCustomer.subCustomers, subCustomer)
            }
          } else {
            kit.arr.pushOrReplace(oldCustomer.subCustomers, subCustomer)
          }
        } else {
          let newCustomer = this.dataList.find(c => c.id === subCustomer.adCustomerId)
          if (newCustomer) {
            kit.arr.pushOrReplace(newCustomer.subCustomers, subCustomer)
          }
        }
      },
      handleTableExpand (row, expandedRows) {
        let found = expandedRows.find(r => r === row)
        if (!!found) {
          this.loadSubCustomer(row)
        }
      },
      handleEditSubCustomer (subCustomer, customer) {
        if (subCustomer.adCustomerId === customer.id) {
          subCustomer.adCustomerName = customer.name
        } else {
          subCustomer.adCustomerName = ''
        }
        this.$refs.subCustomerEditor.open(subCustomer)
      },
      loadSubCustomer (adCustomer) {
        getSubCustomerList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            adCustomer.subCustomers = resp.data
          })
          .send(adCustomer.id)
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>

</style>
