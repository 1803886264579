<template>
  <div class="panel login-panel">
    <div class="form-item input-item">
      <label>邮箱</label>
      <input type="text" v-model.trim="username" @keyup.enter="login">
      <i class="el-icon-close clear-username" @click="clearUsername"></i>
    </div>
    <div class="form-item input-item">
      <label>密码</label>
      <input type="password" v-model="password" @keyup.enter="login">
    </div>
    <div class="form-item input-item">
      <label>验证码</label>
      <input type="text" v-model="validateCode" maxlength="4" @keyup.enter="login">
      <span class="code-image">
        <img :src="loginValidateCodeUrl" @click="refreshValidateCode">
      </span>
    </div>
    <div class="form-item button-bar">
      <el-button type="default" style="float: left;" @click="$emit('changePanel', 'wechat')">微信登录</el-button>
      <el-button type="primary" @click="login" :disabled="loginButtonDisabled">{{loginButtonText}}</el-button>
      <el-button @click="$emit('changePanel', 'password')">忘记密码</el-button>
    </div>
  </div>
</template>

<script>
  import { ROUTE_HOME } from '../../router/router-constants'
  import { login, loginValidateCode } from '../../http/api/manager'

  export default {
    data () {
      return {
        loginValidateCodeUrl: '',
        loginButtonDisabled: false,
        loginButtonText: '登录系统',
        username: '',
        password: '',
        code: ''
      }
    },
    computed: {
      validateCode: {
        get () {
          return this.code
        },
        set (v) {
          this.code = v.toUpperCase()
        }
      }
    },
    methods: {
      login () {
        if (this.loginButtonDisabled) return
        this.loginButtonDisabled = true
        this.loginButtonText = '正在验证账号信息，请稍等...'
        login()
          .complete(() => {
            this.loginButtonDisabled = false
            this.loginButtonText = '登录系统'
          })
          .success(() => {
            localStorage.setItem('username', this.username)
            if (this.$route.query.from) {
              this.$router.push(this.$route.query.from)
            } else {
              this.$router.push({ name: ROUTE_HOME })
            }
          })
          .send(this.username, this.password, this.validateCode)
      },
      refreshValidateCode () {
        loginValidateCode()
          .success(resp => {
            this.loginValidateCodeUrl = resp.data
          })
          .send()
        this.validateCode = ''
      },
      clearUsername () {
        this.username = ''
        localStorage.removeItem('username')
      }
    },
    mounted () {
      this.refreshValidateCode()
      this.username = localStorage.getItem('username')
    }
  }
</script>

<style lang="less" scoped>
</style>
