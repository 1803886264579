<!--
  阶段的附件上传组件。
-->
<template>
  <div style="padding: 0 20px;">
    <h4 style="margin-bottom: 10px;">
      附件要求
      <span style="font-size: 12px; color: #b53639;">（已上传 {{uploadFileTotal}} 个文件）</span>
    </h4>
    <div class="file-spec list-group">
      <div class="file list-group-item" v-for="fs in fileSpecs" :key="fs.name">
        <div class="file-item">
          <el-tag size="mini" :type="fs.required ? 'danger' : 'info'">{{fs.required ? '必须' : '可选'}}</el-tag>
          <div class="file-name font-mini">{{fs.title}}</div>
          <el-button
            size="mini"
            type="primary"
            @click="selectUploadFiles(fs.name, fs.limit)"
            :disabled="fs.uploadFiles.length >= fs.limit"
          >
            上传附件({{fs.limit}})
          </el-button>
        </div>
        <div class="font-mini text-muted">{{fs.description}}</div>
        <!--已上传的附件-->
        <div class="upload-files" v-if="fs.uploadFiles.length > 0">
          <div class="upload-file" v-for="f in fs.uploadFiles" :key="f.id">
            <file-icon :file-name="f.fileName"></file-icon>
            <file-download :url="f.url" :name="f.fileName" class="file-name text-overflow font-small"></file-download>
            <div><i class="el-icon-close" style="cursor: pointer;" @click="deleteFile(fs, f)"></i></div>
          </div>
        </div>
      </div>
    </div>

    <uploader ref="uploader" :uploadExec="handleFileUpload"></uploader>

    <file-content-render :file-items="fileRenders"></file-content-render>
  </div>
</template>

<script>
  import kit from '../../../../../tools/kit'
  import selectFile from '../../../../../tools/select-file'
  import mixins from '../mixins'
  import {
    getStageProcessingAttachments,
    deleteStageAttachment,
    uploadStageAttachment
  } from '../../../../../http/api/stage'
  import FileContentRender from '../../comps/file-content-render'

  export default {
    components: { FileContentRender },
    mixins: [mixins],
    props: {
      attachmentConfig: { type: Object, required: true },
      stage: { type: String, required: true },
      conditionId: String,
      getForm: { type: Function, required: true }
    },
    data () {
      let fileSpecs = []
      for (let fs of this.attachmentConfig.files) {
        fileSpecs.push(Object.assign({
          uploadFiles: []
        }, fs))
      }
      return {
        fileSpecs: fileSpecs
      }
    },
    computed: {
      // 已上传的文件数量
      uploadFileTotal () {
        let total = 0
        for (let fs of this.fileSpecs) {
          total += fs.uploadFiles.length
        }
        return total
      },
      fileRenders () {
        let renders = []
        for (const fs of this.fileSpecs) {
          if (fs.uploadFiles) {
            for (const f of fs.uploadFiles) {
              if (f.fileRender) {
                renders.push(f)
              }
            }
          }
        }
        return renders
      }
    },
    watch: {
      uploadFileTotal (total) {
        this.$emit('fileTotalChanged', total)
      }
    },
    methods: {
      handleFileUpload (file, callback) {
        let formJson = this.getForm()
        if (formJson) {
          formJson = JSON.stringify(formJson)
        }
        uploadStageAttachment()
          .success(resp => {
            callback.success()
            this.pushFile(resp.data)
          })
          .error(resp => {
            callback.error(resp.message, resp.cancel)
          })
          .final(() => {
            callback.complete()
          })
          .config(cfg => {
            cfg.onUploadProgress = e => {
              callback.progress(Math.round((e.loaded * 100) / e.total))
            }
          })
          .send(this.flowId, this.stage, file.params.name, file.params.conditionId, formJson, file.file)
      },
      selectUploadFiles (name, limit) {
        selectFile({
          select: files => {
            if (limit > 0) {
              files = files.slice(0, limit)
            }
            for (let file of files) {
              file.params = {
                name: name,
                conditionId: this.conditionId
              }
            }
            this.$refs.uploader.upload(files)
          }
        })
      },
      pushFile (file) {
        let fs = this.fileSpecs.find(fs => fs.name === file.name)
        fs && fs.uploadFiles.push(file)
      },
      deleteFile (fileSpec, file) {
        this.$pageLoading(true)
        deleteStageAttachment()
          .complete(() => (this.$pageLoading(false)))
          .success(() => {
            kit.arr.remove(fileSpec.uploadFiles, file)
          })
          .send(file.id)
      }
    },
    mounted () {
      getStageProcessingAttachments()
        .success(resp => {
          resp.data.forEach(f => this.pushFile(f))
        })
        .send(this.flowId, this.stage)
    }
  }
</script>

<style lang="less" scoped>
  .file-spec {
    .file-item {
      display: flex;
      padding: 3px 0;
      .file-name {
        flex: 1;
        padding: 0 10px;
        line-height: 24px;
      }
    }
  }

  .upload-files {
    margin-left: 25px;
    margin-top: 10px;
    .upload-file {
      display: flex;
      line-height: 24px;
      .file-name {
        flex: 1;
        padding: 0 10px;
      }
    }
  }
</style>
