<!--
  数字、货币类型的表单输入项。
  组件支持数字（整型，浮点型）和货币类型的字段，如果是货币类型，在右边会提供一个货币列表选择。
  公式：如果字段配置带有formula属性，表示该字段由公式生成数据，而不是用户输入。
-->
<template>
  <div class="number-field-item">

    <div style="display: flex;">
      <div class="number-val">
        <el-tooltip effect="dark"
                    :content="field.formulaRemark"
                    placement="bottom"
                    :disabled="!hasFormula"
        >
          <el-input type="number"
                    v-model="val"
                    :class="{'formula-bg': hasFormula}"
                    :placeholder="hasFormula ? '公式自动填充' : ''"
          >
            <template slot="append" v-if="!!field.suffix">{{field.suffix}}</template>
          </el-input>
        </el-tooltip>
      </div>


      <div class="currency" v-if="field.type === 'currency'">
        <el-select v-model="currency" placeholder="货币" style="width: 100%;">
          <el-option v-for="item in currencies" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>

  const TYPE_INT = 'int'
  const TYPE_DOUBLE = 'double'
  const TYPE_CURRENCY = 'currency'

  import kit from '../../../../../../tools/kit'
  export default {
    props: {
      field: { required: true, type: Object },
      // 整个表单对象，如果有公式，需要监听表单中的其他字段数据变化
      formData: { required: false, type: Object },
      value: { required: false, type: [String, Number] }
    },
    data () {
      return {
        currencies: this.field.currency,
        val: this.value,
        currency: null // 已选择的货币类型
      }
    },
    computed: {
      hasFormula () {
        return !!this.field.formula
      }
    },
    watch: {
      val () {
        this.updateValue()
      },
      value () {
        this.val = this.value
      },
      currency () {
        this.updateValue()
      }
    },
    methods: {
      updateValue () {
        if (this.field.type === TYPE_CURRENCY) {
          this.$emit('input', (this.currency || '') + '#' + (this.val || ''))
        } else {
          this.$emit('input', this.val)
        }
      },
      /**
       *
       * @param str 如：CNY#122.01
       */
      updateFieldData (str) {
        if (str) {
          str = str + ''
          if (str.includes('#')) {
            let tmp = str.split('#')
            this.currency = tmp[0]
            this.val = parseFloat(tmp[1])
          } else {
            this.val = parseFloat(str)
          }
        }
      },
      /**
       *fieldName中包含@说明是table字段，@后面的字段为table中的子字段
       *如：$ptxq@je 是对je列中进行求和计算
       */
      updateValueByFormula () {
        let f = this.field.formula
        for (let fieldName of this.field.formulaFields) {
          let val
          let subFieldName
          let tmpFieldName
          if (fieldName.includes('@')) {
            subFieldName = fieldName.split('@')[1]
            tmpFieldName = fieldName.split('@')[0]
            if (this.field.fullPrefix) {
              tmpFieldName = this.field.fullPrefix + tmpFieldName
            }
            let sum = 0
            for (let r of this.formData[tmpFieldName]['rows']) {
              sum = sum + r[subFieldName]
            }
            val = sum
          } else {
            if (this.field.fullPrefix) {
              val = this.formData[this.field.fullPrefix + fieldName]
            } else {
              val = this.formData[fieldName]
            }
          }
          if (val && typeof val === 'string' && val.includes('#')) {
            val = val.split('#')[1]
          }
          if (val === undefined || val == null || (typeof val === 'string' && val.length === 0)) {
            this.val = null
            return
          }
          f = kit.str.replaceAll(f, '\\$' + fieldName, val)
        }
        try {
          this.val = kit.num.toFixedTrimZero(eval(f), this.field.point)
        } catch (e) {
          this.val = null
        }
      }
    },
    mounted () {
      if (this.formData && this.hasFormula) {
        this.$watch(
          () => {
            let tmp = ''
            // fieldName不包含组的前缀
            for (let fieldName of this.field.formulaFields) {
              if (fieldName.includes('@')) {
                let subFieldName = fieldName.split('@')[1]
                let tmpFieldName = fieldName.split('@')[0]
                if (this.field.fullPrefix) {
                  tmpFieldName = this.field.fullPrefix + tmpFieldName
                }
                let sum = 0
                if (this.formData[tmpFieldName]) {
                  for (let r of this.formData[tmpFieldName]['rows']) {
                    sum = sum + r[subFieldName]
                  }
                }
                tmp += sum
              } else {
                if (this.field.fullPrefix) {
                  tmp += this.formData[this.field.fullPrefix + fieldName]
                } else {
                  tmp += this.formData[fieldName]
                }
              }
            }
            return tmp
          },
          () => {
            this.updateValueByFormula()
          }
        )
      }
    }
  }
</script>

<style lang="less" scoped>
  .number-val {
    flex: 1;
  }

  .currency {
    margin-left: 5px;
    width: 80px;
  }

  @deep: ~'>>>';
  .formula-bg @{deep} input {
    background-color: #ebfeef !important;
  }
</style>
