<template>
  <div class="split-line" :class="align">
    <div class="line"></div>
    <span :style="{ 'background-color': backgroundColor }">{{text}}</span>
  </div>
</template>

<script>
  export default {
    name: 'split-line',
    props: {
      text: { type: String, required: true },
      align: { type: String, default: 'left' },
      backgroundColor: { type: String, default: '#fff' }
    }
  }
</script>

<style lang="less" scoped>
  .split-line {
    position: relative;
    margin: 15px 0;
    .line {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      z-index: -1;
      height: 1px;
      background-color: #e6e6e6;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &.left {
      padding-right: 10px;
    }
    &.center {
      text-align: center;
      span {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &.right {
      text-align: right;
      span {
        padding-left: 10px;
      }
    }
  }
</style>
