<!--
  ///////////////// SlidePanel ////////////////////
  滑动面板，设计目的类似Dialog，面板会从某一方向滑出来。
  注意事项1：你应当每次只能打开一个SlidePanel，虽然这不是强制性的，但如果同时打开多个，可能会出现z轴顺序问题。
  注意事项2：在SlidePanel中打开ElementUI的Dialog时可能会出现Dialog在遮罩层底下的问题。

  用法：
  <slide-panel :visible.sync="visible">
    <span slot="title">面板标题</span>
    自定义内容在这里
  </slide-panel>

  属性：
    visible {boolean} 控制面板的显示和隐藏，你需要使用.sync修饰符来绑定属性。
    closeable {boolean} 控制面板是否能自我关闭，如果设置为false，点击面板的关闭按钮和遮罩层是无法关闭面板的，你需要使用.sync修饰符来绑定属性。
    position {String} 面板滑出的方向，如"left"表示从左侧滑出，支持的值有：left, right, top, bottom。
      注：如果你使用水平方向滑出时（left或right），面板的高度会被强制性设置为最大，你的height属性会被忽略。
    width {Number | String} 面板的宽度，可使用字符串设置百分比宽度，如：'60%'。
    height {Number | String} 面板的高度，同width属性。

  事件：
    beforeOpen
    open
    beforeClose
    close
-->
<template>
  <transition
    @before-enter="onPanelBeforeOpen"
    @enter="onPanelOpen"
    @leave="onPanelClose">
    <div v-transfer-dom :data-transfer="true" class="slide-panel" :class="positionClass" v-show="visible">
      <div class="header">
        <i class="close-button el-icon-close" :class="{disabled: !canClose}" @click="close"></i>
        <span class="panel-title">
          <slot name="title"></slot>
        </span>
      </div>
      <div class="body" v-loading="loading" element-loading-text="正在加载...">
        <slot></slot>
      </div>
      <div class="footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
  import Velocity from 'velocity-animate'
  import Dom from '@/tools/dom'
  import TransferDom from '../../directives/transfer-dom'

  export default {
    directives: { TransferDom },
    props: {
      visible: { type: Boolean, default: false },
      closeable: { type: Boolean, default: true },
      position: {
        type: String,
        default: 'right',
        validator (v) {
          return v === 'left' || v === 'top' || v === 'right' || v === 'bottom'
        }
      },
      width: {
        type: [String, Number],
        default: 400,
        validator (v) {
          return typeof v === 'number' || /^[0-9]+%$/.test(v)
        }
      },
      height: {
        type: [String, Number],
        default: '100%',
        validator (v) {
          return typeof v === 'number' || /^[0-9]+%$/.test(v)
        }
      }
    },
    data () {
      return {
        canClose: this.closeable,
        loading: false
      }
    },
    computed: {
      positionClass () {
        return {
          'position-horizontal': this.position === 'left' || this.position === 'right',
          'position-vertical': this.position === 'top' || this.position === 'bottom',
          'position-left': this.position === 'left',
          'position-right': this.position === 'right',
          'position-top': this.position === 'top',
          'position-bottom': this.position === 'bottom'
        }
      },
      panelWidth () {
        let bodyWidth = document.body.clientWidth
        let w = this.width
        if (typeof this.width === 'string') {
          w = parseInt(this.width.replace('%', '')) / 100 * bodyWidth
        }
        return w
      },
      panelHeight () {
        let bodyHeight = document.body.clientHeight
        let h = this.height
        if (typeof this.height === 'string') {
          h = parseInt(this.height.replace('%', '')) / 100 * bodyHeight
        }
        return h
      },
      /**
       * 面板出现的方向，true表示水平方向，false表示垂直方向。
       * @return {boolean}
       */
      direction () {
        return this.position === 'left' || this.position === 'right'
      },
      /**
       * 如果面板是水平方向出现，返回{@link panelWidth}，否则返回{@link panelHeight}。
       * @return {*}
       */
      directionSize () {
        return this.direction ? this.panelWidth : this.panelHeight
      }
    },
    methods: {
      onPanelBeforeOpen (el) {
        this.modal = new Dom('div')
          .css({
            'position': 'fixed',
            'left': 0,
            'top': 0,
            'right': 0,
            'bottom': 0,
            'background-color': 'rgba(255,255,255,.3)',
            'zIndex': 999
          })
          .on('click', this.close, this)
          .appendTo(el.parentElement)
        el.style.width = this.panelWidth + 'px'
        el.style[this.position] = -this.directionSize + 'px'
        if (this.direction) {
          el.style.height = 'auto'
        } else {
          el.style.height = this.panelHeight + 'px'
          el.style.left = '50%'
          el.style.marginLeft = -(this.panelWidth / 2) + 'px'
        }
      },
      onPanelOpen (el, done) {
        let _this = this
        _this.$emit('beforeOpen')
        Velocity(el, { [this.position]: 0 }, { duration: 200 })
        Velocity(this.modal.element, 'fadeIn', {
          duration: 200,
          complete: function () {
            done()
            _this.$emit('open')
          }
        })
      },
      onPanelClose (el, done) {
        let _this = this
        _this.$emit('beforeClose')
        Velocity(el, { [this.position]: -this.directionSize + 'px' }, { duration: 200 })
        Velocity(this.modal.element, 'fadeOut', {
          duration: 200,
          complete: function () {
            _this.modal.remove()
            _this.modal = null
            done()
            _this.$emit('close')
          }
        })
      },
      close () {
        if (this.canClose) {
          this.setLoading(false)
          this.$emit('update:visible', false)
        }
      },
      setCloseable (closeable) {
        this.canClose = closeable
        this.$emit('update:closeable')
      },
      setLoading (loading, closeable) {
        this.loading = loading
        closeable !== undefined && this.setCloseable(closeable)
      }
    }
  }
</script>

<style lang="less" scoped>

  @border: solid 1px #ddd;
  .slide-panel {
    position: fixed;
    display: flex;
    flex-flow: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    overflow: hidden;
    z-index: 2000;
    &.position-horizontal {
      top: 0;
      bottom: 0;
    }
    &.position-top {
      border-left: @border;
      border-right: @border;
      border-bottom: @border;
    }
    &.position-bottom {
      border-left: @border;
      border-right: @border;
      border-top: @border;
    }
    &.position-right {
      border-left: @border;
    }
    &.position-left {
      border-right: @border;
      .close-button {
        float: right;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }

  .slide-panel .header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    border-bottom: solid 1px #ddd;
    background-color: #f9f9f9;
    .close-button, .panel-title {
      vertical-align: middle;
      font-size: 14px;
      font-weight: bold;
      color: #2a2a2a;
    }
    .close-button {
      cursor: pointer;
      padding: 4px;
      border-radius: 3px;
      user-select: none;
      &:hover {
        background-color: #f2f2f2;
      }
      &:active {
        background-color: #f9f9f9;
      }
      &.disabled {
        color: #828282;
        cursor: not-allowed;
      }
    }
  }

  .slide-panel .body {
    flex: 1;
    position: relative;
    padding: 10px;
    overflow-x: visible;
    overflow-y: auto;
    font-size: 12px;
  }

  .slide-panel .footer {
    border-top: solid 1px #ddd;
    padding: 10px;
  }

  @deep: ~'>>>';
  .slide-panel @{deep} .el-form-item:last-of-type {
    margin-bottom: 0 !important;
  }
</style>
