<!--
  考核客户
-->
<template>
  <page route-name="KpiCustomer">
    <el-button slot="toolbar" icon="el-icon-plus"  type="primary" @click="$refs['customerPanel'].open()">创建</el-button>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="名称查询">
          <el-input v-model="query.name" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      stripe
      :data="dataList">
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-delete" type="danger" @click="deleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" ></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <!--编辑-->
    <customer-editor ref="customerPanel" @submit="onEditorSubmit"></customer-editor>
  </page>
</template>

<script>
  import CustomerEditor from './comp/editor'
  import api, { API_KPI_CUSTOMERS, API_KPI_CUSTOMER_DELETE } from '../../../config/urls'
  import kit from '@/tools/kit'
  import PaginationMixins from '@/mixins/pagination'
  export default {
    components: { CustomerEditor },
    mixins: [PaginationMixins],
    data () {
      return {
        dataList: [],
        query: {
          name: null
        }
      }
    },
    computed: {},
    methods: {
      load () {
        this.$http.get(api(API_KPI_CUSTOMERS), {
          params: {
            name: this.query.name,
            start: this.page.start,
            limit: this.page.limit
          },
          success: resp => {
            this.dataList = resp.data
            this.page.total = resp.total
          }
        })
      },
      onEditorSubmit (data) {
        kit.arr.pushOrReplace(this.dataList, data)
      },
      deleteData (data) {
        this.$confirm(`确定要删除[${data.name}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            this.$http.del(api(API_KPI_CUSTOMER_DELETE, { id: data.id }), {
              completeBefore: () => this.$pageLoading(false),
              success: () => {
                this.$message.success('删除成功。')
                kit.arr.remove(this.dataList, data)
              }
            })
          })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
