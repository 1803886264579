import defineApi from '../define-api'
import http from '../application-api'

export const getUpDownCostReportList = defineApi((config, params) => {
  config.params = params
  return http.load('/up_down_cost_report/list', config)
})
export const getSumCost = defineApi((config, params) => {
  config.params = params
  return http.load('/up_down_cost_report/sumcost', config)
})
