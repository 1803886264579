import AliyunData from '../../pages/aliyun-data'
/**
 * 阿里云数据。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'AliyunData',
    path: '/aliyun_data',
    meta: { title: '阿里云数据' },
    component: AliyunData
  }
]
