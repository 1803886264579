import defineApi from '../define-api'
import http from '../application-api'

export const getAdChannelList = defineApi((config, keyword) => {
  config.params = {
    keyword: keyword
  }
  return http.load('/ad_channel_info/list', config)
})

export const addAdChannelInfo = defineApi((config, info) => {
  config.data = info
  http.post('/ad_channel_info/add', config)
})

export const updateAdChannelInfo = defineApi((config, info) => {
  config.data = info
  http.post('/ad_channel_info/update', config)
})

export const deleteAdChannelInfo = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/ad_channel_info/delete', config)
})

