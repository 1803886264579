import { userStore } from '../store'

/*
  注册为全局指令，用于判断当前登录用户是否有权限拥有UI。
  <div v-authority="'权限ID'"></div>
 */
const directive = {
    inserted (el, { value }) {
      let auth = userStore.state.authorities
      if (!auth.includes(value)) {
        el.remove()
      }
    }
}

export default directive
