<template>
  <page route-name="DataCollectSearch">
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="创建日期">
          <el-date-picker
                  v-model="query.createDate"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="query.key" style="width: 150px;" clearable placeholder="输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      :data="dataList"
    >
      <el-table-column label="图片"  width="150">
        <template slot-scope="scope">
          <img class="image-cover" :src="'/api/hehu/data-collect/image/' + scope.row.id" @click="viewImageUrl = '/api/hehu/data-collect/image/' + scope.row.id">
        </template>
      </el-table-column>
      <el-table-column prop="caizhi" label="材质" ></el-table-column>
      <el-table-column prop="name" label="名称" ></el-table-column>
      <el-table-column prop="bianhao" label="编号" width="200"></el-table-column>
      <el-table-column prop="kuanhao" label="款号" width="200"></el-table-column>
      <el-table-column prop="guige" label="规格" ></el-table-column>
      <el-table-column prop="jiage" label="价格" width="200"></el-table-column>
      <el-table-column prop="createTime" label="创建日期" ></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <image-browser :url="viewImageUrl"></image-browser>
  </page>
</template>

<script>
  import kit from '../../tools/kit'
  import { getDataCollectList } from '../../http/api/data-collect'

  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        query: {
          createDate: [],
          key: null
        },
        viewImageUrl: null
      }
    },
    computed: {
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    methods: {
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        let params = Object.assign({}, this.query)
        params.start = this.page.start
        params.limit = this.page.limit
        if (params.createDate && this.query.createDate.length === 2) {
          params.createDateStart = this.query.createDate[0] + ' 00:00:00'
          params.createDateEnd = this.query.createDate[1] + ' 23:59:59'
          delete params.createDate
        }
        this.$pageLoading(true)
        getDataCollectList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            let page = resp.data
            this.dataList = page.records
            this.page.total = page.total
          })
          .send(params)
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .image-cover {
    max-width: 150px;
    height: auto;
    margin: 0;
    display: block;
  }
</style>
