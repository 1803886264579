import LowerPolicyList from '../../pages/policy/lower'
import { createWorkflowDetailRouters } from './workflow'
import { FLOW_AD_POLICY } from '../../config/flows'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'LowerPolicyList',
    path: '/lower_policy_list',
    meta: { title: '客户政策列表' },
    component: LowerPolicyList,
    children: [
      createWorkflowDetailRouters('flowId', FLOW_AD_POLICY)
    ]
  }
]
