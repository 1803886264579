<template>
  <modal-panel
    :visible.sync="panelVisible"
    :closable="panelCloseable"
    :width="panelWidth"
    :disableFooterButton="true"
    title="订单编辑"
    @close="panelBeforeClose"
    @save="submit"
    class="bg-order-dialog"
  >
    <div class="main-content">
      <!-- 转账截图 -->
      <div style="position: relative; width: 500px; margin-right: 10px;">
        <div style="display: flex; flex-direction: row;">
          <el-button
              type="primary"
              style="flex: .5;"
              @click="selectUploadFiles()"
          >
            上传附件
          </el-button>
          <el-dropdown @command="handleUploadFileFromScreenshot" style="flex: .5; margin-left: 10px;">
            <el-button type="primary" style="width: 100%;">
              上传附件并自动提取转账信息
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="st in screenshotTypes" :key="st.id" :command="st.id">{{st.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="position: relative; margin-top: 5px;" v-if="imageUrl">
          <i class="remove-image el-icon-close" @click="handleRemoveImage"></i>
          <img :src="imageUrl" style="width: 100%">
        </div>
        <div v-else style="font-size: 12px; text-align: center; margin-top: 20px; color: #4f4e4e">未上传截图</div>
        <div v-if="modelData.processStatus === status.verified" class="order-validated">已校验</div>
      </div>
      <!-- 数据表单 -->
      <el-form ref="form" :model="form" status-icon label-width="120px" style="flex: 1;">
        <el-form-item label="业务订单号" prop="recieveName" class="field-item">
          <span class="field-value">{{modelData.bizNo}}</span>
        </el-form-item>
        <el-form-item label="收款人" prop="recieveName" class="field-item">
          <span class="field-value">{{modelData.recieveName}}</span>
        </el-form-item>
        <el-form-item label="收款账户" prop="recieveAccount" class="field-item">
          <span class="field-value">{{modelData.recieveAccount}}</span>
        </el-form-item>
        <el-form-item label="收款类型" prop="tradeTypeStr" class="field-item">
          <span class="field-value">{{modelData.tradeTypeStr}}</span>
        </el-form-item>
        <el-form-item label="收款金额" prop="price">
          <span style="font-size: 22px; font-weight: bold; color: #96342d">{{modelData.price}}</span>
          {{modelData.currency}}
          <el-alert
              :closable="false"
              type="error"
              class="price-not-same" v-show="!modelData.priceSameWithScreenshot"
          >
            转账金额不正确，从截图中提取的转账金额为<span>{{modelData.priceFromImage}}</span>。
          </el-alert>
        </el-form-item>
        <el-form-item label="支付情况" prop="payStatus" class="app_required-input">
          <el-select
              style="width: 100%;"
              v-model="form.payStatus"
              filterable
              placeholder="请选择支付情况"
          >
            <el-option v-for="item in payStatusOption" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流水号" prop="serialNo" v-if="form.payStatus === payStatusSuccess">
          <el-input class="pretty-input" v-model="form.serialNo"></el-input>
        </el-form-item>
        <el-form-item label="付款手续费" prop="payeeAmount" v-if="form.payStatus === payStatusSuccess">
          <el-input-number v-model="form.payeeAmount"  :min="0"  label="付款手续费" style="width: 100%;"></el-input-number>
        </el-form-item>
        <el-form-item label="付款时间" prop="payTime" v-if="form.payStatus === payStatusSuccess">
          <el-date-picker
              v-model="form.payTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="12:00:00"
              style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款账户" prop="payeeAccount" v-if="form.payStatus === payStatusSuccess">
          <el-input v-model="form.payeeAccount"></el-input>
        </el-form-item>
        <el-form-item label="付款户名" prop="payeeName" v-if="form.payStatus === payStatusSuccess">
          <el-input v-model="form.payeeName"></el-input>
        </el-form-item>
        <el-form-item label="支付备注" prop="remark" >
          <el-input type="textarea" :rows="2" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="bottom-bar">
      <div class="loading" v-show="submitting">
        <i class="el-icon-loading" style="color: #fff;"></i>
      </div>
      <div style="flex: .5; text-align: left;" v-if="models.length > 0">
        <el-button
            icon="el-icon-arrow-left"
            @click="handleChangeModel('prev')"
            :disabled="currentModelIndex === 0"
        >
          上一个订单
        </el-button>
        <span class="total-message">{{currentModelIndex + 1}} / {{models.length}}</span>
        <el-button
            icon="el-icon-arrow-right"
            @click="handleChangeModel('next')"
            :disabled="currentModelIndex === models.length - 1"
        >
          下一个订单
        </el-button>
      </div>
      <div style="flex: 1; text-align: right;">
        <el-button type="success" @click="handleChecked">保存并校验</el-button>
        <el-button type="primary" @click="handleSave">保存数据</el-button>
        <el-button type="primary" @click="handleSubmitToBangGu">提交到棒谷</el-button>
        <el-button @click="panelVisible = false">取消</el-button>
      </div>
    </div>
  </modal-panel>

</template>

<script>
  import {
    updateOrder,
    uploadImg,
    uploadImgFromScreenshot,
    sendOrderBangList
  } from '../../../../http/api/bg-order'
  import kit from '../../../../tools/kit'
  import {
    payStatusOption,
    PAY_STATUS_WAIT,
    PAY_STATUS_SUCCESS,
    STATUS_VERIFIED,
    STATUS_SUCCESS,
    screenshotTypes, STATUS_ENTERED
  } from '../../common-attributes'
  import selectFile from '../../../../tools/select-file'
  import { getAdminUrl } from '@/http/application-api'

  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    data () {
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        models: [1], // 用于进行轮询编辑的数据列表
        form: {
          id: null,
          payStatus: null,
          payTime: null,
          payeeAccount: null,
          payeeName: null,
          payeeAmount: null,
          remark: null,
          serialNo: null,
          receiptImageUrl: null,
          processStatus: null,
          priceFromImage: null
        },
        payStatusOption: payStatusOption,
        payStatusSuccess: PAY_STATUS_SUCCESS,
        status: {
          success: STATUS_SUCCESS,
          verified: STATUS_VERIFIED
        },
        screenshotTypes: screenshotTypes
      }
    },
    computed: {
      modelData () {
        return this.model || {}
      },
      panelWidth () {
        // if (this.form.payStatus === PAY_STATUS_SUCCESS) {
        return 1000
        // } else {
        //   return 600
        // }
      },
      isUpdate () {
        return !!this.model
      },
      imageUrl () {
        if (this.form.receiptImageUrl) {
          return getAdminUrl('/file/' + this.form.receiptImageUrl)
        }
        return null
      },
      currentModelIndex () {
        if (this.models && this.models.length > 0) {
          return this.models.findIndex(item => item === this.model)
        } else {
          return -1
        }
      }
    },
    methods: {
      open (model, models) {
        this.panelVisible = true
        this.models = models || []
        this.renderModel(model)
      },
      renderModel (model) {
        this.model = model
        kit.obj.getProperties(this.form, model)
        if (this.form.payStatus === PAY_STATUS_WAIT) {
          this.form.payStatus = PAY_STATUS_SUCCESS
        }
        this.takeDataFromLocalStorage()
      },
      saveDataToLocalStorage () {
        localStorage.setItem('usersPayeeAccount', this.form.payeeAccount)
        localStorage.setItem('usersPayeeName', this.form.payeeName)
      },
      takeDataFromLocalStorage () {
        if (!this.form.payeeAccount) {
          this.form.payeeAccount = localStorage.getItem('usersPayeeAccount')
        }
        if (!this.form.payeeName) {
          this.form.payeeName = localStorage.getItem('usersPayeeName')
        }
      },
      submit (successCallback) {
        if (this.canSubmit()) {
          this.submitting = true
          this.panelCloseable = false
          updateOrder()
            .complete(() => {
              this.panelCloseable = true
              this.submitting = false
            })
            .success(resp => {
              this.saveDataToLocalStorage()
              this.$message.success('保存成功。')
              this.$emit('submit', resp.data)
              kit.obj.getProperties(this.form, resp.data)
              kit.obj.getProperties(this.model, resp.data)
              successCallback && successCallback()
            })
            .send(this.form)
        } else {
          this.$message.warning('请上传转账截图。')
        }
      },
      handleSave () {
        this.form.processStatus = STATUS_ENTERED
        this.submit()
      },
      handleChecked () {
        if (!kit.str.isBlank(this.form.receiptImageUrl)) {
          this.form.processStatus = STATUS_VERIFIED
          this.submit()
        } else {
          this.$message.error('请上传转账截图。')
        }
      },
      handleSubmitToBangGu () {
        this.submitting = true
        this.panelCloseable = false
        sendOrderBangList()
          .complete(() => {
            this.panelCloseable = true
            this.submitting = false
          })
          .success(resp => {
            if (resp.data.success.length > 0) {
              this.$message.success('已经提交到棒谷。')
              this.form.processStatus = STATUS_SUCCESS
              this.model.processStatus = STATUS_SUCCESS
              this.$emit('submit', this.model)
            } else {
              this.$message.error(resp.data.error[this.model.id])
            }
          })
          .send([this.model.id])
      },
      handleRemoveImage () {
        this.form.receiptImageUrl = null
      },
      handleChangeModel (dir) {
        let idx = this.currentModelIndex
        let model = null
        if (idx >= 0) {
          if (dir === 'prev') {
            idx--
            model = this.models[idx]
          } else {
            idx++
            model = this.models[idx]
          }
        }
        if (model) {
          this.renderModel(model)
        }
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
        this.models = []
        this.form.id = null
        this.form.receiptImageUrl = null
      },
      selectUploadFiles (useOcr) {
        if (this.submitting) {
          return
        }
        selectFile({
          accept: 'image/png,image/jpeg',
          select: files => {
            let file = files[0]
            uploadImg()
              .complete(() => {})
              .success(resp => {
                this.form.receiptImageUrl = resp.data
                this.model.receiptImageUrl = resp.data
              })
              .send(this.model.id, file, useOcr)
          }
        })
      },
      handleUploadFileFromScreenshot (screenshotType) {
        if (this.submitting) {
          return
        }
        selectFile({
          accept: 'image/png,image/jpeg',
          select: files => {
            let file = files[0]
            uploadImgFromScreenshot()
              .complete(() => {})
              .success(resp => {
                kit.obj.getProperties(this.form, resp.data)
                kit.obj.merge(this.model, resp.data)
                this.saveDataToLocalStorage()
                this.$emit('submit', resp.data)
              })
              .send(this.model.id, file, screenshotType, this.form.payeeAccount, this.form.payeeName)
          }
        })
      },
      canSubmit () {
        if (this.form.payStatus === PAY_STATUS_SUCCESS) {
          return !kit.str.isBlank(this.form.receiptImageUrl)
        }
        return true
      }
    }
  }
</script>

<style scoped lang="less">
  @deep: ~'>>>';

  .main-content {
    display: flex;
    flex-direction: row;
    .field-value {
      color: #2a4686;
    }
  }
  .field-item {
    margin-bottom: 5px !important;
  }
  .bottom-bar {
    flex-direction: row;
    display: flex;
    .total-message {
      padding: 0 20px;
      font-size: 13px;
      color: #3a3a3a;
    }
    .loading {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,.2);
      text-align: center;
    }
  }

  .remove-image {
    @size: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    width: @size;
    line-height: @size;
    height: @size;
    border-radius: @size;
    color: #96342d;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
  }

  .order-validated {
    position: absolute;
    transform:rotate(-20deg);
    left: 50%;
    margin-left: -50px;
    top: 250px;
    padding: 20px;
    border: solid 5px #88c17a;
    color: #88c17a;
    font-weight: bold;
    font-size: 30px;
    opacity: .5;
    letter-spacing: 5px;
    border-radius: 20px;
  }

  .pretty-input @{deep} input {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: bold;
    color: #96342d;
  }
  .price-not-same {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .price-not-same @{deep} .el-alert__description {
    margin-top: 0 !important;
  }
</style>
