<template>
  <div class="absolute-full auto-layout flow-col template-container">
    <div style="padding: 5px 5px 5px;">
      <el-form :inline="true" :model="search">
          <el-form-item label="推广平台" prop="platformId">
            <el-select v-model="search.platformId" :clearable="true" style="width:120px;">
              <el-option
                v-for="item in platforms"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="创建日期" prop="createDate">
          <el-date-picker
            v-model="search.createDate"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 280px;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="账期类型" prop="rechargePayType" >
          <el-select v-model="search.rechargePayType" :clearable="true" style="width: 120px;">
            <el-option
              v-for="item in rechargePayTypeOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户" prop="customerId">
          <el-select
            v-model="search.customerId"
            filterable
            remote
            :clearable="true"
            placeholder="请输入关键词"
            :remote-method="handleSearchCustomer"
            :loading="searchCustomerLoading"
            style="width: 100%;"
          >
            <el-option v-for="item in searchCustomers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告账户Id" prop="adId" >
          <el-input v-model="search.adId" clearable style="width: 150px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel()" class="button" v-authority="'DownloadExcel'" ><i class="el-icon-download"></i>导出excel</a>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel('detail')" class="button" v-authority="'DownloadExcel'" ><i class="el-icon-download"></i>导出明细excel</a>
        </el-form-item>
      </el-form>
    </div>
    <div class="auto-layout-full" style="position: relative;">
    <div class="mlu-table" id="mlu-table">
    <el-table
      slot="center"
      :data="dataList"
      ref="table"
      :max-height="maxHeight"
    >
      <el-table-column type="expand">
        <template slot-scope="{ row }">
            <el-table
              :data="row.flowRecharge.orderDetails"
              stripe
              style="width: 500px; margin-left: 40px;"
            >
              <el-table-column label="账户id" prop="id" ></el-table-column>
              <el-table-column label="账户名称" prop="name" ></el-table-column>
              <el-table-column label="下单金额" prop="je" width="100">
                <template slot-scope="{ row }">
                  <span style="font-weight: bold; color: #a94442">{{row.je | formatCurrency}}</span>
                </template>
              </el-table-column>
            </el-table>
        </template>
      </el-table-column>
      <el-table-column  label="充值时间" width="145px;" >
        <template slot-scope="{ row }">{{row.flowRecharge.rechargeDate}}</template>
      </el-table-column>
      <el-table-column  label="平台" width="80px;">
        <template slot-scope="{ row }">
          <span v-if="row.ads">{{row.ads.platformName}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="客户签约主体名称" width="200">
        <template slot-scope="{ row }">
          <span v-if="row.adCustomer">{{row.adCustomer.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="付款类型" width="80">
        <template slot-scope="{ row }">
          <span  v-if="row.flowRecharge.payType === 'payLater'">账期</span>
          <span  v-else-if="row.flowRecharge.payType === 'imprest2'">垫款</span>
          <span  v-else-if="row.flowRecharge.payType === 'rebate'">返点抵扣</span>
          <span  v-else>预付</span>
        </template>
      </el-table-column>
        <el-table-column  label="充值金额" width="100px;">
          <template slot-scope="{ row }">
            <span style="font-weight: bold; color: #a94442">{{row.flowRecharge.price | formatCurrency}}</span>
            <span  v-if="row.flowRecharge.orderCurrency === 'USD'">美元</span>
            <span  v-else-if="row.flowRecharge.orderCurrency === 'HKD'">港币</span>
            <span  v-else-if="row.flowRecharge.orderCurrency === 'CNY'">人民币</span>
            <span  v-else>{{row.flowRecharge.orderCurrency}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="预计到账日期" width="100px;">
          <template slot-scope="{ row }">{{row.flowRecharge.paymentDays}}</template>
        </el-table-column>
        <el-table-column  label="款项状态" width="80px;">
          <template slot-scope="{ row }">
            <span style="color: #3C763C" v-if="row.flowRecharge.payed">已回款</span>
            <span style="color: #a94442" v-else>未回款</span>
          </template>
        </el-table-column>
        <el-table-column  label="实际付款日期" width="100px;">
          <template slot-scope="{ row }">{{row.flowRecharge.payedDate}}</template>
        </el-table-column>
        <el-table-column  label="实收金额" width="100px;">
          <template slot-scope="{ row }">
            <span style="font-weight: bold; color: #a94442">{{row.flowRecharge.actualPrice | formatCurrency}}</span>
            {{row.flowRecharge.actualCurrency}}
          </template>
        </el-table-column>
        <el-table-column prop="flowRecharge.feesPrice" label="手续费" ></el-table-column>
        <el-table-column prop="flowRecharge.servicePrice" label="服务费" ></el-table-column>
        <el-table-column prop="flowRecharge.taxesPrice" label="税金" ></el-table-column>
        <el-table-column prop="flowRecharge.ratesPrice" label="汇率" ></el-table-column>
        <el-table-column prop="createUserName" label="创建人" width="100px;" ></el-table-column>
        <el-table-column prop="createDate" label="创建时间" width="130px;" ></el-table-column>
    </el-table>
      </div>
    </div>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </div>
  </template>
<script>
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import { searchRechargeFlowReportData } from '../../../../http/api/workflow'
  import * as flows from '../../../../config/flows'
  import kit from '../../../../tools/kit'
  import { getAdminUrl } from '../../../../http/application-api'

  export default {
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          createDate: [],
          customerId: null,
          rechargePayType: null,
          adId: null
        },
        rechargePayTypeOptions: [
          { id: 'payLater', title: '账期' },
          { id: 'imprest1', title: '预付' },
          { id: 'imprest2', title: '垫付' },
          { id: 'rebate', title: '返点抵扣' }
        ],
        searchCustomerLoading: false,
        searchCustomers: [],
        platforms: [],
        maxHeight: 350
      }
    },
    computed: {
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    filters: {
      formatCurrency: function (price) {
        return kit.str.fmtCurrency(price, 2, '')
      }
    },
    methods: {
      handleSearchCustomer (q) {
        this.searchCustomerLoading = true
        getAdCustomerListByKeyword()
          .complete(() => (this.searchCustomerLoading = false))
          .success(resp => {
            this.searchCustomers = resp.data
          })
          .send(q)
      },
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        if (this.search.createDate && this.search.createDate.length === 2) {
          params.startCreateTime = this.search.createDate[0] + ' 00:00:00'
          params.endCreateTime = this.search.createDate[1] + ' 23:59:59'
        }
        params.createDate = undefined
        this.$pageLoading(true)
        searchRechargeFlowReportData()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.page.total = resp.data.total
            this.dataList = resp.data.records
          })
          .send(flows.FLOW_RECHARGE, params)
      },
      downloadExcel (type) {
        let params = Object.assign({}, this.search)
        if (this.search.createDate && this.search.createDate.length === 2) {
          params.startCreateTime = this.search.createDate[0] + ' 00:00:00'
          params.endCreateTime = this.search.createDate[1] + ' 23:59:59'
        }
        params.createDate = undefined
        let arr = []
        for (let key of Object.keys(params)) {
          if (params[key]) {
            arr.push(key + '=' + params[key])
          }
        }
        let url = ''
        if (type === 'detail') {
          url = '/flow/Recharge/download/detail_excel?' + arr.join('&')
        } else {
          url = '/flow/Recharge/download/excel?' + arr.join('&')
        }
        return getAdminUrl(url)
      }
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    },
    updated () {
      this.$nextTick(() => {
        // 在这里面去获取DOM
        let dom = document.getElementById('mlu-table')
        this.maxHeight = dom.clientHeight
      })
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .template-container @{deep} .mlu-table {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
  }

  .template-container @{deep} .el-table__body-wrapper {
    flex: 1;
    overflow-y: auto !important;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
