<template>
  <page route-name="Todo">

    <el-button slot="toolbar" type="primary" icon="el-icon-refresh" @click="reload">刷新</el-button>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="内容">
          <el-input v-model="query.data" style="width: 150px;"></el-input>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="query.createDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始"
            end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="完成状态">
          <el-select v-model="query.completed" :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in [{id: 1, title: '已完成'}, {id: 0, title: '未完成'}]"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类别">
          <el-select v-model="query.tag" :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in tagFilter"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      @sort-change="onSort"
      :data="dataList">
      <el-table-column label="操作" width="50">
        <template slot-scope="scope">
          <el-button size="mini" @click="processTodo(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="completed"
        label="是否完成"
        width="100">
        <template slot-scope="scope">
          <span class="text-muted font-mini" v-if="scope.row.completed">已完成</span>
          <el-button @click="markComplete(scope.row)" type="success" size="mini" v-else>标记完成</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="tag"
        label="类别"
        width="120">
        <template slot-scope="scope">
          {{scope.row.tag | tagText}}
        </template>
      </el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <div v-html="formatTodoContent(scope.row)"></div>
        </template>
      </el-table-column>
      <el-table-column sortable="custom" prop="createDate" label="创建日期" width="150"></el-table-column>
      <el-table-column sortable="custom" prop="activatedTime" label="激活时间" width="150"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
  </page>
</template>

<script>
  import { FLOW_NAMES_MAPPING } from '../../../config/flows'
  import flowDetailNavMixin from '../../../mixins/flow-detail-nav'
  import kit from '../../../tools/kit'
  import { getMyTodoList, markTodoAsCompleted } from '../../../http/api/todo'
  import { MSG_TYPE_TODO } from '@/components/stomp'

  export default {
    mixins: [flowDetailNavMixin],
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        query: {
          data: null,
          createDate: null,
          completed: 0,
          tag: null
        }
      }
    },
    computed: {
      tagFilter () {
        let arr = []
        for (let k of Object.keys(FLOW_NAMES_MAPPING)) {
          arr.push({
            text: FLOW_NAMES_MAPPING[k],
            value: k
          })
        }
        return arr
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    filters: {
      tagText (tag) {
        return FLOW_NAMES_MAPPING[tag]
      }
    },
    methods: {
      processTodo (todo) {
        this.navToWorkflowDetail('_CUSTOM_Todo', todo.data.flowId, todo.data.flowType)
      },
      markComplete (todo) {
        markTodoAsCompleted()
          .success(() => {
            todo.completed = true
            this.$message.success('事项已完成。')
          })
          .send(todo.id)
      },
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        let params = kit.obj.merge({}, {
          start: this.page.start,
          limit: this.page.limit
        }, this.query)
        delete params.createDate
        if (this.query.createDate && this.query.createDate.length === 2) {
          params.startCreateDate = this.query.createDate[0] + ' 00:00:00'
          params.endCreateDate = this.query.createDate[1] + ' 23:59:59'
        }
        this.$pageLoading(true)
        getMyTodoList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            let page = resp.data
            for (let todo of page.records) {
              try {
                todo.data = JSON.parse(todo.data)
              } catch (e) {
                todo.data = {}
              }
            }
            this.dataList = page.records
            this.page.total = page.total
          })
          .send(params)
      },
      formatTodoContent (todo) {
        let content = todo.content
        for (let k of Object.keys(todo.data)) {
          content = content.replace('${' + k + '}', `<span class="text-danger font-bold">${todo.data[k]}</span>`)
        }
        return content
      },
      onSort (column) {
        if (column.prop) {
          this.query.orderName = column.prop
          this.query.order = column.order.startsWith('asc') ? 'asc' : 'desc'
        } else {
          this.query.order = undefined
          this.query.orderName = undefined
        }
        this.reload()
      }
    },
    stomp: {
      [MSG_TYPE_TODO] (msg) {
        if (this.query.completed === 0 && this.page.start === 0) {
          this.reload()
        }
      }
    },
    mounted () {
      this.load()
      this.$dispatcher.listen('flow_stage_submit', 'flow_stage_submit_todo', flowId => {
        for (let todo of this.dataList) {
          if (todo.relativeData === flowId) {
            todo.completed = true
          }
        }
      })
    },
    beforeDestroy () {
      this.$dispatcher.removeListen('flow_stage_submit', 'flow_stage_submit_todo')
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
