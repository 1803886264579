export const tradeTypesOption = [{
  id: '1',
  label: '支付宝'
}, {
  id: '2',
  label: '网银'
}, {
  id: '3',
  label: 'Paypal'
}, {
  id: '4',
  label: '微信'
}, {
  id: '5',
  label: '全球付'
}, {
  id: '6',
  label: 'payoneer'
}]

export const PAY_STATUS_WAIT = '4'
export const PAY_STATUS_SUCCESS = '6'
export const PAY_STATUS_FAIL = '-5'

export const payStatusOption = [{
  id: PAY_STATUS_WAIT,
  label: '等待付款'
}, {
  id: PAY_STATUS_SUCCESS,
  label: '支付成功'
}, {
  id: PAY_STATUS_FAIL,
  label: '支付失败'
}]

export const isOwnerOption = [{
  id: 'owner',
  label: '自己处理的'
}, {
  id: 'all_people',
  label: '全部'
}]

/**
 * 从棒谷下载，等待用户选择处理
 * @type {Number}
 */
export const STATUS_WAITING = 1
/**
 * 正在处理
 * @type {Number}
 */
export const STATUS_PENDING = 2
/**
 * 已录入
 * @type {Number}
 */
export const STATUS_ENTERED = 4
/**
 * 已校验
 * @type {Number}
 */
export const STATUS_VERIFIED = 5
/**
 * 已经提交给棒谷
 * @type {Number}
 */
export const STATUS_SUCCESS = 3
export const processStatusOption = [{
  id: STATUS_WAITING,
  label: '等待处理'
}, {
  id: STATUS_PENDING,
  label: '正在处理'
}, {
  id: STATUS_ENTERED,
  label: '已录入'
}, {
  id: STATUS_VERIFIED,
  label: '已校验'
}, {
  id: STATUS_SUCCESS,
  label: '已提交到棒谷'
}]

/**
 * 截图类型。
 */
export const SCREENSHOT_ALIPAY_MOBILE = 'aliPayMobile'
export const SCREENSHOT_ALIPAY_PC = 'aliPayPc'
export const SCREENSHOT_PAYPAL_PC = 'paypalPc'
export const screenshotTypes = [
  {
    id: SCREENSHOT_ALIPAY_MOBILE,
    name: '支付宝截图（手机端）'
  },
  {
    id: SCREENSHOT_ALIPAY_PC,
    name: '支付宝截图（PC端）'
  },
  {
    id: SCREENSHOT_PAYPAL_PC,
    name: 'PayPal截图（PC端）'
  }
]
