import defineApi from '../define-api'
import http from '../application-api'

function flowUrl (flowType, path) {
  return '/flow/' + flowType + path
}

export const addFlow = defineApi((config, flowType, title, tags) => {
  config.params = {
    title: title,
    tags: tags
  }
  http.post(flowUrl(flowType, '/add'), config)
})

export const updateFlow = defineApi((config, flowType, title, tags, id) => {
  config.params = {
    id: id,
    title: title,
    tags: tags
  }
  http.post(flowUrl(flowType, '/update'), config)
})

/**
 * 根据id获取流程数据。
 * @type {Function}
 */
export const getFlow = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/flow/by_id', config)
})

/**
 * 获取流程的基本配置信息。
 * @type {Function}
 */
export const getFlowConfig = defineApi((config, flowType) => {
  http.get(flowUrl(flowType, '/config'), config)
})

/**
 * 旧的流程列表加载方式，已废弃，请使用{@link #searchFlow}
 * @type {Function}
 */
export const getFlowList = defineApi((config, flowType, searchParams) => {
  config.params = searchParams
  http.get(flowUrl(flowType, '/list'), config)
})

/**
 * 流程搜索的推广平台选项列表。
 * @type {Function}
 */
export const getSearchFlowPlatformOptions = defineApi((config, flowType) => {
  http.get(flowUrl(flowType, '/search/platform_options'), config)
})

/**
 * 使用全文检索的方式获取流程列表数据
 * @type {Function}
 */
export const searchFlow = defineApi((config, flowType, params) => {
  config.params = params
  http.get(flowUrl(flowType, '/search'), config)
})

/**
 * 获取流程的所有阶段配置信息。
 * @type {Function}
 */
export const getFlowStageConfigs = defineApi((config, flowType) => {
  http.get(flowUrl(flowType, '/stage_configs'), config)
})

/**
 * 获取流程的流程图配置信息。
 * @type {Function}
 */
export const getFlowDiagram = defineApi((config, flowType, version) => {
  config.params = { version: version }
  http.get(flowUrl(flowType, '/diagram'), config)
})

export const getFlowStatusLogs = defineApi((config, flowType, id) => {
  config.params = { id: id }
  http.get(flowUrl(flowType, '/status/logs'), config)
})

export const updateFlowStatus = defineApi((config, flowType, id, status, reason) => {
  config.params = {
    id: id,
    status: status,
    reason: reason
  }
  http.post(flowUrl(flowType, '/update_status'), config)
})

export const isProcessableByCurrentUser = defineApi((config, flowId) => {
  config.params = {
    id: flowId
  }
  http.get('/flow/processable', config)
})

export const findFlowSubmitData = defineApi((config, flowType, source, keyword, dq, params) => {
  config.params = {
    flowType: flowType,
    source: source,
    keyword: keyword,
    dq: dq,
    params: params
  }
  http.get('/flow/submits/auto_complete', config)
})

export const getAllFlowConfigs = defineApi((config) => {
  http.get('/flow/configs', config)
})

export const updateFlowRemark = defineApi((config, flowType, remark, id) => {
  config.params = {
    workflowId: id,
    remark: remark
  }
  http.post(flowUrl(flowType, '/remark/update'), config)
})

/**
 * 使用全文检索的方式获取流程列表数据
 * @type {Function}
 */
export const searchRechargeFlowReportData = defineApi((config, flowType, params) => {
  config.params = params
  http.get(flowUrl(flowType, '/report'), config)
})

export const adPolicyWorkflowChanged = defineApi((config, flowType, policyType, id) => {
  config.params = {
    workflowId: id,
    policyType: policyType
  }
  http.post(flowUrl(flowType, '/changed'), config)
})

export const adPolicyWorkflowCheck = defineApi((config, flowType, customerId, platformId, id) => {
  config.params = {
    workflowId: id,
    customerId: customerId,
    platformId: platformId
  }
  http.get(flowUrl(flowType, '/check'), config)
})
