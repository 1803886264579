<!--
  工作流详细页面，显示流程的走向，每个流程阶段的简要信息，和下一个阶段的信息。
-->
<template>
  <page :route-name="getRouteName('WorkflowDetail')" :page-title="flow.title">

    <el-button @click="closeFlow"
               type="danger"
               icon="el-icon-circle-close"
               v-if="!flow.completed"
               v-show="flow.status === 'open'"
               slot="toolbar">
      关闭流程
    </el-button>

    <el-button @click="resumeFlow"
               type="success"
               icon="el-icon-success"
               v-if="!flow.completed"
               v-show="flow.status === 'close'"
               slot="toolbar">
      恢复流程
    </el-button>

    <el-button v-if="flowInitialized"
               slot="toolbar"
               icon="el-icon-news"
               @click="$refs.diagram.open(flow.type, flow.version)">
      流程图
    </el-button>

    <div slot="center" style="margin: 0 20px;">

      <!--流程信息-->
      <div class="flow-detail" v-show="flowInitialized">
        <div class="title">
          <span class="text-main font-large-x font-bold" style="margin-right: 10px;">
            【{{flow.title}}】的流程
          </span>
        </div>
        <div class="text-muted font-mini" style="margin-top: 5px;">
          <span style="margin-right: 10px;">创建人：{{flow.createUserName}}</span>
          <span style="margin-right: 10px;">创建时间：{{flow.createDate}}</span>
          <el-tag :key="index" size="mini" type="info" v-for="(tag,index) in flow.tagList">{{tag}}</el-tag>
        </div>
      </div>

      <!--阶段列表-->
      <stages
        v-if="flowInitialized"
        :flow-id-name="flowIdName"
        :flow-id="flowId"
        :flow-type="flowType"
        :flow-status="flow.status"
        :flow-reject="flow.reject"
        ref="stages"
        style="margin-top: 20px; padding-bottom: 30px;"></stages>

      <!--流程状态历史日志-->
      <div class="workflow-status-logs font-mini text-muted" style="margin-top: 50px;">
        <div v-for="log in workflowStatusLogs">
          <i
            :class="{
              'el-icon-success': log.status === 1,
              'el-icon-error': log.status === 0,
              'text-danger': log.status === 0,
              'text-success': log.status === 1
            }"></i>
          {{log['updateUserName']}} 在 {{log.date}} {{log.status ? '恢复' : '关闭'}} 了流程，原因： {{log.reason}}
        </div>
      </div>
    </div>

    <flow-diagram ref="diagram"></flow-diagram>
  </page>
</template>

<script>
  import Stages from './stages'
  import FlowDiagram from '../flow-diagram'
  import kit from '../../../../tools/kit'
  import mixins from './mixins'
  import { getFlow, getFlowStatusLogs, updateFlowStatus } from '../../../../http/api/workflow'

  export default {
    mixins: [mixins],
    components: { Stages, FlowDiagram },
    data () {
      return {
        flow: {}, // 流程信息
        workflowStatusLogs: [] // 流程的状态更新日志
      }
    },
    computed: {
      flowInitialized () {
        return !kit.obj.isEmpty(this.flow)
      }
    },
    methods: {
      load (notLogs) {
        getFlow()
          .success(resp => {
            this.flow = resp.data
          })
          .send(this.flowId)
        if (notLogs !== true) {
          getFlowStatusLogs()
            .success(resp => {
              this.workflowStatusLogs = resp.data
            })
            .send(this.getFlowType, this.flowId)
        }
      },
      /**
       * 更新流程的状态。
       * @param action {boolean} true表示恢复流程，false关闭流程
       */
      updateFlowStatus (action) {
        let actionMsg = action ? '恢复' : '关闭'
        let status = action ? 'open' : 'close'
        let process = ({ value }) => {
          updateFlowStatus()
            .success(resp => {
              this.flow.status = status
              this.workflowStatusLogs.push(resp.data)
              this.$message({
                message: `流程已${actionMsg}`,
                type: action === 'open' ? 'success' : 'info'
              })
              this.$dispatcher.dispatch('FlowUpdate', this.flowId)
            })
            .send(this.getFlowType, this.flowId, status, value)
        }
        this.$prompt(`请简短描述${actionMsg}的理由。`, '提示', {
          inputValidator: (value) => {
            if (kit.str.isBlank(value)) {
              return '理由不能为空。'
            }
            if (value.length > 200) {
              return '字符长度不能超过200个字符。'
            }
          }
        }).then(process).catch(() => {})
      },
      closeFlow () {
        this.updateFlowStatus(false)
      },
      resumeFlow () {
        this.updateFlowStatus(true)
      }
    },
    mounted () {
      this.load()
    },
    watch: {
      flowId () {
        this.load()
      },
      '$route.query.reloadStages' () {
        this.load(true)
        this.$refs.stages.loadStages()
      }
    }
  }
</script>

<style lang="less" scoped>
  .flow-detail {
    padding-bottom: 10px;
    border-bottom: solid 1px #ededed;
  }
</style>
