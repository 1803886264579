<!--
  用户的消息面板

  事件：
    messageRead(count) 当用户将消息标记为已读后触发的事件，count表示读了多少条消息。
-->
<template>
  <slide-panel ref="panel" :visible.sync="openMessagePanel" @close="handlePanelClose" :width="600" position="right">
    <span slot="title">我的消息</span>
    <div style="line-height: normal">

      <div class="toolbar">
        <el-button
          type="primary"
          icon="el-icon-check"
          v-show="messages.length > 0"
          @click="markAllRead"
          :loading="markAllButtonLoading">全部标记已读</el-button>

        <el-button icon="el-icon-refresh" @click="load">刷新</el-button>

        <div class="search">
          <el-checkbox v-model="unreadStatus" label="只看未读" border></el-checkbox>
        </div>
      </div>

      <div class="text-muted font-mini content-center" style="margin-top: 30px;" v-show="messages.length === 0">无新的消息</div>

      <ul class="list-group">
        <li
          class="list-group-item hover-item"
          :key="m.id"
          v-for="m in messages">
          <div class="scene-bar" :class="{success: m.scene === 'success', warning: m.scene === 'warning', danger: m.scene === 'danger'}"></div>
          <div
            class="content font-mini text-normal"
            :class="{link: messageCanRedirect(m.type)}"
            @click="goToPage(m)"
            v-html="$options.filters.messageContent(m.content)"></div>
          <el-tag type="info" size="mini" v-if="m.read">已读</el-tag>
          <el-button type="text" icon="el-icon-check" size="mini" v-if="!m.read" @click="markRead(m.id)">标记已读</el-button>
          <el-tag type="warning" size="mini" v-if="!!m.typeDescription">{{m.typeDescription}}</el-tag>
          <div class="time font-mini text-muted">{{m.sendTime | formatTime}}</div>
        </li>
      </ul>

      <div style="text-align: center; margin-top: 15px;" v-show="messages.length > 0">
        <el-button class="load-more" @click="load(true)" style="width: 200px;">加载更多...</el-button>
      </div>
    </div>
  </slide-panel>
</template>

<script>
  import kit from '../tools/kit'
  let PAGE_LIMIT = 15
  import { getMessageList, markMessageAsReaded } from '../http/api/app-message'

  export default {
    data () {
      return {
        pageStart: 0,
        markAllButtonLoading: false,
        openMessagePanel: false,
        unreadStatus: true,
        messages: []
      }
    },
    watch: {
      unreadStatus () {
        this.load()
      }
    },
    filters: {
      formatTime (value) {
        return kit.str.fmtBeforeTime(Date.parse(value.replace(/-/g, '/'))) || value
      },
      messageContent (message) {
        return message.replace(/(\[.+?\])/g, '<span class="main">$1</span>')
      }
    },
    methods: {
      load (nextPage) {
        this.pageStart = nextPage === true ? this.pageStart + PAGE_LIMIT : 0
        this.$refs.panel.setLoading(true)
        this.abortLoadData = getMessageList()
          .complete(() => (this.$refs.panel.setLoading(false)))
          .success(resp => {
            let list = resp.data
            if (nextPage === true) {
              if (list.length === 0) {
                this.$message({ message: '所有消息已经加载完毕。', type: 'success' })
                if (this.pageStart > 0) {
                  this.pageStart -= PAGE_LIMIT
                }
              } else {
                this.messages.push(...list)
              }
            } else {
              this.messages = list
            }
          })
          .final((success) => {
            if (!success && this.pageStart > 0) {
              this.pageStart -= PAGE_LIMIT // 发生错误时要将分页数据还原到上一次
            }
          })
          .send(this.pageStart, PAGE_LIMIT, this.unreadStatus ? 0 : null)
      },
      markRead (id) {
        markMessageAsReaded()
          .success(() => {
            for (let m of this.messages) {
              if (id === m.id) {
                m.read = true
              }
            }
            this.$emit('messageRead', 1)
          })
          .send(id)
      },
      markAllRead () {
        let unread = this.messages.filter(m => m.read === false).map(m => m.id)
        if (unread && unread.length > 0) {
          this.markAllButtonLoading = true
          markMessageAsReaded()
            .complete(() => (this.markAllButtonLoading = false))
            .success(() => {
              let readCount = 0
              for (let m of this.messages) {
                if (unread.includes(m.id)) {
                  readCount++
                  m.read = true
                }
              }
              this.$emit('messageRead', readCount)
            })
            .send(unread.join(','))
        }
      },
      messageCanRedirect (type) {
        return type === 'flow'
      },
      goToPage (msg) {
        let data = msg.data
        let type = msg.type
        try {
          data = JSON.parse(data)
          if (!this.messageCanRedirect(type)) {
            return
          }
          if (type === 'flow') {
            this.$router.push({ name: 'AutoWorkflowDetail', params: { id: data['flowId'], type: data['flowType'] } })
          }
          this.openMessagePanel = false
          msg.read === false && this.markRead(msg.id)
        } catch (e) {
          console.log(e)
        }
      },
      open () {
        this.openMessagePanel = true
        this.messages.length === 0 && this.load()
      },
      handlePanelClose () {
        if (this.abortLoadData) {
          this.abortLoadData.cancel()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  @deep: ~'>>>';

  .link {
    color: #3a3a3a;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .toolbar {
    margin-bottom: 20px;
    overflow: auto;
    .search {
      float: right;
    }
  }

  .list-group-item {
    position: relative;
    overflow: hidden;
    .scene-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background-color: #ddd;
      &.success {
        background-color: #67C23A;
      }
      &.warning {
        background-color: #E6A23C;
      }
      &.danger {
        background-color: #F56C6C;
      }
    }
    .content {
      margin-bottom: 10px;
      padding: 5px 10px;
      background-color: #f2f2f2;
      border-radius: 5px;
      font-size: 12px;
      & @{deep} .main {
        display: inline-block;
        margin: 0 3px;
        font-weight: bold;
      }
    }
    .time {
      float: right;
    }
  }
</style>
