const LOCAL_STORAGE_KEY = 'form_copy'

export default {
  copy (fieldMap) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(fieldMap))
  },
  past () {
  },
  getCopyData () {
    let str = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (str && str.length > 0) {
      return JSON.parse(str)
    }
    return null
  }
}
