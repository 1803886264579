import kit from '../../tools/kit'

export default {
  data () {
    return {
      propTemplates: []
    }
  },
  computed: {
    propsOnSorted () {
      let map = {}
      let groups = []
      for (let p of this.propTemplates) {
        let groupName = p.groups
        if (kit.str.isBlank(groupName)) {
          groupName = '无分组'
        }
        let group = map[p.groups]
        if (group == null) {
          group = {
            name: groupName,
            props: []
          }
          groups.push(group)
        }
        if (!p.vueKey) {
          // p的id可能会为空，为了避免vue里的for需要key，给一个临时的id
          p.vueKey = p.id || kit.str.id(12)
        }
        group.props.push(p)
        map[groupName] = group
      }
      groups.sort((a, b) => a.name.localeCompare(b.name))
      for (let g of groups) {
        g.props.sort((a, b) => a.position - b.position)
      }
      return groups
    }
  }
}
