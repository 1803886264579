<!--
  事件：
    create(flowBean) 创建流程成功后。
-->
<template>
  <slide-panel ref="panel" :visible.sync="openPanel" @beforeClose="onBeforeClosePanel" position="top" :height="220" :width="600">
    <span slot="title">创建流程</span>
    <el-input v-model="flowTitle" :placeholder="titleAdvise || '请输入标题'" style="margin-bottom: 20px;"></el-input>
    <el-tag
      :key="tag"
      v-for="tag in tags"
      closable
      :disable-transitions="false"
      @close="tags.splice(tags.indexOf(tag), 1)">
      {{tag}}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="tagValue"
      ref="tagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm">
    </el-input>
    <el-button class="button-new-tag" v-else size="small" @click="showInput">增加标签</el-button>

    <div style="margin-top: 20px; text-align: right;">
      <el-button type="primary" size="medium" @click="create">{{isUpdate ? '修改' : '创建'}}</el-button>
    </div>
  </slide-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import { addFlow, updateFlow } from '../../../../http/api/workflow'

  export default {
    props: {
      flowType: { type: String, required: true },
      titleAdvise: String
    },
    data () {
      return {
        openPanel: false,
        inputVisible: false,
        updateModel: null,
        tagValue: null,
        flowTitle: null,
        tags: []
      }
    },
    computed: {
      isUpdate () {
        return !!this.updateModel
      }
    },
    methods: {
      showInput () {
        this.inputVisible = true
        this.$nextTick(() => (this.$refs.tagInput.$refs.input.focus()))
      },
      handleInputConfirm () {
        if (!kit.str.isBlank(this.tagValue)) {
          this.tags.push(this.tagValue)
        }
        this.inputVisible = false
        this.tagValue = ''
      },
      create () {
        this.$refs.panel.setLoading(true, false)
        let api
        if (this.isUpdate) {
          api = updateFlow
        } else {
          api = addFlow
        }
        api()
          .complete(() => (this.$refs.panel.setLoading(false, true)))
          .success(resp => {
            let flow =resp.data
            this.$emit('save', flow, this.isUpdate)
            this.tags = []
            this.flowTitle = null
            this.openPanel = false
          })
          .send(this.flowType, this.flowTitle, this.tags.join(','), this.isUpdate ? this.updateModel.id : null)
      },
      onBeforeClosePanel () {
        this.tags = []
        this.flowTitle = null
        this.inputVisible = false
        this.tagValue = null
        this.updateModel = null
      },
      open (model) {
        this.updateModel = model
        if (model) {
          this.flowTitle = model.title
          this.tags = model.tags
        }
        this.openPanel = true
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-tag {
    height: 32px;
    line-height: 32px;
    vertical-align: bottom;
    & + & {
      margin-left: 10px;
    }
  }
  .input-new-tag {
    width: 90px;
    vertical-align: bottom;
  }
  .button-new-tag {
    height: 32px;
    vertical-align: bottom;
  }
  .el-tag, .input-new-tag, .button-new-tag {
    margin-bottom: 10px;
  }
  .el-tag + .input-new-tag, .el-tag + .button-new-tag {
    margin-left: 5px;
  }
</style>
