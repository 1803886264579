<template>
  <page route-name="FlowAttachmentSearch">

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="关键字">
          <el-input v-model="search.keyword" :clearable="true" style="width: 300px;" placeholder="可以输入文件标题，文件里的内容"></el-input>
        </el-form-item>

        <el-form-item label="类型">
          <el-select v-model="search.type" :clearable="true" style="width: 100px;">
            <el-option
              v-for="item in [{id: 'contract', title: '合同'}, {id: 'service', title: '服务单'}]"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户">
          <el-select
            v-model="search.customerId"
            filterable
            remote
            :clearable="true"
            placeholder="请输入关键词"
            :remote-method="handleSearchCustomer"
            :loading="searchCustomerLoading"
            style="width: 300px;"
          >
            <el-option v-for="item in searchCustomers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      :data="dataList">
      <el-table-column label="操作" width="180">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="handleGotoWorkflowDetail(row)" v-if="row.workflowId">查看流程</el-button>
          <el-dropdown size="mini"
                       split-button
                       @click="handlePreviewFile(row)"
                       @command="handleFileAction"
                       style="margin-left: 5px;"
          >
            预览文件
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{'action': 'download', 'model': row}">下载文件</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div class="info-detail">
            <div class="detail-item">
              <label>文件类型</label>
              <span>{{ row.fileType }}</span>
            </div>
            <div class="detail-item">
              <label>文件大小</label>
              <span>{{ row.fileSize | fileSizeFormat }}</span>
            </div>
            <div class="detail-item">
              <label>上传时间</label>
              <span>{{ row.createDate }}</span>
            </div>
            <div class="detail-item" v-if="row.adCustomer">
              <label>签约客户</label>
              <span>{{ row.adCustomer.name}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template slot-scope="{ row }">
          <span style="color: #bb3e30;" v-if="row.deleted">无效/作废</span>
          <span style="color: #429957;" v-else>有效</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="文件名"></el-table-column>
      <el-table-column prop="typeText" label="类型" width="100"></el-table-column>
      <el-table-column prop="createUserName" label="上传人" width="100"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

    <file-preview ref="filePreview"></file-preview>

  </page>
</template>

<script>
  import flowDetailNavMixins from '../../mixins/flow-detail-nav'
  import { searchFlowAttachments } from '../../http/api/flow-attachment-search'
  import { getAdCustomerListByKeyword } from '../../http/api/ad-customer'
  import FilePreview from '../../components-business/file-preview'
  import kit from '../../tools/kit'

  export default {
    mixins: [flowDetailNavMixins],
    components: {
      FilePreview
    },
    data () {
      return {
        dataList: [],
        searchCustomerLoading: false,
        searchCustomers: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          keyword: null,
          type: null,
          customerId: null
        }
      }
    },
    filters: {
      fileSizeFormat (size) {
        return kit.str.fmtSize(size)
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        searchFlowAttachments()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.page.total = resp.data.total
            this.dataList = resp.data.records
          })
          .send(params)
      },
      handleGotoWorkflowDetail (model) {
        this.navToWorkflowDetail('_CUSTOM_FlowAttachment', model.workflowId, model.flowType)
      },
      handleFileAction (command) {
        switch (command.action) {
          case 'download':
            window.open(command.model.url)
            break
        }
      },
      handlePreviewFile (model) {
        this.$refs.filePreview.open(model)
      },
      handleSearchCustomer (q) {
        this.searchCustomerLoading = true
        getAdCustomerListByKeyword()
          .complete(() => (this.searchCustomerLoading = false))
          .success(resp => {
            this.searchCustomers = resp.data
          })
          .send(q)
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>

  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

</style>
