<!--
  从提供的文件名自动检测文件类型并提供一个对应的图标。
-->
<template>
  <i class="iconfont" :class="cls"></i>
</template>

<script>
  import kit from '@/tools/kit'
  export default {
    props: {
      fileName: { type: String, required: true }
    },
    computed: {
      cls () {
        let ext = kit.str.fileExt(this.fileName)
        let cls = {}
        switch (ext) {
        case 'doc':
        case 'docx':
          cls['icon-file-word'] = true
          break
        case 'xls':
        case 'xlsx':
          cls['icon-file-xls'] = true
          break
        case 'ppt':
        case 'pptx':
          cls['icon-file-ppt'] = true
          break
        case 'rar':
        case 'zip':
        case 'gzip':
          cls['icon-file-zip'] = true
          break
        case 'pdf':
          cls['icon-file-pdf'] = true
          break
        case 'txt':
        case 'rtf':
          cls['icon-file-text'] = true
          break
        case 'psd':
          cls['icon-file-psd'] = true
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
          cls['icon-file-image'] = true
          break
        case 'mp4':
        case 'avi':
        case 'wmv':
        case 'mpeg':
        case 'mov':
        case 'mkv':
        case 'flv':
        case 'f4v':
        case 'm4v':
        case 'rmvb':
        case 'rm':
        case 'gp':
          cls['icon-file-video'] = true
          break
        default:
          cls['icon-file'] = true
          break
        }
        return cls
      }
    }
  }
</script>

<style lang="less" scoped>
  .icon-file {
    color: #383838;
  }
  .icon-file-word {
    color: #3a70ff;
  }
  .icon-file-xls {
    color: #419156;
  }
  .icon-file-ppt {
    color: #bc6e41;
  }
  .icon-file-image {
    color: #383838;
  }
  .icon-file-video {
    color: #383838;
  }
  .icon-file-text {
    color: #383838;
  }
  .icon-file-zip {
    color: #b4312b;
  }
  .icon-file-pdf {
    color: #e24d44;
  }
  .icon-file-psd {
    color: #3a70ff;
  }
</style>
