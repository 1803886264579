import defineApi from '../define-api'
import http from '../application-api'

export const addAdCustomer = defineApi((config, cust) => {
  config.data = cust
  http.post('/ad_customer/add', config)
})

export const updateAdCustomer = defineApi((config, cust) => {
  config.data = cust
  http.post('/ad_customer/update', config)
})

export const getAdCustomerList = defineApi((config, searchParams) => {
  config.params = searchParams
  http.get('/ad_customer/search', config)
})

export const getAdCustomerListByKeyword = defineApi((config, keyword) => {
  config.params = { keyword: keyword }
  http.get('/ad_customer/search/by_keyword', config)
})

export const getSubCustomerList = defineApi((config, parentId) => {
  config.params = { adCustomerId: parentId }
  http.get('/ad_customer/sub/list', config)
})

export const addSubCustomer = defineApi((config, data) => {
  config.data = data
  http.post('/ad_customer/sub/add', config)
})

export const updateSubCustomer = defineApi((config, data) => {
  config.data = data
  http.post('/ad_customer/sub/update', config)
})
