<template>
  <page route-name="AdCustomerPayOrder">

    <div slot="toolbar">
      <el-button type="primary" icon="el-icon-plus" @click="$refs.editor.open()">新增订单</el-button>
    </div>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search">

        <el-form-item label="支付人姓名">
          <el-input v-model="search.customerName" clearable style="width: 150px;"></el-input>
        </el-form-item>

        <el-form-item label="支付人电话">
          <el-input v-model="search.customerPhone" clearable style="width: 150px;"></el-input>
        </el-form-item>

        <el-form-item label="创建时间">
          <el-date-picker
            v-model="search.createDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始"
            end-placeholder="结束">
          </el-date-picker>
        </el-form-item>

        <br>

        <el-form-item label="状态">
          <el-select v-model="search.status" :clearable="true" style="width: 100px;">
            <el-option value="created" label="新单"></el-option>
            <el-option value="submit" label="客户已确认"></el-option>
            <el-option value="payed" label="客户已支付"></el-option>
            <el-option value="closed" label="已取消"></el-option>
            <el-option value="fail" label="支付失败"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="业务类型">
          <el-select v-model="search.businessType" :clearable="true" style="width: 100px;">
            <el-option :value="0" label="预付"></el-option>
            <el-option :value="1" label="后付"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="广告平台">
          <el-select v-model="search.adPlatform" :clearable="true" style="width: 100%;">
            <el-option v-for="item in adPlatformsForSearch" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户">
          <el-select
            v-model="search.adCustomerId"
            filterable
            remote
            :clearable="true"
            :remote-method="loadAdCustomersForSearch"
            :loading="adCustomersForSearchLoading"
            placeholder="可输入客户关键字搜索"
          >
            <el-option v-for="item in adCustomersForSearch" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      :data="dataList"
      stripe
    >
      <el-table-column label="操作" width="180">
        <template slot-scope="{ row }">
          <el-button size="mini"
                     @click="$refs.editor.open(row)"
                     :disabled="row.status !== 'created'"
          >
            编辑
          </el-button>

          <el-dropdown size="mini"
                       split-button
                       type="primary"
                       @click="copyOrder(row)"
                       @command="handleCommand($event, row)"
                       class="dropdown-menu"
                       style="margin-left: 10px;"
          >
            复制订单
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="sms" :disabled="!(row.status === 'created' || row.status === 'submit')">短信通知</el-dropdown-item>
              <el-dropdown-item command="close" :disabled="!(row.status === 'created' || row.status === 'submit' || row.status === 'fail')">关闭订单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div class="row-detail">
            <div class="detail-item">
              <label>订单号</label>
              <span>{{ row.orderId }}</span>
            </div>
            <div v-if="row.status === 'payed'" class="detail-item">
              <label>支付时间</label>
              <span>{{ row.payTime }}</span>
            </div>
            <div v-if="row.status === 'closed'" class="detail-item">
              <label>取消时间</label>
              <span>{{ row.closeTime }}</span>
            </div>
            <div class="detail-item">
              <label>客户</label>
              <span>{{ row.adCustomerName }}</span>
            </div>
            <div class="detail-item">
              <label>广告帐户</label>
              <span>{{ row.mccId }}</span>
            </div>
            <div class="detail-item">
              <label>订单创建人</label>
              <span>{{ row.createUserName }}</span>
            </div>
            <div class="detail-item">
              <label>业务类型</label>
              <span>{{ row.businessTypeMessage }}</span>
            </div>
            <div class="detail-item">
              <label>备注</label>
              <span>{{ row.remark }}</span>
            </div>
            <div class="detail-item">
              <label>状态描述</label>
              <span>{{ row.statusMessage }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="支付人" width="120"></el-table-column>
      <el-table-column prop="customerPhone" label="支付人电话" width="150"></el-table-column>
      <el-table-column label="金额" width="160">
        <template slot-scope="{ row }">
          {{row.priceCurrency}}
          <span class="font-bold font-normal" style="color: #316bce;">{{row | formatCurrency}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="订单时间" width="140"></el-table-column>
      <el-table-column label="状态" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.status === 'created'" class="text-muted">新单</span>
          <span v-if="row.status === 'submit'" class="text-primary">待支付</span>
          <span v-if="row.status === 'payed'" class="text-success">已支付</span>
          <span v-if="row.status === 'closed'" class="text-danger">已取消</span>
          <span v-if="row.status === 'fail'" class="text-danger">支付失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="adPlatformName" label="广告平台"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

    <editor ref="editor" @submit="onEditorSave"></editor>
  </page>
</template>

<script>
  import kit from '../../../tools/kit'
  import Editor from './comp/editor'
  import {
    getOrderList,
    sendOrderPaySms,
    cancelOrder
  } from '../../../http/api/customer-order'
  import { getAdCustomerListByKeyword } from '../../../http/api/ad-customer'
  import { getAdPlatformListIdNameMode } from '../../../http/api/ad-platform'

  export default {
    components: {
      Editor
    },
    data () {
      return {
        dataList: [],
        adPlatformsForSearch: [],
        adCustomersForSearch: [],
        adCustomersForSearchLoading: false,
        page: {
          start: 0,
          limit: this.$shareProps.pageLimit,
          total: 0
        },
        search: {
          customerName: null,
          customerPhone: null,
          adPlatform: null,
          adCustomerId: null,
          businessType: null,
          status: null,
          createDate: []
        }
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      },
      query () {
        this.reload()
      }
    },
    filters: {
      formatCurrency: function (order) {
        return kit.str.fmtCurrency(order.price, 2, '')
      }
    },
    methods: {
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        this.$pageLoading(true)
        let data = Object.assign({}, this.search)
        data.start = this.page.start
        data.limit = this.page.limit
        if (data.createDate && data.createDate.length === 2) {
          data.startCreateDate = data.createDate[0] + ' 00:00:00'
          data.endCreateDate = data.createDate[1] + ' 23:59:59'
        }
        delete data.createDate
        getOrderList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data['records']
            this.page.total = resp.data['total']
          })
          .send(data)
      },
      onEditorSave (model, isUpdate) {
        if (isUpdate) {
          kit.arr.pushOrReplace(this.dataList, model)
        } else {
          kit.arr.insert(this.dataList, 0, model)
        }
        if (model.statusCanPay) {
          this.$confirm('订单已保存，是否短信通知客户?', '提示', {
            confirmButtonText: '通知',
            cancelButtonText: '不通知',
            type: 'info'
          }).then(() => {
            this.sendSms(model)
          }).catch(() => {
          })
        }
      },
      copyOrder (row) {
        let copy = Object.assign({}, row)
        copy.id = null
        copy.price = null
        this.$refs.editor.open(copy)
      },
      loadAdCustomersForSearch (query) {
        this.adCustomersForSearchLoading = true
        getAdCustomerListByKeyword()
          .complete(() => (this.adCustomersForSearchLoading = false))
          .success(resp => {
            this.adCustomersForSearch = resp.data
          })
          .send(query)
      },
      sendSms (row) {
        this.$pageLoading(true)
        sendOrderPaySms()
          .complete(() => (this.$pageLoading(false)))
          .success(() => {
            this.$alert(`短信已发送到【${row.customerName}】。`, '提示')
          })
          .send(row.id)
      },
      cancelOrder (row) {
        this.$confirm('是否确保客户没有在进行订单的支付操作?', '订单号：' + row.orderId, {
          confirmButtonText: '是的，关闭订单',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitting = true
          cancelOrder()
            .complete(() => (this.submitting = false))
            .success(resp => {
              this.$message.success('订单已经取消。')
              kit.arr.pushOrReplace(this.dataList, resp.data)
            })
            .send(row.id)
        }).catch(() => {})
      },
      handleCommand (command, row) {
        if (command === 'sms') {
          this.sendSms(row)
        } else if (command === 'close') {
          this.cancelOrder(row)
        }
      }
    },
    mounted () {
      this.load()
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.adPlatformsForSearch = resp.data
        })
        .send()
    }
  }
</script>

<style lang="less" scoped>

  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }

  .row-detail {
    margin: 10px 0 0 60px;
    .detail-item {
      display: flex;
      padding: 5px 0;
      font-size: 12px;
      label {
        width: 150px;
        text-align: right;
        color: #666666;
        font-weight: bold;
      }
      label::after {
        content: '：';
      }
      span {
        margin-left: 10px;
        color: #757575;
      }
    }
  }
</style>
