<template>
  <page route-name="AliyunData">
    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button  type="primary" icon="el-icon-plus" @click="$refs['uploadPanel'].open()">上传文件</el-button>
      </el-form-item>
    </el-form>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="上传日期">
          <el-date-picker
            label="日期范围"
            style="width: 250px;"
            v-model="search.times"
            type="datetimerange"
            align="right"
            :unlink-panels="true"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['12:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
   <el-table
     ref="table"
      slot="center"
      :data="dataList"
    >
     <el-table-column prop="uploadTime" label="上传日期" ></el-table-column>
     <el-table-column prop="userName" label="上传人" ></el-table-column>
     <el-table-column label="报告" width="100" >
       <template slot-scope="{ row }">
         <a :href ="downloadExcel(row.id)" >下载</a>
       </template>
     </el-table-column>
     <el-table-column prop="fbFileName" label="fb文件名" ></el-table-column>
     <el-table-column prop="ldFileName" label="ld文件名" ></el-table-column>
   </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <upload-editor ref="uploadPanel" @saved="onEditorSubmit"></upload-editor>
  </page>
</template>

<script>
  import UploadEditor from './comp/upload-editor'
  import kit from '../../tools/kit'
  import {
    getAliyunDataList
  } from '../../http/api/aliyun-data'
  import { getAdminUrl } from '../../http/application-api'

  export default {
    components: { UploadEditor },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          times: []
        },
        loading: false
      }
    },
    computed: {
    },
    mounted () {
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        let params = Object.assign({}, this.search)
        if (params.times && params.times.length === 2) {
          params.startTime = params.times[0]
          params.endTime = params.times[1]
          delete params.times
        }
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getAliyunDataList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      onEditorSubmit (data) {
            kit.arr.insert(this.dataList, 0, data)
      },
      downloadExcel (id) {
        return getAdminUrl('/aliyun_data_file/download?id=' + id)
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
