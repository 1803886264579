/*
    一些Element UI的默认配置。
 */

let rule = (config) => Object.assign({ trigger: 'change' }, config)

let config = {
  formRule: {
    required (msg) {
      return rule({ required: true, message: msg || '此字段必须提供。', trigger: 'blur' })
    },
    min (v) {
      return rule({ min: v, message: `字符长度不能少于${v}个。` })
    },
    max (v) {
      return rule({ max: v, message: `字符长度不能超过${v}个。` })
    },
    size (min, max) {
      return rule({ min: min, max: max, message: `字符长度必须在${min}-${max}个之间。` })
    },
    email (name) {
      return { type: 'email', message: `${name}不是一个有效的邮箱。` }
    }
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$elConfig = config
  }
}
