<template>
  <page route-name="WorkflowFormDict" :use-tcb-layout="true">

    <el-dropdown slot="toolbar" @command="handleDictAdd" v-show="preDefines.length > 0">
      <el-button type="primary">
        添加字典 <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="d in preDefines" :key="d.id" :command="d">
          <i class="el-icon-plus"></i>
          {{d.title}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-table
      ref="table"
      slot="center"
      :data="dataList"
      @expand-change="handleTableExpand"
      @row-dblclick="handleTableRowClick"
    >
      <el-table-column label="操作" width="60">
        <template slot-scope="{ row }">
          <el-button size="mini" icon="el-icon-delete" type="danger" @click="handleDeleteDict(row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <div style="width: 800px; margin: 0 auto;">
            <el-row :gutter="20">
              <el-col :span="1" style="height: 32px; line-height: 32px; font-weight: bold;">ID</el-col>
              <el-col :span="8">
                <el-input v-model="row.newItem.title" placeholder="请输入标题"></el-input>
              </el-col>
              <el-col :span="5" v-if="row.id === 'adPlatform'">
                <el-select v-model="row.newItem.extraValue">
                  <el-option label="国内平台" value="china"></el-option>
                  <el-option label="海外平台" value="overseas"></el-option>
                </el-select>
              </el-col>
              <el-col :span="3">
                <el-tooltip effect="dark" content="数字越小，越排前面" placement="bottom">
                  <el-input v-model="row.newItem.position" placeholder="排序数字"></el-input>
                </el-tooltip>
              </el-col>
              <el-col :span="7">
                <el-button type="primary" @click="handleAddDictItem(row)">添加</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-for="item in row.items" :key="item.id" style="margin-top: 10px;">
              <el-col :span="1" style="height: 32px; line-height: 32px;  font-weight: bold;">{{item.id}}</el-col>
              <el-col :span="8">
                <el-input placeholder="请输入标题" v-model="item.title" :disabled="item.enabled === 0"></el-input>
              </el-col>
              <el-col :span="5" v-if="row.id === 'adPlatform'">
                <el-select v-model="item.extraValue">
                  <el-option label="国内平台" value="china"></el-option>
                  <el-option label="海外平台" value="overseas"></el-option>
                </el-select>
              </el-col>
              <el-col :span="3">
                <el-input placeholder="排序数字" v-model="item.position" :disabled="item.enabled === 0"></el-input>
              </el-col>
              <el-col :span="7">
                <el-button @click="handleUpdateDictItem(row, item)">更新</el-button>
                <el-button type="danger" icon="el-icon-delete" @click="handleDeleteDictItem(row, item)"></el-button>
                <el-button :type="item.enabled ? 'warning' : 'success'" @click="handleEnableDisableDictItem(row, item)">{{item.enabled ? '禁用' : '启用'}}</el-button>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="200"></el-table-column>
      <el-table-column prop="title" label="标题" width="200"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
    </el-table>

  </page>
</template>

<script>
  import kit from '../../tools/kit'
  import {
    addFlowFormDictItem,
    updateFlowFormDictItem,
    deleteFlowFormDict,
    deleteFlowFormDictItem,
    getFlowFormDictList,
    getFlowFormDictItemList,
    getPreDefineList,
    addFlowFormDict
  } from "../../http/api/workflow-form-dict";

  export default {
    data () {
      return {
        dataList: [],
        preDefines: []
      }
    },
    methods: {
      initDict (dict) {
        dict.items = []
        dict.newItem = {}
        dict.itemLoading = false
        return dict
      },
      loadData () {
        this.$pageLoading(true)
        getFlowFormDictList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            for (let obj of resp.data) {
              this.initDict(obj)
            }
            this.dataList = resp.data
          })
          .send()
      },
      loadPreDefines () {
        getPreDefineList()
          .success(resp => {
            this.preDefines = resp.data
          })
          .send()
      },
      handleDictAdd (dict) {
        this.$pageLoading(true)
        addFlowFormDict()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.$message.success('保存成功。')
            kit.arr.pushOrReplace(this.dataList, this.initDict(resp.data))
            this.loadPreDefines()
          })
          .send(dict)
      },
      handleDeleteDict (dict) {
        this.$confirm('确定要删除吗？', '确定', {
          type: 'warning'
        })
          .then(() => {
            deleteFlowFormDict()
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.removeItem(this.dataList, dict)
                this.loadPreDefines()
              })
              .send(dict.id)
          })
          .catch(() => {})
      },
      handleAddDictItem (row) {
        let newItem = row.newItem
        if (kit.str.isBlank(newItem.title)) {
          return this.$message.warning('请输入标题。')
        }
        newItem.enabled = true
        newItem.workflowFormDictId = row.id
        addFlowFormDictItem()
          .success(resp => {
            kit.arr.pushOrReplace(row.items, resp.data)
            row.newItem = {}
            this.sortDictItems(row.items)
            this.$message.success('添加成功。')
          })
          .send(newItem)
      },
      handleEnableDisableDictItem (row, item) {
        item.enabled = !item.enabled
        this.handleUpdateDictItem(row, item)
      },
      handleUpdateDictItem (row, item) {
        if (kit.str.isBlank(item.title)) {
          return this.$message.warning('请输入标题。')
        }
        updateFlowFormDictItem()
          .success(() => {
            this.sortDictItems(row.items)
            this.$message.success('更新成功。')
          })
          .send(item)
      },
      handleDeleteDictItem (row, item) {
        this.$confirm('确定要删除吗？', '确定', {
          type: 'warning'
        })
          .then(() => {
            deleteFlowFormDictItem()
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.removeItem(row.items, item)
              })
              .send(item.id)
          })
          .catch(() => {})
      },
      sortDictItems (items) {
        return items.sort((a, b) => {
          return a.position - b.position
        })
      },
      handleTableExpand (row) {
        if (row.expanded === undefined) {
          row.expanded = false
        }
        row.expanded = !row.expanded
        if (!row.itemLoading && row.expanded) {
          row.itemLoading = true
          getFlowFormDictItemList()
            .complete(() => (row.itemLoading = false))
            .success(resp => {
              row.items = resp.data
            })
            .send(row.id)
        }
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      }
    },
    mounted () {
      this.loadData()
      this.loadPreDefines()
    }
  }
</script>

<style scoped>

</style>
