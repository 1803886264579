<template>
  <page route-name="AbsentQuery">

    <el-button slot="toolbar" icon="el-icon-refresh" @click="page.start = 0"></el-button>

    <el-table
      slot="center"
      :data="dataList">
      <el-table-column label="操作" width="110">
        <template slot-scope="scope">
          <el-button size="mini" @click="navToWorkflowDetail(flowType, scope.row['workflowId'])">查看审批流程</el-button>
        </template>
      </el-table-column>
      <el-table-column label="请假人" width="160">
        <template slot-scope="{ row }">
          {{row.requestUserName}}（{{row.name}}）
        </template>
      </el-table-column>
      <el-table-column prop="requestDate" label="申请日期" width="100"></el-table-column>
      <el-table-column label="请假日期" width="100">
        <template slot-scope="{ row }">
          <div v-for="d in row.dates.split(',')">{{d}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="days" label="天数" width="50"></el-table-column>
      <el-table-column prop="typeText" label="类别" width="80"></el-table-column>
      <el-table-column prop="reason" label="原因"></el-table-column>
      <el-table-column prop="approve" label="是否批准" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.approve" type="success">同意</el-tag>
          <el-tag type="danger" size="mini" v-else>拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="approveUserName" label="审批人" width="100"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

  </page>
</template>

<script>
  import { FLOW_ABSENT } from '../config/flows'
  import flowDetailNavMixins from '../mixins/flow-detail-nav'
  import { getAbsentList } from '../http/api/absents'

  export default {
    mixins: [flowDetailNavMixins],
    data () {
      return {
        flowType: FLOW_ABSENT,
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        }
      }
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      load () {
        this.$pageLoading(true)
        let params = {
          start: this.page.start,
          limit: this.page.limit
        }
        getAbsentList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data['records']
            this.page.total = resp.data['total']
          })
          .send(params)
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style scoped>

</style>
