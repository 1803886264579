<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="500"
    :height="270"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">账户组编辑</span>

    <el-form ref="form" :model="form" status-icon label-width="120px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="position">
        <el-input v-model="form.position" placeholder="数字越小越靠前"></el-input>
      </el-form-item>
      <el-form-item label="可用状态" prop="enabled">
        <el-switch
          v-model="form.enabled"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="可用"
          inactive-text="禁用"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
    </el-form>

    <div slot="footer" style="text-align: right;">
      <el-button type="primary" @click="submit" :loading="submitting">保存</el-button>
      <el-button @click="panelVisible = false">取消</el-button>
    </div>

  </slide-panel>
</template>

<script>
  import api, {
    API_ADS_REPORT_TEMPLATE_CUSTOMER_ADD,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_UPDATE
  } from '../../../config/urls'
  export default {
    data () {
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        reportTemplateId: null,
        form: {
          name: null,
          position: null,
          enabled: 1
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      /**
       * 打开编辑器。
       * @param reportTemplateId {string} 报表模版ID
       * @param [model] 如果提供一个数据结构，表示进行更新操作，否则新增操作。
       */
      open (reportTemplateId, model) {
        this.reportTemplateId = reportTemplateId
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = model[k]
          }
        }
      },
      submit () {
        this.submitting = true
        this.panelCloseable = false
        let method, path
        if (this.isUpdate) {
          method = 'put'
          path = api(API_ADS_REPORT_TEMPLATE_CUSTOMER_UPDATE, { id: this.model.id })
        } else {
          method = 'post'
          path = api(API_ADS_REPORT_TEMPLATE_CUSTOMER_ADD)
        }
        this.$http[method](path, {
          data: Object.assign({ adsReportTemplateId: this.reportTemplateId }, this.form),
          completeBefore: () => {
            this.panelCloseable = true
            this.submitting = false
          },
          success: (resp) => {
            this.$message.success('保存成功。')
            this.$emit('submit', resp, this.isUpdate)
            this.panelVisible = false
          }
        })
      },
      panelBeforeClose () {
        this.$refs.form.resetFields()
        this.model = null
        this.reportTemplateId = null
      }
    }
  }
</script>

<style scoped>

</style>
