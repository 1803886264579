/**
 * 页面的标题默认在路由配置中设置，有时候我们需要动态的设置页面标题，我们将动态的标题
 * 保存在状态中，在多个页面组件中共享。
 * @see components/layout/page
 */
import Vue from 'vue'
import StoreModule from './module'

export default {
  namespaced: true,
  state: {
    paths: [],
    titleMap: {}
  },
  mutations: {
    setTitle (state, data) {
      Vue.set(state.titleMap, data.name, data.title)
    }
  }
}

export const PAGE_TITLE_MODULE = 'pageTitle'
export class PageTitleStore extends StoreModule {
  constructor (store) {
    super(PAGE_TITLE_MODULE, store)
  }
  setTitleForRouter (routeName, title) {
    this.commit('setTitle', { name: routeName, title: title })
  }
  getTitleForRouter (routeName) {
    let title = this.state.titleMap[routeName]
    if (title && title.length > 15) {
      return title.substring(0, 15) + '...'
    } else {
      return title
    }
  }
}
