<template>
  <page route-name="IkWord">

    <div slot="toolbar">
      <el-button type="primary" size="mini" @click="$refs.editor.open()">创建分词文件</el-button>
    </div>

    <el-table
      slot="center"
      :data="files"
      v-loading="loading"
    >
      <el-table-column label="操作" width="110">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="$refs.editor.open(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column label="类型">
        <template slot-scope="{ row }">
          <span v-if="row.type === 'stop'">停用词</span>
          <span v-else>自定义词项</span>
        </template>
      </el-table-column>
      <el-table-column label="大小">
        <template slot-scope="{ row }">
          {{row.size | sizeFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="lastUpdateTime" label="更新时间"></el-table-column>
    </el-table>

    <ik-word-editor @saved="handleSaved" ref="editor"></ik-word-editor>
  </page>
</template>

<script>

  import kit from '../../../tools/kit'
  import { getIkWordFiles, deleteIkWordFile } from '../../../http/api/elasticsearch'
  import IkWordEditor from './comps/ik-word-editor'

  export default {
    components: { IkWordEditor },
    data () {
      return {
        loading: false,
        files: []
      }
    },
    computed: {
    },
    filters: {
      sizeFormat (size) {
        return kit.str.fmtSize(size)
      }
    },
    methods: {
      load () {
        this.loading = true
        getIkWordFiles()
          .complete(() => (this.loading = false))
          .success(resp => {
            this.files = resp.data
          })
          .send()
      },
      handleSaved () {
        this.load()
      },
      handleDelete (row) {
        this.$confirm(`确定要删除[${row.name}]吗？`, {
          type: 'warning'
        })
          .then(() => {
            deleteIkWordFile()
              .success(() => {
                this.$message.success('删除成功。')
                this.load()
              })
              .send(row.name, row.type)
          })
          .catch(() => {})
      }
    },
    mounted () {
      this.load()
    },
  }
</script>

<style lang="less" scoped>
</style>
