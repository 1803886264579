import defineApi from '../define-api'
import http from '../application-api'

export const getPreDefineList = defineApi((config) => {
  http.get('/flow_form_dict/pre_define', config);
})

export const addFlowFormDict = defineApi((config, dict) => {
  config.data = dict
  http.post('/flow_form_dict/add', config)
})

export const updateFlowFormDict = defineApi((config, dict) => {
  config.data = dict
  http.post('/flow_form_dict/update', config)
})

export const deleteFlowFormDict = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/flow_form_dict/delete', config)
})

export const getFlowFormDictList = defineApi((config) => {
  http.get('/flow_form_dict/list', config)
})

export const addFlowFormDictItem = defineApi((config, dictItem) => {
  config.data = dictItem
  http.post('/flow_form_dict/item/add', config)
})

export const updateFlowFormDictItem = defineApi((config, dictItem) => {
  config.data = dictItem
  http.post('/flow_form_dict/item/update', config)
})

export const deleteFlowFormDictItem = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/flow_form_dict/item/delete', config)
})

export const getFlowFormDictItemList = defineApi((config, dictId) => {
  config.params = { pid: dictId }
  http.get('/flow_form_dict/item/list', config)
})

export const getFlowFormDictItemListEnabled = defineApi((config, dictId) => {
  config.params = { pid: dictId }
  http.get('/flow_form_dict/item/list_enabled', config)
})
