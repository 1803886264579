<template>
  <page route-name="CsCustomer">



    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-input v-model="query.name" placeholder="关键字，客户名称或客服名称" style="width: 300px;">
          <el-button icon="el-icon-refresh" slot="append" @click="reload"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$refs.editor.open()">客户分配</el-button>
      </el-form-item>
    </el-form>


    <el-table
      slot="center"
      :data="dataList"
    >
      <el-table-column label="操作" width="120">
        <template slot-scope="{ row }">
          <el-button size="mini" type="danger" @click="handleRemoveCustomer(row)">从客户移除分配</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="签约客户"></el-table-column>
      <el-table-column prop="userName" label="客服"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <span style="color: #50a654;" v-if="row.maintain">服务中</span>
          <span style="color: #b53639;" v-else>停止服务</span>
        </template>
      </el-table-column>
      <el-table-column prop="startDate" label="接手日期"></el-table-column>
      <el-table-column prop="endDate" label="停止日期"></el-table-column>
    </el-table>

    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>

    <editor ref="editor" @saved="handleEditorSave"></editor>
  </page>
</template>

<script>
  import { getCsCustomerList, cancelMaintain } from '../../../http/api/cs-customer'
  import Editor from './comps/editor'
  import kit from '../../../tools/kit'

  export default {
    components: {
      Editor
    },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        query: {
          name: null
        }
      }
    },
    computed: {
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.reload()
      }
    },
    methods: {
      reload () {
        if (this.page.start === 0) {
          this.load()
        } else {
          this.page.start = 0
        }
      },
      load () {
        this.$pageLoading(true)
        getCsCustomerList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(this.query)
      },
      handleEditorSave (newModel, removedModel) {
        kit.arr.pushOrReplace(this.dataList, newModel)
        if (removedModel) {
          kit.arr.remove(this.dataList, removedModel)
        }
      },
      handleRemoveCustomer (row) {
        this.$confirm(`确定要取消[${row.userName}]的客户吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            cancelMaintain()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('移除成功。')
                kit.arr.removeItem(this.dataList, row)
              })
              .send(row.id)
          })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
