import PolicyCompany from '../../pages/policy/policy-company'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'PolicyCompany',
    path: '/policy_company',
    meta: { title: '代理公司签约主体设置' },
    component: PolicyCompany
  }
]
