import CustomerPlatform from '../../pages/customer-platform'
import CustomerService from '../../pages/customer-platform/customer-service'
export default [
  {
    name: 'CustomerPlatform',
    path: '/customerPlatform',
    meta: { title: '绩效报表' },
    component: CustomerPlatform
  },
  {
    name: 'CustomerService',
    path: '/customerService',
    meta: { title: '绩效用户维护' },
    component: CustomerService
  }
]
