<!--
  已完成的流程阶段，样式在父组件中定义
  @see ./stages.vue
-->
<template>
  <div class="stage-item" :class="{invalid: stage.status === 'invalid'}">
    <div class="time-line-box">
      <span class="time-line"><span class="round"></span></span>
    </div>
    <div class="head">
      <div class="stage-title">
        <a
          href="javascript:void(0)"
          class="text-main font-large-x font-bold"
          @click="toStageDetailPage(stage.id)">
          {{stage.title}}
        </a>
      </div>
      <div class="text-muted font-normal">{{stage.processUserName}}</div>
      <div class="text-muted font-mini">{{stage.processDate}}</div>
    </div>
    <div class="detail">
      <!--combine类型显示处理记录-->
      <combine-stages
        v-if="stage.processType === 'combine'"
        :stage-id="stage.id"
        :flow-id-name="flowIdName"
        :flow-type="flowType"
        :flow-id="flowId">
      </combine-stages>

      <!--其他类型显示内容简介-->
      <div v-else>
        <div class="text-normal font-mini" style="margin-bottom: 10px;">
          <a
            href="javascript:void(0)"
            @click="toStageDetailPage(stage.id)">
            {{stage.pureDescription || '无描述'}}
          </a>
        </div>
        <div class="text-muted font-mini" style="float: right;">
          <span style="margin-right: 10px;">
            <a
              v-if="stage.hasForm"
              href="javascript:void(0)"
              @click="toStageDetailPage(stage.id, 'form')"
            >
              表单(<span :class="{'text-success': stage.hasForm}">{{stage.hasForm ? '有' : '无'}}</span>)
            </a>
            <span v-else>
              表单(<span :class="{'text-success': stage.hasForm}">{{stage.hasForm ? '有' : '无'}}</span>)
            </span>
          </span>
          <span>
            <a
              v-if="stage.attachmentTotal > 0"
              href="javascript:void(0)"
              @click="toStageDetailPage(stage.id, 'attach')"
            >
              附件(<span style="color: #ff4828;">{{stage.attachmentTotal}}</span>)
            </a>
            <span v-else>
              附件(<span style="color: #ff4828;">{{stage.attachmentTotal}}</span>)
            </span>
          </span>
        </div>
      </div>

      <div class="approve font-mini">
        <span><i class="el-icon-circle-check"></i> 处理结果：</span>
        <span class="font-bold">{{stage.processResultText}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import CombineStages from './combine-stages'
  import mixins from './mixins'

  export default {
    mixins: [mixins],
    components: { CombineStages },
    props: {
      stage: {
        type: Object, required: true
      }
    }
  }
</script>

<style scoped></style>
