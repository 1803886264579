<template>
  <modal-panel
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="客户政策编辑"
    @save="onSave"
    @close="onClose"
    :width="800"
  >
    <el-form ref="form" :model="formData" label-width="150px">
      <el-form-item label="区域" prop="area" class="app_required-input">
        <el-select v-model="formData.area" style="width: 100%;" :disabled="copyEditDisabled">
          <el-option
            v-for="item in [{id: 1, label: '海外'}, {id: 2, label: '国内'}]"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户名称" prop="adCustomerId" class="app_required-input">
        <el-select style="width: 100%;" :disabled="copyEditDisabled"
          v-model="formData.adCustomerId"
          filterable
          remote
          :remote-method="searchAdCustomer"
          :loading="loading"
          placeholder="请输入客户名称的关键字进行搜索"
        ><el-option
            v-for="item in adCustomers"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="平台" prop="platform" class="app_required-input">
        <el-select v-model="formData.platformId" placeholder="请选择"  :clearable="true">
          <el-option
            v-for="item in platforms"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="上游政策绑定" prop="upperPolicyId"  class="app_required-input">-->
<!--        <el-select v-model="formData.upperPolicyId"-->
<!--          filterable-->
<!--          remote-->
<!--          :remote-method="searchUpperContract"-->
<!--          :loading="loading"-->
<!--          placeholder="请输入上游代理公司的关键字进行搜索"-->
<!--          style="width: 100%;"-->
<!--        ><el-option-->
<!--          v-for="item in upperContractSubjects"-->
<!--          :key="item.id"-->
<!--          :label="item.platformName + '&#45;&#45;'+item.proxyCompanyName+ '&#45;&#45;'+item.contractSubjectName+ '&#45;&#45;'+item.scaleCategory"-->
<!--          :value="item.id">-->
<!--        </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item label="结算货币" prop="currency" class="app_required-input">
        <el-radio-group v-model="formData.currency">
          <el-radio label="USD">美元</el-radio>
          <el-radio label="CNY">人民币</el-radio>
          <el-radio label="HKD">港币</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="政策开始日" prop="dateStart" class="app_required-input">
        <el-date-picker
          v-model="formData.dateStart"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择政策开始日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="政策结束日" prop="dateEnd" class="app_required-input">
            <el-date-picker
              v-model="formData.dateEnd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择政策结束日期">
            </el-date-picker>
       </el-form-item>
       <el-form-item label="返点结算政策" prop="payPolicyId"  class="app_required-input">
            <el-select v-model="formData.payPolicyId" placeholder="请选择" style="width: 100%;" >
              <el-option
                v-for="item in payPolicies"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
       </el-form-item>
      <el-form-item label="广告款结算帐期" prop="payTypeId"  class="app_required-input">
        <el-select v-model="formData.payTypeId" placeholder="请选择"  style="width: 100%;" >
          <el-option
            v-for="item in payTypes"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户返点类型" prop="rebateType" class="app_required-input">
        <el-radio-group v-model="formData.rebateType">
          <el-radio :label="2">阶梯返点</el-radio>
          <el-radio :label="1">非阶梯返点</el-radio>
          <el-radio :label="3">无返点</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客户返点" prop="rebate" class="app_required-input">
        <div v-if="formData.rebateType == 2">
          <el-row >
            <el-col :span="12">消耗
            </el-col>
            <el-col :span="5" >比例%
            </el-col>
            <el-col :span="6">匹配上游阶梯分类名
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="12">
              <el-input-number v-model="rebate.min"
                               controls-position="right"
                               style=" width:120px;" size="mini" :step="1001" >
              </el-input-number>
              --
              <el-input-number v-model="rebate.max"
                               controls-position="right"
                               style=" width:120px;" size="mini" :step="1000" >
              </el-input-number>
            </el-col>
            <el-col :span="5" >
              <el-input-number v-model="rebate.percent"
                               controls-position="right"
                               style=" width:120px;" size="mini" :min="1" >
              </el-input-number>
            </el-col>
            <el-col :span="5">
              <el-select v-model="rebate.category" placeholder="请输入分类名"  :clearable="true">
                <el-option
                  v-for="item in categories"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" @click="handleAddRebateItem()">添加</el-button>
            </el-col>
          </el-row>
          <el-row  v-for="item in rebateList" :key="item.id" style="margin-top: 5px;">
            <el-col :span="12">
              <el-input-number v-model="item.min"
                               controls-position="right"
                               style=" width:120px;" size="mini"  :disabled="true">
              </el-input-number>
              --
              <el-input-number v-model="item.max"
                               controls-position="right"
                               style=" width:120px;" size="mini" :disabled="true">
              </el-input-number>
            </el-col>
            <el-col :span="5" >
              <el-input-number v-model="item.percent"
                               controls-position="right"
                               style=" width:120px;" size="mini"  :disabled="true">
              </el-input-number>
            </el-col>
            <el-col :span="5">
              <el-select v-model="item.category" placeholder="请输入分类名"  class="app_required-input" :disabled="true">
                <el-option
                  v-for="item in categories"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <el-button type="danger" icon="el-icon-delete" @click="handleDeleteRebateItem(item)"></el-button>
            </el-col>
          </el-row>
        </div>
        <div v-else-if="formData.rebateType == 1">
          <el-row >
            <el-col :span="12" >比例%
            </el-col>
            <el-col :span="12">匹配上游阶梯分类名
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="12" >
              <el-input-number v-model="rebate.percent"
                               controls-position="right"
                               style=" width:120px;" size="mini" >
              </el-input-number>
            </el-col>
            <el-col :span="12">
              <el-select v-model="rebate.category" placeholder="请输入分类名"  class="app_required-input" >
                <el-option
                  v-for="item in categories"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </el-form-item>
      <el-form-item label="客户人员" prop="name" >
        <el-select style="width: 100%;" v-model="formData.csUserId" :disabled="copyEditDisabled">
          <el-option v-for="item in allCustomerServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="销售人员" prop="name" >
        <el-select style="width: 100%;" v-model="formData.saleUserId" :disabled="copyEditDisabled">
          <el-option v-for="item in allSaleServiceUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注说明" prop="name" :disabled="copyEditDisabled">
        <el-input v-model="formData.remark" ></el-input>
      </el-form-item>
    </el-form>
  </modal-panel>
</template>

<script>
  import kit from '../../../../tools/kit'
  import {
    addLowerPolicyDetail,
    updateLowerPolicyDetail,
    getlistRebatesBypolicyId,
    getUpperPolicyByProxyCompany,
    getSaleServiceUserList,
    getCategoryByPlatformId
  } from '../../../../http/api/upper-lower-policy'
  import { getAdPlatformListIdNameMode } from '../../../../http/api/ad-platform'
  import { getFlowFormDictItemListEnabled } from '../../../../http/api/workflow-form-dict'
  import { DICT_PAY_POLICY, DICT_PAY_TYPE } from '../../../../config/flow-form-dict-name'
  import { getAdCustomerListByKeyword } from '../../../../http/api/ad-customer'
  import { getCustomerServiceUserList } from '../../../../http/api/cs-customer'
  export default {
    data () {
      return {
        visible: false,
        closable: true,
        saveButtonLoading: false,
        model: null,
        loading: false,
        formData: {
          id: null,
          area: null,
          remark: null,
          contractSubjectId: null,
          adCustomerId: null,
          saleUserId: null,
          csUserId: null,
          lowerPolicyId: null,
          upperPolicyId: null,
          currency: 'CNY',
          dateStart: null,
          dateEnd: null,
          rebateType: 1,
          payPolicyId: null,
          payTypeId: null,
          platformId: null,
          rebateStrategyList: [],
          createUserId: null,
          updateUserId: null,
          createTime: null,
          updateTime: null
        },
        rebate: {
          id: null,
          min: null,
          max: null,
          percent: null,
          category: null,
          payPolicyId: null
        },
        rebateList: [],
        platforms: [],
        adCustomers: [],
        upperContractSubjects: [],
        payPolicies: [],
        allCustomerServiceUsers: [],
        allSaleServiceUsers: [],
        payTypes: [],
        copyEditDisabled: false,
        categories: []
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    watch: {
      'formData.platformId' (value) {
        this.getCategories(value)
      }
    },
    methods: {
      open (model) {
        this.visible = true
        this.copyEditDisabled = false
        if (model) {
          kit.obj.getProperties(this.formData, model, ['payTypeId', 'payPolicyId'])
          if (model.adCustomerName != null) {
            kit.arr.pushOrReplace(this.adCustomers, {
              'id': model.adCustomerId,
              'name': model.adCustomerName
            })
            this.formData.adCustomerId = model.adCustomerId
          }
          if (model.upperPolicyId != null) {
            kit.arr.pushOrReplace(this.upperContractSubjects, {
              'id': model.upperPolicyId,
              'platformName': model.platformName,
              'contractSubjectName': model.contractSubjectName,
              'proxyCompanyName': model.proxyCompanyName,
              'scaleCategory': model.scaleCategory
            })
            this.formData.upperPolicyId = model.upperPolicyId
          }
          if (model.id) {
            this.model = model
            this.formData.payPolicyId = Number(model.payPolicyId)
            this.formData.payTypeId = Number(model.payTypeId)
            this.getRebateInfo(model.id, model.rebateType)
          } else {
            this.model = null
            this.copyEditDisabled = true
          }
        }
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.payPolicies = resp.data
          })
          .send(DICT_PAY_POLICY)
        getFlowFormDictItemListEnabled()
          .success(resp => {
            this.payTypes = resp.data
          })
          .send(DICT_PAY_TYPE)
        getCustomerServiceUserList()
          .success(resp => {
            this.allCustomerServiceUsers = resp.data
          })
          .send()
        getSaleServiceUserList()
          .success(resp => {
            this.allSaleServiceUsers = resp.data
          })
          .send()
        getAdPlatformListIdNameMode()
          .success(resp => {
            this.platforms = resp.data
          })
          .send()
      },
      onClose () {
        this.$refs['form'].resetFields()
        this.formData.id = null
        this.rebate = {}
        this.rebateList = []
        this.formData.rebateStrategyList = []
      },
      onSave () {
        this.saveButtonLoading = true
        this.closable = false
        if (this.formData.rebateType === 1) {
          kit.arr.pushOrReplace(this.rebateList, this.rebate)
        }
        this.formData.rebateStrategyList = this.rebateList
        let api = this.isUpdate ? updateLowerPolicyDetail() : addLowerPolicyDetail()
        api
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.formData.id = resp.data.id
            this.$message.success('保存成功。')
            this.$emit('saved', resp.data)
            this.visible = false
          })
          .send(this.formData)
      },
      searchAdCustomer (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getAdCustomerListByKeyword()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.adCustomers = resp.data
            })
            .send(keyword)
        }
      },
      searchUpperContract (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getUpperPolicyByProxyCompany()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.upperContractSubjects = resp.data
            })
            .send(keyword)
        }
      },
      handleAddRebateItem () {
        if (this.rebate.min == null || this.rebate.max == null || this.rebate.percent == null) {
          return this.$message.warning('请输入全部阶梯类信息。')
        }
        this.rebate.id = kit.str.id(5)
        let item = { ...this.rebate }
        kit.arr.pushOrReplace(this.rebateList, item)
        this.rebate = {}
      },
      handleDeleteRebateItem (item) {
        kit.arr.removeItem(this.rebateList, item)
      },
      getRebateInfo (policyId, type) {
        getlistRebatesBypolicyId()
          .success(resp => {
            if (type === 2) {
              this.rebateList = resp.data
            } else {
              for (let obj of resp.data) {
                this.rebate.category = obj.category
                this.rebate.percent = obj.percent
              }
            }
          })
          .send(policyId)
      },
      getCategories (keyword) {
        if (keyword > 0) {
          this.loading = true
          getCategoryByPlatformId()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.categories = resp.data
            })
            .send(keyword)
        }
      }
    }
  }
</script>

<style scoped>

</style>
