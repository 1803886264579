<template>
  <page route-name="Checking">
    <div slot="top">
      <div style="margin-top: 15px">填写收款信息</div>
      <div class="group-title">请会计查看是否收到客户款项</div>
      <div class="field-row">
        <div class="field-column">
          <label  class="field-label">
            <span  style="font-weight: bold; font-size: 14px; color: #c01b0a;">*</span>
            客户付款日期
          </label>
          <div  class="field-input">
            <el-date-picker
              v-model="formData.payedDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择付款日期">
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div slot="center" id="check-description">
    </div>
    <image-uploader :rich-editor-id="editorId"></image-uploader>
    <div slot="bottom" >
      <div style="text-align: center">
        <el-button type="primary"  @click="submit" :loading="submitting">确定提交</el-button>
        <el-button  @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </page>
</template>

<script>
  import RichEditor from '../../components/rich-editor'
  import ImageUploader from '../../components-business/rich-editor-image-uploader'
  import { processCheckPaymentRemind } from '../../http/api/recharge-payment-remind'

  export default {
    components: { ImageUploader },
    data () {
      return {
        id: this.$route.params['id'],
        editorId: 'check-description',
        editor: null,
        submitting: false,
        formData: {
          payedDate: null
        },
        description: null
      }
    },
    methods: {
      submit () {
        if (this.formData.payedDate == null) return
        this.submitting = true
        processCheckPaymentRemind()
          .complete(() => (this.submitting = false))
          .success(resp => {
                this.$message.success('处理成功。')
                this.$dispatcher.dispatch('check_account_submit', resp.data)
                this.$router.go(-1)
                this.editor && this.editor.destroy()
              })
          .send(
              this.id,
              this.formData.payedDate,
              this.editor.getContent()
            )
      }
    },
    mounted () {
      this.editor = new RichEditor(this.editorId, { content: '' })
    }
  }
</script>

<style lang="less" scoped>
 .group-title {
      padding: 6px 0;
      font-size: 16px;
      font-weight: bold;
 }
  @borderColor: #b1d2ee;
  @border: solid 1px @borderColor;
  .field-row {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: @border;
    margin-top:-1px ;
    .field-column {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      .field-label {
        padding: 12px 10px 7px 10px;
        width: 100px;
        font-size: 12px;
        background-color: #d6eaf9;
        border-right: @border;
        color: #274a74;
      }
      .field-input {
        position: relative;
        flex: 1;
        padding: 7px 7px 2px 7px;
      }
    }
  }
</style>
