import StoreModule from './module'
import { FLOW_NAMES_MAPPING } from '../../config/flows'

export default {
  namespaced: true,
  state: {
    totals: {}
  },
  getters: {
    allTotal: state => {
      let t = 0
      for (let key of Object.keys(state.totals)) {
        t += state.totals[key] || 0
      }
      return t
    }
  },
  mutations: {
    setTotals (state, data) {
      state.totals = data
    },
    clear (state) {
      state.totals = {}
    }
  }
}

export const TODO_MODULE = 'todo'
export class TodoStore extends StoreModule {
  constructor (store) {
    super(TODO_MODULE, store)
  }

  /**
   * 初始化待办事项的数量，data是一个简单对象，key是事项的标识，value是事项的数量，如：
   * {
   *   Absent: 1, // 请假有一条未处理
   *   NewCustomer: 10 // 客户新签有10条未处理
   * }
   * @param data
   */
  setTotals (data) {
    this.commit('setTotals', data)
  }
  clear () {
    this.commit('clear')
  }
  /**
   * 获取各类型的待办事项数量。
   * @return {Array}
   *   id 事项的标识
   *   name 事项名称
   *   total 未处理数量
   */
  getTotals () {
    let arr = []
    for (let key of Object.keys(this.state.totals)) {
      arr.push({
        id: key,
        name: FLOW_NAMES_MAPPING[key],
        total: this.state.totals[key]
      })
    }
    return arr
  }
}
