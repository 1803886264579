import defineApi from '../define-api'
import http from '../application-api'

export const addRole = defineApi((config, role) => {
  config.data = role
  http.post('/role/add', config)
})

export const updateRole = defineApi((config, role) => {
  config.data = role
  http.post('/role/update', config)
})

export const deleteRole = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/role/delete', config)
})

/**
 * 获取所有角色
 * @return
 *   [
 *     {
 *       id: String,
 *       title: String,
 *       description: String,
 *       enabled: Boolean
 *     }
 *   ]
 * @type {Function}
 */
export const getAllRoles = defineApi((config) => {
  return http.get('/role/list', config)
})

/**
 * 获取所有可用权限
 * @return
 *   [
 *     {
 *       title: String,
 *       authorities: [
 *         {id: String, title: String, description: String, method: String, url: String}
 *       ]
 *     }
 *   ]
 * @type {Function}
 */
export const listAuthorities = defineApi((config) => {
  http.load('/role/authorities', config)
})

export const applyAuthorityToRole = defineApi((config, roleId, authorityId) => {
  config.params = {
    roleId: roleId,
    authorityId: authorityId
  }
  http.post('/role/apply_authority', config)
})

export const removeAuthorityFromRole = defineApi((config, roleId, authorityId) => {
  config.params = {
    roleId: roleId,
    authorityId: authorityId
  }
  http.post('/role/remove_authority', config)
})

/**
 * 获取角色拥有的权限ID
 * @return
 *   [String]
 * @type {Function}
 */
export const getRoleAuthorityIds = defineApi((config, roleId) => {
  config.params = {
    roleId: roleId
  }
  return http.get('/role/role_authority/ids', config)
})

export const getAuthorityAndManagerInfo = defineApi((config, id) => {
  config.params = {
    id: id
  }
  return http.post('/role/authority_and_manager_info', config)
})
