<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="800"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">绑定客户编辑</span>
    <el-form ref="form" :model="form" :rules="rules" status-icon label-width="120px" v-if="isAdd">
      <el-form-item label="客户" prop="csId">
        <el-select v-model="form.csId" filterable placeholder="请选择客服">
          <el-option
            v-for="item in adCsName"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否当前维护" prop="current">
        <el-radio-group v-model="form.current">
          <el-radio label="1">是</el-radio>
          <el-radio label="0" v-if="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开始时间" prop="startDate">
        <el-date-picker
          v-model="form.startDate"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="form.current === '0'" label="结束时间" prop="endDate">
        <el-date-picker
          v-model="form.endDate"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button type="primary" @click="submit" :loading="submitting">保存</el-button>
      </el-form-item>
    </el-form>
    <div class="content_all" v-if="!isAdd">
      <div class="user_content">
        <div class="before_service" v-if="beforeCsName.length > 0">
          <div class="service_left">以前维护的客服:</div>
          <div class="service_right_div">
          <div class="service_right" v-for="item in beforeCsName" :key="item.id"> <div style="width: 60px;float: left">{{item.csName}}</div> <span style="font-size: 10px;margin-left: 10px"> 维护周期：{{item.startDate}}~{{item.endDate}} </span>
            <el-popover
              placement="right"
              width="400"
              trigger="click">
              <el-table
              :data="item.adsReportTemplateCustomerAd"
              height="600"
              >
                <el-table-column property="name" label="名称"></el-table-column>
                <el-table-column width="200" property="adId" label="账号"></el-table-column>
              </el-table>
              <el-button slot="reference">详情</el-button>
            </el-popover>
          </div>
          </div>

        </div>
        <span style="font-size: 15px"> <span style="color: blue">当前维护的客服:</span>{{form.csName}}</span>   <el-button style="margin-left: 20px" type="primary"  @click="moveAccount">{{!isMove ? '交接给其他客服' : '取消'}}</el-button>
      </div>

      <div style="width: 100%;height: auto;" v-if="!isMove">
        <!--搜索-->
        <div class="search-bar" style="margin-top: 10px;margin-bottom: 10px">
          <el-form :inline="true" :model="query">
            <el-form-item label="账户">
              <el-input v-model="query.account" clearable style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="账户名称">
              <el-input v-model="query.name" clearable style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="find()">查询</el-button>
            </el-form-item>
          </el-form>
          <div style="font-size: 15px;text-align: center">如果没有找到广告账户，请先到<router-link to="/ads/report"><span style="color: blue"> 广告日消耗报表</span></router-link>  <!--="/ads/report"-->菜单中绑定</div>
        </div>
        <div class="account" v-for="item in accountData" :key="item.id">
          <span class="title">{{item.username}}</span>
          <ul>
            <li v-for="items in item.adsReportTemplateCustomerAdList" :key="items.id">
              <div class="bing" style="width: 80%;">{{items.adId}}({{items.name}})</div>
              <div class="bing" style="width: 20%">
                <el-button v-if="items.isBind===0" type="primary" @click="binding(items.id)">绑定</el-button>
                <el-button type="danger" v-if="items.isBind===1" @click="binding(items.id)">解除绑定</el-button>
              </div>
            </li>
          </ul>
        </div>
        <div style="text-align: center;font-size: 10px;margin-top: 30px" v-if="accountData.length === 0">暂无数据</div>

      </div>
      <div v-if="isMove" style="margin-top: 10px">
        <el-select v-model="newUserId" filterable placeholder="请选择客服">
          <el-option
            v-for="item in adCsName"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button style="margin-left: 10px" type="primary"  @click="moveSubmit" :loading="submitting">确定移交</el-button>
      </div>
    </div>
  </slide-panel>

</template>

<script>
  import api, { API_AD_CUSTOMER_PLATFORM_OWNER, API_AD_CUSTOMER_PLATFORM_SERVICE, API_AD_CUSTOMER_PLATFORM_ACCOUNTS, API_AD_CUSTOMER_PLATFORM_ACCOUNT, API_AD_CUSTOMER_PLATFORM_OWNER_CHANGE, API_AD_CUSTOMER_PLATFORM_ACCOUNT_FIND } from '../../../../config/urls'
  export default {
    data () {
      let r = this.$elConfig.formRule
      return {
        accountData: [],
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        beforeCsName: [],
        form: {
          csId: null,
          csName: null,
          startDate: null,
          endDate: null,
          adCustomerPlatformId: null,
          current: '1',
          ownerId: null
        },
        query: {
          name: null,
          account: null
        },
        adCsName: [],
        isAdd: true,
        isMove: false,
        newUserId: null,
        rules: {
          current: [r.required()],
          csId: [r.required()],
          startDate: [r.required()],
          endDate: [r.required()]
        },
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() > Date.now()
          }
        }
      }
    },
    methods: {
      open (id) {
        this.form.adCustomerPlatformId = id
        this.$http.get(api(API_AD_CUSTOMER_PLATFORM_SERVICE), {
          params: {
            adCustomerPlatformId: this.form.adCustomerPlatformId
          },
          success: resp => {
            if (resp.services.length === 1) {
              // beforeCsName
              this.form.csName = resp.services[0].csName
              if (resp.services[0].adCustomerPlatformOwnerList.length > 0) {
                this.beforeCsName = resp.services[0].adCustomerPlatformOwnerList
              }
              this.form.ownerId = resp.services[0].id
              this.form.csId = resp.services[0].csId
              this.accountData = resp.accounts
              this.isAdd = false
            } else {
              this.isAdd = true
            }
            this.adCsName = resp.users
            this.panelVisible = true
          }
        })
      },
      submit () {
        this.$refs.form.validate(valid => {
          if (!valid) return
          this.submitting = true
          this.panelCloseable = false
          this.$http['post'](api(API_AD_CUSTOMER_PLATFORM_OWNER), {
            data: this.form,
            context: this,
            completeBefore () {
              this.panelCloseable = true
              this.submitting = false
            },
            success (data) {
              if (data.current === 1) {
                this.isAdd = false
                this.form.csName = data.csName
                this.form.csId = data.csId
                this.form.ownerId = data.id
                this.findAccount(data.id)
              } else {
                alert('保存成功')
                this.isAdd = true
                this.resetValue()
              }
              this.submitting = false
            }
          })
        })
      },
      binding (id) {
        this.$http['put'](api(API_AD_CUSTOMER_PLATFORM_ACCOUNT, { adId: id, ownerId: this.form.ownerId, userId: this.form.csId }), {
          context: this,
          success (data) {
            this.accountData = data
          }
        })
      },
      panelBeforeClose () {
        this.form.adCustomerPlatformId = null
        this.model = null
        this.resetValue()
        this.query.name = null
        this.query.account = null
        this.beforeCsName = []
        this.accountData = []
      },
      findAccount (id) {
        this.$http.get(api(API_AD_CUSTOMER_PLATFORM_ACCOUNTS), {
          params: {
            adCustomerPlatformId: id
          },
          success: resp => {
            this.accountData = resp
          }
        })
      },
      moveAccount () {
        this.isMove = !this.isMove
      },
      moveSubmit () {
        // newUserId , String platFormId, String userId
        if (this.newUserId == null) {
          alert('请选择移交用户')
          return
        }
        this.submitting = true
        this.$http['put'](api(API_AD_CUSTOMER_PLATFORM_OWNER_CHANGE, { newUserId: this.newUserId, platFormId: this.form.adCustomerPlatformId, userId: this.form.csId }), {
          context: this,
          completeBefore () {
            this.submitting = false
          },
          success (resp) {
            // beforeCsName
            this.beforeCsName = resp.services[0].adCustomerPlatformOwnerList
            this.form.csName = resp.services[0].csName
            this.form.ownerId = resp.services[0].id
            this.form.csId = resp.services[0].csId
            this.accountData = resp.accounts
            this.isAdd = false
            this.isMove = false
            this.submitting = false
            this.newUserId = null
          }
        })
      },
      resetValue () {
        this.form.current = '1'
        this.form.csId = null
        this.form.startDate = null
        this.form.endDate = null
        this.isMove = false
      },
      find () {
        this.$http.get(api(API_AD_CUSTOMER_PLATFORM_ACCOUNT_FIND), {
          params: {
            name: this.query.name,
            account: this.query.account,
            ownerId: this.form.ownerId
          },
          success: resp => {
            this.accountData = resp
          }
        })
      }
    }
  }
</script>

<style scoped lang="less" >
  .content_all{
    //border: 1px red solid;
    width: 100%;
    font-size: 20px;
    overflow: hidden;
    height: auto;
  }
  .user_content{
    height: auto;
    font-size: 20px;
    width: 100%;
    overflow: hidden;
  }
  .account{
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    //float: left;
    .title{
      font-size: 22px;
      font-weight: bold;
      margin-top: 5px;
    }
    ul li{
      width: 100%;
      margin-top: 5px;
      overflow: hidden;
    }
  }
  .bing{
    float: left;
    overflow: hidden;
    white-space: nowrap;
  }
  .before_service{
    margin-bottom: 10px;
    width: 100%;
    height: 60px;
    font-size: 15px;
    .service_left{
      float: left;
      width: 15%;
      color: blue;
      height: 100%;
    }
    .service_right_div{
      float: left;
      height: 100%;
      width: 85%;
      overflow-y: auto;
    }
    .service_right{
      width: 83%;
      height: 30px;
      float: left;
    }
  }

</style>
