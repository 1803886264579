import ActualGrossProfit from '../../pages/policy/actual'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'ActualGrossProfit',
    path: '/actual-gross-profit',
    meta: { title: '实际毛利' },
    component: ActualGrossProfit
  }
]
