/*
 * 当store以模块形式定义时，在使用getters，mutations，actions等操作时需要加上模块名前缀。
 * 本类封装模块，限制用户的操作在模块级别，无需关心模块名前缀。
 * 具体模块需要继承本类并export一个类出来。
 *
 * 如何使用：
 * let m = new XXXModule(this.$store)
 *
 * 获取状态：
 * m.state.name
 *
 * 获取属性：
 * m.get('fullname')
 *
 * 提交变更：
 * m.commit('setName', 'my name')
 *
 * 建议：
 * 子类应该将commit等操作封装到具体的函数中，不应该直接让开发员执行commit这种操作，如：
 * setUserInfo (data) {
 *   this.commit('setUserInfo', data)
 * }
 *
 * // 在其他组件
 * xxxStore.setUserInfo('name') // 好的调用方式
 * xxxStore.commit('setUserInfo', 'name') // 不好的方式
 */

import { mapState, mapGetters } from 'vuex'

export default class {
  constructor (moduleName, store) {
    this.moduleName = moduleName
    this.store = store
    this.state = store.state[moduleName]
  }
  get (name) {
    return this.store.getters[`${this.moduleName}/${name}`]
  }
  commit (name, data) {
    if (typeof name === 'object') {
      name.type = `${this.moduleName}/${name.type}`
      this.store.commit(name)
    } else {
      this.store.commit(`${this.moduleName}/${name}`, data)
    }
  }
  dispatch (name, data) {
    if (typeof name === 'object') {
      name.type = `${this.moduleName}/${name.type}`
      this.store.dispatch(name)
    } else {
      this.store.dispatch(`${this.moduleName}/${name}`, data)
    }
  }
  mapState (map) {
    return mapState(this.moduleName, map)
  }
  mapGetters (map) {
    return mapGetters(this.moduleName, map)
  }
}
