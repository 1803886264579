/**
 基于./http.js工具，针对业务系统对http工具的一些行为进行了默认设置。
 本文件export了一个全局的http实例，你应该总是用这个实例，而不要自己去new一个新的。
 系统的api应该统一定义在一个地方，请使用defineApi函数来定义，具体该函数的描述。

 拦截器：
 ===================================================
 request拦截：
 1、在每个请求发送前，将本地的token（会话id）放置到X-Auth-Token头信息中。
 2、如果使用params配置项发送post请求，自动将头信息content-type设置为application/x-www-form-urlencoded，
 并将params格式化后放到data配置项中。

 response拦截：
 1、如果本地没有token（会话id），那么将X-Auth-Token头信息的值更新到本地会话id中。
 2、请求业务逻辑成功后，success回调得到如下数据结构：
   {
     data: Object, // 服务端返回的数据
     status: int, // http状态码
     headers: Object
   }
 3、请求业务逻辑失败后，error回调得到如下数据结构：
   {
     message: String,
     code: int, // 业务错误代码
     cancel: boolean, // true表示请求被取消
     details: Object // 错误详情，一般只有code为11000（数据校验失败）时才会有详情信息
   }
 4、如果业务错误代码为12001（未认证），会忽略请求的回调配置，直接跳转到登录页面。

 全局回调：
 ===================================================
 error：默认是弹出一个提示框显示错误消息。
 */
import Http from './http'
import qs from 'qs'
import kit from '../tools/kit'
import { userStore } from '../store'
import { CODE_UNAUTHENTICATED, CODE_UNAUTHORIZATION, CODE_VALIDATE_FAIL } from './error-code'
import { MessageBox, Message } from 'element-ui'
import authFailDialog from '../components/auth-fail-dialog'

const SESSION_ID_HEADER_NAME = 'x-auth-token'

const http = new Http()

http.baseUrl = '/api/admin'

http.interceptors.request = function (config) {
  config.headers = config.headers || {}
  config.headers[SESSION_ID_HEADER_NAME] = userStore.state.token
  if (config.method.toLowerCase() === 'post' && !kit.obj.isEmpty(config.params)) {
    config.headers['content-type'] = 'application/x-www-form-urlencoded'
    config.data = qs.stringify(config.params)
    config.params = null
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('request: ' + config.url)
    console.log(config)
    console.log('----------------------------')
  }
}

http.interceptors.response = function (success, info) {
  if (success) {
    let token = info.headers[SESSION_ID_HEADER_NAME]
    if (token) {
      userStore.updateToken(token)
      if (process.env.NODE_ENV !== 'production') {
        console && console.log('Token变更：' + token + ' > ' + info.config.url)
      }
    }
    return {
      isSuccess: true,
      data: {
        data: info.data,
        status: info.status,
        headers: info.headers
      }
    }
  } else {
    if (process.env.NODE_ENV !== 'production') {
      console && console.info(`%c [http catch error]`, 'color:orange', info)
    }

    let data = {
      message: null,
      code: null,
      cancel: info.isUserCancel,
      details: null
    }
    let ignoreCallback = {}
    if (info.reqStatus === 'resp') {
      let respData = info.resp
      if (info.status === 404) {
        data.message = `请求地址不存在。`
        data.code = 404
      } else if (respData.code === CODE_UNAUTHENTICATED) {
        authFailDialog.showDialog()
        ignoreCallback = { error: true }
      } else if (respData.code === CODE_UNAUTHORIZATION) {
        MessageBox({
          type: 'error',
          title: '拒绝访问',
          dangerouslyUseHTMLString: true,
          message: `<p>${respData.message}</p><p style="color: #cf7341">请求地址：${respData.path}</p>`
        })
        ignoreCallback = { error: true }
      } else if (typeof respData === 'string') {
        data.message = respData
        data.code = info.status
      } else {
        data = respData
      }
    } else if (info.reqStatus === 'noResp') {
      data.message = '服务器未响应，请重试。'
      data.code = -1
    } else {
      data.message = '请求发生未知错误，请重试。'
      data.code = 0
    }
    return {
      isSuccess: false,
      data: data,
      ignoreCallback: ignoreCallback
    }
  }
}

// http对服务端返回错误消息时的默认处理方式
http.callbacks.error = (resp) => {
  let msg = resp.message
  if (msg == null) {
    msg = '系统发生错误。'
  }
  if (resp.code === CODE_VALIDATE_FAIL) {
    let content = ''
    for (let err of resp.details) {
      content += `<p>${err.message}</p>`
    }
    content = `<div style="max-height: 400px; overflow: auto;">${content}</div>`
    MessageBox({
      type: 'error',
      dangerouslyUseHTMLString: true,
      title: msg,
      message: content
    })
  } else {
    Message.error(msg)
  }
}

export default http

export const getAdminUrl = function (path, disableBaseUrl) {
  let token
  if (path.includes('?')) {
    token = '&_token=' + userStore.state.token
  } else {
    token = '?_token=' + userStore.state.token
  }
  if (disableBaseUrl === true) {
    return path + token
  } else {
    return http.baseUrl + path + token
  }
}
