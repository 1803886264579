<!--
  全屏浏览图片。
  属性绑定：
    url.sync {String} 图片的URL，提供URL后组件自动被打开。
    title.sync {String} 图片的标题。
-->
<template>
  <transition name="fade">
    <div class="container" v-show="show">
      <div class="title">{{title}}</div>
      <div class="image-wrapper" @click="hide">
        <span class="loading" v-show="loading">
          <i class="el-icon-loading"></i>
        </span>
        <img :src="url" v-show="url && url.length > 0">
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      url: { type: String },
      title: { type: String }
    },
    data () {
      return {
        loading: false,
        show: false
      }
    },
    watch: {
      url (url) {
        if (url != null) {
          this.show = true
          let img = new Image()
          this.loading = true
          img.onload = () => (this.loading = false)
          img.onerror = () => (this.loading = false)
          img.src = url
        }
      }
    },
    methods: {
      hide () {
        this.show = false
        setTimeout(() => {
          this.$emit('update:url', null)
          this.$emit('update:title', null)
        }, 500)
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 9999;
  }
  .image-wrapper {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 100px;
    bottom: 30px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    .loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -20px;
      margin-top: -20px;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: rgba(0,0,0,.5);
      text-align: center;
      font-size: 16px;
      border-radius: 5px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      border: none;
      outline: 0;
    }
  }
  .title {
    max-width: 500px;
    margin: 20px auto 0 auto;
    padding: 5px 20px;
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
</style>
