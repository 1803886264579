<!--
  绩效用户维护
-->
<template>
  <page route-name="CustomerService">
    <!--<el-button slot="toolbar" @click="$router.push({name: 'KpiCustomer'})">客户管理</el-button>
    <el-button slot="toolbar" @click="$router.push({name: 'KpiUser'})">考核用户管理</el-button>-->
    <el-button slot="toolbar" icon="el-icon-plus"  type="primary" @click="$refs['customerServicePanel'].open()">绑定销售</el-button>
    <el-button slot="toolbar" icon="el-icon-refresh" @click="load"></el-button>
    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="query">
        <el-form-item label="销售查询">
          <el-input v-model="query.name" clearable style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item label="客户查询">
          <el-input v-model="query.customerName" clearable style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      slot="center"
      stripe
      :data="dataList">
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button icon="el-icon-user" size="mini" @click="$refs['servicePanel'].open(scope.row.id)">绑定客服</el-button>
          <!--<el-button size="mini" icon="el-icon-delete" type="danger" @click="deleteData(scope.row)"></el-button>-->
          <el-button icon="el-icon-delete" type="danger" size="mini" @click="deleteCustomer(scope.row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="adCustomerName" label="客户" width="500"></el-table-column>
      <el-table-column prop="saleName" label="销售" width="300"></el-table-column>
      <el-table-column prop="platform" label="广告平台" width="300"></el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <!--销售编辑-->
    <customer-service-editor ref="customerServicePanel" @submit="onEditorSubmit"></customer-service-editor>
    <!--客服编辑-->
    <service-editor ref="servicePanel"></service-editor>
  </page>
</template>

<script>
  import api, { API_AD_CUSTOMER_PLATFORMS, API_AD_CUSTOMER_PLATFORM_DELETE } from '../../../config/urls'
  import PaginationMixins from '@/mixins/pagination'
  import CustomerServiceEditor from './comp/editor'
  import ServiceEditor from './comp/service-editor'
  export default {
    mixins: [PaginationMixins],
    components: { CustomerServiceEditor, ServiceEditor },
    data () {
      return {
        dataList: [],
        kpiCustomers: [],
        query: {
          name: null,
          customerName: null
        }
      }
    },
    computed: {},
    methods: {
      load () {
        this.$http.get(api(API_AD_CUSTOMER_PLATFORMS), {
          params: {
            kpiCustomerId: this.query.kpiCustomerId,
            name: this.query.name,
            customerName: this.query.customerName,
            start: this.page.start,
            limit: this.page.limit
          },
          success: resp => {
            this.dataList = resp.data
            this.page.total = resp.total
          }
        })
      },
      onEditorSubmit (data) {
        this.load()
      },
      deleteCustomer (data) {
        this.$confirm(`确定要删除[${data.adCustomerName}]吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            this.$http.del(api(API_AD_CUSTOMER_PLATFORM_DELETE, { id: data.id }), {
              completeBefore: () => this.$pageLoading(false),
              success: () => this.load()
            })
          })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
</style>
