import RefundReport from '../../pages/office-app/workflow/report/refund'
/**
 * 充值数据报表的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'RefundReport',
    path: '/refund_report',
    meta: { title: '清零消耗退款数据报表' },
    component: RefundReport
  }
]
