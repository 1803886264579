import FavoriteFlows from '../../pages/office-app/workflow/dir/index'
import { createWorkflowDetailRouters } from './workflow'
/**
 * 收藏夹的路由配置。
 */

export default [
  {
    name: 'FavoriteFlows',
    path: '/flow_favoriteFlows/:dirId',
    meta: { title: '收藏夹' },
    component: FavoriteFlows,
    children: [
      createWorkflowDetailRouters('flowId', 'Favorite')
    ]
}
]
