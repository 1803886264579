import { Bar, mixins } from 'vue-chartjs'
import kit from '../../tools/kit'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  data: () => ({
    options: {
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            offsetGridLines: false
          }
        }],
        yAxes: [{
          ticks: {
            suggestedMin: 0
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let obj = data.datasets[0].data[tooltipItem.index]
            return kit.str.fmtCurrency(obj.y, 2, obj.currency)
          }
        }
      }
    }
  }),
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
