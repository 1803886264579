<template>
  <transition
    @before-enter="onPageBeforeEnter"
    @enter="onPageEnter"
    @leave="onPageClosed"
    appear
    @before-appear="onPageBeforeEnter"
    @appear="onPageEnter"
  >
    <div class="app-page" v-loading="loadingPage" :element-loading-text="loadingText">
      <div class="header">
        <i class="icon-home fas fa-home" @click="$router.push({name: 'Home'})"></i>
        <div class="page-paths">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="p in paths" :to="{ name: p.name, params: p.params }" :key="p.name">
              {{p.title}}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="toolbar">
          <slot name="toolbar"></slot>
        </div>
      </div>
      <div class="body">
        <slot></slot>
        <div class="tcb-layout" v-if="useTcbLayout">
          <div class="top-content" v-if="$slots.top">
            <slot name="top"></slot>
          </div>
          <div class="center-content">
            <slot name="center"></slot>
          </div>
          <div class="bottom-content" v-if="$slots.bottom">
            <slot name="bottom"></slot>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
  /**
   * 负责应用的主内容区域呈现视图。
   * -----------------
   *     |
   *  M  |
   *  U  | 主内容区域
   *  N  |
   *  E  |
   *     |
   * -----------------
   * 页面组件在"主内容区域"中渲染，并自带有一个<router-view>来呈现子路由的页面（子路由的组件也使用页面组件呈现，如此循环），
   * 页面组件的布局为"页头"和"主体"，页头显示路由路径的所有标题，如：用户管理 > 用户编辑，所以你的路由需按照一定的格式设置。
   *
   * //////////////// 与Router配合使用 ////////////////////
   * router配置：
   * {name: 'Users', meta: {title: '用户管理'}}
   * 以上的配置是必须的，使用页面组件时，需要将路由名称传递给routeName属性。
   * <page route-name="Users"></page>
   *
   * //////////////// Slot ////////////////////
   * 默认插槽：当你使用自定义布局（非传统）时，将内容放到默认插槽即可。
   * 工具栏插槽（toolbar）：放一些工具按钮，在页头右侧显示。
   * 传统布局插槽（top, center, bottom）：通常一个页面中，顶部是自定义元素，中间表格组件，底部分页组件，那么你可以使用这三个插槽。
   * 默认插槽和传统布局插槽只能选其中一种，传统布局插槽不需要三个槽都填充，center会自动填充剩余空间。
   * 页面组件默认使用传统布局插槽，如果你不希望使用该布局，请设置useTcbLayout=false。
   *
   * 典型的传统布局使用如下：
   * <page route-name="">
   *   <div slot="top">操作按钮，查询域等</div>
   *   <el-table slot="center">...</el-table> <!--表格会自动占满中间区域，如果表格数据过多，表格tbody区域会出现滚动条-->
   *   <el-pagination slot="bottom"></el-pagination>
   * </page>
   *
   * //////////////// 事件 ////////////////////
   * pageReady 当页面组件进入的动画完成后。
   * pageDestroy 当页面组件离开（销毁）的动画完成后。
   */

  import { mapState } from 'vuex'
  import Velocity from 'velocity-animate'
  import RouterInfo from '@/router/router-info'

  export default {
    props: {
      routeName: { type: String, required: true },
      useTcbLayout: { type: Boolean, default: true },
      pageTitle: { type: String }
    },
    watch: {
      pageTitle (title) {
        this.$pageTitleStore.setTitleForRouter(this.routeName, title)
        let pageTitle = this.$pageTitleStore.getTitleForRouter(this.routeName)
        this.paths.filter((p) => p.name === this.routeName)[0].title = pageTitle
        document.title = pageTitle
      }
    },
    data () {
      return {
        pageReadyIsEmit: false,
        paths: []
      }
    },
    computed: {
      ...mapState(['loadingPage', 'loadingText'])
    },
    methods: {
      onPageBeforeEnter (el) {
        let clientWidth = 0
        let container = document.getElementById('app-body-container')
        if (container) {
          clientWidth = container.clientWidth
        }
        el.style.left = -clientWidth + 'px'
        el.style.width = clientWidth + 'px'
      },
      onPageEnter (el, done) {
        this.pageReadyIsEmit = true
        let _this = this
        Velocity(el, { left: 0 }, {
          duration: 300,
          complete: () => {
            _this.$emit('pageReady')
            el.style.width = 'auto'
            done()
          }
        })
      },
      onPageClosed (el, done) {
        let clientWidth = document.getElementById('app-body-container').clientWidth
        let _this = this
        el.style.width = clientWidth + 'px'
        Velocity(el, { left: clientWidth }, {
          duration: 300,
          complete: () => {
            _this.$emit('pageDestroy')
            el.style.width = 'auto'
            done()
          }
        })
      }
    },
    mounted () {
      new RouterInfo(this.$route).getRoutes(this.routeName).forEach(r => {
        this.paths.push({
          name: r.name,
          title: this.$pageTitleStore.getTitleForRouter(r.name) || r.title,
          params: r.params
        })
      })
      document.title = this.$route.meta.title || this.$shareProps.appName
      // 如果页面不是从其他页面打开时，不会执行动画函数（比如刷新或第一次打开浏览器），这时要在这里触发该事件
      if (!this.pageReadyIsEmit) {
        this.$emit('pageReady')
      }
    },
    beforeDestroy () {
      let index = this.paths.length - 2
      if (index >= 0) {
        document.title = this.paths[index].title
      }
      this.$pageTitleStore.setTitleForRouter(this.routeName, '')
    }
  }
</script>

<style lang="less" scoped>
  .app-page {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: visible;
  }
  @headerHeight: 40px;
  .header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: @headerHeight;
    line-height: @headerHeight;
    padding: 0 10px;
    border-bottom: solid 1px #ddd;
    background-color: #f9f9f9;
    .icon-home {
      float: left;
      padding: 4px;
      margin-top: 9px;
      margin-right: 5px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 4px;
      color: #828282;
      &:hover {
        background-color: #f2f2f2;
      }
      &:active {
        background-color: #f9f9f9;
      }
    }
    .page-paths {
      float: left;
      margin-top: 15px;
    }

    .toolbar {
      float: right;
      margin-right: 20px;
    }
  }
  .body {
    position: absolute;
    left: 0;
    top: @headerHeight + 1;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 10px;
    overflow-x: visible;
    overflow-y: auto;
    background-color: #fff;
  }

  .body .tcb-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-flow: column;

    // el-table:before的z-index为1，会导致底部的页面的横线出现在下个页面的上面，将el-table的容器的z-index设置3即可
    z-index: 3;
    background-color: #fff;
    //////////////////////////////////////////////////////////////////////////////////////////////////
    .top-content {
    }
    .bottom-content {
    }
    .center-content {
      flex: 1;
      position: relative;
      overflow-y: auto;
      padding: 10px;
    }
  }
</style>

<style lang="less">
  .app-page .tcb-layout .center-content {
    > .el-table {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-flow: column;
      .el-table__body-wrapper {
        flex: 1;
        overflow-y: auto !important;
      }
    }
  }
</style>
