<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="400"
    :height="180"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">客户编辑</span>
    <el-form ref="form" :model="form" :rules="rules" status-icon label-width="50px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button type="primary" @click="submit" :loading="submitting">{{isUpdate ? '更新数据' : '创建数据'}}</el-button>
      </el-form-item>
    </el-form>
  </slide-panel>

</template>

<script>
  import api, { API_KPI_CUSTOMER } from '../../../../config/urls'
  export default {
    props: {
      formData: { type: Object }
    },
    watch: {
      formData (val) {
        if (val) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = val[k]
          }
        }
      }
    },
    data () {
      let r = this.$elConfig.formRule
      return {
        panelVisible: false,
        panelCloseable: true,
        submitting: false,
        model: null, // 更新操作时的数据模型
        form: {
          name: null
        },
        rules: {
          name: [r.required(), r.max(30)]
        }
      }
    },
    computed: {
      isUpdate () {
        return !!this.model
      }
    },
    methods: {
      open (model) {
        this.panelVisible = true
        this.model = model
        if (this.isUpdate) {
          for (let k of Object.keys(this.form)) {
            this.form[k] = model[k]
          }
        }
      },
      submit () {
        this.$refs.form.validate(valid => {
          if (!valid) return
          this.submitting = true
          this.panelCloseable = false
          let method, path
          if (this.isUpdate) {
            method = 'put'
            /* path = api(API_KPI_CUSTOMER_UPDATE, { id: this.model.id }) */
          } else {
            method = 'post'
            path = api(API_KPI_CUSTOMER)
          }
          this.$http[method](path, {
            data: this.form,
            context: this,
            completeBefore () {
              this.panelCloseable = true
              this.submitting = false
            },
            success (data) {
              this.$message.success('保存成功。')
              this.$emit('submit', data, this.isUpdate)
              this.panelVisible = false
            }
          })
        })
      },
      panelBeforeClose () {
        this.form.name = null
        this.$refs.form.resetFields()
        this.model = null
      }
    }
  }
</script>

<style scoped>

</style>
