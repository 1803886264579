import AdsAccountConsumption from '../../pages/ads-report/account-consumption'
/**
 * 广告账户消耗的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'AdsConsumption',
    path: '/ads/AccountConsumption',
    meta: { title: '广告账户消耗' },
    component: AdsAccountConsumption
  }
]
