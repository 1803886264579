<template>
  <page route-name="UpperPolicyList">

    <!--工具按钮-->
    <el-form :inline="true" slot="toolbar" style="margin-top: 5px;">
      <el-form-item>
        <el-button v-authority="'UpperPolicyManage'" type="primary" icon="el-icon-plus" @click="$refs['upperPanel'].open()">添加上游政策</el-button>
      </el-form-item>
      <el-form-item>
        <el-button v-authority="'UpperPolicyManage'" type="primary" icon="el-icon-upload" @click="selectFile">Excel文件导入</el-button>
      </el-form-item>
      <el-form-item>
        <a :href ="downloadTemplate()"  class="button" v-authority="'UpperPolicyManage'"><i class="el-icon-download"></i>Excel模版文件下载</a>
      </el-form-item>
    </el-form>

    <div slot="top" class="search-bar">
      <el-form :inline="true" :model="search" size="mini">
        <el-form-item label="平台" prop="platform">
          <el-select v-model="search.platformId" placeholder="请选择"  :clearable="true" style="width:120px;">
            <el-option
              v-for="item in platforms"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上游代理" prop="proxyCompanyId">
          <el-select
            v-model="search.proxyCompanyId"
            filterable
            remote
            :remote-method="searchPolicyCompanyProxy"
            :loading="loading"
            placeholder="请输入代理公司名称的关键字进行搜索" :clearable="true"
          >
            <el-option
              v-for="item in proxyCompanies"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签约主体" prop="contractSubjectId">
          <el-select
            v-model="search.contractSubjectId"
            filterable
            remote
            :remote-method="searchPolicyCompanyContract"
            :loading="loading"
            placeholder="请输入签约主体名称的关键字进行搜索" :clearable="true"
          >
            <el-option
              v-for="item in contractSubjects"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同类型" prop="contractType">
          <el-select v-model="search.contractType" :clearable="true" style="width:120px;" >
            <el-option
              v-for="item in [{id: 1, label: '原始合同'}, {id: 2, label: '非原始合同'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="search.status" :clearable="true" style="width:100px;" >
            <el-option
              v-for="item in [{id: 1, label: '无效'}, {id: 2, label: '生效中'}, {id: 0, label: '到期'}]"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="load()" style="margin-right: 10px;">查询</el-button>
        </el-form-item>
        <el-form-item>
          <a :href ="downloadExcel()" class="button" v-authority="'DownloadExcel'" ><i class="el-icon-download"></i>导出excel</a>
        </el-form-item>
      </el-form>
    </div>
   <el-table
     ref="table"
      slot="center"
      :data="dataList"
      @expand-change="handleTableExpand"
      @row-dblclick="handleTableRowClick"
    >
      <el-table-column label="操作" width="110" v-authority="'UpperPolicyManage'">
        <template slot-scope="{ row }">
          <el-button size="mini" @click="updateUpper(row)" >编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteUpper(row)" v-if="row.status!==3 ">删除</el-button>
        </template>
      </el-table-column>
     <el-table-column type="expand">
       <template slot-scope="{ row }">
         <div style="width: 400px; margin: 0 auto;">
           <el-table
             :data="row.items"
             stripe
           >
             <el-table-column label="消耗" v-if="row.rebateType===2">
               <template slot-scope="{ row }">
                 <span style="color: #50a654;width: 60px;display:inline-block" >{{row.min}}</span>
                 <span style="color: #50a654;width: 20px;display:inline-block" >--</span>
                 <span style="color: #50a654;width: 60px;display:inline-block" >{{row.max}}</span>
               </template>
             </el-table-column>
             <el-table-column label="比例%" prop="percent" width="80"></el-table-column>
             <el-table-column label="分类名" prop="category" width="80"></el-table-column>
           </el-table>
         </div>
       </template>
     </el-table-column>
     <el-table-column prop="platformName" label="平台"></el-table-column>
     <el-table-column prop="level" label="代理级别">
       <template slot-scope="{ row }">
         <span>{{row.level==1?'一代':'二代'}}</span>
       </template>
     </el-table-column>
     <el-table-column prop="contractSubjectName" label="签约主休" ></el-table-column>
     <el-table-column prop="proxyCompanyName" label="上游代理" ></el-table-column>
     <el-table-column prop="currency" label="结算币种" ></el-table-column>
     <el-table-column prop="dateStart" label="政策开始日期" ></el-table-column>
     <el-table-column prop="dateEnd" label="政策结束日期" ></el-table-column>
     <el-table-column prop="payPolicyName" label="返点结算政策" ></el-table-column>
     <el-table-column prop="rebateType" label="返点类型">
       <template slot-scope="{ row }">
         <span>{{row.rebateType==1?'比例返点':'阶梯返点'}}</span>
       </template>
     </el-table-column>
     <el-table-column prop="remark" label="备注" ></el-table-column>
     <el-table-column  label="状态">
       <template slot-scope="{ row }">
         <span style="color: #b53639;" v-if="row.status==1">无效</span>
         <span style="color: #50a654;" v-else-if="row.status==2">生效中</span>
         <span style="color: #1c30a6;" v-else>到期</span>
       </template>
     </el-table-column>
     <el-table-column  label="创建时间">
       <template slot-scope="{ row }">
         <span>{{row.createUserName}}</span><br/>
         <span>{{row.createTime}}</span>
       </template>
     </el-table-column>
     <el-table-column  label="修改时间">
       <template slot-scope="{ row }">
         <span>{{row.updateUserName}}</span><br/>
         <span>{{row.updateTime}}</span>
       </template>
     </el-table-column>
     <el-table-column  label="全同类型">
       <template slot-scope="{ row }">
         <span style="color: #b53639;" v-if="row.contractType==1">原始合同</span>
         <span style="color: #50a654;" v-else-if="row.contractType==2">非原始合同</span>
       </template>
     </el-table-column>
    </el-table>
    <pagination
      slot="bottom"
      :total="page.total"
      :start.sync="page.start"
      :limit.sync="page.limit">
    </pagination>
    <upper-editor ref="upperPanel" @saved="onEditorSubmit"></upper-editor>
    <uploader ref="uploader" :uploadExec="uploadHandle"></uploader>
  </page>
</template>

<script>
  import UpperEditor from './comp/upper-editor'
  import kit from '../../../tools/kit'
  import { getPolicyCompanyByLikenameWithType } from '../../../http/api/policy-company'
  import { getAdPlatformListIdNameMode } from '../../../http/api/ad-platform'
  import { getUpperPolicyList, getlistRebatesBypolicyId, deleteUpperPolicy, uploadExcel } from '../../../http/api/upper-lower-policy'
  import selectFile from '../../../tools/select-file'
  import { getAdminUrl } from '../../../http/application-api'

  export default {
    components: { UpperEditor },
    data () {
      return {
        dataList: [],
        page: {
          total: 0,
          start: 0,
          limit: this.$shareProps.pageLimit
        },
        search: {
          platformId: null,
          contractSubjectId: null,
          proxyCompanyId: null,
          contractType: 1,
          status: 2
        },
        contractSubjects: [],
        proxyCompanies: [],
        platforms: [],
        loading: false
      }
    },
    computed: {
    },
    mounted () {
      getAdPlatformListIdNameMode()
        .success(resp => {
          this.platforms = resp.data
        })
        .send()
      this.load()
    },
    watch: {
      'page.start' () {
        this.load()
      },
      'page.limit' () {
        this.page.start = 0
      }
    },
    methods: {
      initRebate (rebate) {
        rebate.items = []
        rebate.itemLoading = false
        return rebate
      },
      load () {
        let params = Object.assign({}, this.search)
        params.start = this.page.start
        params.limit = this.page.limit
        this.$pageLoading(true)
        getUpperPolicyList()
          .complete(() => (this.$pageLoading(false)))
          .success(resp => {
            for (let obj of resp.data.records) {
              this.initRebate(obj)
            }
            this.dataList = resp.data.records
            this.page.total = resp.data.total
          })
          .send(params)
      },
      onEditorSubmit (up) {
        kit.arr.pushOrReplace(this.dataList, this.initRebate(up))
      },
      updateUpper (up) {
        this.$refs['upperPanel'].open(up)
      },
      deleteUpper (up) {
        this.$confirm(`确定要删除吗？`, { type: 'warning' })
          .then(() => {
            this.$pageLoading(true)
            deleteUpperPolicy()
              .complete(() => (this.$pageLoading(false)))
              .success(() => {
                this.$message.success('删除成功。')
                kit.arr.removeItem(this.dataList, up)
              })
              .send(up.id)
          })
      },
      searchPolicyCompanyProxy (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.proxyCompanies = resp.data
            })
            .send(keyword, 1)
        }
      },
      searchPolicyCompanyContract (keyword) {
        if (keyword && keyword.length > 0) {
          this.loading = true
          getPolicyCompanyByLikenameWithType()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.contractSubjects = resp.data
            })
            .send(keyword, 2)
        }
      },
      handleTableExpand (row) {
        if (row.expanded === undefined) {
          row.expanded = false
        }
        row.expanded = !row.expanded
        if (!row.itemLoading && row.expanded) {
          row.itemLoading = true
          getlistRebatesBypolicyId()
            .complete(() => (row.itemLoading = false))
            .success(resp => {
              row.items = resp.data
            })
            .send(row.id)
        }
      },
      handleTableRowClick (row) {
        this.$refs['table'].toggleRowExpansion(row, !row.expanded)
      },
      // 上传文件
      selectFile () {
        selectFile({
          accept: '.xlsx',
          multi: true,
          select: files => {
            this.$refs.uploader.upload(files)
          }
        })
      },
      uploadHandle (file, callback) {
        uploadExcel()
          .success(resp => {
            callback.success()
          })
          .error(resp => {
            callback.error(resp.message, resp.cancel)
          })
          .final(() => {
            callback.complete()
          })
          .config(cfg => {
            cfg.onUploadProgress = e => {
              callback.progress(Math.round((e.loaded * 100) / e.total))
            }
          })
          .send('upper', file.file)
      },
      downloadTemplate () {
        return getAdminUrl('/upper_policy/download/template?type=upper')
      },
      downloadExcel () {
        let params = Object.assign({}, this.search)
        params.start = 0
        params.limit = 500 // mybatisplus分页时，每页最大是500，些值不能超过500
        let arr = []
        for (let key of Object.keys(params)) {
          if (params[key]) {
            arr.push(key + '=' + params[key])
          }
        }
        return getAdminUrl('/upper_policy/download/excel?' + arr.join('&'))
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-bar {
    padding: 15px 20px 0 20px;
    border-bottom: solid 1px #e6e6e6;
  }
  .button {
    background-color: #409EFF;
    border: none;
    color: white;
    height: 18px;
    line-height: 18px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: none;
  }
</style>
