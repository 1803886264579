<template>
  <div class="container">

    <el-row :gutter="50">
      <el-col :span="12">
        <todo></todo>
      </el-col>

      <el-col :span="12">
        <notice></notice>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import Todo from './comp/todo'
  import Notice from './comp/notice'

  export default {
    components: { Todo, Notice },
    data () {
      return {
      }
    },
    methods: {
    },
    mounted () {
    }
  }
</script>

<style lang="less" scoped>

  .container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 50px 100px 20px 100px;
    overflow: auto;
  }

</style>
