import DataCollectSearch from '../../pages/data-collect'
/**
 * 广告账户下载异常的路由配置。
 *
 * 如何访问"流程详情"路由：
 * this.$router.push({name: 'CustomerWorkflowDetail', params: {flowType: t, flowId: id}})
 */

export default [
  {
    name: 'DataCollectSearch',
    path: '/data_collect_list',
    meta: { title: '数据查询' },
    component: DataCollectSearch
  }
]
