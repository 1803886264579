import defineApi from '../define-api'
import http from '../application-api'

export const getAliyunDataList = defineApi((config, params) => {
  config.params = params
  return http.load('/aliyun_data_file/list', config)
})

export const uploadExcel = defineApi((config, type, id, file) => {
  let form = new FormData()
  form.append('file', file)
  form.append('type', type)
  form.append('id', id)
  config.data = form
  http.post('/aliyun_data_file/upload', config)
})
export const downloadExcel = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/aliyun_data_file/download', config)
})

