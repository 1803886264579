import defineApi from '../define-api'
import http from '../application-api'

/**
 * 更新用户的查询权限。
 * @param data {Object} { userIds: [String], authJson: String }
 * authJson的结构格式为：
 * {
 *   customer: { auth: string },
 *   attachment: { auth: string, authPlatform: string },
 *   workflow: {
 *     auth: [{
 *       flowType: string,
 *       auth: string,
 *       authPlatform: string
 *     }]
 *   }
 * }
 * @type {Function}
 */
export const updateSearchAuthorityForUsers = defineApi((config, data) => {
  config.data = data
  http.post('/search_authority/update', config)
})

export const getUserSearchAuthorities = defineApi((config, userId) => {
  config.params = { userId: userId }
  http.get('/search_authority/by_user', config)
})
