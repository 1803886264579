<!--
  在报表模版中绑定广告平台的广告账户，用于获取数据做报表。
  打开面板会出现左右两个面板，
  左面板又分两个面板：
    外面板：用于登录广告平台进行OAuth2授权，以及显示历史已授权的账号。
    内面板：点击某个已授权账号，进入内面板，显示这个账号下被管理的所有广告账户，点击添加按钮可将账户绑定到报表模版中（放到右边的面板）
  右面板：显示已绑定的广告账户。

  用法：
    <xxx ref="panel"></xxx>
    this.$refs.panel.open(platform, adsReportTemplateCustomerId)

  事件：
    bindAccountSizeChanged(adsReportTemplateCustomerId, bindAccountSize)
-->
<template>
  <slide-panel
    :visible.sync="panelVisible"
    :closeable.sync="panelCloseable"
    :width="1000"
    position="top"
    @beforeClose="panelBeforeClose">

    <span slot="title">广告账户绑定</span>

    <div class="container">

      <!-- 左边面板，授权账号和未绑定广告账户面板 -->
      <div class="sub-container left auto-layout flow-col">
        <div class="container-title">授权账号</div>
        <div class="container-body auto-layout-full">

          <div>
            <el-button type="primary" size="large" @click="openAdsAuth" style="width: 100%;">使用新账号登录授权</el-button>
          </div>

          <div style="border-top: solid 1px #f2f2f2; margin-top: 20px; position: relative;">
            <div style="position: absolute; width: 120px; margin-left: -60px; left: 50%; top: -10px; text-align: center; color: #a8a8a8; background-color: #fff;">
              已授权的账号
            </div>
          </div>

          <ul class="list-group" style="margin-top: 30px;">
            <li class="list-group-item hover-item" style="cursor: pointer;" v-for="acc in adsAccountList" :key="acc.id" @click="showLeftInnerSubContainerPanel(acc)">
              <div class="auto-layout">
                <div class="auto-layout-full">
                  <div class="text-primary font-large font-bold">{{acc.username}}</div>
                  <div class="text-muted font-mini">
                    {{acc.name}}（{{acc.uid}}）
                  </div>
                </div>
                <i class="fa fa-chevron-right" style="padding-top: 7px; font-size: 22px; color: #707070;"></i>
              </div>
            </li>
          </ul>

        </div>


        <!-- 某账号下的广告账户 -->
        <transition name="el-zoom-in-top">
        <div class="sub-container inner auto-layout flow-col" v-show="leftInnerSubContainer.visible">
          <div class="container-title">
            <span style="cursor: pointer;" @click="leftInnerSubContainer.visible = false">
              <i class="fa fa-chevron-left"></i>
              {{leftInnerSubContainer.title}}
            </span>
            <div style="float: right; width: 150px;">
              <el-input placeholder="输入关键字搜索" size="mini" v-model="leftInnerSubContainer.searchKeyword" @keyup.enter.native="filterLeftInnerSubContainerAccounts"></el-input>
            </div>
            <div style="float: right; margin-right: 5px;">
              <el-tooltip effect="dark" content="只看存活的账户" placement="bottom-start">
                <el-switch v-model="leftInnerSubContainer.onlyShowActiveAdAccount" active-text="" inactive-text=""></el-switch>
              </el-tooltip>
            </div>
          </div>

          <div class="container-body auto-layout-full">
            <div v-show="leftInnerSubContainer.loading" class="loading-spinner">
              <i class="el-icon-loading"></i>
            </div>
            <div class="text-muted font-mini content-center" v-if="!leftInnerSubContainer.loading && leftInnerSubContainer.accounts.length === 0">未查到数据</div>
            <ul class="list-group" v-show="!leftInnerSubContainer.loading">
              <ad-account-item
                v-for="acc in leftInnerSubContainer.accounts"
                :key="acc.accountId"
                :acc="acc"
                :adding="acc.adding"
              >
              </ad-account-item>
            </ul>
          </div>
          <div style="padding: 10px;">
            <el-button
              type="primary"
              style="width: 100%;"
              :loading="leftInnerSubContainer.binding"
              @click="handleBindAdAccount"
            >
              绑定选中的账户
            </el-button>
          </div>
        </div>
        </transition>

      </div>

      <!-- 右边面板，已绑定的广告账户 -->
      <div class="sub-container right auto-layout flow-col">
        <div class="container-title">
          已绑定的广告账户
          <div style="float: right; width: 150px;">
            <el-input placeholder="输入关键字搜索" size="mini" v-model="rightSubContainer.searchKeyword" @keyup.enter.native="filterRightSubContainerAccounts()"></el-input>
          </div>
        </div>

        <div class="container-body auto-layout-full">
          <div v-show="rightSubContainer.loading" class="loading-spinner">
            <i class="el-icon-loading"></i>
          </div>

          <div v-show="!rightSubContainer.loading && rightSubContainer.bindAccounts.length === 0" class="text-muted font-mini content-center">
            未绑定任何账户
          </div>

          <ul class="list-group" v-show="!rightSubContainer.loading">
            <li class="list-group-item hover-item" v-for="acc in rightSubContainer.bindAccounts" :key="acc.id">
              <div class="auto-layout">
                <div class="auto-layout-full">
                  <div class="font-normal">{{acc.name}}（{{acc.adId}}）</div>
                  <div class="text-muted font-mini">{{acc.fullName}}</div>
                </div>
                <div style="width: 30px;">
                  <el-button type="danger" icon="el-icon-delete" :loading="rightSubContainer.deleting" @click="deleteCustomerAd(acc)"></el-button>
                  <el-switch
                    @change="enableCustomerAd(acc)"
                    v-model="acc.downloadEnabled"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66">
                  </el-switch>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </slide-panel>
</template>

<script>
  import api, {
    API_ADS_REPORT_AUTHENTICATED_ACCOUNTS,
    API_ADS_REPORT_AUTHENTICATED_ACCOUNT_AD_ACCOUNTS,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_ADS,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_ADD,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_DELETE,
    API_ADS_REPORT_ADS_ACCOUNT_BIND,
    API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_SET_ENABLED
  } from '../../../config/urls'
  import kit from '../../../tools/kit'
  import AdsAuth from '../../../components/ads-auth'
  import AdAccountItem from './ad-account-item'

  export default {
    components: { AdAccountItem },
    data () {
      return {
        adsAccountList: [], // 已登录授权的账号
        // 被adsAccount管理的广告账户
        leftInnerSubContainer: {
          adsAccount: null, // 从父面板进入子面板时，保存进入的是哪个账号
          accountsResp: [], // 广告平台的广告账户
          accounts: [], // 这里是accountsResp的子集，当用户输入了筛选条件后，就会从accountsResp获取相应的数据放到这里
          visible: false,
          title: '',
          loading: false,
          binding: false,
          onlyShowActiveAdAccount: true,
          searchKeyword: ''
        },
        // 已绑定的广告账户
        rightSubContainer: {
          bindAccountsResp: [], // 已绑定的广告账户
          bindAccounts: [], // 这里是bindAccountsResp的子集，当用户输入了筛选条件后，就会从bindAccountsResp获取相应的数据放到这里
          searchKeyword: '',
          loading: false,
          deleting: false
        },
        panelVisible: false,
        panelCloseable: true
      }
    },
    watch: {
      'leftInnerSubContainer.visible' (val) {
        if (val === false) {
          this.leftInnerSubContainer.accountsResp = []
          this.leftInnerSubContainer.accounts = []
          this.leftInnerSubContainer.adsAccount = null
          this.leftInnerSubContainer.loadCancel && this.leftInnerSubContainer.loadCancel()
        }
      },
      'leftInnerSubContainer.onlyShowActiveAdAccount' () {
        this.filterLeftInnerSubContainerAccounts()
      },
      panelVisible () {
        if (this.panelVisible === false) {
          this.leftInnerSubContainer.visible = false
        }
      },
      'rightSubContainer.bindAccountsResp' () {
        this.$emit('bindAccountSizeChanged', this.adsReportTemplateCustomerId, this.rightSubContainer.bindAccountsResp.length)
      }
    },
    computed: {
    },
    methods: {
      open (platform, adsReportTemplateCustomerId) {
        this.platform = platform
        this.adsReportTemplateCustomerId = adsReportTemplateCustomerId
        this.panelVisible = true
        this.$http.get(api(API_ADS_REPORT_AUTHENTICATED_ACCOUNTS, { platform: platform }), {
          success: resp => {
            this.adsAccountList = resp
          }
        })
        this.rightSubContainer.loading = true
        this.$http.load(api(API_ADS_REPORT_TEMPLATE_CUSTOMER_ADS, { customerId: this.adsReportTemplateCustomerId }), {
          complete: () => (this.rightSubContainer.loading = false),
          success: resp => {
            this.rightSubContainer.bindAccountsResp = resp
            this.filterRightSubContainerAccounts()
          }
        })
      },
      showLeftInnerSubContainerPanel (adsAccount) {
        this.leftInnerSubContainer.adsAccount = adsAccount
        this.leftInnerSubContainer.visible = true
        this.leftInnerSubContainer.title = adsAccount.name
        let urlParams = {
          platform: this.platform,
          id: adsAccount.id
        }
        this.leftInnerSubContainer.loading = true
        this.leftInnerSubContainer.loadCancel = this.$http.load(api(API_ADS_REPORT_AUTHENTICATED_ACCOUNT_AD_ACCOUNTS, urlParams), {
          params: {
            adsReportTemplateCustomerId: this.adsReportTemplateCustomerId
          },
          complete: () => (this.leftInnerSubContainer.loading = false),
          success: resp => {
            let accList = []
            let initModel = model => {
              model.added = false
              model.adding = false
              return model
            }
            for (let a of resp) {
              a = initModel(a)
              if (a.children.length > 0) {
                let children = a.children
                a.childrenCount = children.length
                delete a.children
                accList.push(a)
                for (let child of children) {
                  child.parent = a
                  accList.push(initModel(child))
                }
              } else {
                a.childrenCount = 0
                accList.push(a)
              }
            }
            this.leftInnerSubContainer.accountsResp = accList
            this.filterLeftInnerSubContainerAccounts()
          }
        })
      },
      filterLeftInnerSubContainerAccounts () {
        let key = this.leftInnerSubContainer.searchKeyword
        let onlyActive = this.leftInnerSubContainer.onlyShowActiveAdAccount
        let arr = []
        this.leftInnerSubContainer.accounts = []
        for (let acc of this.leftInnerSubContainer.accountsResp) {
          let activeFlag = (onlyActive && acc.active) || !onlyActive
          let keyFlag = false
          if (key === null || key === undefined || key.length === 0) {
            keyFlag = true
          } else {
            keyFlag = key === acc.accountId || acc.name.indexOf(key) !== -1 || acc.fullName.indexOf(key) !== -1
          }
          if (activeFlag & keyFlag) {
            arr.push(acc)
          }
        }
        this.leftInnerSubContainer.accounts = arr
      },
      filterRightSubContainerAccounts () {
        let key = this.rightSubContainer.searchKeyword
        let arr = []
        this.rightSubContainer.bindAccounts = []
        for (let acc of this.rightSubContainer.bindAccountsResp) {
          let keyFlag = false
          if (key === null || key === undefined || key.length === 0) {
            keyFlag = true
          } else {
            keyFlag = key === acc.adId || acc.name.indexOf(key) !== -1 || acc.fullName.indexOf(key) !== -1
          }
          if (keyFlag) {
            arr.push(acc)
          }
        }
        this.rightSubContainer.bindAccounts = arr
      },
      handleBindAdAccount () {
        let checkedList = this.leftInnerSubContainer.accounts.filter(a => a.checked && !a.added)
        if (checkedList.length === 0) {
          return this.$message.warning('请选择要绑定的广告账户。')
        }
        this.$confirm('绑定账户后，系统会在后台为账户下载所有报表数据到本地，需要过一会儿才能看到账户的数据（每个账户约20秒），是否确认绑定账户？', '确认', {
          confirmButtonText: '立即绑定',
          cancelButtonText: '取消'
        })
          .then(() => {
            this.addCustomerAd(checkedList)
          })
          .catch(() => {})
      },
      addCustomerAd (adAccountListFromAdPlatform) {
        let arr = []
        adAccountListFromAdPlatform.forEach(acc => {
          arr.push({
            adId: acc.accountId,
            name: acc.name,
            fullName: acc.fullName,
            adsReportTemplateCustomerId: this.adsReportTemplateCustomerId,
            adsAccountId: this.leftInnerSubContainer.adsAccount.id
          })
        })
        this.leftInnerSubContainer.binding = true
        this.$http.post(api(API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_ADD), {
          dataType: 'form',
          data: {
            json: JSON.stringify(arr)
          },
          complete: () => (this.leftInnerSubContainer.binding = false),
          success: resp => {
            let success = resp['success']
            let fail = resp['fail']

            let map = {}

            success.forEach(acc => {
              map[acc.adId] = true
              kit.arr.insert(this.rightSubContainer.bindAccounts, 0, acc)
              kit.arr.insert(this.rightSubContainer.bindAccountsResp, 0, acc)
            })
            adAccountListFromAdPlatform.filter(a => map[a.accountId] === true).forEach(a => {
              a.added = true
              a.checked = false
            })

            let messageContent = `<p>成功绑定${success.length}个账户，失败${fail.length}个。</p>`
            for (let failItem of fail) {
              messageContent += `<div style="margin-top: 5px; font-size: 12px; color: #7d7d7d;"><i class="el-icon-warning" style="color: #c01b0a; margin-right: 5px;"></i>${failItem}</div>`
            }
            this.$alert(messageContent, '绑定结果', {
              dangerouslyUseHTMLString: true
            })
          }
        })
      },
      deleteCustomerAd (bindAccount) {
        this.deleting = true
        this.$http.del(api(API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_DELETE, { id: bindAccount.id }), {
          complete: () => (this.deleting = false),
          success: () => {
            kit.arr.removeItem(this.rightSubContainer.bindAccounts, bindAccount)
            kit.arr.removeItem(this.rightSubContainer.bindAccountsResp, bindAccount)
            // 恢复左边广告账户的"添加"状态，使其可再次添加
            let found = kit.arr.find(this.leftInnerSubContainer.accountsResp, item => item.accountId === bindAccount.adId)
            if (found) {
              found.added = false
              found.checked = false
            }
          }
        })
      },
      enableCustomerAd (bindAccount) {
        this.$pageLoading(true)
        this.$http.put(api(API_ADS_REPORT_TEMPLATE_CUSTOMER_AD_SET_ENABLED, {id: bindAccount.id, enabled: bindAccount.downloadEnabled}), {
          context: this,
          success: newAccount => Object.assign(bindAccount, newAccount),
          complete: success => !success && (bindAccount.downloadEnabled = bindAccount.downloadEnabled ? 0 : 1)
        })
      },
      panelBeforeClose () {
        this.adsAccountList = []
      },
      openAdsAuth () {
        AdsAuth.open(this.platform, (success, acc) => {
          if (success) {
            this.$http.post(api(API_ADS_REPORT_ADS_ACCOUNT_BIND), {
              data: acc,
              success: (resp) => {
                kit.arr.pushOrReplace(this.adsAccountList, resp)
                this.$message.success('绑定成功。')
              }
            })
          } else {
            this.$alert(acc, '错误', { type: 'error' })
          }
        })
      }
    },
    destroy () {
      AdsAuth.close()
    }
  }
</script>

<style lang="less" scoped>
  @panelWidth: 1000px;
  @containerPadding: 20px;

  .container {
    position: absolute;
    left: @containerPadding;
    top: @containerPadding;
    right: @containerPadding;
    bottom: @containerPadding;
  }

  .sub-container {
    @line: solid 1px #ddd;
    position: absolute;
    top: 0;
    bottom: 0;
    border: @line;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    &.inner {
      left: 0;
      right: 0;
      border: none;
    }
    &.left {
      left: 0;
      width: @panelWidth / 2 - @containerPadding - 10;
    }
    &.right {
      right: 0;
      width: @panelWidth / 2 - @containerPadding - 10;
    }
    .container-title {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      font-size: 13px;
      color: #cf3f28;
      font-weight: bold;
      background-color: #f9f9f9;
      border-bottom: @line;
    }
    .container-body {
      padding: 20px;
      overflow: auto;
    }
  }

  .loading-spinner {
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
  }
</style>
